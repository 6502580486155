import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import configurationApi from '../../../network/api/configurationApi';
import {
  analyticsParams, DASHBOARD_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  SYMPTOM_CHECKER_RESULTS_PAGE_ROUTE,
} from '../../../const';
import { currentLanguage } from '../../../services/languageService';
import { notifyError } from '../../../network/notification';
import ModalSenselyExitPage from '../../../components/modal/ModalSenselyExitPage';
import { interviewType } from '../../../types/interviewType';
import { SenselyConfigType, SenselyDescriptionType, SenselyResponse } from '../SenselyTypes';
import {
  updateProcessData,
  updateServiceInfo,
} from '../../../services/analyticsService';
import ContentHeader from '../../../components/layout/ContentHeader';
import doctors from '../../../img/doctors.png';
import ModalBase from '../../../components/modal/ModalBase';
import { loadSenselyScript } from '../../../services/senselyService';

const actions = {
  PUSH: 'PUSH',
};

type Props = {
  history: any,
  location: any,
};


type State = {
  description: any,
  exitPopin: boolean,
  exitLocation: string,
  didLoad: boolean,
  acknowledgment: boolean,
};

class SenselyPage extends React.Component<Props, State> {
  state = {
    description: null,
    exitPopin: false,
    exitLocation: '',
    didLoad: false,
    acknowledgment: false,
  };

  initSenselyWidget = () => {
    configurationApi.fetchSenselyPageConfig(currentLanguage().code)
      .then((res) => res.json())
      .then((senselyConfig: SenselyConfigType) => {
        loadSenselyScript().then(() => {
          // @ts-ignore
          SenselyWidget.init({
            token: senselyConfig.token,
            procedureId: senselyConfig.procedureId,
            region: 'germany',
            anonymousMode: senselyConfig.anonymousMode,
            lang: senselyConfig.lang,
            conversationData: {
              userInfo: {
                dob: senselyConfig.dob,
                gender: senselyConfig.gender,
                organization_user_id: senselyConfig.organizationUserId,
              },
            },
          });
        })
        .catch(notifyError);
      }).then(() => {
        this.setState({
          didLoad: true,
        });
        const senselyWidget = document.getElementById('sensely-widget');
        if (senselyWidget) {
          this.loadEndEventListener(senselyWidget);
        } else {
          console.log('Sensely widget was not mounted, whereas Sensely indicated it was loaded');
        }
      })
      .catch(notifyError);
  };

  loadEndEventListener = (senselyWidget: HTMLElement) => {
    // ajout du listener pour ecouter l'event de fin de processus Sensely
    senselyWidget
      .addEventListener('onSenselyWidgetResults', (event: SenselyResponse & Event) => {
        const results = event.detail.results.flat();
        const descriptionResult = results.filter((r) => r.resultType === 'WEB_DONE_COMPLETE')[0];
        const interview = new Array<interviewType>();
        event.detail.conversationOutput.forEach((conversation) => {
          interview.push({ value: conversation.output, author: 'Sensely' });
          interview.push({ value: conversation.input[0], author: 'Patient' });
        });
        const senselyDescription: SenselyDescriptionType = JSON.parse(descriptionResult.description);
        this.setState({
          description: {
            interview,
            recommendationUrl: senselyDescription.mayo_recommendation_url,
            recommendationTitle: senselyDescription.rec_title,
            urgency: senselyDescription.urgency,
            assetId: senselyDescription.asset_id,
            timeframe: senselyDescription.timeframe,
          },
        });

        this.fetchSenselyConfigByUrgency(senselyDescription.urgency, senselyDescription.timeframe);
      });
  };


  componentDidMount(): void {
    // set sensely widget to 100% to have large view on computer
    document.body.style.setProperty('--SENSELY_WIDGET_WIDTH', '100%');
    if (this.state.description) {
      this.fetchSenselyConfigByUrgency(this.state.description.urgency, this.state.description.timeframe);
    } else {
      this.initSenselyWidget();
    }
  }

  componentWillUnmount(): void {
    localStorage.removeItem('SENSELY_RESULT');
    const existingScript = document.getElementById('senselyScript');
    if (existingScript) {
      document.body.removeChild(existingScript);
    }
  }

  fetchSenselyConfigByUrgency = (urgencyCode, timeframe) => {
    configurationApi
      .fetchSenselyConfigByUrgency(urgencyCode, timeframe)
      .then((res) => res.json())
      .then((senselyUrgencyConfiguration) => {
        const options = analyticsParams.service.categories.map(
          (senselyOption) => (senselyUrgencyConfiguration.autorisations.includes(senselyOption.senselyName) ? senselyOption.analyticsName : ''),
        ).join('|');
        updateProcessData({
          processName: analyticsParams.processName.SYMPTOM_CHECKER,
          status: analyticsParams.status.COMPLETED,
          category: this.state.description.urgency,
          completeTime: moment().toISOString(),
        });
        updateServiceInfo({
          categories: options,
        });
        this.props.history.push(
          SYMPTOM_CHECKER_RESULTS_PAGE_ROUTE,
          { senselyUrgencyConfiguration, description: this.state.description },
        );
        updateProcessData({}, true);
      })
      .catch(notifyError);
  };

  render() {
    return (
      <div className="sensely-container">
        <ContentHeader
          pageTitle={I18n.t('services.SYMPTOM_CHECKER')}
          image={doctors}
        />
        <Prompt
          when={!this.state.exitPopin}
          message={(location, action) => {
            this.setState({ exitPopin: true, exitLocation: location.pathname });
            if (((
              location.pathname === SYMPTOM_CHECKER_RESULTS_PAGE_ROUTE
                || location.pathname === LOGOUT_PAGE_ROUTE) && action === actions.PUSH) || (!this.state.acknowledgment && action === actions.PUSH)) {
              return true;
            }
            return false;
          }}
        />
        <ModalSenselyExitPage
          isOpen={this.state.exitPopin}
          toggle={() => {
            this.setState({ exitPopin: !this.state.exitPopin });
          }}
          action={() => {
            this.setState({ exitPopin: false });
            updateProcessData({
              status: analyticsParams.status.COMPLETED,
              completeTime: moment().toISOString(),
            });
            this.props.history.push(this.state.exitLocation);
            updateProcessData({}, true);
          }}
        />
        { !this.state.acknowledgment && (
        <ModalBase
          isOpen
          toggle={() => {
            this.props.history.push(DASHBOARD_PAGE_ROUTE);
          }}
          title={I18n.t('sensely.SENSELY_TITLE')}
          action={() => {
            this.setState({ acknowledgment: true });
            window.scrollTo(0, 0);
          }}
          actionButton={I18n.t('welcomePage.CONTINUE')}
          secondaryActionButton={I18n.t('actions.BACK')}
        >
          <div className="classic-text light">{I18n.t('sensely.WARNING_TEXT')}</div>
        </ModalBase>
        )}
        <div className="page-container">
          {!this.state.didLoad && (
          <div className="sensely-loader">
            <div className="sensely-loader-text">{I18n.t('sensely.LOADER')}</div>
          </div>
          )}
          <div id="sensely-widget" />
        </div>
      </div>
    );
  }
}

export default withRouter(SenselyPage);
