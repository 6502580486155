import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import Badge from '../../components/badge/Badge';
import { BadgeType } from '../../types/BadgeTypes';
import { CallBackResult } from '../callBack/callBackDetails/CallBackDetailsType';
import { IS_USER_ALLOWED_TO_GO_BACK } from '../../state/login/loginReducer';
import { store as storeFunction } from '../../network/reduce';
import { AppointmentInfos, CallbackInfos } from '../../types/appointmentType';
import historyApi from '../../network/api/historyApi';
import { notifyError } from '../../network/notification';
import { HISTORY_PAGE_ROUTE, HistoryType } from '../../const';
import { currentLanguage } from '../../services/languageService';
import { deviceIsMobile } from '../../services/displayService';
import { ConsultationTileInfo } from '../../components/tile/history/ConsultationTileType';
import Loader from '../../components/layout/Loader';
import AppointmentReleaseTile from '../../components/tile/history/custom/AppointmentReleaseTile';
import CallbackTile from '../../components/tile/history/custom/CallbackTile';
import ConsultationTile from '../../components/tile/history/custom/ConsultationTile';
import Button from '../../components/buttons/Button';
import ButtonType from '../../types/ButtonType';
import EventCategories from '../../components/history/EventCategories';
import HistoryRouter from '../../components/history/HistoryRouter';
import ModalIcs from '../../components/modal/ModalIcs';
import { createIcsFile } from '../../services/icsService/icsService';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import NoEventsTile from '../../components/tile/NoEventsTile';

type Props = {
  history: any,
  translations: Object,
  location: {
    state: {
      callBackResult: CallBackResult,
    }
  }
  isUserAllowedToGoBack: boolean,
  dispatch: Function,
};

type ModalStateType = {
  isOpen: boolean,
  id: string,
  type: string,
};

type SecondaryActionType = {
  title: string,
  action: Function,
};

type MetaDataType = {
  pagination: {
    currentPage: number,
    pageCount: number,
  }
};

type ActiveHistoryType = AppointmentInfos | CallbackInfos;

type PastHistoryDataType = {
  items: ConsultationTileInfo[],
  metadata: MetaDataType,
};

function History({
  history,
  translations,
  location,
  isUserAllowedToGoBack,
  dispatch,
}: Props) {
  const [modalIcs, setModalIcs] = useState<boolean>(!!location?.state?.callBackResult);
  const [activeHistory, setActiveHistory] = useState<ActiveHistoryType[]>([]);
  const [activeHistoryFiltered, setActiveHistoryFiltered] = useState<ActiveHistoryType[]>([]);
  const [pastHistory, setPastHistory] = useState<ConsultationTileInfo[]>([]);
  const [metaData, setMetaData] = useState<MetaDataType>(undefined);
  const [selectedCategory, setSelectedCategory] = useState<string>(HistoryType.ALL);
  const [isSearchingPast, setIsSearchingPast] = useState<boolean>(true);
  const [isSearchingActive, setIsSearchingActive] = useState<boolean>(true);
  const [isFilteringPastEvents, setIsFilteringPastEvents] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const [modalState, setModalState] = useState<ModalStateType>({
    id: undefined,
    isOpen: false,
    type: undefined,
  });

  const getDynamicTranslations = () => {
    const translationObject = translations[currentLanguage().code];
    return {
      consultationTitle: translationObject.tile.history.CONSULTATION,
      consultationMainTitle: translationObject.tile.history.CONSULTATION_TITLE,
      callbackTitle: translationObject.tile.history.CALLBACK_REQUEST,
      callbackMainTitle: translationObject.tile.history.CALLBACK_REQUEST_TITLE,
      registrationTitle: translationObject.tile.history.REGISTRATION,
      registration: translationObject.registration.urgencies,
      appointmentReleaseTitle: translationObject.tile.history.APPOINTMENT_RELEASE,
      appointmentRelease: translationObject.doctorSpecialities,
      announceAppointmentTitle: translationObject.tile.history.ANNOUNCE_APPOITMENT,
    };
  };

  const globalSearch = (searchData: { search?: string }) => {
    setIsSearchingActive(true);
    setIsSearchingPast(true);
    setPage(1);

    historyApi.fetch(searchData.search, getDynamicTranslations())
      .then((response) => response.json())
      .then((activeHistoryData: ActiveHistoryType[]) => {
        setActiveHistory(activeHistoryData);
        setActiveHistoryFiltered(activeHistoryData);
        setIsSearchingActive(false);
      })
      .catch(notifyError);

    historyApi.fetchPast(
      HistoryType.CONSULTATION,
      null,
      searchData.search,
      page,
      getDynamicTranslations(),
    )
      .then((response) => response.json())
      .then((pastHistoryData: PastHistoryDataType) => {
        setPastHistory(pastHistoryData.items);
        setMetaData(pastHistoryData.metadata);
        setIsSearchingPast(false);
      })
      .catch(notifyError);
  };

  const fetchPastEvents = () => {
    setPage(page + 1);
    historyApi.fetchPast(null, null, null, page + 1, getDynamicTranslations())
      .then((response) => response.json())
      .then((pastHistoryData: PastHistoryDataType) => {
        setPastHistory(pastHistoryData.items);
        setMetaData(pastHistoryData.metadata);
        setIsFilteringPastEvents(false);
      })
      .catch(notifyError);
  };

  const openModalState = (id: string, type: string) => {
    setModalState({
      isOpen: true,
      id,
      type,
    });

    if (deviceIsMobile() && !isUserAllowedToGoBack) {
      dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, true));
    }
  };

  const handleModalState = () => {
    setModalState({
      ...modalState,
      isOpen: !modalState?.isOpen,
    });

    if (deviceIsMobile() && !isUserAllowedToGoBack) {
      dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, true));
    }
  };

  const handleFilterSelection = (category: string) => {
    if (category === HistoryType.ALL) {
      setActiveHistoryFiltered(activeHistory);
    } else {
      setActiveHistoryFiltered(activeHistory
        .filter((event: ActiveHistoryType) => {
          if (category === HistoryType.APPOINTMENT_RELEASE) {
            return [
              HistoryType.APPOINTMENT_RELEASE,
              HistoryType.LATE_REGISTRATION,
              HistoryType.PAST_APPOINTMENT,
            ].includes(event.type);
          }

          return event.type === category;
        }));
    }
    setSelectedCategory(category);
  };

  useOnComponentMounted(() => {
    dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, undefined));
    globalSearch({});
  });

  return (
    <div className="history-page">
      {
        (isUserAllowedToGoBack === false ? false : modalState.isOpen)
        && modalState.id
        && modalState.type
        && (
          <HistoryRouter
            id={modalState.id}
            type={modalState.type}
            handleModalState={handleModalState}
          />
        )
      }
      <ModalIcs
        isOpen={modalIcs}
        toggle={() => {
          setModalIcs(!modalIcs);
          history.replace(HISTORY_PAGE_ROUTE);
        }}
        backdrop
        edited={location?.state?.callBackResult ? location.state.callBackResult.edition : false}
        action={() => {
          if (location.state.callBackResult) {
            createIcsFile(location.state.callBackResult.startDate,
              location.state.callBackResult.endDate,
              location.state.callBackResult.uploadPicture);

            setModalIcs(!modalIcs);
            history.replace(HISTORY_PAGE_ROUTE);
          }
        }}
      />

      <div className="history-page-header">
        <h1 className="history-page-title">
          {I18n.t('history.mainTitle')}
        </h1>

        <div className="history-page-subtitle-container">
          <h2 className="history-page-subtitle">
            {I18n.t('history.title.active')}
          </h2>
          <Badge
            text={I18n.t(
              activeHistoryFiltered.length === 1 ? 'history.title.event' : 'history.title.events',
              { count: activeHistoryFiltered.length },
            )}
            type={BadgeType.PRIMARY}
          />
        </div>
      </div>

      {isSearchingActive
        ? <Loader />
        : (
          <>
            {activeHistory.length > 0 && (
              <EventCategories
                handleSelect={handleFilterSelection}
                selected={selectedCategory}
              />
            )}

            {activeHistoryFiltered.length > 0
              ? (
                <div id="active" className="history-events-grid">
                  {activeHistoryFiltered.map((infos) => {
                    if (infos.type === HistoryType.APPOINTMENT_RELEASE
                      || infos.type === HistoryType.LATE_REGISTRATION
                      || infos.type === HistoryType.PAST_APPOINTMENT) {
                      return (
                        <AppointmentReleaseTile
                          key={infos.prmId}
                          infos={infos as AppointmentInfos}
                          setModalStatus={openModalState}
                        />
                      );
                    }

                    if (infos.type === HistoryType.CALLBACK_REQUEST) {
                      return (
                        <CallbackTile
                          key={infos.prmId}
                          infos={infos as CallbackInfos}
                          setModalStatus={openModalState}
                        />
                      );
                    }

                    return <React.Fragment key={infos.prmId} />;
                  })}
                </div>
              )
              : <NoEventsTile />}
          </>
        )}

      <h2 className="history-page-subtitle">
        {I18n.t('history.title.pastEvents')}
      </h2>

      {isSearchingPast
        ? <Loader />
        : (
          <div id="past" className="history-events-grid">
            {pastHistory.length > 0
              ? pastHistory.map((infos) => {
                if (infos.type === HistoryType.CONSULTATION) {
                  return (
                    <ConsultationTile
                      key={infos.prmId}
                      infos={infos}
                      onClick={openModalState}
                    />
                  );
                }
                return <React.Fragment key={infos.prmId} />;
              }) : <div className="no-history"><h3>{I18n.t('history.NO_EVENTS')}</h3></div>}
          </div>
        )}

      {isFilteringPastEvents && <Loader />}
      {(metaData?.pagination.pageCount > metaData?.pagination.currentPage)
        && (
          <div className="button-group">
            <Button
              htmlType="button"
              type={ButtonType.TEXT}
              onClick={() => {
                setIsFilteringPastEvents(true);
                fetchPastEvents();
              }}
              disabled={isFilteringPastEvents}
              text={I18n.t('history.SEE_MORE')}
            />
          </div>
        )}
    </div>
  );
}

export default withRouter(connect((state) => ({
  translations: state.i18n.translations,
  isUserAllowedToGoBack: state.login.isUserAllowedToGoBack,
}))(History));
