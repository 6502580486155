import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from './ModalBase';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  edited: boolean
  action: any,
};

class ModalIcs extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    size: '',
  };

  render() {
    return (
      <ModalBase
        {...this.props}
        toggle={this.props.toggle}
        title={I18n.t('modal.ICS')}
        action={this.props.action}
        actionButton={I18n.t('modal.ICS_YES')}
        secondaryActionButton={I18n.t('modal.ICS_NO')}
      >
        {this.props.edited
          ? (
            <div
              className="classic-text"
            >
              {(`${I18n.t('modal.ICS_CONTENT')} ${I18n.t('callback.calendarEvent.ICS_EDITION')}`)}
            </div>
          )
          : <div className="classic-text">{I18n.t('modal.ICS_CONTENT')}</div>}
      </ModalBase>
    );
  }
}

export default withRouter(ModalIcs);
