import React from 'react';
import { deviceIsMobile } from '../../services/displayService';

type Props = {
  pageTitle: string,
  image?: string,
};

function ContentHeader({ pageTitle, image }: Props) {
  return (
    <div className="content-header">
      <h1 className="page-title">
        { pageTitle }
      </h1>
      {image && !deviceIsMobile() && (
        <img className="header-img" src={image} alt="header-img" />
      )}
    </div>
  );
}

export default ContentHeader;
