import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { caseStatus } from '../../../const';
import senselyApi from '../../../network/api/senselyApi';
import { notifyError, notifySuccess } from '../../../network/notification';
import ModalBase from '../ModalBase';
import { deviceIsMobile } from '../../../services/displayService';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  senselyDescription: any,
  afterChoice: any,
  madeACall: boolean,
  emergencyPhoneNumber?: string,
};

class ModalSenselyEndProcess extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    size: '',
  };

  redirectToDashboard() {
    this.props.afterChoice();
    this.props.toggle();
  }

  saveAssignment() {
    senselyApi
      .saveSenselyResults({
        senselyDescription: {
          interview: this.props.senselyDescription.interview,
          recommendationTitle: this.props.senselyDescription.recommendationTitle,
          recommendationUrl: this.props.senselyDescription.recommendationUrl,
        },
        consultationStatus: caseStatus.INACTIVE,
      })
      .then((response) => {
        notifySuccess(I18n.t('sensely.RESULTS_SAVED'), response);
        this.props.afterChoice();
        this.props.toggle();
      })
      .catch(notifyError);
  }

  render() {
    return (
      <ModalBase
        {...this.props}
        toggle={this.props.toggle}
        title={I18n.t('modal.senselySaved.TITLE')}
        action={() => this.saveAssignment()}
        secondaryAction={() => this.redirectToDashboard()}
        actionButton={I18n.t('actions.YES')}
        secondaryActionButton={I18n.t('actions.NO')}
      >
        {!deviceIsMobile() && this.props.emergencyPhoneNumber
        && (
        <div className="classic-text">
          {`${I18n.t('contact.EMERGENCY_HINT', { tel: this.props.emergencyPhoneNumber })}`}
          <div className="separator" />
        </div>
        )}
        <div className="classic-text">{I18n.t('modal.senselyExit.SAVE_ASSESSMENT')}</div>
      </ModalBase>
    );
  }
}

export default withRouter(ModalSenselyEndProcess);
