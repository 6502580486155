export enum SignupInsuranceProduct {
  YES = 'YES',
  NO = 'NO'
}

export enum SignupGender {
  MALE = 'M',
  FEMALE = 'F'
}

export enum SignupLanguage {
  DEUTSCH = 'DEUTSCH',
  FRENCH = 'FRENCH',
  ITALIAN = 'ITALIAN',
  ENGLISH = 'ENGLISH'
}

export type SignupDetailsType = {
  partner: string,
  contractIdPatient: string,
  contractIdUser: string,
  hasInsuranceProduct: boolean,
  firstName: string,
  lastName: string,
  dateOfBirth: Date,
  gender: SignupGender,
  street: string,
  zipcode: number,
  city: string,
  firstLanguage: SignupLanguage,
  secondLanguage: SignupLanguage,
  phoneNumber: number,
  mobilePhoneNumber: number
}
