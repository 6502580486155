import RestClientAuthenticated from '../clients/restClientAuthenticated';

const historyApi = {
  fetch: (searchText: string, translations: Object) => new RestClientAuthenticated(`/history?search=${searchText || ''}`, 'POST').jsonBody(translations).execute(),
  fetchPast: (type: string,
    orderBy: string,
    search: string,
    page: number,
    translations: Object) => new RestClientAuthenticated(
    `/history/past?type=${type || ''}&orderBy=${orderBy || ''}&search=${search || ''}&page=${page || ''}`,
    'POST',
  ).jsonBody(translations).execute(),
};

export default historyApi;
