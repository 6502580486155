/**
 * Handles session token renewal
 */
export class SessionRefreshManager {
  private jsIntervalForRefresh: ReturnType<typeof setInterval>;
  private sessionToken: string;
  private refreshIntervalInMillis: number;

  /**
   * @param refreshTokenFunction The function that will do the HTTP call to get the new session token.
   * This function implementation may also propagate the session information to Redux.
   */
  constructor(readonly refreshTokenFunction: (currentToken: string) => Promise<string | void>) {
  }

  startService(initialSessionToken: string, refreshIntervalInMillis: number) {
    if (this.jsIntervalForRefresh) {
      // do not start the service if it is already started
      return;
    }

    this.sessionToken = initialSessionToken;
    this.refreshIntervalInMillis = refreshIntervalInMillis;
    this.jsIntervalForRefresh = setInterval(() => {
      this
        .refreshTokenFunction(this.sessionToken)
        .then((newSessionToken) => {
          this.sessionToken = String(newSessionToken);
        })
    }, refreshIntervalInMillis);
  }

  stopService() {
    clearInterval(this.jsIntervalForRefresh);
    this.jsIntervalForRefresh = null;
  }

}
