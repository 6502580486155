import moment from 'moment';
import React, { useState } from 'react';
import { Field, FieldInputProps, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from '../../../components/buttons/Button';
import DateInput from '../../../components/form/DateInput';
import SelectInput from '../../../components/form/SelectInput';
import TextInput from '../../../components/form/TextInput';
import Loader from '../../../components/layout/Loader';
import { currentLanguage } from '../../../services/languageService';
import ButtonType from '../../../types/ButtonType';
import {
  notRequired, required, requiredDate, requiredZipcode, validatePhone,
} from '../../../validator';
import { SignupGender, SignupInsuranceProduct, SignupLanguage } from './SignupFormType';
import signupApi from '../../../network/api/signupApi';
import ModalBase from '../../../components/modal/ModalBase';

type Props = {
  history: any,
  partner: string,
  contractIdPatient: string,
  contractIdUser: string
  backAction: () => void,
};

function SignupForm({
  history, partner, contractIdPatient, contractIdUser, backAction
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isOpenSucessPopin, setIsOpenSuccessPopin] = useState<boolean>(false);
  const [isOpenErrorPopin, setIsOpenErrorPopin] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>();

  const changeInsuranceProduct = (newValue: SignupInsuranceProduct, input: FieldInputProps<any, HTMLElement>) => {
    if (newValue == SignupInsuranceProduct.NO) {
      // WHEN I select “No” in the initial question of the form THEN the system should automatically bring me back to the previous page
      backAction();
    } else {
      input.onChange(newValue);
    }
  };

  const onSubmit = (values) => {
    setIsSubmitDisabled(true);
    setIsLoading(true);
    const scrollElement = document.getElementById('app');

    if (scrollElement) {
      scrollElement.scrollTo(0, 0);
    }

    setIsLoading(true);

    const signupRequestCreation = {
      partner,
      contractIdPatient,
      contractIdUser,
      hasInsuranceProduct: values.insuranceProduct === SignupInsuranceProduct.YES,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth.format('YYYY-MM-DD'),
      gender: values.gender,
      street: values.street,
      zipcode: values.zipcode,
      city: values.city,
      firstLanguage: values.firstLanguage,
      secondLanguage: values.secondLanguage,
      phoneNumber: values.phoneNumber.replace(/\s/g, ''),
      mobilePhoneNumber: values.mobilePhoneNumber?.replace(/\s/g, ''),
    };

    return signupApi
      .createSignupRequest(signupRequestCreation)
      .then((response) => {
        if (response.ok) {
          const url = new URL(response.url);
          setRedirectUrl(url.pathname + url.search);
          setIsOpenSuccessPopin(true);
        } else {
          setIsOpenErrorPopin(true);
        }
      })
      .catch((error) => {
        setIsOpenErrorPopin(true);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
        setIsSubmitDisabled(false);
      });
  };

  if (isLoading) {
    return (<Loader />);
  }

  return (
    <div className="signup-page">
      <ModalBase
        isOpen={isOpenSucessPopin}
        toggle={() => setIsOpenSuccessPopin(!isOpenSucessPopin)}
        title={I18n.t('signup.successModal.title')}
        actionButton={I18n.t('signup.successModal.action')}
        action={() => history.push(redirectUrl)}
      >
        <div className="classic-text">
          {I18n.t('signup.successModal.description')}
        </div>
      </ModalBase>
      <ModalBase
        isOpen={isOpenErrorPopin}
        toggle={() => setIsOpenErrorPopin(!isOpenErrorPopin)}
        title={I18n.t('signup.errorModal.title')}
        actionButton={I18n.t('signup.errorModal.action')}
        action={() => setIsOpenErrorPopin(false)}
      >
        <div className="classic-text">
          {I18n.t('signup.errorModal.description')}
        </div>
      </ModalBase>
      <div className="signup-page-header">
        <h1 className="signup-page-title">
          {I18n.t('signup.TITLE')}
        </h1>
      </div>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form
            className="signup-page"
            onSubmit={handleSubmit}
          >
            <div className="signup-page-group">
              <h2 className="signup-page-subtitle">
                {I18n.t('signup.INSURANCE_PRODUCT')}
              </h2>
              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="insurance-input">
                    {`${I18n.t('signup.INSURANCE_PRODUCT_INPUT')} *`}
                  </label>
                  <Field
                    id="insurance-product-input"
                    name="insuranceProduct"
                    className="signup-page-input"
                    validate={required}
                    required
                  >
                    {(props) => {
                      const { meta, input } = props;
                      return (
                        <>
                          <div className="signup-page-choices">
                            {Object.keys(SignupInsuranceProduct).map((choice: string) => (
                              <div className="choice-group" key={choice}>
                                <div
                                  data-checked={input.value === SignupInsuranceProduct[choice]}
                                  className="outer-input"
                                />
                                <h4 className="choice-label">{I18n.t(`actions.${choice}`)}</h4>
                                <button
                                  type="button"
                                  className="hidden-button"
                                  onClick={() => changeInsuranceProduct(SignupInsuranceProduct[choice], input)}
                                  aria-label={`button to change the callback type to ${choice}`}
                                />
                              </div>
                            ))}
                          </div>
                          {meta.error && meta.touched && (
                            <p className="error-text">{meta.error}</p>
                          )}
                        </>
                      );
                    }}
                  </Field>
                </div>
              </div>
            </div>

            <div className="signup-page-group">
              <h2 className="signup-page-subtitle">
                {I18n.t('signup.PERSONAL_INFORMATION')}
              </h2>
              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="first-name-input">
                    {`${I18n.t('signup.FIRST_NAME_INPUT')} *`}
                  </label>

                  <Field
                    id="first-name-input"
                    name="firstName"
                    className="signup-page-input"
                    validate={required}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                      />
                    )}
                  </Field>
                </div>

                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="last-name-input">
                    {`${I18n.t('signup.LAST_NAME_INPUT')} *`}
                  </label>

                  <Field
                    id="last-name-input"
                    name="lastName"
                    className="signup-page-input"
                    validate={required}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="date-input">
                    {`${I18n.t('signup.DATE_OF_BIRTH_INPUT')} *`}
                  </label>

                  <Field
                    id="date-input"
                    name="dateOfBirth"
                    component={DateInput}
                    locale={moment.locale(currentLanguage().code)}
                    views={['year', 'month', 'date']}
                    openTo="year"
                    dateFormat={I18n.t('date.ONLY_DATE_FORMAT')}
                    className="signup-page-input"
                    validate={requiredDate}
                    required
                  />
                </div>

                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="signup-gender">
                    {`${I18n.t('signup.GENDER')} *`}
                  </label>

                  <Field
                    id="gender-input"
                    name="gender"
                    className="signup-page-input"
                    validate={required}
                    required
                  >
                    {(props) => {
                      const { meta, input } = props;
                      return (
                        <>
                          <div className="signup-page-choices">
                            {Object.keys(SignupGender).map((choice: string) => (
                              <div className="choice-group" key={choice}>
                                <div data-checked={input.value === SignupGender[choice]} className="outer-input" />
                                <h4 className="choice-label">{I18n.t(`signup.GENDER_CHOICES.${SignupGender[choice]}`)}</h4>
                                <button
                                  type="button"
                                  className="hidden-button"
                                  onClick={() => input.onChange(SignupGender[choice])}
                                  aria-label={`button to change the callback type to ${choice}`}
                                />
                              </div>
                            ))}
                          </div>
                          {meta.error && meta.touched && (
                            <p className="error-text">{meta.error}</p>
                          )}
                        </>
                      );
                    }}
                  </Field>
                </div>
              </div>
            </div>

            <div className="signup-page-group">
              <h2 className="signup-page-subtitle">
                {I18n.t('signup.ADDRESS')}
              </h2>
              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="street-input">
                    {`${I18n.t('signup.STREET_INPUT')} *`}
                  </label>

                  <Field
                    id="street-input"
                    name="street"
                    className="signup-page-input"
                    validate={required}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="zipcode-input">
                    {`${I18n.t('signup.ZIP_CODE_INPUT')} *`}
                  </label>

                  <Field
                    id="zipcode-input"
                    name="zipcode"
                    className="signup-page-input"
                    validate={requiredZipcode}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                      />
                    )}
                  </Field>
                </div>
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="city-input">
                    {`${I18n.t('signup.CITY_INPUT')} *`}
                  </label>

                  <Field
                    id="city-input"
                    name="city"
                    className="signup-page-input"
                    validate={required}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>

            <div className="signup-page-group">
              <h2 className="signup-page-subtitle">
                {I18n.t('signup.LANGUAGES')}
              </h2>
              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="first-language-input">
                    {`${I18n.t('signup.FIRST_LANGUAGE_INPUT')} *`}
                  </label>

                  <Field
                    id="first-language-input"
                    name="firstLanguage"
                    className="signup-page-input"
                    component={SelectInput}
                    list={Object.keys(SignupLanguage).map((choice: string) => ({
                      id: choice,
                      label: I18n.t(`signup.LANGUAGE_CHOICES.${choice}`),
                    }))}
                    validate={required}
                    required
                    onChange={(newValue) => {
                      const secondLanguage = form.getFieldState('secondLanguage');
                      if (secondLanguage?.value === newValue) {
                        secondLanguage.change(undefined);
                      }
                    }}
                  />
                </div>
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="second-language-input">
                    {`${I18n.t('signup.SECOND_LANGUAGE_INPUT')}`}
                  </label>

                  <Field
                    id="second-language-input"
                    name="secondLanguage"
                    className="signup-page-input"
                    component={SelectInput}
                    list={Object.keys(SignupLanguage)
                      .filter((choice: string) => choice !== form.getFieldState('firstLanguage')?.value)
                      .map((choice: string) => ({
                        id: choice,
                        label: I18n.t(`signup.LANGUAGE_CHOICES.${choice}`),
                      }))}
                    validate={notRequired}
                  />
                </div>
              </div>
            </div>

            <div className="signup-page-group">
              <h2 className="signup-page-subtitle">
                {I18n.t('signup.CONTACT_INFORMATION')}
              </h2>
              <div className="signup-inputs">
                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="phone-number-input">
                    {`${I18n.t('signup.PHONE_NUMBER_INPUT')} *`}
                  </label>

                  <Field
                    id="phone-number-input"
                    name="phoneNumber"
                    type="tel"
                    className="signup-page-input"
                    validate={validatePhone}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                        onChange={(e) => {
                          if (e?.target?.value && parsePhoneNumberFromString(e?.target?.value, 'CH')) {
                            props.input.onChange(parsePhoneNumberFromString(e?.target?.value, 'CH').formatInternational());
                          } else props.input.onChange(e?.target?.value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className="signup-input-group">
                  <label className="signup-data-label" htmlFor="mobile-phone-number-input">
                    {`${I18n.t('signup.MOBILE_PHONE_NUMBER_INPUT')}`}
                  </label>

                  <Field
                    id="mobile-phone-number-input"
                    name="mobilePhoneNumber"
                    type="tel"
                    className="signup-page-input"
                    validate={(value) => validatePhone(value, false)}
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                        onChange={(e) => {
                          if (e?.target?.value && parsePhoneNumberFromString(e?.target?.value, 'CH')) {
                            props.input.onChange(parsePhoneNumberFromString(e?.target?.value, 'CH').formatInternational());
                          } else props.input.onChange(e?.target?.value);
                        }}
                      />
                    )}
                  </Field>
                  <p className="characters-left">
                    {I18n.t('callback.REQUIRED')}
                  </p>
                </div>
              </div>
            </div>
            <div className="button-group">
              <Button
                type={ButtonType.PRIMARY}
                htmlType="submit"
                text={I18n.t('signup.SIGN_UP')}
                disabled={isSubmitDisabled}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default withRouter(SignupForm);
