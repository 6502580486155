import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import thunk from 'redux-thunk';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support

import App from './App';
import './sass/app.scss';
import fr from './i18n/locales/fr';
import en from './i18n/locales/en';
import de from './i18n/locales/de';
import it from './i18n/locales/it';
import reducers from './state/reducers';
import { store as storeFunction } from './network/reduce';
import { currentLanguage } from './services/languageService';
import { initToast } from './network/notification';
import { getPageData, initAdobeScript } from './services/analyticsService';
import { initTarteAuCitron } from './services/cookiesService';
import { SessionService, SessionServiceConfiguration } from './services/session/SessionService';
import { SessionServiceHolder } from './services/session/SessionServiceHolder';
import { loadSenselyScript } from './services/senselyService';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk)),
);

function wrapApp(RootComponent, reduxStore) {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" component={RootComponent} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

initAdobeScript().then((adobeScriptId) => {
  const sessionService = new SessionService(new SessionServiceConfiguration(store, storeFunction));
  SessionServiceHolder.setSessionService(sessionService);
  sessionService.tryInitializingSessionFromStorage();
  getPageData();
  initTarteAuCitron(adobeScriptId);
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations({
    fr,
    en,
    de,
    it,
  }));
  initToast();
  store.dispatch(setLocale(currentLanguage().code));
  ReactDOM.render(wrapApp(App, store), document.getElementById('root'));
})
  .then(() => loadSenselyScript().catch(() => console.log('Sensely script could not be preloaded')));
