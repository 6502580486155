import configurationApi from '../network/api/configurationApi';

export const loadSenselyScript = () => {
  console.log('Loading sensely script');
  return new Promise(((resolve, reject) => {
    configurationApi.fetchSenselyWebSdkUrlConfig()
      .then((res) => res.text())
      .then((webSdkUrl: string) => {
        // chargement du script JS Sensely
        const existingScript = document.getElementById('senselyScript');
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = webSdkUrl;
          script.id = 'senselyScript';
          document.body.appendChild(script);

          script.onload = () => {
            console.log('Widget loads');
            resolve();
          };

          script.onerror = (message, url, line, col, errorObj) => {
            console.log('Widget loading failed', { message, url, line, col, errorObj });
            reject();
          };
        } else {
          console.log('Widget already loaded');
          resolve();
        }
      })
      .catch((reason) => {
        console.log('Cannot load widget url', reason);
        reject(reason);
      });
  }));
};
