import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { notifyError } from '../../../network/notification';
import partnerApi from '../../../network/api/partnerApi';
import { useOnComponentMounted } from '../../../lib/react-hook-alias/ReactHookAlias';

type State = {
  text: string
};

export default function DataPrivacy() {
  const [text, setText] = useState<string>(undefined);

  useOnComponentMounted(() => {
    partnerApi.fetchPartnerDataPrivacy()
      .then((res) => res.text())
      .then(setText)
      .catch(notifyError);
  });

  return (
    <div className="legal-content-page">
      <div className="legal-content-page-header">
        <h1 className="legal-content-page-title">
          {I18n.t('dataPrivacy.TITLE_ALREADY_VALIDATE')}
        </h1>

        <p className="legal-content-page-text">
          {I18n.t('dataPrivacy.SUB_TITLE')}
        </p>
      </div>

      <div className="legal-content-page-separator" />

      <div
        className="legal-content-page-body"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}
