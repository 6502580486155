import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { BrifecaseCross } from 'iconsax-react';
import {
  analyticsParams,
  ANNOUCEMENT_PAGE_ROUTE,
  HistoryType,
  TYPE,
} from '../../../../../const';
import { updateProcessData } from '../../../../../services/analyticsService';
import ModalAnnounceReminder from '../../../../modal/modalAnnounceReminder/ModalAnnounceReminder';
import { AnnounceAppointmentConf } from '../AnnounceAppointmentTypes';
import ModalAnnounceLimit from '../../../../modal/modalAnnounceReminder/ModalAnnounceLimit';
import { notifyError } from '../../../../../network/notification';
import announceAppointmentApi from '../../../../../network/api/announceAppointmentApi';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';
import { useOnComponentMounted } from '../../../../../lib/react-hook-alias/ReactHookAlias';

type Props = {
  history: any,
};

type State = {
  isOpenReminder: boolean,
  isOpenExceeded: boolean,
  lateAppointmentDaysLimit: number,
  lateAppointmentLimit: number,
  hasExceeded: boolean
  type: string,
};

function LateRegistrationTile({ history }: Props) {
  const [isOpenReminder, setIsOpenReminder] = useState<boolean>(false);
  const [isOpenExceeded, setIsOpenExceeded] = useState<boolean>(false);
  const [lateAppointmentDaysLimit, setLateAppointmentDaysLimit] = useState<number>(0);
  const [lateAppointmentLimit, setLateAppointmentLimit] = useState<number>(0);

  useOnComponentMounted(() => {
    announceAppointmentApi
      .fetchAnnounceAppointmentConfiguration(HistoryType.LATE_REGISTRATION)
      .then((response) => response.json())
      .then((announceAppointmentConf: AnnounceAppointmentConf) => {
        setLateAppointmentDaysLimit(announceAppointmentConf.lateAppointmentDaysLimit);
        setLateAppointmentLimit(announceAppointmentConf.lateAppointmentLimit);
      })
      .catch(notifyError);
  });

  const hasUserExceededAnnounceAppointment = () => {
    announceAppointmentApi
      .hasUserExceededAnnounceAppointment(HistoryType.LATE_REGISTRATION)
      .then((response) => response.text())
      .then((exceeded) => {
        const hasExceeded = exceeded === 'true';
        if (hasExceeded) {
          setIsOpenExceeded(true);
        } else {
          setIsOpenReminder(true);
        }
      })
      .catch(notifyError);
  };

  const closeReminderModal = () => {
    setIsOpenReminder(false);
  };

  const closeExceededModal = () => {
    setIsOpenExceeded(false);
  };

  const goToAnnounceAppointment = () => {
    history.push(`${ANNOUCEMENT_PAGE_ROUTE}?${TYPE}=${HistoryType.LATE_REGISTRATION}`);
  };

  return (
    <>
      <ModalAnnounceReminder
        history={history}
        action={goToAnnounceAppointment}
        toggle={closeReminderModal}
        isOpen={isOpenReminder}
        backdrop
        lateAppointmentDaysLimit={lateAppointmentDaysLimit}
      />
      <ModalAnnounceLimit
        history={history}
        action={goToAnnounceAppointment}
        toggle={closeExceededModal}
        isOpen={isOpenExceeded}
        backdrop
        limit={lateAppointmentLimit}
      />
      <FeaturedTile
        title={I18n.t('services.ANNOUNCEMENT')}
        icon={<BrifecaseCross />}
        type={FeaturedTileType.SECONDARY}
        onClick={() => {
          hasUserExceededAnnounceAppointment();
          updateProcessData({
            processName: analyticsParams.processName.ANNOUNCE_APPOINTMENT,
            status: analyticsParams.status.STARTED,
            startTime: moment().toISOString(),
          }, true);
        }}
      />
    </>
  );
}

export default withRouter(LateRegistrationTile);
