import { Call } from 'iconsax-react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Button from '../../components/buttons/Button';
import FileInputPda from '../../components/fileInputPda/FileInputPda';
import DateInput from '../../components/form/DateInput';
import SelectInput from '../../components/form/SelectInput';
import TextInput from '../../components/form/TextInput';
import Loader from '../../components/layout/Loader';
import CallBackDeleteModal from '../../components/modal/callBackDeleteModal/CallBackDeleteModal';
import {
  analyticsParams, callbackRequestStaff, caseStatus, HISTORY_PAGE_ROUTE,
} from '../../const';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import callbackApi from '../../network/api/callbackApi';
import timeFrameApi from '../../network/api/timeFrameApi';
import userApi from '../../network/api/userApi';
import { notifyError, notifySuccess } from '../../network/notification';
import { updateProcessData } from '../../services/analyticsService';
import {
  compareTimeFrames,
  isInvalidDateInPast,
  timeFrameStringFromStartDateAndEndDateHours,
} from '../../services/dateService/dateService';
import { currentLanguage } from '../../services/languageService';
import { TimeFrame } from '../../types/appointmentType';
import ButtonType from '../../types/ButtonType';
import {
  required, requiredFiles, validateDate, validatePhone,
} from '../../validator';
import { CallbackChannel, CallBackDetailsType } from './callBackDetails/CallBackDetailsType';

const DESCRIPTION_MAX_LENGTH = 4000;

type Props = {
  location: {
    state: {
      initialValues: CallBackDetailsType,
      maxAfter?: number,
      senselyDescription?: any,
    }
  }
  history: any,
  isUploadPicture: boolean,
};

function CallBackForm({ history, isUploadPicture, location }: Props) {
  const [isEditing] = useState<boolean>(!!location.state.initialValues);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<TimeFrame[]>([]);
  const [callbackChannel, setCallbackChannel] = useState<CallbackChannel>(
    location.state.initialValues?.callbackChannel
    ?? CallbackChannel.PHONE,
  );
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [date, setDate] = useState<moment.Moment>(location.state.initialValues?.startDate
    ? moment(location.state.initialValues.startDate).startOf('day')
    : moment().startOf('day'));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CallBackDetailsType>(location
    .state
    .initialValues);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const addPdaTimeFrame = (timeSlots: TimeFrame[]) => {
    const editedTimeSlots = [...timeSlots];

    if (isEditing && location.state.initialValues.timeFrameId === null) {
      editedTimeSlots.push({
        id: -1,
        startDate: location.state.initialValues.startDate.toString(),
        endDate: location.state.initialValues.endDate.toString(),
      });

      setInitialValues((prevState) => ({
        ...prevState,
        timeFrameId: -1,
      }));
    }

    return editedTimeSlots.sort(compareTimeFrames);
  };

  const addOriginalTimeFrameToArrayIfNeeded = (
    timeSlots: TimeFrame[],
    targetedDate: moment.Moment,
  ) => {
    const editedTimeSlots: TimeFrame[] = [...timeSlots];

    if (isEditing && (targetedDate.startOf('day').diff(date) === 0 && location.state.initialValues.timeFrameId !== null)) {
      if (!editedTimeSlots
        .find((timeSlot: TimeFrame) => timeSlot.id === location.state.initialValues.timeFrameId)
      ) {
        editedTimeSlots.push({
          id: location.state.initialValues.timeFrameId,
          startDate: location.state.initialValues.startDate.toString(),
          endDate: location.state.initialValues.endDate.toString(),
        });
      }
    }

    return editedTimeSlots.sort(compareTimeFrames);
  };

  const onSubmit = (values) => {
    const scrollElement = document.getElementById('app');

    if (scrollElement) {
      scrollElement.scrollTo(0, 0);
    }

    setIsLoading(true);

    const callbackRequestCreation = {
      startDate: values.timeFrameId === -1 ? location.state.initialValues.startDate : values.date.startOf('day'),
      endDate: values.timeFrameId === -1 ? location.state.initialValues.endDate : null,
      timeFrameId: values.timeFrameId,
      files: values.files,
      type: isUploadPicture ? callbackRequestStaff.DOCTOR : callbackRequestStaff.NURSE,
      description: values.description,
      callBackNumber: values.callBackNumber,
      id: isEditing ? location.state.initialValues.id : null,
      senselyResult: location.state.senselyDescription ? {
        senselyDescription: location.state.senselyDescription,
        consultationStatus: caseStatus.ACTIVE,
      } : undefined,
      callbackChannel,
    };

    callbackApi
      .createCallBackRequest(callbackRequestCreation)
      .then((response) => response.json()
        .then((callBackResult) => {
          if (isEditing) {
            notifySuccess(I18n.t('callback.CALLBACK_MODIFIED'));
          } else {
            notifySuccess(I18n.t('callback.CALLBACK_REGISTERED'));
          }

          updateProcessData({
            completeTime: moment().toISOString(),
            status: analyticsParams.status.COMPLETED,
          });

          if (callBackResult.ics) {
            history.push(HISTORY_PAGE_ROUTE, { callBackResult });
          } else {
            history.push(HISTORY_PAGE_ROUTE);
          }

          updateProcessData({}, true);
        }))
      .catch((error) => {
        notifyError(error);
        setInitialValues(values);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const discardChanges = () => {
    updateProcessData({}, true);
    history.push(HISTORY_PAGE_ROUTE);
    notifySuccess(I18n.t('callback.CHANGES_DISCARDED'), new Response());
  };

  useOnComponentMounted(() => {
    timeFrameApi.getAvailableTimeFrames(date, isUploadPicture ? 'DOCTOR' : 'NURSE')
      .then((response) => response.json())
      .then((listOfTimeSlots: TimeFrame[]) => {
        const mappedTimeSlots = location.state.initialValues
          ? addPdaTimeFrame(addOriginalTimeFrameToArrayIfNeeded(listOfTimeSlots, moment(date)))
          : listOfTimeSlots;

        setAvailableTimeSlots(mappedTimeSlots);
      })
      .catch(notifyError);

    userApi.fetchContactPhoneNumber()
      .then((response) => response.text())
      .then(setPhoneNumber)
      .catch(notifyError);
  });

  if (isLoading) {
    return (<Loader />);
  }

  return (
    <div className="callback-page">
      <div className="callback-page-header">
        <h1 className="callback-page-title">
          {isUploadPicture ? I18n.t('callback.UPLOAD_PICTURES') : I18n.t('callback.CALLBACK_REQUEST')}
        </h1>
        <div className="home-page-subtitle-container">
          <p className="callback-page-text">
            {I18n.t('callback.CALLBACK_DESCRIPTION')}
          </p>
        </div>
      </div>

      <Form
        keepDirtyOnReinitialize
        initialValues={{
          date,
          timeFrameId: availableTimeSlots.length > 0
            ? availableTimeSlots[0].id : undefined,
          ...initialValues,
          callBackNumber: phoneNumber,
        }}
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <form className="callback-page" onSubmit={formRenderProps.handleSubmit}>
            {!initialValues?.isCreatedByDoctor
              && (
                <>
                  <div className="callback-page-group">
                    <div className="callback-inputs">
                      <div className="callback-input-group">
                        <label className="callback-data-label" htmlFor="description">
                          {`${I18n.t('callback.DESCRIPTION')} *`}
                        </label>

                        <Field
                          id="description-input"
                          name="description"
                          component={TextInput}
                          multiline
                          rows={8}
                          className="callback-input"
                          maxLength={DESCRIPTION_MAX_LENGTH}
                          validate={(value) => {
                            if (!initialValues?.isCreatedByDoctor) {
                              return required(value);
                            }

                            return undefined;
                          }}
                          required
                        />

                        <p className="characters-left">
                          {`${formRenderProps.values.description ? DESCRIPTION_MAX_LENGTH - formRenderProps.values.description.length : DESCRIPTION_MAX_LENGTH} ${I18n.t('callback.CHARACTERS_LEFT')}`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="callback-page-separator" />
                </>
              )}

            {isUploadPicture && (
              <Field
                id="file-input"
                name="files"
                component={FileInputPda}
                validate={requiredFiles}
                required
              />
            )}

            <h2 className="callback-page-subtitle">
              {I18n.t('callback.WHEN_HOW')}
            </h2>

            <div className="callback-page-group">
              <div className="callback-inputs">
                <div className="callback-input-group">
                  <label className="callback-data-label" htmlFor="date-input">
                    {`${I18n.t('callback.DATE_INPUT')} *`}
                  </label>

                  <Field
                    id="date-input"
                    name="date"
                    component={DateInput}
                    locale={moment.locale(currentLanguage().code)}
                    placeholder={moment()}
                    dateFormat={I18n.t('date.DATE_FORMAT')}
                    className="callback-page-input"
                    validate={validateDate}
                    shouldDisableDate={(value) => isInvalidDateInPast(
                      value,
                      this?.props?.location?.state?.maxAfter,
                    )}
                    onChange={(momentDate: moment.Moment) => {
                      // The maximum date which is now + the maxAfter decided by sensely
                      const maxDate = moment().add(location.state.maxAfter, 'hours');
                      // Chosen date at start of day to avoid problems
                      // if callback request is made late in the day
                      const chosenDate = momentDate;
                      // Is the chosen date less than 24 hours away from max date (if exist)
                      const isLessThan24 = location.state.maxAfter ? maxDate.diff(chosenDate, 'hours') < 24 : false;
                      // If lessthan24 is true then we should send the number of hours
                      // before maxdate so that we only get the timeframes
                      // that are less than this number hours away

                      timeFrameApi.getAvailableTimeFrames(
                        momentDate,
                        isUploadPicture ? 'DOCTOR' : 'NURSE',
                        isLessThan24
                          ? maxDate.diff(chosenDate, 'hours')
                          : undefined,
                      )
                        .then((response) => response.json())
                        .then((timeSlots: TimeFrame[]) => {
                          setDate(momentDate);
                          setAvailableTimeSlots(addOriginalTimeFrameToArrayIfNeeded(
                            timeSlots,
                            momentDate,
                          ));
                        })
                        .catch(notifyError);
                    }}
                    required
                  />
                </div>

                <div className="callback-input-group">
                  <label className="callback-data-label" htmlFor="time-input">
                    {`${I18n.t('callback.TIME_INPUT')} *`}
                  </label>

                  <Field
                    id="time-input"
                    name="timeFrameId"
                    component={SelectInput}
                    list={availableTimeSlots
                      ? availableTimeSlots.map((timeSlot) => ({
                        id: timeSlot.id,
                        label: timeFrameStringFromStartDateAndEndDateHours(
                          timeSlot.startDate,
                          timeSlot.endDate,
                        ),
                      }))
                      : undefined}
                    className="callback-page-input"
                    placeholder={availableTimeSlots.length === 0 ? I18n.t('callback.NO_TIMEFRAMES_AVAILABLE') : undefined}
                    validate={required}
                    disabled={availableTimeSlots.length === 0 ? I18n.t('callback.NO_TIMEFRAMES_AVAILABLE') : undefined}
                    required
                  />
                </div>
              </div>

              <div className="callback-inputs">
                <div className="callback-input-group">
                  <label className="callback-data-label" htmlFor="callback-channel">
                    {`${I18n.t('callback.type.label')} *`}
                  </label>

                  <div className="callback-page-choices">
                    {Object.keys(CallbackChannel).map((choice: string) => (
                      <div className="choice-group" key={choice}>
                        <div data-checked={callbackChannel === CallbackChannel[choice]} className="outer-input" />
                        <h4 className="choice-label">{I18n.t(`callback.type.${choice}`)}</h4>
                        <button
                          type="button"
                          className="hidden-button"
                          onClick={() => setCallbackChannel(CallbackChannel[choice])}
                          aria-label={`button to change the callback type to ${choice}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="callback-input-group">
                  <label className="callback-data-label" htmlFor="phone-input">
                    {`${I18n.t('callback.PHONE_INPUT')} *`}
                  </label>

                  <Field
                    id="phone-input"
                    name="callBackNumber"
                    type="tel"
                    className="callback-page-input"
                    validate={validatePhone}
                    required
                  >
                    {(props) => (
                      <TextInput
                        {...props}
                        onChange={(e) => {
                          if (e?.target?.value && parsePhoneNumberFromString(e?.target?.value, 'CH')) {
                            props.input.onChange(parsePhoneNumberFromString(e?.target?.value, 'CH').formatInternational());
                          } else props.input.onChange(e?.target?.value);
                        }}
                      />
                    )}
                  </Field>

                  <p className="characters-left">
                    {I18n.t('callback.REQUIRED')}
                  </p>
                </div>
              </div>
            </div>

            {!isEditing
              && (
                <div className="button-group">
                  <Button
                    htmlType="button"
                    onClick={() => {
                      notifySuccess(I18n.t('callback.ACTION_CANCELED'), new Response());
                      history.goBack();
                    }}
                    text={I18n.t('actions.CANCEL')}
                  />
                  <Button
                    type={ButtonType.PRIMARY}
                    htmlType="submit"
                    text={I18n.t('callback.ARRANGE')}
                    icon={<Call />}
                  />
                </div>
              )}

            {isEditing
              && (
                <div className="button-group">
                  {!location.state.senselyDescription
                    && location.state.initialValues
                    && (
                      <div>
                        <CallBackDeleteModal
                          isOpen={isOpen}
                          toggle={() => { setIsOpen(!isOpen); }}
                          backdrop
                          callbackId={location.state.initialValues.id}
                        />
                        <Button
                          id="delete"
                          htmlType="button"
                          onClick={() => setIsOpen(true)}
                          text={I18n.t('actions.DELETE')}
                        />
                      </div>
                    )}
                  <div className="button-group">
                    <Button
                      htmlType="button"
                      onClick={discardChanges}
                      text={I18n.t('callback.DISCARD_CHANGES')}
                    />
                    <Button
                      type={ButtonType.PRIMARY}
                      htmlType="submit"
                      text={I18n.t('callback.EDIT_CHANGES')}
                    />
                  </div>
                </div>
              )}
          </form>
        )}
      />
    </div>
  );
}

export default withRouter(CallBackForm);
