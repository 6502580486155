import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { FieldRenderProps } from 'react-final-form';
import {
  ArrowLeft,
  CloseCircle,
  Eye,
  Trash,
} from 'iconsax-react';
import { CallbackFiles, isCallbackFile } from '../../pages/callBack/callBackDetails/CallBackDetailsType';
import { acceptedFiles } from '../../const';
import Button from '../buttons/Button';
import ButtonType from '../../types/ButtonType';
import ModalBase from '../modal/ModalBase';
import FileVisualiser from './FileVisualiser';

export default function FileInputPda({ input }: FieldRenderProps<HTMLElement, any>) {
  const [files, setFiles] = useState<CallbackFiles[]>(input.value ? [].concat(input.value) : []);
  const [isFileTooBig, setIsFileTooBig] = useState<boolean>(false);
  const [isFileTypeNotAccepted, setIsFileTypeNotAccepted] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewedFile, setPreviewedFile] = useState<CallbackFiles | null>(null);

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesCopy: File[] = Array.from(event.target.files);

    if (!filesCopy.length) {
      return;
    }

    filesCopy
      .forEach((file: File) => {
        if (file.size > 50000000) {
          setIsFileTooBig(true);
        }

        if (!acceptedFiles.includes(file.type)) {
          setIsFileTypeNotAccepted(true);
        }
      });

    const newFilesArray = [
      ...files,
      ...filesCopy
        .filter((file: File) => (file.size < 50000000 && acceptedFiles.includes(file.type))),
    ];

    Promise.all(newFilesArray.map(async (file: CallbackFiles) => {
      let base64;

      if (!isCallbackFile(file)) {
        base64 = await toBase64(file);
      } else {
        base64 = file.base64;
      }

      return {
        base64,
        name: file.name,
        type: file.type,
        prmId: file.prmId ? file.prmId : null,
        pdaCreated: file.pdaCreated !== false,
      };
    }))
      .then((callbackFiles: Array<any>) => {
        input.onChange(callbackFiles);
        setFiles(callbackFiles);
      });
  };

  const previewFile = (fileToDisplay: CallbackFiles) => {
    setIsPreviewOpen(true);
    setPreviewedFile(fileToDisplay);
  };

  const closeFilePreview = () => {
    setIsPreviewOpen(false);
    setPreviewedFile(null);
  };

  const removeFile = (fileToDelete: CallbackFiles) => {
    closeFilePreview();
    setFiles((newFiles: CallbackFiles[]) => {
      const filteredFiles = newFiles
        .filter((file: CallbackFiles) => JSON.stringify(file) !== JSON.stringify(fileToDelete));

      input.onChange(filteredFiles);
      return filteredFiles;
    });
  };

  return (
    <>
      <h2 className="callback-page-subtitle">
        {I18n.t('callback.UPLOAD')}
      </h2>

      <div className="callback-page-group">
        <div className="callback-inputs">
          <div className="callback-input-group">
            <div className="data-label-container">
              <label className="callback-data-label" htmlFor="fileInput">
                {I18n.t('consultation.DOCUMENTS')}
              </label>
              {files.length < 1 && (
                <p className="error-text">
                  {` - ${I18n.t('validator.FILE')}`}
                </p>
              )}
            </div>

            <input
              id="file-input"
              name="fileInput"
              type="file"
              className="hidden-input"
              value={[]}
              onChange={handleFiles}
              multiple
              accept={acceptedFiles}
            />

            {files.map((file: CallbackFiles) => (
              <div
                key={file.name}
                className="callback-page-action"
              >
                <h3 className="callback-data-value">{file.name}</h3>
                <Button
                  type={ButtonType.TEXT}
                  htmlType="button"
                  icon={<Eye />}
                  onClick={() => previewFile(file)}
                  text={I18n.t('callback.file.open')}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="button-group">
        <Button
          type={ButtonType.PRIMARY}
          htmlType="button"
          onClick={() => {
            document.getElementsByTagName('input').item(0).click();
          }}
          text={I18n.t('callback.UPLOAD_BUTTON')}
        />
      </div>

      {isFileTooBig && (<div className="error-text">{I18n.t('validator.FILE_TOO_BIG')}</div>)}
      {isFileTypeNotAccepted && (<div id="not-accepted" className="error-text">{I18n.t('validator.FILE_NOT_ACCEPTED')}</div>)}

      <div className="callback-page-separator" />

      <ModalBase
        isOpen={isPreviewOpen && !!previewedFile}
        toggle={closeFilePreview}
        title={I18n.t('callback.file.modalTitle')}
        titleAction={(
          <>
            <Button
              id="modal-close_mobile"
              ariaLabel="close"
              onClick={closeFilePreview}
              icon={<CloseCircle className="preview-leave" />}
            />
            <Button
              id="modal-close_desktop"
              ariaLabel="close"
              onClick={closeFilePreview}
              icon={<ArrowLeft className="preview-leave" />}
            />
          </>
        )}
        actionButton={I18n.t('actions.BACK')}
        action={closeFilePreview}
        fullScreenPopin
        className="preview-popin"
      >
        <div className="callback-page">
          {previewedFile && <FileVisualiser file={previewedFile} />}
          <div className="callback-page-action">
            <h3 className="callback-data-value">{previewedFile?.name}</h3>
            {previewedFile?.pdaCreated && (
              <Button
                type={ButtonType.TEXT}
                onClick={() => { removeFile(previewedFile); }}
                htmlType="button"
                icon={<Trash className="fa-trash-alt" />}
              />
            )}
          </div>
        </div>
      </ModalBase>
    </>
  );
}
