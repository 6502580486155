import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { I18n } from 'react-redux-i18n';
import { CallbackFiles } from '../../pages/callBack/callBackDetails/CallBackDetailsType';
import { FileMimeType, acceptedFiles } from '../../const';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import base64toBlob from '../../services/fileService/fileService';

type Props = {
  file: CallbackFiles
};

function FileVisualiser({ file }: Props) {
  const [blob, setBlob] = useState<Blob | null>(null);

  useOnComponentMounted(() => {
    base64toBlob(file.base64)
      .then(setBlob);
  });

  if (file.type.startsWith(FileMimeType.img)
    && acceptedFiles.includes(file.type)
    && blob
  ) {
    return (
      <embed
        type={file.type}
        src={URL.createObjectURL(blob)}
        className="image-preview"
      />
    );
  }

  if (file.type === FileMimeType.pdf) {
    return (
      <Document
        className="image-preview"
        file={`data:${file.type};base64,${file.base64}`}
      >
        <Page pageNumber={1} />
      </Document>
    );
  }

  if (!acceptedFiles.includes(file.type)) {
    return (
      <h3>{I18n.t('callback.file.NOT_AVAILABLE')}</h3>
    );
  }

  return <></>;
}

export default FileVisualiser;
