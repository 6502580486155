import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { BrifecaseCross } from 'iconsax-react';
import {
  analyticsParams,
  ANNOUCEMENT_PAGE_ROUTE,
  HistoryType,
  TYPE,
} from '../../../../../const';
import { updateProcessData } from '../../../../../services/analyticsService';
import ModalAnnounceReminder from '../../../../modal/modalAnnounceReminder/ModalAnnounceReminder';
import { AnnounceAppointmentConf } from '../AnnounceAppointmentTypes';
import ModalAnnounceLimit from '../../../../modal/modalAnnounceReminder/ModalAnnounceLimit';
import { notifyError } from '../../../../../network/notification';
import announceAppointmentApi from '../../../../../network/api/announceAppointmentApi';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';

type Props = {
  history: any,
  small: boolean,
  senselyDescription: any,
};

type State = {
  isOpenReminder: boolean,
  isOpenExceeded: boolean,
  lateAppointmentDaysLimit: number,
  lateAppointmentLimit: number,
  hasExceeded: boolean,
};

class PastAppointmentTile extends React.Component<Props, State> {
  state = {
    isOpenReminder: false,
    isOpenExceeded: false,
    lateAppointmentLimit: 0,
    lateAppointmentDaysLimit: 0,
    hasExceeded: false,
  };

  componentDidMount() {
    announceAppointmentApi
      .fetchAnnounceAppointmentConfiguration(HistoryType.PAST_APPOINTMENT)
      .then((response) => response.json())
      .then((announceAppointmentConf: AnnounceAppointmentConf) => {
        this.setState({
          lateAppointmentDaysLimit: announceAppointmentConf.lateAppointmentDaysLimit,
          lateAppointmentLimit: announceAppointmentConf.lateAppointmentLimit,
        });
      })
      .catch(notifyError);
  }

  hasUserExceededAnnounceAppointment = () => {
    announceAppointmentApi
      .hasUserExceededAnnounceAppointment(HistoryType.PAST_APPOINTMENT)
      .then((response) => response.text())
      .then((hasExceeded) => this.setState({ hasExceeded: (hasExceeded === 'true') }))
      .then(this.openRightPopin)
      .catch(notifyError);
  };


  closeReminderModal = () => {
    this.setState({ isOpenReminder: false });
  };

  closeExceededModal = () => {
    this.setState({ isOpenExceeded: false });
  };

  goToAnnounceAppointment = () => {
    const { history } = this.props;
    history.push(`${ANNOUCEMENT_PAGE_ROUTE}?${TYPE}=${HistoryType.PAST_APPOINTMENT}`);
  };

  openRightPopin = () => {
    const { hasExceeded } = this.state;
    if (hasExceeded) {
      this.setState({
        isOpenExceeded: true,
      });
    } else {
      this.setState({
        isOpenReminder: true,
      });
    }
  };

  render() {
    const { history } = this.props;
    const {
      isOpenReminder, isOpenExceeded, lateAppointmentDaysLimit, lateAppointmentLimit,
    } = this.state;
    return (
      <>
        <ModalAnnounceReminder
          history={history}
          action={this.goToAnnounceAppointment}
          toggle={this.closeReminderModal}
          isOpen={isOpenReminder}
          backdrop
          lateAppointmentDaysLimit={lateAppointmentDaysLimit}
        />
        <ModalAnnounceLimit
          history={history}
          action={this.goToAnnounceAppointment}
          toggle={this.closeExceededModal}
          isOpen={isOpenExceeded}
          backdrop
          limit={lateAppointmentLimit}
        />
        <FeaturedTile
          title={I18n.t('services.ANNOUNCEMENT')}
          icon={<BrifecaseCross />}
          type={FeaturedTileType.SECONDARY}
          onClick={() => {
            this.hasUserExceededAnnounceAppointment();
            updateProcessData({
              processName: analyticsParams.processName.ANNOUNCE_APPOINTMENT,
              status: analyticsParams.status.STARTED,
              startTime: moment().toISOString(),
            }, true);
          }}
        />
      </>
    );
  }
}

export default withRouter(PastAppointmentTile);
