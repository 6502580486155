import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { TimeFrame } from '../../types/appointmentType';
import { currentLanguage } from '../languageService';

export function timeFrameStringFromStartDateAndEndDateHours(startDate, endDate) {
  return `${moment(startDate).format(I18n.t('date.ONLY_HOURS_FORMAT'))} - ${moment(endDate).format(I18n.t('date.ONLY_HOURS_FORMAT'))}`;
}

export function timeFrameStringFromStartDateAndEndDate(startDate, endDate) {
  return `${moment(startDate).format(I18n.t('date.ONLY_DATE_FORMAT'))} - ${moment(endDate).format(I18n.t('date.ONLY_DATE_FORMAT'))}`;
}

export function handleDaylightSavingTime(momentDate : moment.Moment) {
  if (!momentDate.isDST()) {
    return momentDate.add(1, 'h');
  }
  return momentDate;
}

export function compareTimeFrames(a : TimeFrame, b : TimeFrame) : number {
  const startA = moment(a.startDate);
  const startB = moment(b.startDate);
  const minutesA = startA.hours() * 60 + startA.minutes();
  const minutesB = startB.hours() * 60 + startB.minutes();
  if (minutesA - minutesB !== 0) {
    return minutesA - minutesB;
  }
  const endA = moment(a.endDate);
  const endB = moment(b.endDate);
  const minutesEndA = endA.hours() * 60 + endA.minutes();
  const minutesEndB = endB.hours() * 60 + endB.minutes();
  return minutesEndA - minutesEndB;
}

export function isInvalidDateInFuture(daysInPastAllowed: number, date: string) : boolean {
  return moment(date).isAfter(moment().endOf('day'))
  || moment(date).isBefore(moment().startOf('day').subtract(daysInPastAllowed, 'days'));
}

export function isInvalidDateInPast(date: string, maxAfter: number): boolean {
  if (maxAfter && moment(date).startOf('day').isAfter(moment().add(maxAfter, 'hours'))) {
    return true;
  }
  return moment(date).isBefore(moment().startOf('day'));
}

/**
 * @returns {{
 *   bodyTitle: string,
 *   bodySubtitle: string,
 *   daysLeft: number,
 *   periodDuration: number,
 * }}
 * - bodyTitle represents the date or the period displayed
 * - bodySubtitle represents the hourly period
 * - daysLeft reprsents the countdown till the event
 * - periodDuration reprsents the days between the start of the event till its end
 */
export function extractDuration(startDate: string, endDate?: string) {
  const today = handleDaylightSavingTime(moment());
  const start = handleDaylightSavingTime(moment(startDate));
  const end = endDate ? handleDaylightSavingTime(moment(endDate)) : null;

  const formattedStartDate = start.locale(currentLanguage().code).format(`${I18n.t('date.ONLY_DATE_FORMAT')}`);
  const formattedEndDate = end && end.locale(currentLanguage().code).format(`${I18n.t('date.ONLY_DATE_FORMAT')}`);

  const isStartSameAsEnd: boolean = start.isSame(end, 'day');

  const displayedTitle = end && !isStartSameAsEnd ? `${formattedStartDate} ${I18n.t('date.period.TO')} ${formattedEndDate}` : formattedStartDate;
  const subtitle = end && isStartSameAsEnd ? timeFrameStringFromStartDateAndEndDateHours(startDate, endDate) : '';

  let left: number;
  let duration: number;

  if (today.isBefore(start)) {
    left = start.diff(today, 'days');
    duration = endDate ? end.diff(today, 'days') : left;
  } else if (endDate && today.isBefore(end)) {
    left = end.diff(today, 'days');
    duration = end.diff(start, 'days');
  } else {
    left = 0;
    duration = endDate ? end.diff(start, 'days') : 0;
  }

  return {
    bodyTitle: displayedTitle,
    bodySubtitle: subtitle,
    daysLeft: left,
    periodDuration: duration,
  };
}
