import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import EmergencyTile from '../../components/tile/service/custom/emergencyTile/EmergencyTile';
import HotlineTile from '../../components/tile/service/custom/hotlineTile/HotlineTile';
import UploadPictureTile from '../../components/tile/service/custom/uploadPictureTile/UploadPictureTile';
import CallbackRequestTile from '../../components/tile/service/custom/callbackRequestTile/CallbackRequestTile';
import RegistrationTile from '../../components/tile/service/custom/registrationTile/RegistrationTile';
import Medi24SupportTile from '../../components/tile/service/custom/medi24SupportTile/Medi24SupportTile';
import PastAppointmentTile from '../../components/tile/service/custom/pastAppointmentTile/PastAppointmentTile';
import LateRegistrationTile from '../../components/tile/service/custom/lateRegistrationTile/LateRegistrationTile';
import ReferralTile from '../../components/tile/service/custom/referralTile/ReferralTile';
import { DashboardServicesType } from '../../types/dashboardServicesType';
import dashboardApi from '../../network/api/dashboardApi';
import { notifyError } from '../../network/notification';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';


export default function ContactPage() {
  const [contactPageServices, setContactPageServices] = useState<DashboardServicesType>({
    isAllowedToSymptomChecker: false,
    isAllowedToUploadPicture: false,
    isAllowedToCallBackRequest: false,
    isAllowedToRegister: false,
    isAllowedToReferral: false,
    isAllowedToLateRegistration: false,
    isAllowedToPastAppointment: false,
  });

  useOnComponentMounted(() => {
    dashboardApi.getDashboardServices()
      .then((response) => response.json())
      .then((userRights) => {
        setContactPageServices({
          isAllowedToUploadPicture: userRights.uploadPicture,
          isAllowedToSymptomChecker: false,
          isAllowedToCallBackRequest: userRights.callBackRequest,
          isAllowedToRegister: userRights.registration,
          isAllowedToLateRegistration: userRights.lateRegistration,
          isAllowedToPastAppointment: userRights.pastAppointementRealease,
          isAllowedToReferral: userRights.referral,
        });
      })
      .catch(notifyError);
  });

  return (
    <div className="contact-page">
      <div className="contact-page-header">
        <h1 className="contact-page-title">
          {I18n.t('contact.TITLE')}
        </h1>
      </div>
      <h2 className="contact-page-subtitle">
        {I18n.t('contact.CONTACT_TITLE')}
      </h2>
      <div className="featured-tiles">
        <HotlineTile />
        {contactPageServices.isAllowedToRegister && (<RegistrationTile />)}
        {contactPageServices.isAllowedToPastAppointment && (<PastAppointmentTile />)}
        {contactPageServices.isAllowedToLateRegistration && (<LateRegistrationTile />)}
        {contactPageServices.isAllowedToCallBackRequest && (<CallbackRequestTile />)}
        {contactPageServices.isAllowedToUploadPicture && (<UploadPictureTile />)}
        {contactPageServices.isAllowedToReferral && (<ReferralTile />)}
        <EmergencyTile />
      </div>
      <h2 className="contact-page-subtitle">
        {I18n.t('contact.HELP_TITLE')}
      </h2>
      <div className="featured-tiles">
        <Medi24SupportTile />
      </div>
    </div>
  );
}
