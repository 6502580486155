import RestClientAuthenticated from '../clients/restClientAuthenticated';

const servicePackageApi = {
  fetchAppointmentReleaseExtension: () => new RestClientAuthenticated('/servicePackage/appointmentrelease/extension').execute(),

  fetchContactPhoneNumber: () => new RestClientAuthenticated('/servicePackage/hotlineNumber', 'GET').execute(),

  fetchCustomerSupportNumber: () => new RestClientAuthenticated('/servicePackage/customerSupportNumber', 'GET').execute(),

  hasAppointmentRelease: () => new RestClientAuthenticated('/servicePackage/appointmentrelease', 'GET').execute(),
};

export default servicePackageApi;
