import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FieldRenderProps } from 'react-final-form';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const DateInput = ({
  input: {
    value, onChange, onBlur,
  },
  dateFormat,
  locale,
  disabled,
  meta,
  formControlProps,
  ...rest
}: FieldRenderProps<moment.Moment>) => {

  const [isYearPickerOpen, setIsYearPickerOpen] = useState<boolean>(false);

  return (
    <FormControl
      {...formControlProps}
      style={{
        minWidth: '100%',
      }}
    >
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
        <KeyboardDatePicker
          {...rest}
          onBlur={onBlur}
          format={dateFormat}
          error={meta.touched ? meta.error : undefined}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          value={value}
          autoOk
          onChange={(newValue) => {
            onChange(newValue);
            if (rest.onChange) {
              rest.onChange(newValue);
            }
          }}
          InputProps={{ readOnly: true }}
          onClick={() => setIsYearPickerOpen(true)}
          open={isYearPickerOpen}
          onClose={() => setIsYearPickerOpen(false)}
        />
      </MuiPickersUtilsProvider>
      {meta.error && meta.touched && (
        <p className="error-text">{meta.error}</p>
      )}
    </FormControl>
  );
};

export default DateInput;
