import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Prompt } from 'react-router';
import { TickCircle } from 'iconsax-react';
import HotlineTile from '../../../components/tile/service/custom/hotlineTile/HotlineTile';
import {
  CALLBACK_REQUEST_PAGE_ROUTE, CONTACT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  HAVE_CALLBACK_REQUEST,
  HAVE_REGISTRATION,
  HAVE_UPLOAD_PICTURE, LOGOUT_PAGE_ROUTE,
  REGISTRATION_PAGE_ROUTE,
  UPLOAD_PICTURE_PAGE_ROUTE,
} from '../../../const';
import CallbackRequestTile from '../../../components/tile/service/custom/callbackRequestTile/CallbackRequestTile';
import UploadPictureTile from '../../../components/tile/service/custom/uploadPictureTile/UploadPictureTile';
import EmergencyTile from '../../../components/tile/service/custom/emergencyTile/EmergencyTile';
import RegistrationTile from '../../../components/tile/service/custom/registrationTile/RegistrationTile';
import ModalSenselyEndProcess from '../../../components/modal/modalSenselyEnd/ModalSenselyEndProcess';
import { SenselyUrgencyConfiguration } from '../SenselyTypes';
import ModalSenselyExitPage from '../../../components/modal/ModalSenselyExitPage';
import { SessionServiceHolder } from '../../../services/session/SessionServiceHolder';
import ContentHeader from '../../../components/layout/ContentHeader';
import doctors from '../../../img/doctors.png';
import FeaturedTile from '../../../components/featuredTiles/FeaturedTile';

type Props = {
  history: any,
  location: {
    state: {
      description: any,
      senselyUrgencyConfiguration: SenselyUrgencyConfiguration,
    }
  }
};

const actions = {
  PUSH: 'PUSH',
};

const senselyPossibilities = {
  CALL_MEDI24: 'CALL_MEDI24',
  CALLBACK: 'CALLBACK',
  CALL_144: 'CALL_144',
  ANNOUNCE_TREATMENT: 'ANNOUNCE_TREATMENT',
};

type State = {
  description: any,
  senselyUrgencyConfiguration: SenselyUrgencyConfiguration,
  exitPagePopin: boolean,
  exitLocation: string,
  exitAction: any,
  endProcess: boolean,
  madeACall: boolean,
  hotlinePhoneNumber: string,
  emergencyPhoneNumber: string,
};

class SenselyResultPage extends React.Component<Props, State> {
  state = {
    description: this.props.location.state.description,
    senselyUrgencyConfiguration: this.props.location.state.senselyUrgencyConfiguration,
    exitPagePopin: false,
    exitLocation: '',
    exitAction: undefined,
    endProcess: false,
    madeACall: false,
    hotlinePhoneNumber: null,
    emergencyPhoneNumber: null,
  };

  render() {
    const isFinished = !!this.state.description;
    const possibilities = this.state.senselyUrgencyConfiguration;
    return (
      <div>
        <Prompt
          when={!this.state.exitPagePopin && !this.state.endProcess}
          message={(location, action) => {
            this.setState({ exitPagePopin: true, exitLocation: location.pathname });
            if (((
              location.pathname === CALLBACK_REQUEST_PAGE_ROUTE
              || location.pathname === REGISTRATION_PAGE_ROUTE
              || location.pathname === UPLOAD_PICTURE_PAGE_ROUTE
              || location.pathname === REGISTRATION_PAGE_ROUTE
              || location.pathname === LOGOUT_PAGE_ROUTE) && action === actions.PUSH)) {
              return true;
            }
            return false;
          }}
        />
        <ModalSenselyExitPage
          backdrop
          isOpen={this.state.exitPagePopin}
          madeACall={this.state.madeACall}
          toggle={() => {
            this.setState({ exitPagePopin: !this.state.exitPagePopin });
          }}
          hotlineNumber={this.state.hotlinePhoneNumber}
          action={() => {
            this.setState({ exitPagePopin: false });
            this.state.madeACall
              ? this.props.history.push(CONTACT_PAGE_ROUTE)
              : this.props.history.push(this.state.exitLocation);
          }}
        />
        <ModalSenselyEndProcess
          isOpen={this.state.endProcess}
          toggle={() => {
            this.setState({ endProcess: !this.state.endProcess });
          }}
          backdrop
          madeACall={this.state.madeACall}
          senselyDescription={this.state.description ? this.state.description : undefined}
          afterChoice={this.state.exitAction}
          emergencyPhoneNumber={this.state.emergencyPhoneNumber}
        />
        <div className="sensely">
          {
            isFinished
            && (
              <div>
                <ContentHeader
                  pageTitle={this.state.description.recommendationTitle}
                  image={doctors}
                />
                <div className="page-container">
                  <div className="service-tile" id="results">
                    <h2 className="title-sensely">
                      {I18n.t('sensely.TITLE')}
                    </h2>
                    <div className="featured-tiles">
                      {
                        possibilities.autorisations.includes(senselyPossibilities.CALL_MEDI24)
                        && (
                          <div
                            className="hotline-container"
                            onClick={() => {
                              this.setState({
                                exitPagePopin: true,
                                madeACall: true,
                              });
                            }}
                          >
                            <HotlineTile senselyDescription={this.state.description} hotlineNumberFetched={(hotlinePhoneNumber) => this.setState({ hotlinePhoneNumber })} />
                          </div>
                        )
                      }
                      {
                        SessionServiceHolder.hasPermission(HAVE_REGISTRATION)
                        && possibilities.autorisations.includes(senselyPossibilities.ANNOUNCE_TREATMENT)
                        && (
                          <RegistrationTile
                            senselyDescription={this.state.description}
                          />
                        )
                      }
                      {
                        possibilities.autorisations.includes(senselyPossibilities.CALLBACK)
                        && (
                          <>
                            {SessionServiceHolder.hasPermission(HAVE_CALLBACK_REQUEST) && (
                              <CallbackRequestTile
                                maxAfter={possibilities.callbackWithin}
                                senselyDescription={this.state.description}
                              />
                            )}
                            {SessionServiceHolder.hasPermission(HAVE_UPLOAD_PICTURE) && (
                              <UploadPictureTile
                                maxAfter={possibilities.callbackWithin}
                                senselyDescription={this.state.description}
                              />
                            )}
                          </>
                        )
                      }
                      {
                        possibilities.autorisations.includes(senselyPossibilities.CALL_144)
                        && (
                          <div onClick={() => {
                            this.setState({
                              endProcess: true,
                              madeACall: true,
                              exitAction: () => {
                                this.props.history.push(CONTACT_PAGE_ROUTE);
                              },
                            });
                          }}
                          >
                            <EmergencyTile senselyDescription={this.state.description} emergencyNumberFetched={(emergencyPhoneNumber) => this.setState({ emergencyPhoneNumber })} />
                          </div>
                        )
                      }
                      <FeaturedTile
                        id="endProcess"
                        title={I18n.t('sensely.QUIT_PROCESS')}
                        icon={<TickCircle />}
                        onClick={() => {
                          this.setState({
                            endProcess: true,
                            exitAction: () => {
                              this.props.history.push(DASHBOARD_PAGE_ROUTE);
                            },
                          });
                        }}
                      />
                      {possibilities.text
                        && (
                          <div className="sensely-result-footer">
                            {I18n.t(`sensely.footer.${possibilities.text}`)}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
} export default withRouter(SenselyResultPage);
