import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { baseApiUrl } from '../../const';
import TextInput from '../../components/form/TextInput';
import DateInput from '../../components/form/DateInput';
import ContentHeader from '../../components/layout/ContentHeader';
import Button from '../../components/buttons/Button';
import ButtonType from '../../types/ButtonType';


class ExportFeedback extends React.Component {
  onSubmit(formRenderProps) {
    const startDate = moment(formRenderProps.values.startDate).startOf('day').toISOString();
    const endDate = moment(formRenderProps.values.endDate).endOf('day').toISOString();
    window.open(`${baseApiUrl}/extract/feedback?authLogin=${formRenderProps.values.login}&authPassword=${formRenderProps.values.password}&startDate=${startDate}&endDate=${endDate}`);// , '_blank');
  }

  render() {
    return (
      <div>
        <ContentHeader
          pageTitle="Export Feedbacks"
        />
        <div className="page-container">
          <Form
            initialValues={{ startDate: moment().subtract(1, 'day').startOf('day'), endDate: moment().endOf('day') }}
            onSubmit={this.onSubmit}
            render={(formRenderProps) => (
              <form onSubmit={formRenderProps.handleSubmit}>
                <div className="feedback-form-input-group">
                  <label
                    className="classic-text"
                    htmlFor="login-input"
                  >
                    Login
                  </label>
                  <Field
                    id="login-input"
                    name="login"
                    component={TextInput}
                    type="text"
                    className="profile-form-text"
                  />
                </div>
                <div className="feedback-form-input-group">
                  <label
                    className="classic-text"
                    htmlFor="password-input"
                  >
                    Password
                  </label>
                  <Field
                    id="password-input"
                    name="password"
                    component={TextInput}
                    type="password"
                    className="profile-form-text"
                  />
                </div>
                <div className="feedback-form-input-group">
                  <label
                    className="classic-text"
                    htmlFor="startDate-input"
                  >
                    Start Date
                  </label>
                  <Field
                    id="startDate-input"
                    name="startDate"
                    component={DateInput}
                    dateFormat={I18n.t('date.DATE_FORMAT')}
                    onlyDate
                  />
                </div>
                <div className="feedback-form-input-group">
                  <label
                    className="classic-text"
                    htmlFor="endDate-input"
                  >
                    End Date
                  </label>
                  <Field
                    id="endDate-input"
                    name="endDate"
                    component={DateInput}
                    dateFormat={I18n.t('date.DATE_FORMAT')}
                    onlyDate
                  />
                </div>
                <div className="feedback-button-group ">
                  <div className="button-group">
                    <Button
                      id="submit"
                      type={ButtonType.PRIMARY}
                      htmlType="button"
                      onClick={() => { this.onSubmit(formRenderProps); }}
                      text="Download Feedbacks"
                    />
                  </div>
                </div>
                <div className="feedback-button-group " />
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default ExportFeedback;
