import React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import Loader from '../../../components/layout/Loader';
import { AppointmentFormFields } from '../AppointmentTypes';
import { notifyError, notifySuccess } from '../../../network/notification';
import {
  required,
} from '../../../validator';
import {
  analyticsParams, caseStatus, HISTORY_PAGE_ROUTE, HistoryType, TYPE,
} from '../../../const';
import { updateProcessData } from '../../../services/analyticsService';
import { currentLanguage } from '../../../services/languageService';
import partnerApi from '../../../network/api/partnerApi';
import SelectInput from '../../../components/form/SelectInput';
import DateInput from '../../../components/form/DateInput';
import TextInput from '../../../components/form/TextInput';
import ContentHeader from '../../../components/layout/ContentHeader';
import { isInvalidDateInPast, isInvalidDateInFuture } from '../../../services/dateService/dateService';
import appointmentApi from '../../../network/api/appointmentApi';
import Button from '../../../components/buttons/Button';
import ButtonType from '../../../types/ButtonType';


const DESCRIPTION_MAX_LENGTH = 4000;
const DOC_INFO_MAX_LENGTH = 2000;

type Props = {
  location: {
    pathname: string,
    search: string,
  }
  senselyDescription?: any,
  history: any,
};

type State = {
  loading: boolean,
  formFields: AppointmentFormFields,
  specialityList: {
    label: string,
    value: string,
  },
  supportNumber: string,
  type: string,
  partner: string,
};

class AppointmentForm extends React.Component<Props, State> {
  state = {
    loading: true,
    formFields: undefined,
    specialityList: undefined,
    supportNumber: undefined,
    type: 'NONE',
    partner: undefined,
  };

  componentDidMount() {
    appointmentApi
      .fetchRegistrationFormFields(this.getType())
      .then((response) => response.json())
      .then((formFields) => {
        this.setState({
          formFields,
        });
      })
      .catch(notifyError);

    appointmentApi
      .fetchDoctorSpeciality(this.getType())
      .then((response) => response.json())
      .then((doctorList) => {
        this.setState({
          specialityList: doctorList.specialitylist,
          loading: false,
        });
      })
      .catch(notifyError);

    partnerApi
      .fetchSupportNumber()
      .then((response) => response.text())
      .then((supportNumber) => {
        this.setState({
          supportNumber,
        });
      });

    partnerApi.fetchPartner()
      .then((response) => response.json())
      .then((partner) => {
        this.setState({
          partner: partner.name,
        });
      })
      .catch(notifyError);
  }

  getType = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const type = queryParams.get(TYPE);
    this.setState({ type });
    return type;
  };

  onSubmit = (values) => {
    const scrollElement = document.getElementById('app');
    if (scrollElement) {
      scrollElement.scrollTo(0, 0);
    }
    this.setState({
      loading: true,
    });
    const AppointmentBean = {
      urgency: values.urgency ? values.urgency : 1,
      description: values.description ? values.description : null,
      date: values.date ? values.date.toISOString() : moment().toISOString(),
      dateInterval: this.state.formFields.timeFrameLength ? this.state.formFields.timeFrameLength : 0,
      doctorSpeciality: values.doctor ? values.doctor : '',
      limitation: this.state.formFields.timeFrameLength - 1 >= 0 ? this.state.formFields.timeFrameLength - 1 : 0,
      doctorInformation: values.doctorInformation ? values.doctorInformation : null,
      type: this.state.type,

      senselyResult: this.props.senselyDescription ? {
        senselyDescription: this.props.senselyDescription,
        consultationStatus: caseStatus.INACTIVE,
      } : undefined,
    };
    appointmentApi
      .createRegistration(AppointmentBean)
      .then((response) => {
        notifySuccess(I18n.t('registration.CREATION'), response);
        updateProcessData({
          status: analyticsParams.status.COMPLETED,
          completeTime: moment().toISOString(),
        });
        this.props.history.push(HISTORY_PAGE_ROUTE);
        updateProcessData({}, true);
      })
      .catch(notifyError);
  };

  formateDatePickerValue = (startDate: string, dateInterval: number) => {
    const startDateFormate = startDate === '' ? moment() : moment(startDate, I18n.t('date.DATE_FORMAT'));
    return `${startDateFormate.locale(currentLanguage().code).format(I18n.t('date.DATE_FORMAT')).toString()} ➔ ${startDateFormate.locale(currentLanguage().code).add(dateInterval, 'days').format(I18n.t('date.DATE_FORMAT')).toString()}`;
  };

  render() {
    const { supportNumber } = this.state;
    if (this.state.loading) {
      return (<Loader />);
    }

    return (
      <div>
        {(this.state.type === HistoryType.PAST_APPOINTMENT || this.state.type === HistoryType.LATE_REGISTRATION)
          && (
            <ContentHeader
              pageTitle={I18n.t('announceAppointment.ANNOUNCE')}
            />
          )}
        {this.state.type === HistoryType.REGISTRATION
          && (
            <ContentHeader
              pageTitle={I18n.t('registration.REGISTRATION')}
            />
          )}
        {this.state.type === HistoryType.REFERRAL
          && (
            <ContentHeader
              pageTitle={I18n.t('registration.REFERRAL')}
            />
          )}
        <div className="page-container">
          <div className="callback-form">
            <Form
              initialValues={{ date: moment(), urgency: 1 }}
              onSubmit={this.onSubmit}
              render={(formRenderProps) => (
                <form onSubmit={formRenderProps.handleSubmit}>
                  <div className="callback-form-block">
                    <div
                      className="classic-text callback-form-block-title"
                    >
                      {I18n.t('registration.REGISTRATION_DESCRIPTION')}
                    </div>
                    <div className="callback-form-input-group">
                      <div className="callback-form-small-inputs">
                        {this.state.formFields && this.state.formFields.haveUrgency
                          && (
                            <div className="callback-form-input">
                              <label
                                className="classic-text"
                                htmlFor="description-input"
                              >
                                {`${I18n.t('registration.URGENCY')}`}
                                *
                              </label>
                              <div
                                className="callback-form-select"
                              >
                                <Field
                                  id="urgency-input"
                                  name="urgency"
                                  component={SelectInput}
                                  list={[{
                                    id: 1,
                                    label: I18n.t('registration.urgencies.URGENCY_NORMAL'),
                                  }, {
                                    id: 2,
                                    label: I18n.t('registration.urgencies.URGENCY_URGENT'),
                                  }]}
                                  validate={required}
                                  required
                                />
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="callback-form-row">
                        {this.state.formFields && this.state.formFields.haveTimeFrame
                          && (
                            <div className="callback-form-row-item">
                              <div className="callback-form-input">
                                <label
                                  className="classic-text"
                                  htmlFor="date-input"
                                >
                                  {this.state.type === HistoryType.REFERRAL ? `${I18n.t('registration.DATE_TOP_MESSAGE_REFERRAL')}` : `${I18n.t('registration.DATE_TOP_MESSAGE')}`}
                                  *
                                </label>
                                <div className="callback-form-date-picker">
                                  <Field
                                    id="date-input"
                                    name="date"
                                    shouldDisableDate={this.state.type === HistoryType.REFERRAL ? (value) => isInvalidDateInPast(value, undefined) : (day) => isInvalidDateInFuture(this.state.formFields.daysInPastAllowed, day)}
                                    component={DateInput}
                                    locale={moment.locale(currentLanguage().code)}
                                    dateFormat={I18n.t('date.DATE_FORMAT')}
                                    required
                                    placeholder={moment()}
                                  />
                                </div>
                                {this.state.partner
                                  && (
                                    <div className="registration-description">
                                      {this.state.formFields.daysInPastAllowed && this.state.type !== HistoryType.REFERRAL ? `${I18n.t('registration.DATE_BOTTOM_MESSAGE', {
                                        days: this.state.formFields.daysInPastAllowed,
                                        number: supportNumber,
                                      })}` : ''}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                        {this.state.formFields && this.state.formFields.haveDoctorSpeciality
                          && (
                            <div className="callback-form-row-item">
                              <div className="callback-form-input">
                                <label
                                  className="classic-text"
                                  htmlFor="doctor-input"
                                >
                                  {this.state.type === HistoryType.REFERRAL ? `${I18n.t('registration.DOCTOR_TOP_MESSAGE_REFERRAL')}` : `${I18n.t('registration.DOCTOR_TOP_MESSAGE')}`}
                                  *
                                </label>
                                <div className="callback-form-select">
                                  <Field
                                    id="doctor-input"
                                    name="doctor"
                                    placeholder={I18n.t('registration.SELECT')}
                                    validate={required}
                                    required
                                    component={SelectInput}
                                    list={this.state.specialityList ? this.state.specialityList.map((speciality) => ({
                                      id: speciality.value,
                                      label: I18n.t(`doctorSpecialities.${speciality.value}`),
                                    })) : undefined}
                                  />
                                </div>
                                <div
                                  className="registration-description"
                                >
                                  {this.state.type === HistoryType.REFERRAL ? `${I18n.t('registration.DOCTOR_BOTTOM_MESSAGE_REFERRAL')}` : `${I18n.t('registration.DOCTOR_BOTTOM_MESSAGE')}`}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      {this.state.formFields && this.state.formFields.haveDoctorInformation
                        && (
                          <div className="description-input">
                            <label
                              className="classic-text"
                              htmlFor="description-input"
                            >
                              {`${I18n.t('registration.INFOS_DOCTOR')}`}
                              *
                            </label>
                            <Field
                              validate={required}
                              id="doctor-info-input"
                              name="doctorInformation"
                              maxLength={DOC_INFO_MAX_LENGTH}
                              component={TextInput}
                              variant="outlined"
                              multiline
                              rows={4}
                              required
                            />
                            <p className="characters-left">
                              {`${formRenderProps.values.doctorInformation
                                ? DOC_INFO_MAX_LENGTH - formRenderProps.values.doctorInformation.length
                                : DOC_INFO_MAX_LENGTH} ${I18n.t('callback.CHARACTERS_LEFT')}`}
                            </p>
                          </div>
                        )}
                      {this.state.formFields && this.state.formFields.haveDescription
                        && (
                          <div className="description-input">
                            <label
                              className="classic-text"
                              htmlFor="description-input"
                            >
                              {`${I18n.t('callback.DESCRIPTION')}`}
                            </label>
                            <Field
                              id="description-input"
                              name="description"
                              placeholder={this.state.formFields && this.state.type === HistoryType.REFERRAL ? I18n.t('registration.placeholders.DESCRIPTION_REFERRAL') : this.state.formFields.haveTimeFrame ? I18n.t('registration.placeholders.DESCRIPTION_APPOINTMENT') : I18n.t('registration.placeholders.DESCRIPTION')}
                              maxLength={DESCRIPTION_MAX_LENGTH}
                              component={TextInput}
                              variant="outlined"
                              multiline
                              rows={8}
                            />
                            <p className="characters-left">
                              {`${formRenderProps.values.description
                                ? DESCRIPTION_MAX_LENGTH - formRenderProps.values.description.length
                                : DESCRIPTION_MAX_LENGTH} ${I18n.t('callback.CHARACTERS_LEFT')}`}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="button-group">
                    <Button
                      htmlType="button"
                      onClick={() => {
                        notifySuccess(I18n.t('callback.ACTION_CANCELED'), new Response());
                        this.props.history.goBack();
                      }}
                      text={I18n.t('actions.CANCEL')}
                    />
                    {(this.state.type === HistoryType.PAST_APPOINTMENT || this.state.type === HistoryType.LATE_REGISTRATION)
                      && (
                        <Button
                          type={ButtonType.PRIMARY}
                          htmlType="submit"
                          text={I18n.t('announceAppointment.ANNOUNCE')}
                        />
                      )}
                    {this.state.type === HistoryType.REGISTRATION
                      && (
                        <Button
                          type={ButtonType.PRIMARY}
                          htmlType="submit"
                          text={I18n.t('registration.ARRANGE')}
                        />
                      )}
                    {this.state.type === HistoryType.REFERRAL
                      && (
                        <Button
                          type={ButtonType.PRIMARY}
                          htmlType="submit"
                          text={I18n.t('registration.REFERRAL')}
                        />
                      )}
                  </div>
                </form>
              )}
            />
          </div>
        </div>
        <div className="footer-handler" />
      </div>
    );
  }
}

export default withRouter(AppointmentForm);
