import React from 'react';
import CustomRouter from '../layout/CustomRouter';
import { CustomRoute } from '../layout/CustomRoute';
import ConsultationDetails from '../../pages/consultation/ConsultationDetails';
import { HistoryType } from '../../const';
import AppointmentRelease from '../../pages/appointmentRelease/AppointmentRelease';
import CallBackDetails from '../../pages/callBack/callBackDetails/CallBackDetails';

type Props = {
  id: string,
  type: string,
  handleModalState?: Function,
};

const APPOINTMENT_TYPES = [
  HistoryType.APPOINTMENT_RELEASE,
  HistoryType.PAST_APPOINTMENT,
  HistoryType.LATE_REGISTRATION,
];

function HistoryRouter({
  handleModalState,
  id,
  type,
}: Props) {
  return (
    <CustomRouter currentPageType={type}>
      <CustomRoute pageType={[HistoryType.CONSULTATION]}>
        <ConsultationDetails
          idConsultation={id}
          handleModalState={handleModalState}
        />
      </CustomRoute>
      <CustomRoute pageType={APPOINTMENT_TYPES}>
        <AppointmentRelease
          idAppointment={id}
          handleModalState={handleModalState}
          type={type}
        />
      </CustomRoute>
      <CustomRoute pageType={[HistoryType.CALLBACK_REQUEST]}>
        <CallBackDetails
          idCallback={id}
          handleModalState={handleModalState}
        />
      </CustomRoute>
    </CustomRouter>
  );
}

export default HistoryRouter;
