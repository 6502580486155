import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { CloseCircle } from 'iconsax-react';
import {
  useOnComponentUnMounted,
} from '../../lib/react-hook-alias/ReactHookAlias';
import Button from '../buttons/Button';
import ButtonType from '../../types/ButtonType';

type Props = {
  isOpen: boolean,
  toggle: any,
  title?: string,
  action?: any,
  secondaryAction?: any,
  actionButton?: string,
  secondaryActionButton?: string
  children: React.ReactNode,
  id?: string,
  fullScreenPopin?: boolean,
  className?: string,
  titleAction?: React.ReactNode,
};

const TransitionUp = React.forwardRef((props: TransitionProps & { children?: React.ReactElement<any, any> }, ref) => <Slide direction="up" ref={ref} {...props} />);

function ModalBase(props: Props) {
  const {
    isOpen,
    toggle,
    title,
    action,
    actionButton,
    secondaryAction,
    secondaryActionButton,
    children,
    fullScreenPopin,
    className,
    titleAction,
  } = props;

  const hasDisabledScroll = useRef<boolean>(false);

  const dialogsClassName: string = classNames(
    className ?? '',
    { 'fullScreen-popin': fullScreenPopin },
  );

  const disableScroll = () => {
    const body = document.getElementsByTagName('body').item(0);
    if (!body) {
      return;
    }
    body.style.setProperty('overflow-y', 'hidden');
    body.style.setProperty('max-height', '100vh');
    hasDisabledScroll.current = true;
  };

  const enableScroll = () => {
    const body = document.getElementsByTagName('body').item(0);
    const isOtherPopin = document.querySelectorAll('[role="presentation"]').length > 1;
    if (!body || isOtherPopin) {
      return;
    }

    body.style.removeProperty('max-height');
    body.style.removeProperty('overflow-y');
    hasDisabledScroll.current = false;
  };

  useLayoutEffect(() => {
    if (isOpen) {
      disableScroll();
    } else if (hasDisabledScroll.current) {
      enableScroll();
    }
  }, [isOpen]);

  useOnComponentUnMounted(() => {
    if (hasDisabledScroll.current) {
      enableScroll();
    }
  });

  return (
    <Dialog
      {...props}
      open={isOpen}
      TransitionComponent={TransitionUp}
      className={dialogsClassName}
    >
      {!fullScreenPopin && (
        <Button
          id="modal-close-button"
          ariaLabel="close"
          onClick={toggle}
          icon={<CloseCircle className="preview-leave" />}
        />
      )}
      {(title || !!titleAction) && (
        <div className="modal-title-container">
          {title && (
            <DialogTitle>
              <h2 className="modal-title">
                {I18n.t(title)}
              </h2>
            </DialogTitle>
          )}
          {!!titleAction && titleAction}
        </div>
      )}
      <DialogContent>
        {children}
      </DialogContent>
      {(actionButton || secondaryActionButton)
        && (
          <DialogActions>
            <div className="button-group">
              {secondaryActionButton && (
                <Button
                  onClick={secondaryAction || toggle}
                  text={secondaryActionButton}
                />
              )}
              {actionButton && (
                <Button
                  type={ButtonType.PRIMARY}
                  onClick={action}
                  text={actionButton}
                />
              )}
            </div>
          </DialogActions>
        )}
    </Dialog>
  );
}

export default ModalBase;
