import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { TickSquare } from 'iconsax-react';
import { notifyError, notifySuccess } from '../../network/notification';
import {
  analyticsParams,
  ERROR_BLOCKED_PAGE_ROUTE,
  FORM_SUBMITED,
  LOGOUT_PAGE_ROUTE,
  PARTNER,
  PARTNER_NAME,
  TOKEN,
} from '../../const';
import { FeedbackType } from './FeedbackType';
import feedbackApi from '../../network/api/feedbackApi';
import { updateProcessData } from '../../services/analyticsService';
import Button from '../../components/buttons/Button';
import ButtonType from '../../types/ButtonType';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import filledStar from '../../img/starFilled.svg';
import unfilledStar from '../../img/starUnfilled.svg';

type Props = {
  history: any
  location: {
    search: string,
    state?: {
      token: string,
      initialValues: object,
      partner: string,
    },
  },
};

export default function Feedback({ history, location }: Props) {
  const [alreadySent, setAlreadySent] = useState<boolean>(false);
  const [partnerName, setPartnerName] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  const [feedback, setFeedBack] = useState<string>('');

  useOnComponentMounted(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get(TOKEN);
    const partnerNameParam = queryParams.get(PARTNER_NAME);
    const formSubmited = queryParams.get(FORM_SUBMITED);

    setPartnerName(partnerNameParam);
    setToken(tokenParam);

    if ((!tokenParam || !partnerNameParam) && !formSubmited) {
      history.push(ERROR_BLOCKED_PAGE_ROUTE);
    }
  });

  const onSubmit = () => {
    const partnerCode = localStorage.getItem(PARTNER);

    if (rate !== 0) {
      const values: FeedbackType = {
        rating: rate,
        feedbackDescription: feedback,
        consentToBeRecontacted: checked,
        idInsurer: partnerCode,
      };

      feedbackApi.sendFeedback(values, token)
        .then((response) => {
          setAlreadySent(true);
          notifySuccess(I18n.t('feedback.SUCCESS'), response);
          updateProcessData({
            processName: analyticsParams.processName.FEEDBACK,
            status: analyticsParams.status.COMPLETED,
            completeTime: moment().toISOString(),
          });
          history.push(`${LOGOUT_PAGE_ROUTE}?${FORM_SUBMITED}=true&${PARTNER_NAME}=${partnerName}`, {
            initialValues: {
              ...values,
              conset: checked,
            },
          });
          updateProcessData({}, true);
        })
        .catch(notifyError);
    }
  };

  return (
    <div className="feedback-page">
      <div className="feedback-page-header">
        <h1 className="feedback-page-title">
          {I18n.t('logout.DISCONNECTED')}
        </h1>
      </div>

      {partnerName && (
        <p className="feedback-page-text">
          {I18n.t('feedback.SUB_TITLE', { partner: partnerName })}
        </p>
      )}

      <div className="stars-container">
        {new Array(5).fill(0).map((_, index: number) => (
          <div
            key={index}
            className="star-container"
          >
            <button
              type="button"
              aria-label={`${index + 1} star`}
              className="hidden-button"
              onClick={() => {
                setRate(index + 1);
              }}
            />
            <img
              className="star"
              alt="star"
              src={(index + 1) <= rate ? filledStar : unfilledStar}
            />
          </div>
        ))}
      </div>
      <div className="feedback-page-caption">{I18n.t('feedback.RATING_MANDATORY')}</div>

      <div className="feedback-input-group">
        <label className="feedback-data-label" htmlFor="feedback-input">
          {`${I18n.t('feedback.DESCRIPTION')}`}
        </label>
        <textarea
          className="feedback-input"
          value={feedback}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setFeedBack(event.target.value);
          }}
        />
      </div>

      <div className="checkbox-group">
        <input
          name="isGoing"
          type="checkbox"
          checked={checked}
        />
        {checked
          ? <TickSquare className="tick" />
          : <div className="tick tick--empty" />}
        <label htmlFor="isGoing" className="feedback-page-text">
          {`${I18n.t('feedback.CONSENT')}`}
        </label>
        <button
          className="hidden-button"
          onClick={() => setChecked(!checked)}
          aria-label="continue checkbox"
          type="button"
        />
      </div>

      {!alreadySent && token && (
        <div className="button-group">
          <Button
            id="submit"
            type={ButtonType.PRIMARY}
            htmlType="button"
            disabled={rate === 0}
            onClick={onSubmit}
            text={I18n.t('feedback.SEND')}
          />
        </div>
      )}
    </div>
  );
}
