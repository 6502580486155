import React, { ReactElement } from 'react';
import { CustomRouteType } from './CustomRoute';

type Props = {
  currentPageType: string,
  children: ReactElement<CustomRouteType>[]
};
export default function CustomRouter({ currentPageType, children }: Props) {
  const possibleChildren = React.Children.map(children, (child) => child).filter((child) => React.isValidElement(child)
        && (child.props.default || child.props.pageType.includes(currentPageType)));
  return possibleChildren.some((child) => child.props.pageType.includes(currentPageType))
    ? possibleChildren.find((child) => child.props.pageType.includes(currentPageType))
    : possibleChildren.find((child) => child.props.default);
}
