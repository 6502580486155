import React, { ChangeEvent } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { FieldRenderProps } from 'react-final-form';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectItem } from './FormTypes';

const SelectInput = ({
  input: {
    name, value, onChange, onBlur, ...restInput
  },
  meta,
  list,
  formControlProps,
  placeholder,
  disabled,
  ...rest
}: FieldRenderProps<SelectProps>) => (
  <FormControl
    {...formControlProps}
    error={(meta.error && meta.touched)}
    disabled={disabled}
  >
    <Select
      {...rest}
      onBlur={onBlur}
      name={name}
      onChange={(e: ChangeEvent<SelectProps>) => {
        if (rest.onChange) {
          rest.onChange(e.target.value);
        }
        onChange(e);
      }}
      inputProps={restInput}
      value={value}
      displayEmpty
      variant="outlined"
    >
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>
      {list && list.map((menuItem: SelectItem) => (
        <MenuItem value={menuItem.id}>
          {menuItem.label}
        </MenuItem>
      ))}
    </Select>
    {meta.error && meta.touched && (
      <p className="error-text">{meta.error}</p>
    )}
  </FormControl>
);

export default SelectInput;
