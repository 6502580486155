import RestClientAuthenticated from '../clients/restClientAuthenticated';

const appointmentApi = {
  getNextAppointment: () => new RestClientAuthenticated('/dashboard/next-appointment', 'GET').execute(),
  getConsultationDetails: (consultationId : string) => new RestClientAuthenticated(`/consultation/${consultationId}`, 'GET').execute(),
  fetchRegistrationFormFields: (type: string) => new RestClientAuthenticated(`/appointment/form-fields-configuration?type=${type}`, 'GET').execute(),
  createRegistration: (registration) => new RestClientAuthenticated('/appointment', 'POST').jsonBody(registration).execute(),
  fetchRegistrationDetails: (registrationId : string) => new RestClientAuthenticated(`/appointment/${registrationId}`, 'GET').execute(),
  fetchDoctorSpeciality: (type: string) => new RestClientAuthenticated(`/appointment/list-doctor-speciality?type=${type}`, 'GET').execute(),

};

export default appointmentApi;
