import {FeedbackType} from '../../pages/feedback/FeedbackType';
import RestClientAuthenticated from "../clients/restClientAuthenticated";
import RestClient from "../clients/restClient";


const feedbackApi = {
    fetchInfos: () => new RestClientAuthenticated('/feedback/infos', 'POST').execute(),
    sendFeedback: (feedback: FeedbackType, token: string) => new RestClient(`/feedback?token=${token}`, 'POST').jsonBody(feedback).execute(),
};

export default feedbackApi;
