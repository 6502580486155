import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { ConsultationTileInfo } from '../ConsultationTileType';
import { currentLanguage } from '../../../../services/languageService';
import paperclip from '../../../../img/paperclip.svg';
import illustration from '../../../../img/past_consultation.svg';
import Badge from '../../../badge/Badge';
import { BadgeType } from '../../../../types/BadgeTypes';
import { timeFrameStringFromStartDateAndEndDate, timeFrameStringFromStartDateAndEndDateHours } from '../../../../services/dateService/dateService';

type Props = {
  infos: ConsultationTileInfo,
  onClick: Function,
};

export default function ConsultationTile({
  infos,
  onClick,
}: Props) {
  const handleModalStatus = () => {
    const { prmId, type } = infos;
    onClick(prmId, type);
  };

  const getBadgeText = () => {
    const count = (infos.appointmentRelease ? 1 : 0)
      + (infos.phonecalls ? 1 : 0)
      + (infos.registration ? 1 : 0);

    if (count === 1) {
      return `${count} ${I18n.t('tile.history.event')}`;
    }

    return `${count} ${I18n.t('tile.history.events')}`;
  };

  return (
    <div className="event event--consultation">
      <div className="illustration">
        <img className="illustration-img" src={illustration} alt="history-img" />
        {!!infos?.documentsNames?.length && (
          <img src={paperclip} alt="paperclip" className="attachment-icon" />
        )}
      </div>

      <div className="content">
        <div className="content-infos">
          <div className="content-header">
            <div className="content-header-group">
              <h2 className="content-header-title">{I18n.t('tile.services.CONSULTATION')}</h2>
              <div className="content-body">
                <h4 className="content-body-subtitle">
                  {moment(infos.sortingDate).locale(currentLanguage().code).format(I18n.t('date.ONLY_DATE_FORMAT'))}
                </h4>
              </div>
            </div>
            <Badge
              text={getBadgeText()}
              type={BadgeType.PRIMARY}
            />
          </div>
          <div className="content-body">
            {infos?.phonecalls && (
              <div className="content-body-section">
                <h2 className="content-body-section-title">
                  {I18n.t('consultation.TILE.BASED_ON')}
                </h2>
                <h3 className="content-body-section-subtitle">
                  {I18n.t('consultation.callback.CALLBACK_REQUEST')}
                </h3>
                <h4 className="content-body-subtitle">
                  {moment(infos.phonecalls.startDate).locale(currentLanguage().code).format(I18n.t('date.ONLY_DATE_FORMAT'))}
                </h4>
                <h4 className="content-body-subtitle">
                  {timeFrameStringFromStartDateAndEndDateHours(
                    infos.phonecalls.startDate,
                    infos.phonecalls.endDate,
                  )}
                </h4>
              </div>
            )}

            {infos?.appointmentRelease && (
              <div className="content-body-section">
                <h2 className="content-body-section-title">
                  {I18n.t('tile.history.APPOINTMENT_RELEASE')}
                </h2>
                <h4 className="content-body-section-subtitle">
                  {infos.appointmentRelease.isDummy ? I18n.t(`doctorSpecialities.${infos.appointmentRelease.specialty}`) : I18n.t(`registration.urgencies.${infos.appointmentRelease.name}`)}
                </h4>
                <h4 className="content-body-subtitle">
                  {timeFrameStringFromStartDateAndEndDate(
                    infos.appointmentRelease.startDate,
                    infos.appointmentRelease.endDate,
                  )}
                </h4>
              </div>
            )}

            {infos?.registration && (
              <div className="content-body-section">
                <h2 className="content-body-section-title">
                  {I18n.t('tile.history.REGISTRATION')}
                </h2>
                <h3 className="content-body-section-subtitle">
                  {I18n.t(`registration.urgencies.${infos.registration.urgency}`)}
                </h3>
                <h3 className="content-body-title">
                  {moment(infos.registration.modifiedOn).locale(currentLanguage().code).format(I18n.t('date.ONLY_DATE_FORMAT'))}
                </h3>
                <h4 className="content-body-subtitle">
                  {moment(infos.registration.modifiedOn).format(I18n.t('date.ONLY_HOURS_FORMAT'))}
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>

      <button
        className="hidden-button"
        type="button"
        onClick={handleModalStatus}
        aria-label={`select ${infos.title} event`}
      />
    </div>
  );
}
