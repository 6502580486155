import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from './ModalBase';
import { callIfMobile } from '../../const';

type Props = {
  isOpen: boolean,
  toggle: any,
  number: string,
};

function ModalHotlineNumber(props: Props) {
  const {
    toggle, isOpen, number,
  } = props;
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title={I18n.t('contact.HINT_TITLE')}
      secondaryActionButton={I18n.t('actions.BACK')}
    >
      <div className="classic-text">
        {`${I18n.t('contact.HINT')} `}
        <a className="call-link" role="link" tabIndex={0} onClick={() => { callIfMobile(number); }}>
          {number}
        </a>
      </div>
    </ModalBase>
  );
}

export default withRouter(ModalHotlineNumber);
