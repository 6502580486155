import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import ModalBase from '../modal/ModalBase';
import { IS_IDLE } from '../../state/login/loginReducer';
import { SessionServiceHolder } from '../../services/session/SessionServiceHolder';
import { store as storeFunction } from '../../network/reduce';
import { UserSession } from '../../services/session/SessionTypes';
import loginHistoryApi from '../../network/api/loginHistoryApi';

type Props = {
  history: any
  className?: string,
  size?: string,
  userSession: UserSession,
  dispatch: Function,
  timeElapsed: number,
};

type State = {
  initialTimer: number,
  arrivalTime: moment.Moment,
  timeElapsed: number,
};

class AutomaticLogoutModal extends React.Component<Props, State> {
  countDown = null;

  static defaultProps = {
    className: '',
    size: '',
  };

  state = {
    initialTimer: (this.props.userSession.session.inactiveDurationInMillis - this.props.userSession.session.inactiveWarningDurationInMillis),
    arrivalTime: moment(),
    timeElapsed: 0,
  };

  componentDidMount() {
    this.countDown = setInterval(this.timer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.countDown);
    this.countDown = null;
  }

  disconnectUser = () => {
    loginHistoryApi.logout().finally(() => {
      SessionServiceHolder.getSessionService().discardSession();
    });
  };

  extendSession = () => {
    SessionServiceHolder.getSessionService().restartSessionRefreshAndIdleDetection();
    this.props.dispatch(storeFunction(IS_IDLE, false));
  };

  timer = () => {
    if (this.state.timeElapsed >= this.state.initialTimer) {
      this.disconnectUser();
      return;
    }
    this.setState({
      timeElapsed: moment().diff(this.state.arrivalTime, 'milliseconds'),
    });
  };

  formatMillisToMinutesAndSeconds(millis) {
    const minutes: number = Math.floor(millis / 60000);
    const seconds: number = Number(((millis % 60000) / 1000).toFixed());
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  render() {
    return (
      <div>
        <ModalBase
          {...this.props}
          isOpen
          toggle={() => this.extendSession()}
          title={I18n.t('modal.LOGOUT_MODAL')}
          action={() => this.extendSession()}
          secondaryAction={() => this.disconnectUser()}
          actionButton={I18n.t('actions.BACK')}
          secondaryActionButton={I18n.t('actions.CONFIRM_LOGOUT')}
        >
          <div className="logout-content">
            <div className="classic-text">{I18n.t('modal.INACTIVITY_MESSAGE')}</div>
            <h1>
              {this.formatMillisToMinutesAndSeconds((this.state.initialTimer - this.state.timeElapsed > 0)
                ? this.state.initialTimer - this.state.timeElapsed
                : 0)}
            </h1>
          </div>
        </ModalBase>
      </div>
    );
  }
}

export default withRouter(connect((state) => ({
  userSession: state.login.userSession,
}))(AutomaticLogoutModal));
