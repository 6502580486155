import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { ArrowLeft, CloseCircle } from 'iconsax-react';
import classNames from 'classnames';
import { AppointmentReleaseType } from './AppointmentReleaseType';
import { notifyError, notifySuccess } from '../../network/notification';
import { AppointmentExtension } from '../../types/appointmentType';
import servicePackageApi from '../../network/api/servicePackageApi';
import { CONTACT_PAGE_ROUTE, HISTORY_PAGE_ROUTE, HistoryType } from '../../const';
import Loader from '../../components/layout/Loader';
import { extractDuration } from '../../services/dateService/dateService';
import announceAppointmentApi from '../../network/api/announceAppointmentApi';
import appointmentReleaseApi from '../../network/api/appointmentRelease';
import { useOnComponentMounted, useOnDependenciesChange } from '../../lib/react-hook-alias/ReactHookAlias';
import ModalBase from '../../components/modal/ModalBase';
import emergencyAppointmentRelease from '../../img/emergency_appointment_release_large.svg';
import appointmentRelease from '../../img/appointment_release_large.svg';
import { BadgeType } from '../../types/BadgeTypes';
import Badge, { BadgeProps } from '../../components/badge/Badge';
import Button from '../../components/buttons/Button';
import Attributes from '../../components/appointmentRelease/Attributes';

type Props = {
  idAppointment: string,
  history: any,
  type?: string,
  handleModalState?: Function,
};

type State = {
  appointmentRelease: AppointmentReleaseType,
  appointmentReleaseExtension: AppointmentExtension,
  allowed: boolean,
  loading: boolean,
};

function AppointmentRelease({
  idAppointment,
  history,
  type,
  handleModalState,
}: Props) {
  const [state, setState] = useState<State>({
    appointmentRelease: undefined,
    appointmentReleaseExtension: {
      extension: undefined,
      isAllowed: undefined,
      isPdaExtensionAllowed: undefined,
      extensionHints: undefined,
    },
    allowed: undefined,
    loading: true,
  });
  const [button, setButton] = useState({
    title: '',
    action: undefined,
  });

  const {
    daysLeft,
    periodDuration,
  } = extractDuration(
    state.appointmentRelease?.startDate?.toString(),
    state.appointmentRelease?.endDate?.toString(),
  );

  const extendAppointmentRelease = () => {
    appointmentReleaseApi
      .extendAppointmentRelease(state.appointmentRelease.id)
      .then(() => {
        notifySuccess(I18n.t('appointmentRelease.EXTENSION_VALIDATED'));
        history.push(HISTORY_PAGE_ROUTE);
      })
      .catch(notifyError);
  };

  const handleSecondaryAction = () => {
    if (state.appointmentReleaseExtension.isAllowed
      && state.allowed
      && moment(state.appointmentRelease.startDate).startOf('day').isBefore(moment())
      && moment(state.appointmentRelease.endDate).isSameOrAfter(moment().startOf('days'))
    ) {
      setButton({
        title: I18n.t('appointmentRelease.EXTEND', { days: state.appointmentReleaseExtension.extension }),
        action: () => { extendAppointmentRelease(); },
      });
    } else if (!state.allowed && state.appointmentRelease
      && moment(state.appointmentRelease.startDate).isBefore(moment())
      && moment(state.appointmentRelease.endDate).isAfter(moment())
    ) {
      setButton({
        title: I18n.t('appointmentRelease.CONTACT'),
        action: () => { history.push(CONTACT_PAGE_ROUTE); },
      });
    }
  };

  const getImage = () => (
    state.appointmentRelease.isUrgent ? emergencyAppointmentRelease : appointmentRelease
  );

  const getTypeBadge: () => BadgeProps = () => {
    if (state.appointmentRelease.isUrgent) {
      return {
        text: I18n.t('tile.history.urgent'),
        type: BadgeType.SECONDARY,
      };
    }

    if (state.appointmentRelease.extended) {
      return {
        text: I18n.t('tile.history.extension'),
        type: BadgeType.SECONDARY_OUTLINED,
      };
    }

    if (state.appointmentRelease.isReferral) {
      return {
        text: I18n.t('tile.history.referral'),
        type: BadgeType.PRIMARY_OUTLINED,
      };
    }

    return null;
  };

  const getSpecialistBadge: () => BadgeProps = () => {
    if (state.appointmentRelease.isUrgent) {
      return {
        text: I18n.t(`doctorSpecialities.${state.appointmentRelease.doctorSpeciality}`),
        type: BadgeType.PRIMARY_OUTLINED,
      };
    }

    if (state.appointmentRelease.isReferral) {
      return {
        text: I18n.t(`doctorSpecialities.${state.appointmentRelease.doctorSpeciality}`),
        type: BadgeType.PRIMARY,
      };
    }

    return null;
  };

  const getTimeRemainingBadge: () => BadgeProps = () => {
    if (daysLeft) {
      const progress = (daysLeft * 100) / periodDuration;

      return {
        text: I18n.t('fromTo.remaining', { diff: daysLeft }),
        type: progress >= 50 ? BadgeType.PRIMARY : BadgeType.SECONDARY,
      };
    }

    return null;
  };

  const heroClassName = classNames(
    'details-hero details-hero--appointment',
    { 'details-hero--urgent': state.appointmentRelease?.isUrgent },
  );

  useOnComponentMounted(() => {
    servicePackageApi
      .fetchAppointmentReleaseExtension()
      .then((response) => response.json())
      .then((appointmentReleaseExtension) => {
        setState((oldState) => ({
          ...oldState,
          appointmentReleaseExtension,
        }));
      })
      .catch(notifyError);

    if (type === HistoryType.APPOINTMENT_RELEASE) {
      appointmentReleaseApi
        .getAppointmentReleaseDetails(idAppointment)
        .then((response) => response.json())
        .then((details: AppointmentReleaseType) => {
          setState((oldState) => ({
            ...oldState,
            appointmentRelease: details,
            allowed: details.isExtendable,
          }));
        })
        .catch(notifyError)
        .finally(() => {
          setState((oldState) => ({
            ...oldState,
            loading: false,
          }));
        });
    } else if (type === HistoryType.LATE_REGISTRATION || type === HistoryType.PAST_APPOINTMENT) {
      announceAppointmentApi
        .fetchAnnounceAppointmentDetails(idAppointment)
        .then((response) => response.json())
        .then((details: AppointmentReleaseType) => {
          setState((oldState) => ({
            ...oldState,
            appointmentRelease: details,
            allowed: details.isExtendable,
          }));
        })
        .catch(notifyError)
        .finally(() => {
          setState((oldState) => ({
            ...oldState,
            loading: false,
          }));
        });
    }
  });

  useOnDependenciesChange(() => {
    handleSecondaryAction();
  }, [state]);

  if (state.loading) {
    return (
      <ModalBase
        isOpen
        toggle={handleModalState}
        fullScreenPopin
        className="preview-popin details-popin"
      >
        <Loader />
      </ModalBase>
    );
  }

  return (
    <ModalBase
      isOpen
      toggle={handleModalState}
      fullScreenPopin
      className="preview-popin details-popin"
      actionButton={button.title}
      action={button.action}
    >
      <>
        <Button
          id="modal-close_details_large"
          ariaLabel="close"
          onClick={() => handleModalState()}
          icon={<CloseCircle className="preview-leave" />}
        />
        <Button
          id="modal-close_details_small"
          ariaLabel="close"
          onClick={() => handleModalState()}
          icon={<ArrowLeft className="preview-leave" />}
        />
      </>

      {state.appointmentRelease && (
        <>
          <div className={heroClassName}>
            <img
              src={getImage()}
              alt="Hero's background"
            />
          </div>

          <div className="details-content callback-page">
            <div className="callback-page-header">
              <h2 className="details-text">
                {I18n.t('appointmentRelease.APPOINTMENT_RELEASE')}
              </h2>

              <div className="callback-page-subtitle-container">
                {getTypeBadge() && (
                  <Badge
                    text={getTypeBadge().text}
                    type={getTypeBadge().type}
                  />
                )}
                {getSpecialistBadge() && (
                  <Badge
                    text={getSpecialistBadge().text}
                    type={getSpecialistBadge().type}
                  />
                )}
                {getTimeRemainingBadge() && (
                  <Badge
                    text={getTimeRemainingBadge().text}
                    type={getTimeRemainingBadge().type}
                  />
                )}
              </div>
            </div>

            <Attributes appointmentRelease={state.appointmentRelease} />

            <div className="callback-page-separator" />

            {state.appointmentRelease.description && (
              <>
                <div id="callbackBlock" className="callback-inputs">
                  <div className="callback-input-group">
                    <p className="callback-data-label">
                      {I18n.t('callback.DESCRIPTION')}
                    </p>
                    <p className="callback-data-value">
                      {state.appointmentRelease.description}
                    </p>
                  </div>
                </div>
                <div className="callback-page-separator" />
              </>
            )}

            {state.appointmentRelease.doctorInfos && (
              <div id="callbackBlock" className="callback-inputs">
                <div className="callback-input-group">
                  <p className="callback-data-label">
                    {I18n.t('registration.INFOS_DOCTOR')}
                  </p>
                  <p className="callback-data-value">
                    {state.appointmentRelease.doctorInfos}
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </ModalBase>
  );
} export default withRouter(AppointmentRelease);
