import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from './ModalBase';
import { deviceIsMobile } from '../../services/displayService';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  action: any,
  madeACall: boolean,
  hotlineNumber?: string,
};

class ModalSenselyExitPage extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    size: '',
  };

  render() {
    return (
      <>
        {this.props.madeACall
          ? (
            <ModalBase
              {...this.props}
              toggle={this.props.action}
              title={I18n.t('modal.senselySaved.TITLE')}
              action={this.props.action}
              actionButton={I18n.t('modal.OK')}
              secondaryActionButton={I18n.t('actions.BACK')}
            >
              {!deviceIsMobile() && this.props.hotlineNumber
            && (
            <div className="classic-text">
              {`${I18n.t('contact.HINT')} ${this.props.hotlineNumber}`}
              <div className="separator" />
            </div>
            )}
              <div className="classic-text">{I18n.t('modal.senselySaved.CONTENT_SAVED')}</div>
            </ModalBase>
          )
          : (
            <ModalBase
              {...this.props}
              toggle={this.props.toggle}
              title={I18n.t('modal.senselyExit.TITLE')}
              action={this.props.action}
              actionButton={I18n.t('modal.YES')}
              secondaryActionButton={I18n.t('modal.NO')}
              id="modal-sensely-exit"
            >
              <div className="classic-text">{I18n.t('modal.senselyExit.CONTENT')}</div>
            </ModalBase>
          )}
      </>
    );
  }
}

export default withRouter(ModalSenselyExitPage);
