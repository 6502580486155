import React from 'react';
import { Call, Message } from 'iconsax-react';
import { I18n } from 'react-redux-i18n';
import {
  HAVE_APPOINTMENT_RELEASE,
  HAVE_CALLBACK_REQUEST,
  HAVE_LATE_REGISTRATION,
  HAVE_PAST_APPOINTMENT_RELEASE,
  HistoryType,
} from '../../const';
import { SessionServiceHolder } from '../../services/session/SessionServiceHolder';
import Badge from '../badge/Badge';
import { BadgeType } from '../../types/BadgeTypes';

type EventCategoryType = {
  id: string,
  label: string,
  icon?: React.ReactElement,
};

type Props = {
  handleSelect: (optionId: string) => void,
  selected: string,
};

function EventCategories({ selected, handleSelect }: Props) {
  const getOptionIcon: (label: string) => React.ReactElement = (label) => {
    if (label === HistoryType.APPOINTMENT_RELEASE) {
      return <Message />;
    }

    if (label === HistoryType.CALLBACK_REQUEST) {
      return <Call />;
    }

    return undefined;
  };

  const getOptionsList: () => EventCategoryType[] = () => [
    HistoryType.ALL,
    HistoryType.APPOINTMENT_RELEASE,
    HistoryType.CALLBACK_REQUEST,
  ]
    .filter((name) => {
      const appointmentCondition: boolean = SessionServiceHolder.hasPermission(HAVE_APPOINTMENT_RELEASE)
        || SessionServiceHolder.hasPermission(HAVE_PAST_APPOINTMENT_RELEASE)
        || SessionServiceHolder.hasPermission(HAVE_LATE_REGISTRATION);
      const callbackCondition: boolean = SessionServiceHolder.hasPermission(HAVE_CALLBACK_REQUEST);

      if (!appointmentCondition && callbackCondition) {
        return name !== HistoryType.APPOINTMENT_RELEASE;
      }

      if (appointmentCondition && !callbackCondition) {
        return name !== HistoryType.CALLBACK_REQUEST;
      }

      if (!appointmentCondition && !callbackCondition) {
        return ![HistoryType.CALLBACK_REQUEST, HistoryType.APPOINTMENT_RELEASE].includes(name);
      }

      return name;
    })
    .map((name) => ({
      id: name,
      label: I18n.t(`tile.history.${name}`),
      icon: getOptionIcon(name),
    }));

  return (
    <div className="history-events-types">
      {getOptionsList().map((category: EventCategoryType) => (
        <button
          type="button"
          className={`event-container${selected === category.id ? ' event-container--selected' : ''}`}
          onClick={() => handleSelect(category.id)}
        >
          <Badge
            key={category.id}
            text={category.label}
            type={BadgeType.SECONDARY_OUTLINED}
            icon={category.icon}
          />
        </button>
      ))}
    </div>
  );
}

export default EventCategories;
