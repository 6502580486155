import React from 'react';
import { I18n } from 'react-redux-i18n';
import { BadgeType } from '../../../../types/BadgeTypes';
import emergencyAppointmentRelease from '../../../../img/emergency_appointment_release.svg';
import appointmentRelease from '../../../../img/appointment_release.svg';
import EventTile from './EventTile';
import { BadgeProps } from '../../../badge/Badge';

type Props = {
  infos: {
    isDummy: boolean,
    title: string,
    startDate: string,
    endDate: string,
    isAnExtension: boolean,
    prmId: string,
    type: string
    isReferral: boolean,
    isUrgent: boolean,
  },
  setModalStatus: Function,
};

export default function AppointmentReleaseTile({
  infos,
  setModalStatus,
}: Props) {
  const handleModalStatus = () => {
    const { prmId, type } = infos;
    setModalStatus(prmId, type);
  };

  const useRightImage = () => (
    infos.isUrgent ? emergencyAppointmentRelease : appointmentRelease
  );

  const useRightBadge: () => BadgeProps = () => {
    if (infos.isUrgent) {
      return {
        text: I18n.t('tile.history.urgent'),
        type: BadgeType.SECONDARY,
      };
    }

    if (infos.isAnExtension) {
      return {
        text: I18n.t('tile.history.extension'),
        type: BadgeType.SECONDARY_OUTLINED,
      };
    }

    if (infos.isReferral) {
      return {
        text: I18n.t('tile.history.referral'),
        type: BadgeType.PRIMARY,
      };
    }

    return null;
  };

  const getMetaInfo: () => string = () => {
    if (infos.isUrgent || infos.isReferral) {
      return I18n.t(`doctorSpecialities.${infos.title}`);
    }

    return infos.title;
  };

  return (
    <EventTile
      title={I18n.t('tile.history.APPOINTMENT_RELEASE')}
      illustration={useRightImage()}
      onClick={handleModalStatus}
      badge={useRightBadge()}
      startDate={infos?.startDate}
      endDate={infos?.endDate}
      metaInfo={getMetaInfo()}
    />
  );
}
