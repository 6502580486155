import RestClient from './restClient';
import { SessionServiceHolder } from '../../services/session/SessionServiceHolder';
import { UserSession } from '../../services/session/SessionTypes';

export function getAuthorizationHeader(currentSession: UserSession) {
  return `Bearer ${currentSession.session.webSessionToken}`;
}

export default class RestClientAuthenticated extends RestClient {
  constructor(apiExtensionUrl: string, method: string = 'GET') {
    super(apiExtensionUrl, method);
    const currentSession = SessionServiceHolder.getSessionService().currentSession();
    if (currentSession) {
      this.headers({ Authorization: getAuthorizationHeader(currentSession) });
    }
  }
}
