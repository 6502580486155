const de = {
  footer: {
    COPYRIGHT: '© Medi24 AG 2023. Alle Rechte vorbehalten',
    link: {
      DATA_PROTECTION: 'Datenschutz',
      TERMS_AND_CONDITION: 'Nutzungsbedingungen',
      COMPANY_LEGAL_INFORMATION: 'Impressum',
      HANDLE_COOKIES: 'Cookie-Verwaltung',
    },
  },
  actions: {
    YES: 'Ja',
    NO: 'Nein, abbrechen',
    OK: 'Ok',
    BACK: 'Zurück',
    CONFIRM_LOGOUT: 'Ausloggen',
    CANCEL: 'Abbrechen',
    SAVE: 'Speichern',
    DELETE: 'Löschen',
    NEW: 'Neu',
    CONFIRM: 'Bestätigen',
    SEARCH: 'Suchen...',
    EDIT: 'Bearbeiten',
  },
  authenticationErrors: {
    AUTHENTICATION: 'Ihre Authentifizierung ist fehlgeschlagen',
    DEFAULT_ERROR: 'Es gab einen Fehler',
    WRONG_PARTNER: 'Es gab einen Fehler bei der Verbindung zu MyMedi24. Bitte versuchen Sie es später noch einmal.',
    SAML_SETTINGS: 'Es gab einen Fehler bei der Verbindung zu MyMedi24. Bitte versuchen Sie es später noch einmal.',
    SAML_RESPONSE: 'Es gab einen Fehler bei der Verbindung zu MyMedi24. Bitte versuchen Sie es später noch einmal.',
    PRM_NOT_FOUND: 'Ihre Patientenakte ist nicht in unserer medizinischen Datenbank zu finden. \n'
      + ' Höchstwahrscheinlich ist dies auf eine kürzliche Änderung der Daten Ihres Versicherers zurückzuführen, die mit der nächsten Datensynchronisation behoben wird. \n'
      + 'Bitte versuchen Sie es in einer Woche erneut oder wenden Sie sich an Ihren Versicherer.',
    PRM_NOT_FOUND_HELSANA: {
      PART_1: 'Sie nutzen myMedi24 das erste Mal? Dann gehen Sie bitte so vor: \n \n'
        + 'Mit Basis- oder Hausarztmodell: \n'
        + 'Registrieren Sie sich zuerst ',
      LINK: 'hier',
      PART_2: '. Nach wenigen Minuten haben Sie Zugang zu myMedi24. \n \n'
        + 'Mit Premed-24-, Telmed- oder Flexmed-Modell: \n'
        + 'Versuchen Sie es bitte in einer Woche nochmal. Ihre Daten werden noch synchronisiert.'
    },
    BLOCKED: 'Dieser Link ist aus Sicherheitsgründen nicht verwendbar. Um sich mit den Online-Services von Medi24 zu verbinden, gehen Sie bitte über die Applikation Ihres Krankenversicherers.',
    PATIENT_INACTIVE: 'Sie sind nicht mehr berechtigt die Services von MyMedi24 zu nutzen, da Sie nicht mehr bei %{partner} versichert sind. Falls Sie denken, dass es sich hierbei um einen Fehler handeln könnte, kontaktieren Sie bitte %{email}.',
    UNABLE_TO_IDENTIFY_PATIENT: 'Wir waren nicht in der Lage, Sie anhand der von %{partner} bereitgestellten Informationen zu identifizieren. Für weitere Informationen kontaktieren Sie uns bitte unter %{email}.',
  },
  welcomePage: {
    WELCOME: 'Willkommen',
    CONNECTED: 'Sie sind jetzt mit den Services von Medi24 verbunden.',
    DISCLAIMER: 'Keine der hier gesammelten Daten werden an Ihren Versicherer weitergegeben.',
    CONTINUE: 'Weiter',
    DO_NOT_SHOW_AGAIN: 'Nicht noch einmal zeigen.',
  },
  dataPrivacy: {
    TITLE_BEFORE: 'Bevor wir anfangen',
    TITLE_ALREADY_VALIDATE: 'Datenschutz',
    SUB_TITLE_FIRST_TIME: 'Dies ist Ihre erste Nutzung, darum möchten wir Sie darauf hinweisen, dass die folgende Datenschutzerklärung nun für alle Seiten gilt, die Sie in MyMedi24 konsultieren werden. Wir bitten Sie, diese zur Kenntnis zu nehmen, indem Sie auf "fortfahren" klicken.',
    SUB_TITLE: 'Wir möchten Sie daran erinnern, dass die folgende Datenschutzerklärung nun für alle Seiten gilt, die Sie in myMedi24 konsultieren werden.',
    CONTINUE: 'Fortfahren',
  },
  termsAndConditions: {
    TITLE: 'Nutzungsbedingungen',
    SUB_TITLE: 'Wir möchten Sie daran erinnern, die folgenden Nutzungsbedingungen nun für alle Seiten gelten, die Sie in MyMedi24 konsultieren werden.',
  },
  companyLegalInformation: {
    TITLE: 'Impressum',
  },
  dashboardPage: {
    WELCOME: 'Willkommen, \n',
    NEXT_APPOINTMENT: 'Ihr nächster Termin',
    ALL_APPOINTMENT: 'Alle Termine',
    TREATMENT_SINCE: 'Von',
    VALID_TILL: 'Gültig bis',
    CALLBACK: 'Rückruf',
    CALL_DATE: 'Wir rufen Sie an am',
    BETWEEN: 'zwischen',
    NO_NEXT_APPOINTMENT: 'Sie haben derzeit keine bevorstehenden Termine',
    FUNCTIONALITIES: 'Meine Funktionen',
  },
  menu: {
    START: 'Start',
    CONTACT: 'Kontakt',
    PROFILE: 'Profil',
    HISTORY: 'Mein Dossier',
    LOGOUT: 'Logout',
  },
  services: {
    UPLOAD_PICTURE: 'Bild hochladen',
    CALLBACK_REQUEST: 'Rückruf vereinbaren',
    SYMPTOM_CHECKER: 'Symptom-Checker',
    CONSULTATION: 'Vergangene Konsultation',
    REGISTRATION: 'Terminmeldung',
    ANNOUNCEMENT: 'Notfall nachmelden',
    REFERRAL: 'Weiterweisung anmelden',
  },
  contact: {
    HINT_SUPPORT: 'Falls Sie Hilfe bei der Benutzung unseres Online-Dienstes MyMedi24 benötigen, kontaktieren Sie bitte unseren Anwendungssupport unter der Nummer: ',
    HINT_TITLE: 'Kontakt',
    HINT: 'Um uns zu kontaktieren, wählen Sie bitte die folgende Nummer:',
    EMERGENCY_HINT: 'Sollte es sich um einen Notfall handeln, wählen Sie bitte die folgende Nummer: %{tel}',
    TITLE: 'Kontakt',
    CONTACT_TITLE: 'Wie möchten Sie uns kontaktieren',
    HELP_TITLE: 'Benötigen Sie Hilfe bei MyMedi24?',
    hotline: {
      TITLE: 'Medi24 anrufen',
      BODY: 'Für eine telemedizinische Beratung rufen Sie Medi24 unter folgender Nummer an:',
      TEL: ' %{tel}',
    },
    SUPPORT_TITLE: 'MyMedi24 Support',
    emergency: {
      TITLE: 'Notruf 144',
      BODY: 'Falls es sich um einen Notfall handelt, wählen Sie bitte die Nummer 144.',
    },
    CLOSE: 'Schließen',
  },
  signup: {
    TITLE: 'Registrierung zur Nutzung von MyMedi24',
    INSURANCE_PRODUCT: 'Versicherungsprodukt',
    INSURANCE_PRODUCT_INPUT: 'Sind sie im basis- oder Hausarztmodell versichert?',
    PERSONAL_INFORMATION: 'Persönliche Informationen',
    FIRST_NAME_INPUT: 'Vorname',
    LAST_NAME_INPUT: 'Nachname',
    DATE_OF_BIRTH_INPUT: 'Geburtsdatum',
    GENDER: 'Geschlecht',
    GENDER_CHOICES: {
      M: 'Männlich',
      F: 'Weiblich'
    },
    ADDRESS: 'Anschrift',
    STREET_INPUT: 'Strasse',
    ZIP_CODE_INPUT: 'Postleitzahl',
    CITY_INPUT: 'Stadt',
    LANGUAGES: 'Sprachen',
    FIRST_LANGUAGE_INPUT: 'Erste sprache',
    SECOND_LANGUAGE_INPUT: 'Zweite sprache',
    LANGUAGE_CHOICES: {
      DEUTSCH: 'Deutsch',
      FRENCH: 'Französisch',
      ITALIAN: 'Italienisch',
      ENGLISH: 'Englisch'
    },
    CONTACT_INFORMATION: 'Kontaktinformationen',
    PHONE_NUMBER_INPUT: 'Telefonnummer',
    MOBILE_PHONE_NUMBER_INPUT: 'Mobiltelefonnummer',
    SIGN_UP: 'Registrieren',
    successModal: {
      title: 'Online-Registrierung',
      description: 'Ihre Registrierung war erfolgreich.\n Sie können nun mit der Nutzung der MyMedi24-Dienste fortfahren.',
      action: 'Weiter'
    },
    errorModal: {
      title: 'Online-Registrierung',
      description: 'Leider war Ihre Registrierung für MyMedi24 nicht erfolgreich.\n' +
        + 'Bitte versuchen Sie es später noch einmal.\n'
        + '\n'
        + 'Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an MyMedi24 unter der Ihnen von Ihrer Krankenkasse mitgeteilten Telefonnummer.',
      action: 'Close'
    }
  },
  sensely: {
    WARNING_TEXT: 'Die Durchführung des Symptom-Checkers auf MyMedi24 befreit Sie nicht von Ihren Meldepflichten aus Ihrem Krankenversicherungsvertrag, wie z.B. die Anmeldung von Arztbesuchen oder die Durchführung einer telemedizinischen Beratung.\n'
      + '\n'
      + 'Führen Sie bitte alle Schritte bis zum Ende des Prozesses durch und schließen Sie mit dem Klick auf "fertig" ab, um aus den von MyMedi24 empfohlenen nächsten Schritten auszuwählen.\n'
      + '\n'
      + 'Bitte beachten Sie, dass der Symptom-Checker bei Unsicherheiten zum weiteren Vorgehen unterstützen kann. Eine Diagnosestellung ist dabei ausgeschlossen.\n'
      + '\n'
      + 'Sollten Sie sich mit der Empfehlung von MyMedi24 unwohl fühlen, zögern Sie bitte nicht, Medi24 zu kontaktieren und das weitere Vorgehen abzustimmen.',
    LOADER: 'Laden des Symptom Checkers...',
    QUIT_PROCESS: 'Prozess beenden',
    QUIT_PROCESS_2: 'Möchten Sie das Interview trotzdem speichern?',
    CALLBACK: 'Wir werden Sie für Ihr medizinisches Anliegen kontaktieren.',
    UPLOAD_PICTURE: 'Laden Sie ein Bild/Dokument hoch und wir werden Sie kontaktieren.',
    REGISTRATION: 'Informieren Sie uns über eine Konsultation.',
    ANNOUNCEMENT: 'Informieren Sie uns über eine Konsultation.',
    REFERRAL: 'Uns über eine Empfehlung informieren.',
    TITLE: 'Wie können wir Ihnen helfen?',
    footer: {
      TEXT1: 'Falls Sie keine der Optionen wählen wollen und trotzdem ihren Arzt aufsuchen möchten, denken Sie daran, den Termin bei Medi24 telefonisch zu melden.',
      TEXT2: 'Rufen Sie nach der Konsultation beim Arzt Medi24 an, um den Notfalltermin zu melden.',
      TEXT3: 'Falls Sie keine der Optionen wählen wollen und trotzdem Ihren Arzt aufsuchen werden, denken Sie daran, den Termin bei Medi24 telefonisch zu melden oder diesen in MyMedi24 zu registrieren.',
      TEXT4: 'Wenn Sie Zweifel haben, ob diese Dringlichkeitsempfehlung auf Ihre Situation zutrifft, rufen Sie bitte Medi24 an, um dieses Ergebnis mit einer Fachperson zu verifizieren.',
      TEXT5: 'Wenn Sie Zweifel haben, ob diese Dringlichkeitsempfehlung auf Ihre Situation zutrifft, rufen Sie bitte Medi24 an, um dieses Ergebnis mit einer Fachperson zu verifizieren. \n'
        + 'Rufen Sie nach der Konsultation beim Arzt Medi24 an, um den Notfalltermin zu melden.',
      TEXT6: 'Wenn Sie Zweifel haben, ob diese Dringlichkeitsempfehlung auf Ihre Situation zutrifft, rufen Sie bitte Medi24 an, um dieses Ergebnis mit einer Fachperson zu verifizieren. \n'
        + 'Falls Sie keine der Optionen wählen wollen und trotzdem Ihren Arzt aufsuchen werden, denken Sie daran, den Termin bei Medi24 telefonisch zu melden oder diesen in MyMedi24 zu registrieren.',
    },
    RESULTS_SAVED: 'Gespeicherte Ergebnisse',
    SENSELY_TITLE: 'Hinweis zum Symptom-Checker',
  },
  message: {
    SUCCESS: 'Änderungen wurden erfolgreich gespeichert.',
    ERROR: 'Beim Speichern der Änderungen ist ein Fehler aufgetreten.',
    YES: 'Ja',
    NO: 'Nein',
    SENSELY_INIT_ERROR: 'Fehler bei der Verbindung mit dem Symptom Checker.',
  },
  wsError: {
    AUTHENTIFICATION_EXPIRED: 'Authentifizierung abgelaufen',
    INTERNAL_ERROR: 'Verbindungsfehler',
    NETWORK_ERROR: 'Netzwerkfehler, Ihre Internetverbindung scheint nicht verfügbar zu sein',
    TIMEFRAME_NOT_AVAILABLE: 'Der gewünschte Zeitfenster ist nicht mehr verfügbar. Bitte wählen Sie ein anderes Zeitfenster oder ein anderes Datum.',
    MESSAGE: '%{field0}',
    NO_PARTNER_FOUND: 'Ihr Versicherungspartner wurde nicht gefunden.',
    NOT_ALLOWED_BY_PARTNER: 'Sie haben von Ihrem Versicherungspartner nicht die Berechtigung erhalten, eine Terminfreigabe zu verlängern.',
    ALREADY_EXTENDED_MY_MEDI_24: 'Dies ist bereits eine Terminverlängerung.',
    ALREADY_CREATED: 'Sie haben bereits eine Verlängerung für diese spezielle Terminfreigabe vorgenommen.',
    ERROR_HTML_SENSELY: 'Beim Speichern des Ergebnisses ist ein Fehler aufgetreten.',
    ERROR_SENSELY_URGENCY_NOT_FOUND: 'Unbekannte Dringlichkeit',
    NO_SENSELY_RESULTS: 'Ergebnis konnte nicht übertragen werden.',
    TEXT_NOT_FOUND: 'Das von Ihnen angeforderte Dokument ist momentan nicht verfügbar. Bitte  versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    NOT_FOUND: 'Informationen nicht gefunden',
    UNABLE_TO_CREATE_FEEDBACK: 'Ihr Feedback konnte nicht gespeichert werden.',
    ALREADY_EXPIRED_SESSION_TOKEN: 'Ihre Sitzung ist abgelaufen.',
    AMOUNT_EXCEEDED: 'Sie haben Ihr Jahreskontingent bereits erfüllt',
  },
  logout: {
    DISCONNECTED: 'Erfolgreich abgemeldet',
  },
  date: {
    LONG_DATE_NO_YEAR: 'dddd DD. MMMM',
    long: 'MMMM Do, YYYY HH:mm',
    DATE_START: 'Startdatum',
    DATE_END: 'Enddatum',
    DATE_FORMAT: 'ddd, DD.MM.YYYY',
    ONLY_DATE_FORMAT: 'DD.MM.YYYY',
    ONLY_HOURS_FORMAT: 'HH[h]mm',
    ICS_DATE_FORMAT: 'YYYY-M-D-H-m',
    HISTORY_TILE_DATE_FORMAT: 'D.MM.YY',
    period: {
      ON: 'Am',
      BETWEEN: 'Zwischen',
      TIMEFRAME: 'Zeitraum',
      VALID_FROM: 'Gültig von',
      TO: 'bis',
    },
  },
  modal: {
    LOGOUT_MODAL: 'Logout',
    LOGOUT_MESSAGE: 'Sind Sie sicher, dass Sie sich ausloggen möchten?',
    INACTIVITY_MESSAGE: 'Sie sind inaktiv, Sie werden in abgemeldet in:',
    ICS: 'Kalender-Eintrag',
    ICS_CONTENT: 'Klicken Sie auf "Zum Kalender hinzufügen", um einen Termin in Ihrem persönlichen Kalender zu erstellen.',
    ICS_YES: 'Zum Kalender hinzufügen',
    ICS_NO: 'Schliessen',
    YES: 'Ja',
    NO: 'Nein',
    senselyExit: {
      TITLE: 'Möchten Sie den Symptom Checker verlassen?',
      CONTENT: 'Wenn Sie den Symptom Checker verlassen, gehen Ihre eingegebenen Daten verloren. Möchten Sie trotzdem abbrechen?',
      SAVE_ASSESSMENT: 'Wollen Sie vor dem Verlassen das Symptom Checker Ergebnis speichern?',
    },
    senselySaved: {
      TITLE: 'Sie verlassen den Symptom Checker',
      CONTENT_SAVED: 'Ihre Informationen wurden gespeichert. Drücken Sie "Ok", um den Symptom Checker zu verlassen.',
    },
    announceReminder: {
      TITLE: 'Hinweis',
      YES: 'Ja, es war ein Notfall',
      NO: 'Schliessen',
      CONTENT: 'Terminnachmeldungen sind gemäss den Versicherungsbedingungen nur für notfallmässige Behandlungen erlaubt und haben innert %{lateAppointmentDaysLimit} Tagen zu erfolgen.',
    },
    announceAmountExceeded: {
      TITLE: 'Maximale Anzahl erreicht',
      YES: 'Medi24 anrufen',
      NO: 'Schliessen',
      CONTENT: 'Notfallmässige Nachmeldungen können online %{limit} mal pro Kalenderjahr gemeldet werden. Für weitere Terminnachmeldungen, rufen Sie uns an.',
      CONTENT_REFERRAL: 'Weiterweisungen können online höchsten %{limit}-mal pro Kalenderjahr gemeldet werden. Für weitere Weiterweisungen, rufen Sie uns bitte an.',
    },
    referralNotAllowed: {
      TITLE: 'Unberechtigte Weiterweisung',
      YES: 'Medi24 anrufen',
      NO: 'Schliessen',
      CONTENT: 'Sie können nur Weiterweisungen anmelden, wenn Sie eine gültige, aktive Terminfreigabe haben. Für weitere Informationen, rufen Sie uns gerne an.',
    },
    uploadPictureWarning: {
      TITLE: 'Hinweis',
      YES: 'Ja',
      NO: 'Nein',
      CONTENT: 'Bitte beachten Sie, dass die Inanspruchnahme dieser Dienstleistung gemäss den Versicherungsbedingungen für Sie kostenpflichtig sein können. Möchten Sie die Dienstleistung dennoch in Anspruch nehmen?\n',
    },
    CLOSE: 'Schliessen',
  },
  appointmentRelease: {
    APPOINTMENT_RELEASE: 'Terminfreigabe',
    FROM: 'Von ',
    TO: 'bis ',
    EXTEND: 'Zeitfenster um %{days} Tage verlängern',
    EXTENSION_VALIDATED: 'Ihre Terminfreigabe wurde verlängert.',
    EXTENSION_ADVICE: 'Wenn Sie diese Terminfreigabe weiter verlängern möchten, wenden Sie sich bitte an Medi24.',
    CONTACT: 'Kontaktieren Sie Medi24',
    DETAIL: {
      SPECIALIST: 'Ihr Spezialist',
      SPECIALTY: 'Medizinisches Fachgebiet',
      TIMEFRAME: 'Zeitraum',
      SITUATION: 'Situation',
    },
  },
  callback: {
    UPLOAD_PICTURES: 'Ihr Bild-Upload',
    CALLBACK_REQUEST: 'Rückrufwunsch',
    CALLBACK_DESCRIPTION: 'Damit wir uns auf Ihre Situation vorbereiten können, beschreiben Sie bitte Ihr Anliegen so genau wie möglich.',
    DESCRIPTION: 'Beschreibung',
    ARRANGE: 'Rückruf vereinbaren',
    EDIT_CHANGES: 'Änderungen speichern',
    EDIT_ADVICE: 'Wenn Sie diesen Rückruf bearbeiten oder löschen möchten, wenden Sie sich bitte an Medi24.',
    CONTACT: 'Kontakt zu Medi24',
    UPLOAD: 'Wenn Sie Fotos, Dokumente, Zertifikate oder andere Unterlagen haben, können Sie diese hier hochladen.',
    UPLOAD_BUTTON: 'Datei(en) hochladen',
    WHEN_HOW: 'Wann und wie können wir Sie erreichen?',
    DATE_INPUT: 'Datum',
    PHONE_INPUT: 'Rückrufnummer',
    TIME_INPUT: 'Zeitpunkt',
    NO_TIMEFRAMES_AVAILABLE: 'An diesem Tag ist kein Zeitfenster verfügbar, bitte wählen Sie ein anderes Datum.',
    CHARACTERS_LEFT: 'verbleibende Zeichen',
    CALLBACK_REGISTERED: 'Wir haben Ihre Anfrage erhalten.',
    CALLBACK_DELETED: 'Ihr Rückruftermin wurde erfolgreich gelöscht.',
    CALENDAR: 'Zum Kalender hinzufügen',
    REQUEST: 'Ihre Anfrage',
    FILES: 'Anhänge',
    NUMBER: 'Rückruf-Nummer',
    calendarEvent: {
      EVENT_TITLE: 'Rückruf durch myMedi24',
      FILE_NAME: 'rückrufMyMedi24',
      CREATION_ERROR: 'Dateierstellung fehlgeschlagen',
      ICS_DESCRIPTION: 'Sie werden wie gewünscht am %{weekday}, %{date} von %{role} von Medi24 zwischen %{startTime} und %{endTime} angerufen.',
      ICS_EDITION: 'Vergessen Sie nicht, den vorherigen Termin in Ihrem Kalender zu löschen. Dieser hat keine Gültigkeit mehr.',
      DOCTOR: 'einem Arzt',
      NURSE: 'einer medizinischen Fachperson',
      PARTNER: 'Um Ihren Rückrufwunsch auf MyMedi24 zu verwalten, loggen Sie sich bitte über die App Ihres Versicherers ein. %{partnerUrl}',
    },
    file: {
      NOT_AVAILABLE: 'Wir können diese Datei nicht anzeigen',
      open: 'Dokument öffnen',
      modalTitle: 'Bild hochgeladen',
    },
    CHANGES_DISCARDED: 'Änderungen verworfen.',
    CALLBACK_MODIFIED: 'Änderungen gespeichert.',
    DISCARD_CHANGES: 'Änderungen verwerfen',
    DELETE_WARNING: 'Sind Sie sicher, dass Sie diesen Rückruf löschen möchten? Sie können ihn später nicht nochmals abrufen.',
    DELETE_MODAL: 'Löschen',
    SAVE_CHANGES: 'Änderungen speichern',
    DETAIL: {
      DESCRIPTION_HTML: 'Wir rufen Sie <b>%{date}</b> unter <b>%{number}</b> zwischen <b>%{hours}</b> Uhr an.',
    },
    ACTION_CANCELED: 'Vorgang abgebrochen',
    REQUIRED: '* PFLICHTFELD',
    type: {
      label: 'Rückruftyp',
      PHONE: 'Anruf',
      VIDEO: 'Videoanruf',
      BOTH: 'Spielt keine Rolle',
    },
  },
  consultation: {
    TRIAGE: 'Empfehlung von Medi24',
    triageRecommendation: {
      RECOMMENDATION_3: 'Ihnen wurde empfohlen, die Ambulanz anzurufen.',
      RECOMMENDATION_1: 'Ihnen wurde empfohlen, selbständig die nächstgelegene Notfallstation aufzusuchen.',
      RECOMMENDATION_4: 'Ihnen wurde empfohlen, eine Permanence aufzusuchen.',
      RECOMMENDATION_5: 'Ihnen wurde empfohlen, eine Walk-In Praxis/Permanence aufzusuchen.',
      RECOMMENDATION_2: 'Ihnen wurde empfohlen, sich mit dem Hausarzt, ansonsten mit einem Notfallarzt in Verbindung zu setzen.',
      RECOMMENDATION_6: 'Ihnen wurde empfohlen, sich binnen 24 Stunden mit dem Hausarzt, ansonsten mit einem Notfallarzt in Verbindung zu setzen.',
      RECOMMENDATION_7: 'Ihnen wurde empfohlen, mit ihrem Hausarzt einen Termin in den nächsten 2 - 7 Tagen zu vereinbaren.',
      RECOMMENDATION_8: 'Ihnen wurde empfohlen, eine Apotheke aufzusuchen.',
      RECOMMENDATION_9: 'Ihnen wurde empfohlen, sich gemäss den Empfehlungen selbst zu behandeln. Falls sich Ihre Situation verschlimmert oder ändert, ist Medi24  rund um die Uhr für sie erreichbar.',
      RECOMMENDATION_283030000: 'Ihnen wurde eine Telekonsultation empfohlen.',
    },
    triageTitle: {
      Test: 'This is a german test',
    },
    SICK_LEAVE_NOTE: 'Arbeitsunfähigkeitszeugnis',
    PRESCRIPTION: 'Rezept',
    description: {
      ON: 'Vom',
      AT: 'Um',
    },
    symptomChecker: {
      TITLE: 'Symptom Checker',
      LINK: 'Siehe Einschätzung',
    },
    callback: {
      FILES: 'Hochgeladene Dateien',
      REQUEST: 'Ihre Anfrage',
      UPLOAD_PICTURE: 'Bilder hochladen',
      CALLBACK_REQUEST: 'Rückruf-Vereinbarung',
    },
    DOCUMENTS: 'Dokumente',
    TILE: {
      BASED_ON: 'Basierend auf',
    },
    PREVIOUS: 'vorherige',
    NEXT: 'nächste',
  },
  fromTo: { remaining: 'Verbleibende Tage: %{diff}' },
  tile: {
    history: {
      REGISTRATION: 'Terminmeldung',
      CONSULTATION: 'Konsultation',
      CONSULTATION_TITLE: 'Medi24 Konsultation',
      APPOINTMENT_RELEASE: 'Terminfreigabe',
      CALLBACK_REQUEST: 'Rückruf',
      CALLBACK_REQUEST_TITLE: 'Medi24 wird Sie anrufen',
      ANNOUNCE_APPOITMENT: 'Terminfreigabe',
      PAST_APPOINTMENT: 'Notfall nachmelden',
      LATE_REGISTRATION: 'Notfall nachmelden',
      ALL: 'Alle',
      daysAdded: '%{days} Tage hinzugefügt.',
      extend: 'Zum Verlängern hier klicken',
      DETAILS: 'Details',
      CALLBACK_NUMBER: 'Rufnummer',
      TYPE: 'Typ',
      referral: 'Weiterweisung',
      urgent: 'Notfall',
      phoneCallback: 'Telefonanruf',
      videoCallback: 'Videoanruf',
      event: 'Ereignis',
      events: 'Ereignisse',
    },
  },
  history: {
    mainTitle: 'Mein Dossier',
    title: {
      active: 'Aktive Ereignisse',
      event: '%{count} Ereignis',
      events: '%{count} Ereignisse',
      pastEvents: 'Vergangene Ereignisse',
    },
    date: 'Datum',
    SEE_MORE: 'Mehr anzeigen',
    NO_EVENTS: 'Keine Ereignisse',
  },
  validator: {
    FILE: 'Bitte laden Sie mindestens eine Datei hoch.',
    FILE_TOO_BIG: 'Datei zu groß, maximale Größe: 50 MB',
    FILE_NOT_ACCEPTED: 'Dieser Dateityp wird nicht akzeptiert',
    REQUIRED: 'Dies ist ein Pflichtfeld.',
    VALIDATE_ZIPCODE: 'Bitte geben Sie eine gültige Postleitzahl ein',
    VALIDATE_PHONE_NO_0: 'Bitte geben Sie eine korrekte Telefonnummer ein.',
    VALIDATE_PHONE: 'Diese Telefonnummer ist nicht gültig',
    VALIDATE_EMAIL: 'Diese E-Mail-Adresse ist nicht gültig',
    DATE_TOO_FAR: 'Bitte wählen Sie ein Datum im laufenden Jahr.',
    DATE: 'Bitte wählen Sie ein Datum in der Zukunft.',
  },
  doctorSpecialities: {
    R010000: 'Allgemeine Innere Medizin',
    R010200: 'Chirurgie',
    R010300: 'Dermatologie und Venerologie',
    R010400: 'Gynäkologie und Geburtshilfe',
    R010600: 'Endokrinologie und Diabetologie',
    R010700: 'Lungen- und Bronchialheilkunde',
    R010800: 'Neurochirurgie',
    R010900: 'Neurologie',
    R011000: 'Psychiatrie und Psychotherapie',
    R011300: 'Augenheilkunde',
    R011400: 'Orthopädische Chirurgie & Erkrankungen des Bewegungsapparats',
    R011500: 'HNO-Heilkunde',
    R011600: 'Pädiatrie',
    R011700: 'Radiologie',
    R011800: 'Tropen- und Reisemedizin',
    R011900: 'Urologie',
    R012000: 'Rheumatologie',
    R012200: 'Angiologie',
    R013500: 'Kardiologie',
    R013600: 'Hämatologie',
    R014500: 'Gastroenterologie',
    R015200: 'Physikalische Medizin und Rehabilitation',
    R015400: 'Allergologie und klinische Immunologie',
    R015700: 'Infektiologie',
    R015900: 'Mund- Kiefer- und Gesichtschirurgie',
    R016300: 'Onkologie',
    R016400: 'Nephrologie',
    R030000: 'Chiropraktik',
    R100000: 'Spital',
    R101400: 'Rehabilitationsklinik',
    R103100: 'Psychiatrische Kliniken',
    R110000: 'Pflegeheim',
    Z999980: 'Arzt / Médecin',
  },
  registration: {
    DESCRIPTION: 'Beschreibung',
    REGISTRATION_DETAILS: 'Terminmeldung',
    REGISTRATION: 'Terminmeldung',
    REGISTRATION_DESCRIPTION: 'Damit wir Ihre Situation nachvollziehen können, füllen Sie bitte die folgenden Felder aus.',
    URGENCY: 'Dringlichkeit',
    urgencies: {
      URGENCY_URGENT: 'Notfall',
      URGENCY_NORMAL: 'Normal',
    },
    placeholders: {
      DESCRIPTION: 'Ich besuche meinen Hausarzt; ich gehe zu Dr. Winter; ich habe Fieber; etc.',
      DESCRIPTION_APPOINTMENT: 'Ich wurde notfallmässig im Inselspital behandelt; Ich musste mit der Ambulanz transportiert werden; Ich habe Symptome einer Vergiftung etc.\n'
          + '(Meldungen über nicht notfallmässige Behandlungen werden nicht berücksichtigt)',
      DESCRIPTION_REFERRAL: '"Ich wurde von meinem Hausarzt an einen Dermatologen weitergewiesen", "Mein behandelnder Arzt weist mich zwecks Abklärung meiner allergischen Reaktionen zu einem Allergologen weiter", o.ä. ',
    },
    ARRANGE: 'Terminmeldung erstellen',
    REFERRAL: 'Weiterweisung anmelden',
    CREATION: 'Terminmeldung erstellt',
    CANCEL: 'Abbrechen',
    SELECT: 'Wählen Sie...',
    DATE_TOP_MESSAGE: 'Wann ist der Notfall eingetreten?',
    DATE_TOP_MESSAGE_REFERRAL: 'Wann findet der erste Termin beim Facharzt statt?',
    DATE_BOTTOM_MESSAGE: 'Die Nachmeldung einer notfallmässigen Behandlung muss spätestens innert %{days} Tagen erfolgen. Bei Fragen zu Ihrem Versicherungsprodukt kontaktieren Sie den MyMedi24 Support: %{number}',
    DOCTOR_TOP_MESSAGE: 'Welchem Fachgebiet gehört der behandelnde Arzt an?',
    DOCTOR_TOP_MESSAGE_REFERRAL: 'Welchem Fachgebiet gehört der behandelnde Arzt an?',
    DOCTOR_BOTTOM_MESSAGE: 'Bitte geben Sie das medizinische Fachgebiet des behandelnden Arztes an oder wählen Sie "Spital", sollten Sie in einem Krankenhaus behandelt werden.',
    DOCTOR_BOTTOM_MESSAGE_REFERRAL: 'Bitte geben Sie das medizinische Fachgebiet des Arztes an, an den Sie überwiesen wurden.',
    INFOS_DOCTOR: 'Name und Ort des behandelnden Arztes',
  },
  announceAppointment: {
    ANNOUNCE: 'Notfall nachmelden',
    urgencies: {
      URGENCY_URGENT: 'Notfallbehandlung',
      URGENCY_NORMAL: 'Behandlung',
    },
    doctorSpeciality: 'Spezialität des Arztes',
    FROM: 'VON:',
    TO: 'BIS:',
    EXTEND: 'Zeitfenster um %{days} Tage verlängern',
    EXTENSION_VALIDATED: 'Ihre Terminfreigabe wurde verlängert.',
    EXTENSION_ADVICE: 'Wenn Sie diese Terminfreigabe weiter verlängern möchten, wenden Sie sich bitte an Medi24.',
    CONTACT: 'Kontaktieren Sie Medi24',
  },
  profile: {
    TITLE: 'Profil',
    SUBTITLE: 'Ihre persönlichen Daten',
    WARNING: 'Folgende Daten haben wir von Ihrem Versicherer erhalten, um Ihr Patienten-Dossier anzulegen. Wenn diese Daten veraltet sind, wenden Sie sich bitte an Ihren Versicherer, um sie zu aktualisieren.',
    NAME: 'Name',
    DATE_OF_BIRTH: 'Geburtsdatum',
    ADDRESS: 'Adresse',
    LANGUAGE: 'Sprache',
    CHANGE_LANGUAGE: 'Sprache ändern',
    LANGUAGE_SUBTITLE: 'Bitte beachten Sie, dass sich die Änderung der Sprache auf die weitere Nutzung des Dienstes und die Kontaktaufnahme auswirkt.',
    languages: {
      ENGLISH: 'Englisch',
      FRENCH: 'Französisch',
      ITALIAN: 'Italienisch',
      DEUTSCH: 'Deutsch',
    },
    languagesModal: {
      title: 'Sprache ändern',
      subtitle: 'Bitte wählen Sie Ihre bevorzugte Sprache aus',
      warning: 'Beachten Sie, dass sich die Änderung der Sprache auf die weitere Nutzung des Dienstes und die Kontaktaufnahme auswirkt',
      back: 'Zurück',
      save: 'Änderungen speichern',
    },
    ADDITIONNAL_TITLE: 'Zusätzliche Kontaktinformationen',
    ADDITIONNAL: 'Diese zusätzlichen Kontaktinformationen, die Sie direkt mit Medi24 teilen, helfen uns, Ihnen den bestmöglichen Service zu bieten. Diese Daten werden nicht weitergegeben.',
    EMAIL: 'E-Mail-Adresse',
    MOBILE: 'Handynummer',
    CHANGES_SAVED: 'Informationen gespeichert',
  },
  feedback: {
    SUB_TITLE: 'Sie haben sich erfolgreich ausgeloggt und können nun das Browserfenster schliessen. \n'
      + 'Bevor sie gehen, würden wir es schätzen, ein Feedback zu ihrer Benutzererfahrung zu erhalten. Dies ermöglicht uns, unseren Service zu verbessern und auf Ihre Bedürfnisse zuzuschneiden.\n'
      + 'Um den Service von MyMedi24 erneut zu nutzen, loggen Sie sich mit Ihrem Helsana Benutzerkonto ein.\n'
      + 'Vielen Dank und auf Wiedersehen.',
    DESCRIPTION: 'Rückmeldung (optional)',
    CONSENT: 'Ich bin damit einverstanden, vom Kundendienst für weitere Einzelheiten kontaktiert zu werden.',
    SEND: 'Rückmeldung senden',
    SUCCESS: 'Rückmeldung gesendet',
    RATING_MANDATORY: 'Um eine Bewertung abzugeben, klicken Sie bitte auf die gewünschte Anzahl von Sternen (1=schlecht, 5=sehr gut).',
  },
  eventDetails: {
    callback: 'Rückruf von Medi24',
  },
};

export default de;
