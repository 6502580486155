import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import userApi from '../../network/api/userApi';
import { BEFORE_WE_START_ROUTE, DASHBOARD_PAGE_ROUTE } from '../../const';
import { notifyError } from '../../network/notification';
import Button from '../../components/buttons/Button';
import ButtonType from '../../types/ButtonType';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import doctorsImage from '../../img/doctors.png';
import { TickSquare } from 'iconsax-react';

type Props = {
  history: any,
};

type State = {
  checked: boolean,
  legalPageShouldBeShown: boolean,
};

export default function WelcomePage({ history }: Props) {
  const [checked, setChecked] = useState<boolean>(false);
  const [legalPageShouldBeShown, setLegalPageShouldBeShown] = useState<boolean>(false);

  useOnComponentMounted(() => {
    userApi.userShouldSeeTermsAndConditions()
      .then((res) => res.text())
      .then((userShouldSeeTermsAndConditions) => setLegalPageShouldBeShown(userShouldSeeTermsAndConditions === 'true')).catch(notifyError);
  });

  const goToNextPage = () => {
    if (checked) {
      userApi.changeWelcomePageChoice(false).catch(notifyError);
    }
    if (legalPageShouldBeShown) {
      history.push(BEFORE_WE_START_ROUTE);
    } else {
      history.push(DASHBOARD_PAGE_ROUTE);
    }
  };

  const handleCheck = () => {
    setChecked(!checked);
  };

  return (
    <div className="welcome-page">
      <div className="welcome-page-header">
        <h1 className="welcome-page-title">
          {I18n.t('welcomePage.WELCOME')}
        </h1>
        <p className="welcome-page-text">
          {`${I18n.t('welcomePage.CONNECTED')} ${I18n.t('welcomePage.DISCLAIMER')}`}
        </p>
      </div>

      <div className="welcome-page-hero-image">
        <img
          alt="doctors"
          src={doctorsImage}
        />
      </div>

      <div className="checkbox-group">
        <input
          name="isGoing"
          type="checkbox"
          checked={checked}
        />
        {checked
          ? <TickSquare className="tick" />
          : <div className="tick tick--empty" />}
        <label htmlFor="isGoing" className="welcome-page-text">
          {I18n.t('welcomePage.DO_NOT_SHOW_AGAIN')}
        </label>
        <button
          className="hidden-button"
          onClick={handleCheck}
          aria-label="continue checkbox"
          type="button"
        />
      </div>

      <div className="button-group">
        <Button
          id="welcome-button"
          text={I18n.t('welcomePage.CONTINUE')}
          onClick={goToNextPage}
          type={ButtonType.PRIMARY}
          htmlType="button"
        />
      </div>
    </div>
  );
}
