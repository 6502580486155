const it = {
  footer: {
    COPYRIGHT: '© Medi24 AG 2023. Tutti i diritti riservati',
    link: {
      DATA_PROTECTION: 'Protezione dei dati',
      TERMS_AND_CONDITION: 'Condizioni d’uso',
      COMPANY_LEGAL_INFORMATION: 'Impressum',
      HANDLE_COOKIES: 'Politica sull’uso dei cookie',
    },
  },
  actions: {
    YES: 'Sì',
    NO: 'No, chiudi',
    OK: 'Ok',
    BACK: 'Indietro',
    CONFIRM_LOGOUT: 'Logout',
    CANCEL: 'Annulare',
    SAVE: 'Salva',
    DELETE: 'Elimina',
    NEW: 'Nuovo',
    CONFIRM: 'Conferma',
    SEARCH: 'Cerca...',
    EDIT: 'Modifica',
  },
  authenticationErrors: {
    AUTHENTICATION: 'Autentificazione fallita',
    DEFAULT_ERROR: 'Si è verificato un errore',
    WRONG_PARTNER: 'Si è verificato un errore nella connessione a MyMedi24. Si prega di riprovare più tardi.',
    SAML_SETTINGS: 'Si è verificato un errore nella connessione a MyMedi24. Si prega di riprovare più tardi.',
    SAML_RESPONSE: 'Si è verificato un errore nella connessione a MyMedi24. Si prega di riprovare più tardi.',
    PRM_NOT_FOUND: 'La cartella del paziente non è stata trovata nella banca dati.  \n'
      + ' Ciò è probabilmente dovuto a una modifica dei dati del vostro assicuratore che sarà riparato alla prossima sincronizzazione. \n \n'
      + 'La preghiamo di riprovare entro una settimana o di contattare il suo assicuratore.',
    PRM_NOT_FOUND_HELSANA: {
      PART_1: 'Utilizzate myMedi24 per la prima volta? In questo caso dovete procedere nel modo seguente: \n \n'
        + 'Con il modello BASIS o Medico di famiglia: \n'
        + 'Effettuate prima la registrazione ',
      LINK: 'qui',
      PART_2: '. Dopo pochi minuti potrete accedere a myMedi24. \n \n'
        + 'Con il modello PREMED-24, Telmed o Flexmed: \n'
        + 'Riprovate tra una settimana. I vostri dati verranno sincronizzati.'
    },
    BLOCKED: 'Questo link non è utilizzabile per motivi di sicurezza. Per poter accedere ai servizi online di Medi24, si prega di usare l’applicazione del proprio assicuratore malattia.',
    PATIENT_INACTIVE: 'Lei non è più autorizzato ad utilizzare i servizi di My Medi 24 perché non è più assicurato/a da %{partner}. Se pensa di aver ricevuto questo messaggio per errore è pregato/a di contattare %{email}.',
    UNABLE_TO_IDENTIFY_PATIENT: 'Non siamo stati in grado di identificarti con le informazioni fornite da %{partner}. Per ulteriori informazioni, contattateci a %{email}.',
  },
  welcomePage: {
    WELCOME: 'Benvenuto/a',
    CONNECTED: 'I dati raccolti in questa sede non saranno trasmessi al suo assicuratore.',
    DISCLAIMER: 'Nessuno dei dati qui raccolti sarà condiviso con il vostro assicuratore.',
    CONTINUE: 'Avanti',
    DO_NOT_SHOW_AGAIN: 'Non mostrare più.',
  },
  dataPrivacy: {
    TITLE_BEFORE: 'Prima di iniziare',
    TITLE_ALREADY_VALIDATE: 'Protezione dei dati',
    SUB_TITLE_FIRST_TIME: 'Questo è il vostro primo collegamento, vi ricordiamo che la seguente informativa sulla privacy è ora applicata a tutte le pagine che consulterete sul MyMedi24. La preghiamo di prendere atto di queste informazioni cliccando su continua.',
    SUB_TITLE: 'Le ricordiamo che la seguente Dichiarazione in materia di protezione dei dati è valida per tutte le pagine che consulterà all’interno di MyMedi24.',
    CONTINUE: 'Continua',
  },
  termsAndConditions: {
    TITLE: 'Condizioni d’uso',
    SUB_TITLE: 'Le ricordiamo che le seguenti Condizioni d’uso sono valide per tutte le pagine che consulterà all’interno di MyMedi24.',
  },
  companyLegalInformation: {
    TITLE: 'Impressum',
  },
  dashboardPage: {
    WELCOME: 'Benvenuto/a, \n',
    NEXT_APPOINTMENT: 'Il suo prossimo evento',
    ALL_APPOINTMENT: 'Tutti gli eventi',
    TREATMENT_SINCE: 'Da',
    VALID_TILL: 'Valido fino a',
    CALLBACK: 'Richiamata',
    CALL_DATE: 'La richiameremo il',
    BETWEEN: 'tra',
    NO_NEXT_APPOINTMENT: 'Non hai appuntamenti imminenti in questo momento',
    FUNCTIONALITIES: 'Le mie funzioni',
  },
  menu: {
    START: 'Inizio',
    CONTACT: 'Contatto',
    PROFILE: 'Profilo',
    HISTORY: 'Cartella Clinica',
    LOGOUT: 'Logout',
  },
  services: {
    UPLOAD_PICTURE: 'Caricare immagine',
    CALLBACK_REQUEST: 'Concorda richiamata',
    SYMPTOM_CHECKER: 'Symptom Checker',
    CONSULTATION: 'Consultazione Passata',
    REGISTRATION: 'La mia cartella clinica',
    ANNOUNCEMENT: 'Reregistrare un\' emergenza',
    REFERRAL: 'Annuncio richiesta specialistica',
  },
  contact: {
    HINT_SUPPORT: 'Se dovesse incontrare difficoltà nell’uso del nostro servizio online MyMedi24, non esiti a rivolgersi al nostro servizio di assistenza per l’applicazione: ',
    HINT_TITLE: 'Contatto',
    HINT: 'Per contattarci, si prega di comporre il seguente numero:',
    EMERGENCY_HINT: 'In caso di emergenza, si prega di comporre il seguente numero: %{tel}',
    TITLE: 'Contatto',
    CONTACT_TITLE: 'Come desidera contattarci?',
    HELP_TITLE: 'Hai bisogno di aiuto con MyMedi24',
    hotline: {
      TITLE: 'Chiama Medi24',
      BODY: 'Per una consulenza di telemedicina contatti Medi24 al seguente numero:',
      TEL: ' %{tel}',
    },
    SUPPORT_TITLE: 'Assistenza MyMedi24',
    emergency: {
      TITLE: 'Chiamata di emergenza 144',
      BODY: 'In caso di emergenza, si prega di comporre il numero 144.',
    },
    CLOSE: 'Chiudere',
  },
  signup: {
    TITLE: 'Registrazione a MyMedi24',
    INSURANCE_PRODUCT: 'Prodotto assicurativo',
    INSURANCE_PRODUCT_INPUT: 'Ha il modello BASIS o medico di famiglia?',
    PERSONAL_INFORMATION: 'Informazioni personali',
    FIRST_NAME_INPUT: 'Nome',
    LAST_NAME_INPUT: 'Cognome',
    DATE_OF_BIRTH_INPUT: 'Data di nascita',
    GENDER: 'Sesso',
    GENDER_CHOICES: {
      M: 'Maschio',
      F: 'Femmina'
    },
    ADDRESS: 'Indirizzo',
    STREET_INPUT: 'Strada',
    ZIP_CODE_INPUT: 'Codice postale',
    CITY_INPUT: 'Città',
    LANGUAGES: 'Lingue',
    FIRST_LANGUAGE_INPUT: 'Prima lingua',
    SECOND_LANGUAGE_INPUT: 'Seconda lingua',
    LANGUAGE_CHOICES: {
      DEUTSCH: 'Tedesco',
      FRENCH: 'Francese',
      ITALIAN: 'Italiano',
      ENGLISH: 'Inglese'
    },
    CONTACT_INFORMATION: 'Informazioni di contatto',
    PHONE_NUMBER_INPUT: 'Numero di telefono',
    MOBILE_PHONE_NUMBER_INPUT: 'Numero di cellulare',
    SIGN_UP: 'Registro',
    successModal: {
      title: 'Registrazione online',
      description: 'La sua registrazione è avvenuta con successo.\n Ora può procedere con i servizi MyMedi24',
      action: 'Continua'
    },
    errorModal: {
      title: 'Registrazione online',
      description: 'Purtroppo la sua registrazione a MyMedi24 non è andata a buon fine.\n' +
        + 'Si prega di riprovare più tardi.\n'
        + '\n'
        + 'Nel caso in cui il problema persista, la preghiamo di contattare MyMedi24 tramite il numero di telefono che le è stato comunicato dalla sua assicurazione sanitaria.',
      action: 'Chiudi'
    }
  },
  sensely: {
    WARNING_TEXT: 'L’utilizzo del Symptom Checker su MyMedi24 non la esonera dagli obblighi derivanti dal suo contratto di assicurazione malattia, come ad es. la comunicazione delle visite mediche o lo svolgimento di una consulenza di telemedicina.\n'
      + '\n'
      + 'La preghiamo di completare tutti i passaggi fino alla fine del processo e di concludere l’operazione cliccando su «Fatto» per poter selezionare uno dei passaggi successivi proposti da MyMedi24.\n\n'
      + '\n'
      + 'Prenda in considerazione che il Symptom Checker non è in grado di stabilire una diagnosi ma può aiutarla nella scelta dei prossimi passi in caso di insicurezza.\n\n'
      + '\n'
      + 'Se non siete d\'accordo con il consiglio proposto da MyMedi24, non esitate a contattare Medi24 per concordare come procedere.\n',
    LOADER: 'Caricamento del Symptom Checker...',
    QUIT_PROCESS: 'Chiudi processo',
    QUIT_PROCESS_2: 'Desidera in ogni caso salvare il colloquio?',
    CALLBACK: 'Provvederemo a contattarla per rispondere alla sua richiesta di carattere medico.',
    UPLOAD_PICTURE: 'Carichi un’immagine/un documento e provvederemo a contattarla.',
    REGISTRATION: 'Ci informi in merito a una consultazione medica.',
    ANNOUNCEMENT: 'Ci informi in merito a una consultazione medica.',
    REFERRAL: 'Informarci di una raccomandazione.',
    TITLE: 'Come possiamo aiutarla?',
    footer: {
      TEXT1: 'Se non desidera scegliere nessuna delle opzioni e intende comunque rivolgersi al suo medico, si ricordi di contattare telefonicamente Medi24 per comunicare l’appuntamento.',
      TEXT2: 'Dopo la consultazione con il suo medico contatti Medi24 per comunicare l’appuntamento urgente.',
      TEXT3: 'Se non desidera scegliere nessuna delle opzioni e intende comunque rivolgersi al suo medico, si ricordi di contattare telefonicamente Medi24 per comunicare l’appuntamento o di registrarlo in MyMedi24.',
      TEXT4: 'Se ritiene che la raccomandazione di urgenza non sia adeguata alla sua situazione, la preghiamo di contattare Medi24 per verificare tale esito con uno specialista.',
      TEXT5: 'Se ritiene che la raccomandazione di urgenza non sia adeguata alla sua situazione, la preghiamo di contattare Medi24 per verificare tale esito con uno specialista.\n'
        + 'Dopo la consultazione con il suo medico contatti Medi24 per comunicare l’appuntamento urgente.',
      TEXT6: 'Se ritiene che la raccomandazione di urgenza non sia adeguata alla sua situazione, la preghiamo di contattare Medi24 per verificare tale esito con uno specialista.\n'
        + 'Se non desidera scegliere nessuna delle opzioni e intende comunque rivolgersi al suo medico, si ricordi di contattare telefonicamente Medi24 per comunicare l’appuntamento o di registrarlo in MyMedi24.',
    },
    RESULTS_SAVED: 'Risultati salvati',
    SENSELY_TITLE: 'Nota sul Symptom Checker',
  },
  message: {
    SUCCESS: 'Le modifiche sono state correttamente salvate.',
    ERROR: 'Si è verificato un errore durante il salvataggio delle modifiche.',
    YES: 'Sì',
    NO: 'No',
    SENSELY_INIT_ERROR: 'Errore nella connessione con il Symptom Checker.',
  },
  wsError: {
    AUTHENTIFICATION_EXPIRED: 'Autenticazione scaduta',
    INTERNAL_ERROR: 'Errore di collegamento',
    NETWORK_ERROR: 'Errore di rete, la vostra connessione internet sembra non essere disponibile',
    TIMEFRAME_NOT_AVAILABLE: 'La fascia oraria desiderata non è più disponibile. Si prega di selezionare un’altra fascia oraria o un’altra data.',
    MESSAGE: '%{field0}',
    NO_PARTNER_FOUND: 'Non è stato possibile trovare il suo partner assicurativo.',
    NOT_ALLOWED_BY_PARTNER: 'Il suo partner assicurativo non la autorizza a prorogare un appuntamento accordato.',
    ALREADY_EXTENDED_MY_MEDI_24: 'Questa è già una proroga.',
    ALREADY_CREATED: 'Ha già ottenuto una proroga per questo specifico appuntamento accordato.',
    ERROR_HTML_SENSELY: 'Si è verificato un errore durante il salvataggio del risultato.',
    ERROR_SENSELY_URGENCY_NOT_FOUND: 'Urgenza non nota',
    NO_SENSELY_RESULTS: 'Non è stato possibile trasmettere il risultato.',
    TEXT_NOT_FOUND: 'Il documento da lei richiesto non è al momento disponibile. Si prega di riprovare in un secondo momento.',
    NOT_FOUND: 'Informazioni non trovate',
    UNABLE_TO_CREATE_FEEDBACK: 'Non è stato possibile salvare il suo feedback.',
    ALREADY_EXPIRED_SESSION_TOKEN: 'La vostra sessione è scaduta.',
    AMOUNT_EXCEEDED: 'Hai già riempito la tua quota annuale',
  },
  logout: {
    DISCONNECTED: 'Logout avvenuto con successo',
  },
  date: {
    LONG_DATE_NO_YEAR: 'dddd DD. MMMM',
    long: 'MMMM Do, YYYY HH:mm',
    DATE_START: 'Data iniziale',
    DATE_END: 'Data finale',
    DATE_FORMAT: 'ddd, DD.MM.YYYY',
    ONLY_DATE_FORMAT: 'DD.MM.YYYY',
    ONLY_HOURS_FORMAT: 'HH[h]mm',
    ICS_DATE_FORMAT: 'YYYY-M-D-H-m',
    HISTORY_TILE_DATE_FORMAT: 'D.MM.YY',
    period: {
      ON: 'Il',
      BETWEEN: 'Tra',
      TIMEFRAME: 'Periodo',
      VALID_FROM: 'Valido da',
      TO: 'al',
    },
  },
  modal: {
    LOGOUT_MODAL: 'Logout',
    LOGOUT_MESSAGE: 'È sicuro/a di voler effettuare il logout?',
    INACTIVITY_MESSAGE: 'Sei inattivo, sarai disconnesso in:\n',
    ICS: 'Evento in calendario',
    ICS_CONTENT: 'Clicchi su «Aggiungi al calendario», per creare un evento nel suo calendario personale.',
    ICS_YES: 'Aggiungi al calendario',
    ICS_NO: 'Chiusa',
    YES: 'Sì',
    NO: 'No',
    senselyExit: {
      TITLE: 'Desidera uscire dal Symptom Checker?',
      CONTENT: 'Se esce ora dal Symptom Checker, i dati da lei inseriti andranno persi. Desidera comunque uscire?',
      SAVE_ASSESSMENT: 'Prima di andarsene, desidera salvare il colloquio realizzato con il Symptom Checker?',
    },
    senselySaved: {
      TITLE: 'Sta uscendo dal Symptom Checker',
      CONTENT_SAVED: 'I suoi dati sono stati salvati. Prema ok per uscire dal Symptom Checker.',
    },
    announceReminder: {
      TITLE: 'Nota',
      YES: 'Si,era un\'emergenza',
      NO: 'Chiusa',
      CONTENT: 'Le notifiche tardive sono solamente consentite in caso di trattamento di emergenza e devono avvenire entro e non oltre i %{lateAppointmentDaysLimit} giorni.',
    },
    announceAmountExceeded: {
      TITLE: 'Numero massimo superato',
      YES: 'Chiama Medi24',
      NO: 'Chiusa',
      CONTENT: 'Registrazione tardiva di emergenza  può essere registrato online %{limit} volte. Per ulteriori registrazioni tardive chiamaci.',
      CONTENT_REFERRAL: 'Le richieste per consultazioni specialistiche possono venir effettuate online per un massimo di %{limit} volte all’anno. Per ulteriori richieste di consultazioni specialistiche la preghiamo di contattarci telefonicamente.',
    },
    referralNotAllowed: {
      TITLE: 'Richiesta di cosultazione specialistica non valida',
      YES: 'Chiama Medi24',
      NO: 'Chiusa',
      CONTENT: 'Può annunciare una richiesta di consultazione specialistica solamente se è stato registrato und appuntamento presso il medico di famiglia. ',
    },
    uploadPictureWarning: {
      TITLE: 'Nota',
      YES: 'Si',
      NO: 'No',
      CONTENT: 'La avevertiamo che, a secondo i termini assicurativi la richiesta di questi servizi potrebe ssere a pagamento. Lei è tuttavia dispota/o ad uzilizzare questi servizi?',
    },
    CLOSE: 'Chiusa',
  },
  appointmentRelease: {
    APPOINTMENT_RELEASE: 'Appuntamento accordato',
    FROM: 'Da ',
    TO: 'a ',
    EXTEND: 'Prorogare la fascia temporale di %{days} giorni.',
    EXTENSION_VALIDATED: 'L’appuntamento accordato è stato prorogato.',
    EXTENSION_ADVICE: 'Se desidera prorogare ulteriormente questo appuntamento accordato, la preghiamo di rivolgersi a Medi24.',
    CONTACT: 'Contattare Medi24',
    DETAIL: {
      SPECIALIST: 'Il tuo specialista',
      SPECIALTY: 'Specializzazione',
      TIMEFRAME: 'Periodo',
      SITUATION: 'Situazione',
    },
  },
  callback: {
    UPLOAD_PICTURES: 'Caricamento immagine',
    CALLBACK_REQUEST: 'Richiesta di richiamata',
    CALLBACK_DESCRIPTION: 'Per poterci preparare alla sua situazione, la preghiamo di descrivere la sua richiesta nel modo più preciso possibile.',
    DESCRIPTION: 'Descrizione',
    ARRANGE: 'Concorda richiamata',
    EDIT_CHANGES: 'Salva modifiche',
    EDIT_ADVICE: 'Se desidera modificare o eliminare questa richiesta di richiamata, la preghiamo di rivolgersi a Medi24.',
    CONTACT: 'Contattare Medi24',
    UPLOAD: 'Se dispone di foto, documenti, certificati o documentazione di altro genere, la può caricare qui.',
    UPLOAD_BUTTON: 'Carica file',
    WHEN_HOW: 'Quando e come possiamo raggiungerla?',
    DATE_INPUT: 'Data',
    PHONE_INPUT: 'Numero per il richiamo',
    TIME_INPUT: 'Orario',
    NO_TIMEFRAMES_AVAILABLE: 'In questo giorno non è disponibile alcuna fascia oraria, si prega di scegliere un’altra data.',
    CHARACTERS_LEFT: 'caratteri rimanenti',
    CALLBACK_REGISTERED: 'Abbiamo ricevuto la sua richiesta.',
    CALLBACK_DELETED: 'Il suo appuntamento per essere richiamato/a è stato correttamente eliminato.',
    CALENDAR: 'Aggiungi al calendario',
    REQUEST: 'La sua richiesta',
    FILES: 'Allegati',
    NUMBER: 'Numero per richiamata',
    calendarEvent: {
      EVENT_TITLE: 'Richiamata da parte di MyMedi24',
      FILE_NAME: 'richiamataMyMedi24',
      CREATION_ERROR: 'Creazione del file fallita',
      ICS_DESCRIPTION: 'Come richiesto, sarà contattato/a %{weekday} %{date} da %{role} di Medi24 tra le %{startTime} e le %{endTime}.',
      ICS_EDITION: 'Ricordi di cancellare l\'appuntamento precedente nel suo calendario. Non è più valido.',
      DOCTOR: 'un medico',
      NURSE: 'personale medico specializzato',
      PARTNER: 'Per poter gestire la sua richiesta di richiamata su MyMedi24, la preghiamo di effettuare l’accesso tramite l’app del suo assicuratore. %{partnerUrl}',
    },
    file: {
      NOT_AVAILABLE: 'Non possiamo visualizzare questo file',
      open: 'Apri documento',
      modalTitle: 'Immagine caricata',
    },
    CHANGES_DISCARDED: 'Modifiche rifiutate.',
    CALLBACK_MODIFIED: 'Modifiche salvate.',
    DISCARD_CHANGES: 'Rifiuta modifiche.',
    DELETE_WARNING: 'È sicuro/a di voler eliminare questa richiesta di richiamata? Non sarà più possibile ripristinarla in un secondo momento.',
    DELETE_MODAL: 'Elimina',
    SAVE_CHANGES: 'Salva modifiche',
    DETAIL: {
      DESCRIPTION_HTML: 'Ti chiameremo <b>%{date}</b> allo <b>%{number}</b> tra <b>%{hours}</b>.',
    },
    ACTION_CANCELED: 'Processo annullato',
    REQUIRED: '* CAMPO OBBLIGATORIO',
    type: {
      label: 'Tipo di richiamata',
      PHONE: 'Telefonata',
      VIDEO: 'Video chiamata',
      BOTH: 'Non importa',
    },
  },
  consultation: {
    TRIAGE: 'Raccomandazione di Medi24',
    triageRecommendation: {
      RECOMMENDATION_3: 'Le è stato consigliato di chiamare l\'ambulanza.',
      RECOMMENDATION_1: 'Le è stato consigliato di andare al pronto soccorso più vicino.',
      RECOMMENDATION_4: 'Le è stato consigliato di andare alla permanence.',
      RECOMMENDATION_5: 'Le è stato consigliato di andare in una clinica walk-in/alla permanence.',
      RECOMMENDATION_2: 'Le è stato consigliato di contattare il medico di famiglia, o altrimenti il medico di guardia.',
      RECOMMENDATION_6: 'Le è stato consigliato di contattare il medico di famiglia, o altrimenti il medico di guardia, entro 24 ore.',
      RECOMMENDATION_7: 'Le è stato consigliato di fissare un appuntamento dal suo medico di famiglia entro i prossimi 2 a 7 giorni .',
      RECOMMENDATION_8: 'Le è stato consigliato di andare in farmacia.',
      RECOMMENDATION_9: 'Le è stato consigliato di curarsi da solo/a seguendo i consigli ricevuti. Se la situazione dovesse peggiorare o cambiare, Medi24 è raggiungibile 24 ore.',
      RECOMMENDATION_283030000: 'Le è stato consigliato una teleconsultazione.',
    },
    triageTitle: {
      Test: 'This is an italian test',
    },
    SICK_LEAVE_NOTE: 'Certificato di incapacità lavorativa',
    PRESCRIPTION: 'Prescrizione',
    description: {
      ON: 'Del',
      AT: 'Presso',
    },
    symptomChecker: {
      TITLE: 'Symptom Checker',
      LINK: 'Cfr. valutazione',
    },
    callback: {
      FILES: 'File caricati',
      REQUEST: 'La sua richiesta',
      UPLOAD_PICTURE: 'Carica immagini',
      CALLBACK_REQUEST: 'Accordo di richiamata',
    },
    DOCUMENTS: 'Documenti',
    TILE: {
      BASED_ON: 'Basato su',
    },
    PREVIOUS: 'precedente',
    NEXT: 'successivo',
  },
  fromTo: { remaining: 'Giorni rimanenti: %{diff}' },
  tile: {
    history: {
      REGISTRATION: 'Inserisci evento',
      CONSULTATION: 'Consultazione',
      CONSULTATION_TITLE: 'Consultazione Medi24',
      APPOINTMENT_RELEASE: 'Appuntamento accordato',
      CALLBACK_REQUEST: 'Richiama',
      CALLBACK_REQUEST_TITLE: 'Medi24 la contatterà',
      ANNOUNCE_APPOITMENT: 'Annuncio',
      PAST_APPOINTMENT: 'Reregistrare un\' emergenza',
      LATE_REGISTRATION: 'Reregistrare un\' emergenza',
      ALL: 'Tutti',
      daysAdded: '%{days} giorni aggiunti.',
      extend: 'Per prorogare cliccare qui',
      DETAILS: 'Dettagli',
      CALLBACK_NUMBER: 'Numero',
      TYPE: 'Tipo',
      referral: 'Richiesta specialistica',
      urgent: 'Emergenza',
      phoneCallback: 'Chiamata telefonica',
      videoCallback: 'Videochiamata',
      event: 'Evento',
      events: 'Eventi',
    },
  },
  history: {
    mainTitle: 'La mia cartella clinica',
    title: {
      active: 'Eventi attivi',
      event: '%{count} Evento',
      events: '%{count} Eventi',
      pastEvents: 'Eventi passati',
    },
    date: 'Data',
    SEE_MORE: 'Visualizza di più',
    NO_EVENTS: 'Nessun risultato',
  },
  validator: {
    FILE: 'Si prega di caricare almeno un file',
    FILE_TOO_BIG: 'File troppo grande, dimensione massime: 50 MB',
    FILE_NOT_ACCEPTED: 'Questo tipo di file non è accettato',
    REQUIRED: 'Campo obbligatorio.',
    VALIDATE_ZIPCODE: 'Inserire un codice postale valido',
    VALIDATE_PHONE_NO_0: 'Si prega di inserire un numero telefonico corretto.',
    VALIDATE_PHONE: 'Numero di telefono non valido',
    VALIDATE_EMAIL: 'Indirizzo e-mail non valido',
    DATE_TOO_FAR: 'Si prega di selezionare una data dell’anno corrente.',
    DATE: 'Si prega di selezionare una data futura.',
  },
  doctorSpecialities: {
    R010000: 'Medicina iterna generale',
    R010200: 'Chirurgia',
    R010300: 'Dermatologia e venereologia',
    R010400: 'Ginecologia e ostetricia',
    R010600: 'Endocrinologia e diabetologia',
    R010700: 'Pneumologia',
    R010800: 'Neurochirurgia',
    R010900: 'Neurologia',
    R011000: 'Psichiatria e psicoterapia',
    R011300: 'Oftalmologia',
    R011400: 'Chirurgia ortopedica e malattie dell’apparato locomotore',
    R011500: 'Otorinolaringoiatria',
    R011600: 'Pediatria',
    R011700: 'Radiologia',
    R011800: 'Medicina tropicale e dei viaggi',
    R011900: 'Urologia',
    R012000: 'Reumatologia',
    R012200: 'Angiologia',
    R013500: 'Cardiologia',
    R013600: 'Ematologia',
    R014500: 'Gastroenterologia',
    R015200: 'Fisiatria e medicina riabilitativa',
    R015400: 'Allergologia e immunologia clinica',
    R015700: 'Infettivologia',
    R015900: 'Chirurgia maxillofacciale',
    R016300: 'Oncologia',
    R016400: 'Nefrologia',
    R030000: 'Chiropratica',
    R100000: 'Ospedale',
    R101400: 'Clinica riabilitativa',
    R103100: 'Cliniche psichiatriche',
    R110000: 'Casa di cura',
    Z999980: 'Medico',
  },
  registration: {
    DESCRIPTION: 'Descrizione',
    REGISTRATION_DETAILS: 'Inserisci evento',
    REGISTRATION: 'Inserisci evento',
    REGISTRATION_DESCRIPTION: 'Per aiutarci a comprendere la sua situazione, la preghiamo di compilare i seguenti campi.',
    URGENCY: 'Urgenza',
    urgencies: {
      URGENCY_URGENT: 'Emergenza',
      URGENCY_NORMAL: 'Normale',
    },
    placeholders: {
      DESCRIPTION: 'Mi rivolgo al mio medico di famiglia; vado dal Dr. Winter; ho la febbre; ecc.',
      DESCRIPTION_APPOINTMENT: 'Ho avuto un trattamento d\'emergenza all\'Inselspital; ho dovuto chiamare un\'ambulanza; ho avuto sintomi di avvelenamento ecc.\n'
        + '(Gli annunci di trattamenti non collegati a un\'emergenza non saranno considerati).',
      DESCRIPTION_REFERRAL: '"Il mio medico di famiglia ha richiesto una consultazione dermatologica." oppure "Il mio medico curante ha richiesto una consultazione specialistica presso und allergologo per effettuare accertamenti inerenti le mie reazioni allergiche." etc.',
    },
    ARRANGE: 'Genera inserimento evento',
    REFERRAL: 'Annuncio richiesta specialistica',
    CREATION: 'Inserimento evento generato',
    CANCEL: 'Annulare',
    SELECT: 'Seleziona...',
    DATE_TOP_MESSAGE: "Quando è avvenuta l'emergenza?",
    DATE_TOP_MESSAGE_REFERRAL: 'Data del primo appuntamento con il medico specialista?',
    DATE_BOTTOM_MESSAGE: 'La notifica di un trattamento di emergenza deve avvenire entre e non oltre i %{days} giorni. In caso di questioni riguardanti il suo modello assicurativo può contattare il assistenza MyMedi24 al numero %{number}.',
    DOCTOR_TOP_MESSAGE: 'Specializzazione del medico consulente?',
    DOCTOR_TOP_MESSAGE_REFERRAL: 'Quale è la specializzazione del medico consulente?',
    DOCTOR_BOTTOM_MESSAGE: "Le preghiamo di inserire la specialistia del medico che l'ha presa/o in carica o scelga \"ospedale\" se è stat/o tratttata/o in un ospedale.",
    DOCTOR_BOTTOM_MESSAGE_REFERRAL: 'Si prega di indicare la specializzazione del medico consulente o selezionare "ospedale" qualora il trattamento avvenga in un ospedale.',
    INFOS_DOCTOR: 'Nome e luogo del medico consulente',
  },
  announceAppointment: {
    ANNOUNCE: 'Approvazione dell\'appuntamento',
    urgencies: {
      URGENCY_URGENT: 'Trattamento d\'emergenza',
      URGENCY_NORMAL: 'Trattamento',
    },
    doctorSpeciality: 'Specialità del medico',
    FROM: 'DA:',
    TO: 'A:',
    EXTEND: 'Prorogare la fascia temporale di %{days} giorni.',
    EXTENSION_VALIDATED: 'L’appuntamento accordato è stato prorogato.',
    EXTENSION_ADVICE: 'Se desidera prorogare ulteriormente questo appuntamento accordato, la preghiamo di rivolgersi a Medi24.',
    CONTACT: 'Contattare Medi24',
  },
  profile: {
    TITLE: 'Profilo',
    SUBTITLE: 'I tuoi dati personali',
    WARNING: 'Abbiamo ricevuto i seguenti dati dal suo assicuratore per creare il suo dossier paziente. Se i dati non sono più attuali, la preghiamo di contattare il suo assicuratore per aggiornarli.',
    NAME: 'Nome',
    DATE_OF_BIRTH: 'Data di nascita',
    ADDRESS: 'Indirizzo',
    LANGUAGE: 'Lingua',
    CHANGE_LANGUAGE: 'Cambia lingua',
    LANGUAGE_SUBTITLE: 'Tieni presente che il cambio di lingua influenzerà l\'ulteriore utilizzo del servizio e del contatto.',
    languages: {
      ENGLISH: 'Inglese',
      FRENCH: 'Francese',
      ITALIAN: 'Italiano',
      DEUTSCH: 'Tedesco',
    },
    languagesModal: {
      title: 'Cambia lingua',
      subtitle: 'Seleziona la lingua preferita.',
      warning: 'Si noti che il cambio di lingua influirà sull\'ulteriore utilizzo del servizio e del contatto',
      back: 'Indietro',
      save: 'Salva modifiche',
    },
    ADDITIONNAL_TITLE: 'Ulteriori informazioni di contatto',
    ADDITIONNAL: 'Queste informazioni di contatto aggiuntive che condivide direttamente con Medi24, ci aiutano a offrirle un servizio della migliore qualità. Questi dati non saranno divulgati.',
    EMAIL: 'Indirizzo e-mail',
    MOBILE: 'Numero di cellulare',
    CHANGES_SAVED: 'Informazioni salvate',
  },
  feedback: {
    SUB_TITLE: 'Il logout è stato effettuato con successo, ora può chiudere la finestra del browser. '
      + '\n La preghiamo di lasciarci un feedback relativo alla sua esperienza per permetterci di migliorare il nostro servizio e di adattarlo alle sue esigenze.'
      + '\n Per continuare ad usare i servizi MyMedi24 la preghiamo di effettuare nuovamente il login con l\'account Helsana.'
      + '\n Grazie mille ed arrivederci!',
    DESCRIPTION: 'Feedback (facoltativo)',
    CONSENT: 'Acconsento a essere contattato/a dal servizio clienti per ulteriori dettagli.',
    SEND: 'Invia feedback',
    SUCCESS: 'Feedback inviato',
    RATING_MANDATORY: 'Per fornire una valutazione, clicchi sul numero di stelle desiderato (1=negativo, 5=molto positivo).',
  },
  eventDetails: {
    callback: 'Richiamata da Medi24',
  },
};

export default it;
