import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AutomaticLogoutModal from './AutomaticLogoutModal';

type Props = {
  isConnected: boolean,
  isIdle: boolean
};

type State = {};

class AutomaticLogoutContainer extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      this.props.isConnected && this.props.isIdle
        ? (
          <AutomaticLogoutModal
          />
        )
        : <div />
    );
  }
}

export default withRouter(connect((state) => ({
  isIdle: state.login.isIdle,
  isConnected: state.login.userSession !== null,
}))(AutomaticLogoutContainer));
