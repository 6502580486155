import RestClientAuthenticated from '../clients/restClientAuthenticated';

const loginHistoryApi = {
    login: () => new RestClientAuthenticated(`/loginHistory`, 'GET').execute(),

    logout: () => new RestClientAuthenticated('/loginHistory/logout', 'GET').execute(),

};

export default loginHistoryApi;
