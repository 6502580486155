import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import { MessageQuestion } from 'iconsax-react';
import ModalBase from '../../../../modal/ModalBase';
import {
  fireActionTriggerCustomEvent,
  updateEventInfo,
} from '../../../../../services/analyticsService';
import { analyticsParams, callIfMobile } from '../../../../../const';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';

type Props = {
  small?: boolean,
  contactNumber: string,
};

type State = {
  isOpen: boolean,
};

class Medi24SupportTile extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  callSupport = () => {
    updateEventInfo({
      category: analyticsParams.event.category.buttonClick,
      action: analyticsParams.event.action.CLICK_SUPPORT,
      label: 'Medi24 Support',
      timestamp: moment().toISOString(),
    });
    fireActionTriggerCustomEvent('#click', window.digitalData);
    updateEventInfo({}, true);
    if (this.props.contactNumber) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  render() {
    return (
      <>
        <ModalBase
          isOpen={this.state.isOpen}
          toggle={() => this.setState({ isOpen: !this.state.isOpen })}
          title={I18n.t('contact.HINT_TITLE')}
          secondaryActionButton={I18n.t('actions.BACK')}
        >
          <div className="classic-text">
            {`${I18n.t('contact.HINT_SUPPORT')} `}
            <a className="call-link" role="link" tabIndex={0} onClick={() => { callIfMobile(this.props.contactNumber); }}>
              {this.props.contactNumber}
            </a>
          </div>
        </ModalBase>
        <FeaturedTile
          id="click"
          title={I18n.t('contact.SUPPORT_TITLE')}
          icon={<MessageQuestion />}
          onClick={this.callSupport}
        />
      </>
    );
  }
}
export default connect((state) => ({
  contactNumber: state.contactNumber.contactNumber,
}))(Medi24SupportTile);
