import { jsonResponseCatcher, toJsonError } from '../handleError';
import HttpClient from './httpClient';

export default class RestClient extends HttpClient {
  constructor(apiExtensionUrl: string, method: string = 'GET') {
    super(apiExtensionUrl, method);
  }

  execute() {
    return super
      .execute()
      .then(jsonResponseCatcher)
      .catch(toJsonError);
  }
}
