import { reduce } from '../../network/reduce';
import { LoginStateType } from './loginTypes';

export const USER_SESSION = 'USER_SESSION';
export const IS_IDLE = 'IS_IDLE';
export const IDLE_TIME_LEFT = 'IDLE_TIME_LEFT';
export const FIXED_IDLE_TIME_LEFT = 'FIXED_IDLE_TIME_LEFT';
export const IS_USER_ALLOWED_TO_GO_BACK = 'IS_USER_ALLOWED_TO_GO_BACK';

const initialState: LoginStateType = {
  userSession: null,
  isIdle: false,
  isUserAllowedToGoBack: undefined,
};

// TODO devrait s'appeler sessionReducer : on travaille avec la session, pas avec le login
export const loginReducer = reduce(initialState, {
  [USER_SESSION]: 'userSession',
  [IS_IDLE]: 'isIdle',
  [IDLE_TIME_LEFT]: 'idleTimeLeft',
  [FIXED_IDLE_TIME_LEFT]: 'fixedIdleTimeLeft',
  [IS_USER_ALLOWED_TO_GO_BACK]: 'isUserAllowedToGoBack',
});
