import React, { useLayoutEffect, useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import { Clock } from 'iconsax-react';
import paperclip from '../../../../img/paperclip.svg';
import Badge, { BadgeProps } from '../../../badge/Badge';
import { extractDuration } from '../../../../services/dateService/dateService';

type Props = {
  badge: BadgeProps,
  documents?: string[],
  illustration: string,
  onClick?: () => void,
  title: string,
  startDate: string,
  endDate?: string,
  metaInfo?: string,
};

export default function EventTile({
  badge,
  documents,
  illustration,
  onClick,
  title,
  startDate,
  endDate,
  metaInfo,
}: Props) {
  const barRef: React.MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const {
    bodyTitle,
    bodySubtitle,
    daysLeft,
    periodDuration,
  } = extractDuration(startDate, endDate);

  useLayoutEffect(() => {
    if (barRef.current) {
      const progress = (daysLeft * 100) / periodDuration;

      if (progress >= 50) {
        barRef.current.classList.add('countdown-bar--positive');
      }
    }
  }, [barRef.current, daysLeft, periodDuration]);

  return (
    <div className="event">
      <div className="illustration">
        <img className="illustration-img" src={illustration} alt="history-img" />
        {!!documents?.length && (
          <img src={paperclip} alt="paperclip" className="attachment-icon" />
        )}
      </div>

      <div className="content">
        <div className="content-infos">
          <div className="content-header">
            <h2 className="content-header-title">{title}</h2>
            {badge && (
              <Badge
                text={badge.text}
                type={badge.type}
              />
            )}
          </div>

          <div className="content-body">
            <h3 className="content-body-title">{bodyTitle}</h3>
            {!!bodySubtitle && (
              <p className="content-body-subtitle">
                {bodySubtitle}
              </p>
            )}
          </div>

          {metaInfo && (
            <div className="content-body">
              <h3 className="content-body-title">{metaInfo}</h3>
            </div>
          )}
        </div>

        {!!daysLeft && (
          <div ref={barRef} className="countdown-bar">
            <div className="countdown">
              <Clock className="countdown-icon" />
              <span>{I18n.t('fromTo.remaining', { diff: daysLeft })}</span>
            </div>
            <progress className="bar" max={100} value={(daysLeft * 100) / periodDuration} />
          </div>
        )}
      </div>

      {onClick && (
        <button
          className="hidden-button"
          type="button"
          onClick={onClick}
          aria-label={`select ${title} event`}
        />
      )}
    </div>
  );
}
