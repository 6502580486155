import React from 'react';
import { I18n } from 'react-redux-i18n';
import manCalendar from '../../img/manCalendar.png';

function NoEventsTile() {
  return (
    <div className="empty-state">
      <img className="empty-state-illustration" src={manCalendar} alt="no-event" />
      <h3 className="empty-state-content">
        {I18n.t('dashboardPage.NO_NEXT_APPOINTMENT')}
      </h3>
    </div>
  );
}

export default NoEventsTile;
