import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Message } from 'iconsax-react';
import {
  analyticsParams,
  HAVE_SYMPTOM_CHECKER, SYMPTOM_CHECKER_PAGE_ROUTE,
} from '../../../../../const';
import { updateProcessData } from '../../../../../services/analyticsService';
import { SessionServiceHolder } from '../../../../../services/session/SessionServiceHolder';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';

type Props = {
  history: any,
  small: boolean,
};


type State = {
};

function SymptomCheckerTile() {
  const history = useHistory();

  if (!SessionServiceHolder.hasPermission(HAVE_SYMPTOM_CHECKER)) {
    return false;
  }

  return (
    <FeaturedTile
      title={I18n.t('services.SYMPTOM_CHECKER')}
      icon={<Message />}
      id="symptom-checker-tile"
      type={FeaturedTileType.PRIMARY}
      onClick={() => {
        updateProcessData({
          processName: analyticsParams.processName.SYMPTOM_CHECKER,
          status: analyticsParams.status.STARTED,
          startTime: moment().toISOString(),
        }, true);
        history.push(SYMPTOM_CHECKER_PAGE_ROUTE);
      }}
    />
  );
}

export default withRouter(SymptomCheckerTile);
