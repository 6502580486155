import React from 'react';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { store as storeFunction } from './network/reduce';
import {
  BEFORE_WE_START_ROUTE,
  LOGOUT_PAGE_ROUTE, WELCOME_PAGE_ROUTE,
} from './const';
import configurationApi from './network/api/configurationApi';

import servicePackageApi from './network/api/servicePackageApi';
import { CONTACT_NUMBER } from './state/contactNumberReducer';
import AutomaticLogoutContainer from './components/logout/AutomaticLogoutContainer';
import PublicRouter from './routers/PublicRouter';
import PrivateRouter from './routers/PrivateRouter';

type Props = {
  dispatch: Function,
  history: {
    listen: Function,
  },
  location: {
    pathname: string,
  },
  isConnected: boolean
};

type State = {};

class App extends React.Component<Props, State> {
  state = {};

  componentDidMount(): void {
    configurationApi
      .fetchEnv()
      .then((response) => response.text())
      .then((environment) => {
        if (environment !== 'dev') {
          Sentry.init({
            dsn: 'https://f57df85b24ba4ed586bf8f7dceb7b9d4@o265782.ingest.sentry.io/5270374',
            release: `pda-medi24@${String(process.env.REACT_APP_GIT_SHA)}`,
            environment,
            denyUrls: [
              /clinician-web\.sense\.ly/i,
            ],
          });
        }
      })
      .catch((error) => console.log('Could not load environment', { error }));

    if (this.props.location.pathname !== LOGOUT_PAGE_ROUTE) {
      servicePackageApi
        .fetchCustomerSupportNumber()
        .then((response) => (response.text()))
        .then((contactNumberFetched) => {
          this.props.dispatch(storeFunction(CONTACT_NUMBER, contactNumberFetched));
        })
        .catch(() => {
          console.log('Cannot fetch customers support number');
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const appElement = document.getElementById('app');
      if (appElement) {
        appElement.scrollTo(0, 0);
      }
    }
  }

  render() {
    return (
      <div id="app">
        <div className="container">
          <AutomaticLogoutContainer />
          <div className="content-container">
            <main id="main-content">
              {this.props.isConnected && this.props.location.pathname !== BEFORE_WE_START_ROUTE && this.props.location.pathname !== WELCOME_PAGE_ROUTE
                ? <PrivateRouter />
                : <PublicRouter />}
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect((state) => ({
  isConnected: state.login.userSession !== null,
}))(App));
