import React from 'react';

type Props = {

};

type State = {

};

class Loader extends React.Component<Props, State> {
  render() {
    return (
      <div className="lds-div">
        <div className="lds-spinner">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}
export default Loader;
