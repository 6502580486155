import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import {
  ArrowLeft,
  Calendar,
  Clock,
  CloseCircle,
  ExportSquare,
  Eye,
} from 'iconsax-react';
import appointmentApi from '../../network/api/appointmentApi';
import { notifyError } from '../../network/notification';
import Loader from '../../components/layout/Loader';
import { ConsultationDetailsType } from './ConsultationDetailsType';
import {
  extractDuration,
  handleDaylightSavingTime,
  timeFrameStringFromStartDateAndEndDateHours,
} from '../../services/dateService/dateService';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import ModalBase from '../../components/modal/ModalBase';
import Button from '../../components/buttons/Button';
import consultationImage from '../../img/past_consultation_large.svg';
import ButtonType from '../../types/ButtonType';
import { BadgeType } from '../../types/BadgeTypes';
import Badge from '../../components/badge/Badge';
import { AppointmentReleaseType } from '../appointmentRelease/AppointmentReleaseType';
import Attributes from '../../components/appointmentRelease/Attributes';
import { CallbackFiles } from '../callBack/callBackDetails/CallBackDetailsType';
import FileVisualiser from '../../components/fileInputPda/FileVisualiser';


type Props = {
  idConsultation: string,
  handleModalState?: Function,
};

type State = {
  consultationDetails: ConsultationDetailsType,
  nextConsultation: ConsultationDetailsType,
  previousConsultation: ConsultationDetailsType,
  loading: boolean,
  animation: boolean,
  swipeDirection: 'right' | 'left'
};

function ConsultationDetails({ idConsultation, handleModalState }: Props) {
  const [state, setState] = useState<State>({
    consultationDetails: undefined,
    nextConsultation: undefined,
    previousConsultation: undefined,
    loading: true,
    animation: true,
    swipeDirection: 'right' as 'right',
  });

  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewedFile, setPreviewedFile] = useState<CallbackFiles | null>(null);

  const previewFile = (fileToDisplay: CallbackFiles) => {
    setIsPreviewOpen(true);
    setPreviewedFile(fileToDisplay);
  };

  const closeFilePreview = () => {
    setIsPreviewOpen(false);
    setPreviewedFile(null);
  };

  const getTypeBadge = (appointmentRelease: AppointmentReleaseType) => {
    if (appointmentRelease.isUrgent) {
      return {
        text: I18n.t('tile.history.urgent'),
        type: BadgeType.SECONDARY,
      };
    }

    if (appointmentRelease.extended) {
      return {
        text: I18n.t('tile.history.extension'),
        type: BadgeType.SECONDARY_OUTLINED,
      };
    }

    if (appointmentRelease.isReferral) {
      return {
        text: I18n.t('tile.history.referral'),
        type: BadgeType.PRIMARY_OUTLINED,
      };
    }

    return null;
  };

  useOnComponentMounted(() => {
    appointmentApi
      .getConsultationDetails(idConsultation)
      .then((response) => response.json())
      .then((consultationDetails: ConsultationDetailsType) => {
        setState((oldState: State) => ({
          ...oldState,
          consultationDetails,
        }));
      })
      .catch(notifyError)
      .finally(() => {
        setState((oldState: State) => ({
          ...oldState,
          loading: false,
        }));
      });
  });

  if (state.loading) {
    return (
      <ModalBase
        isOpen
        toggle={handleModalState}
        fullScreenPopin
        className="preview-popin details-popin"
      >
        <Loader />
      </ModalBase>
    );
  }

  return (
    <>
      <ModalBase
        isOpen
        toggle={handleModalState}
        fullScreenPopin
        className="preview-popin consultation-details details-popin"
      >
        <>
          <Button
            id="modal-close_details_large"
            ariaLabel="close"
            onClick={() => handleModalState()}
            icon={<CloseCircle className="preview-leave" />}
          />
          <Button
            id="modal-close_details_small"
            ariaLabel="close"
            onClick={() => handleModalState()}
            icon={<ArrowLeft className="preview-leave" />}
          />

          {state.consultationDetails && (
            <>
              <div className="details-hero details-hero--consultation">
                <img
                  src={consultationImage}
                  alt="Hero's background"
                />
              </div>

              <div className="details-content callback-page">
                <div className="callback-page-header">
                  <h2 className="details-text">
                    {I18n.t('services.CONSULTATION')}
                  </h2>

                  <div className="callback-page-subtitle-container">
                    <div className="consultation-date-container">
                      <Calendar />
                      <p className="callback-page-value">
                        {moment(state.consultationDetails.sortingDate).format(`${I18n.t('date.ONLY_DATE_FORMAT')}`)}
                      </p>
                    </div>

                    <div className="consultation-date-container">
                      <Clock />
                      <p className="callback-page-value">
                        {moment(state.consultationDetails.sortingDate).format(I18n.t('date.ONLY_HOURS_FORMAT'))}
                      </p>
                    </div>
                  </div>
                </div>

                {state.consultationDetails.hasSymptomChecker
                  && (
                    <div className="callback-inputs" id="symptomCheckerBlock">
                      <div className="callback-input-group">
                        <h3 className="callback-data-label">
                          {I18n.t('services.SYMPTOM_CHECKER')}
                        </h3>
                        <a
                          href={state.consultationDetails.mayoLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            type={ButtonType.TEXT}
                            text={state.consultationDetails.urgency}
                            icon={(
                              <ExportSquare />
                            )}
                          />
                        </a>
                      </div>
                    </div>
                  )}
                <div className="callback-page-separator" />

                {state.consultationDetails.triage
                  && (
                    <>
                      <div id="triageBlock" className="callback-inputs">
                        <h3 className="callback-data-label">{I18n.t('consultation.TRIAGE')}</h3>
                        <p className="callback-data-value">
                          {/*
                          * Triage results are always in German so we translate them to patient
                            language
                          */}
                          {I18n.t(`consultation.triageTitle.${state.consultationDetails.triage}`)}
                        </p>
                        {state.consultationDetails.triageRecommendation
                          && (
                            <p className="callback-data-value">
                              {I18n.t(`consultation.triageRecommendation.${state.consultationDetails.triageRecommendation}`)}
                            </p>
                          )}
                      </div>
                      <div className="callback-page-separator" />
                    </>
                  )}

                {state.consultationDetails.appointmentRelease
                  && (
                    <div className="callback-page">
                      <div className="callback-page-action">
                        <h3 className="callback-page-subtitle">
                          {I18n.t('tile.history.APPOINTMENT_RELEASE')}
                        </h3>
                        {getTypeBadge(state.consultationDetails.appointmentRelease) && (
                          <Badge
                            text={getTypeBadge(state.consultationDetails.appointmentRelease).text}
                            type={getTypeBadge(state.consultationDetails.appointmentRelease).type}
                          />
                        )}
                      </div>

                      <Attributes
                        appointmentRelease={state.consultationDetails.appointmentRelease}
                        isConsultation
                      />

                      <div className="callback-page-separator" />

                      {state.consultationDetails.appointmentRelease.description && (
                        <>
                          <div id="callbackBlock" className="callback-inputs">
                            <div className="callback-input-group">
                              <p className="callback-data-label">
                                {I18n.t('callback.DESCRIPTION')}
                              </p>
                              <p className="callback-data-value">
                                {state.consultationDetails.appointmentRelease.description}
                              </p>
                            </div>
                          </div>
                          <div className="callback-page-separator" />
                        </>
                      )}

                      {state.consultationDetails.appointmentRelease.doctorInfos && (
                        <>
                          <div id="callbackBlock" className="callback-inputs">
                            <div className="callback-input-group">
                              <p className="callback-data-label">
                                {I18n.t('registration.INFOS_DOCTOR')}
                              </p>
                              <p className="callback-data-value">
                                {state.consultationDetails.appointmentRelease.doctorInfos}
                              </p>
                            </div>
                          </div>
                          <div className="callback-page-separator" />
                        </>
                      )}
                    </div>
                  )}

                {state.consultationDetails.registration
                  && (
                    <>
                      <div id="registration" className="callback-page">
                        <div className="callback-page-data-container">
                          <div className="callback-page-data-group">
                            <h4 className="callback-data-label">{I18n.t('tile.history.REGISTRATION')}</h4>
                            <h4 className="callback-data-label">{I18n.t('tile.history.date')}</h4>
                          </div>

                          <div className="callback-page-data-group">
                            <p className="callback-data-value">
                              {I18n.t(`registration.urgencies.${state.consultationDetails.registration.urgency}`)}
                            </p>

                            <div className="callback-data-value-group">
                              <p className="callback-data-value">
                                {moment(state.consultationDetails.registration.modifiedOn).format(I18n.t('date.ONLY_DATE_FORMAT'))}
                              </p>
                              <p className="callback-data-value">
                                {moment(state.consultationDetails.registration.modifiedOn).format(I18n.t('date.ONLY_HOURS_FORMAT'))}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="callback-page-separator" />
                    </>
                  )}

                {state.consultationDetails.announceAppointment && (
                  <div id="announce-appointment" className="callback-page">
                    <div className="callback-page-action">
                      <h3 className="callback-page-subtitle">
                        {I18n.t('tile.history.APPOINTMENT_RELEASE')}
                      </h3>
                      {state.consultationDetails.announceAppointment.isUrgent && (
                        <Badge
                          text={I18n.t('tile.history.urgent')}
                          type={BadgeType.SECONDARY}
                        />
                      )}
                    </div>

                    <div className="callback-page-data-container">
                      <div className="callback-page-data-group">
                        <h4 className="callback-data-label">
                          {state.consultationDetails.announceAppointment.isDummy
                            ? I18n.t('appointmentRelease.DETAIL.SPECIALTY')
                            : state.consultationDetails.announceAppointment.doctorName
                              ? I18n.t('appointmentRelease.DETAIL.SPECIALIST')
                              : I18n.t('appointmentRelease.DETAIL.SITUATION')}
                        </h4>

                        <h4 className="callback-data-label">{I18n.t('appointmentRelease.DETAIL.TIMEFRAME')}</h4>

                        {state.consultationDetails.announceAppointment.extended && (
                          <h4 className="callback-data-label">
                            {I18n.t('tile.history.daysAdded', { days: '' })}
                          </h4>
                        )}
                      </div>

                      <div className="callback-page-data-group">
                        <div className="callback-data-value-group">
                          <p className="callback-data-value">
                            {state.consultationDetails.announceAppointment.isDummy
                              ? I18n.t(`doctorSpecialities.${state.consultationDetails.announceAppointment.doctorSpeciality}`)
                              : state.consultationDetails.announceAppointment.doctorName
                              || I18n.t(`registration.urgencies.${state.consultationDetails.announceAppointment.urgency}`)}
                          </p>

                          {!state.consultationDetails.announceAppointment.isDummy
                            && state.consultationDetails.announceAppointment.doctorName
                            && (
                              <p className="callback-data-value">
                                {state.consultationDetails.announceAppointment.address}
                              </p>
                            )}
                        </div>

                        <p className="callback-data-value">
                          {extractDuration(
                            state.consultationDetails.announceAppointment.startDate.toString(),
                            state.consultationDetails.announceAppointment.endDate.toString(),
                          ).bodyTitle}
                        </p>

                        {state.consultationDetails.announceAppointment.extended && (
                          <p className="callback-data-value">
                            {handleDaylightSavingTime(moment(state.consultationDetails.announceAppointment.endDate)).diff(handleDaylightSavingTime(moment(state.consultationDetails.announceAppointment.startDate)), 'days')}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="callback-page-separator" />

                    {state.consultationDetails.announceAppointment.description && (
                      <>
                        <div id="callbackBlock" className="callback-inputs">
                          <div className="callback-input-group">
                            <p className="callback-data-label">
                              {I18n.t('callback.DESCRIPTION')}
                            </p>
                            <p className="callback-data-value">
                              {state.consultationDetails.announceAppointment.description}
                            </p>
                          </div>
                        </div>
                        <div className="callback-page-separator" />
                      </>
                    )}

                    {state.consultationDetails.announceAppointment.doctorInfos && (
                      <>
                        <div className="callback-inputs">
                          <div className="callback-input-group">
                            <p className="callback-data-label">
                              {I18n.t('registration.INFOS_DOCTOR')}
                            </p>
                            <p className="callback-data-value">
                              {state.consultationDetails.announceAppointment.doctorInfos}
                            </p>
                          </div>
                        </div>
                        <div className="callback-page-separator" />
                      </>
                    )}
                  </div>
                )}

                {state.consultationDetails.sickLeaveNote
                  && (
                    <>
                      <h3 className="callback-page-subtitle">
                        {I18n.t('consultation.DOCUMENTS')}
                      </h3>

                      <div className="callback-page-data-container">
                        <div className="callback-page-data-group">
                          <h4 className="callback-data-label">{I18n.t('consultation.SICK_LEAVE_NOTE')}</h4>
                        </div>

                        <div className="callback-page-data-group">
                          <p className="callback-data-value">
                            {extractDuration(
                              state.consultationDetails.sickLeaveNote.startDate.toString(),
                              state.consultationDetails.sickLeaveNote.endDate.toString(),
                            ).bodyTitle}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                {(state.consultationDetails.prescription
                  && state.consultationDetails.prescription.medications.length > 0)
                  && (
                    <div id="prescriptionBlock" className="callback-page-data-container">
                      <div className="callback-page-data-group">
                        <h4 className="callback-data-label">
                          {`${I18n.t('consultation.PRESCRIPTION')} - ${state.consultationDetails.prescription.doctorName}`}
                        </h4>
                      </div>

                      <div className="callback-page-data-group">
                        <p className="callback-data-value">
                          {state.consultationDetails.prescription.medications.map((medication) => (
                            <div className="medication">{medication.name}</div>
                          ))}
                        </p>
                      </div>
                    </div>
                  )}

                {(state.consultationDetails.files
                  && state.consultationDetails.files.length > 0)
                  && (
                    <>
                      <div className="callback-inputs">
                        <div className="callback-input-group">
                          <p className="callback-data-label">
                            {I18n.t('consultation.DOCUMENTS')}
                          </p>
                          {state.consultationDetails.files.map((file: CallbackFiles) => (
                            <div key={file.name} className="callback-page-action">
                              <h3 className="callback-data-value">{file.name}</h3>
                              <Button
                                type={ButtonType.TEXT}
                                htmlType="button"
                                icon={<Eye />}
                                onClick={() => previewFile(file)}
                                text={I18n.t('callback.file.open')}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="callback-page-separator" />
                    </>
                  )}

                {(state.consultationDetails.phonecalls
                  && state.consultationDetails.phonecalls.length > 0
                  && (
                    <>
                      <div className="callback-page-action">
                        <h3 className="callback-page-subtitle">
                          {I18n.t('consultation.callback.CALLBACK_REQUEST')}
                        </h3>
                      </div>
                      {(state.consultationDetails.phonecalls.map((callbackDetail) => (
                        <>
                          <div className="callback-page-data-container">
                            <div className="callback-page-data-group">
                              <h4 className="callback-data-label">
                                {I18n.t('date.period.BETWEEN')}
                              </h4>
                              <h4 className="callback-data-label">
                                {I18n.t('tile.history.CALLBACK_NUMBER')}
                              </h4>
                            </div>

                            <div className="callback-page-data-group">
                              <p className="callback-data-value">
                                {timeFrameStringFromStartDateAndEndDateHours(
                                  callbackDetail.startDate,
                                  callbackDetail.endDate,
                                )}
                              </p>

                              <p className="callback-data-value">
                                {callbackDetail.phoneNumber}
                              </p>
                            </div>
                          </div>

                          <div id="callbackBlock" className="callback-inputs">
                            <div className="callback-input-group">
                              <p className="callback-data-label">
                                {I18n.t('consultation.callback.REQUEST')}
                              </p>
                              <p className="callback-data-value">
                                {callbackDetail.description}
                              </p>
                            </div>
                          </div>

                          <div className="callback-page-separator" />
                        </>
                      )))}
                    </>
                  ))}
              </div>
            </>
          )}
        </>
      </ModalBase>
      <ModalBase
        isOpen={isPreviewOpen && !!previewedFile}
        toggle={closeFilePreview}
        title={I18n.t('callback.file.modalTitle')}
        titleAction={(
          <>
            <Button
              id="modal-close_mobile"
              ariaLabel="close"
              onClick={closeFilePreview}
              icon={<CloseCircle className="preview-leave" />}
            />
            <Button
              id="modal-close_desktop"
              ariaLabel="close"
              onClick={closeFilePreview}
              icon={<ArrowLeft className="preview-leave" />}
            />
          </>
        )}
        actionButton={I18n.t('actions.BACK')}
        action={closeFilePreview}
        fullScreenPopin
        className="preview-popin preview-popin--nested"
      >
        <div className="callback-page">
          {previewedFile && <FileVisualiser file={previewedFile} />}
          <div className="callback-page-action">
            <h3 className="callback-data-value">{previewedFile?.name}</h3>
          </div>
        </div>
      </ModalBase>
    </>
  );
} export default withRouter(ConsultationDetails);
