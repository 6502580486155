import React from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { DATA_PRIVACY_ROUTE, COMPANY_LEGAL_INFORMATION, TERMS_AND_CONDITION_ROUTE } from '../../const';

function Footer() {
  const history = useHistory();
    return (
    <div className="footer">
      <span className="footer-copyright">{I18n.t('footer.COPYRIGHT')}</span>
      <div className="footer-link-container">
        <a className="footer-link" onClick={() => history.push(DATA_PRIVACY_ROUTE)}>{I18n.t('footer.link.DATA_PROTECTION')}</a>
        <a className="footer-link" onClick={() => history.push(TERMS_AND_CONDITION_ROUTE)}>{I18n.t('footer.link.TERMS_AND_CONDITION')}</a>
          <a className="footer-link" onClick={() => history.push(COMPANY_LEGAL_INFORMATION)}>{I18n.t('footer.link.COMPANY_LEGAL_INFORMATION')}</a>
          <a className="footer-link" onClick={() => // @ts-ignore
              tarteaucitron.userInterface.openPanel()}>{I18n.t('footer.link.HANDLE_COOKIES')}</a>
      </div>
    </div>
  );
}

export default Footer;
