import { createEvent } from 'ics';
import FileSaver from 'file-saver';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/fr';

import { notifyErrorWithMessage } from '../../network/notification';
import { currentLanguage } from '../languageService';
import { FileMimeType, TRIGGER } from '../../const';

export async function createIcsFile(startDate: moment.Moment, endDate: moment.Moment, isUploadPicture: boolean) {
  const args = {
    weekday: moment(startDate).locale(currentLanguage().code).format('dddd'),
    date: moment(startDate).format(I18n.t('date.ONLY_DATE_FORMAT')),
    role: isUploadPicture ? I18n.t('callback.calendarEvent.DOCTOR') : I18n.t('callback.calendarEvent.NURSE'),
    startTime: moment(startDate).format(I18n.t('date.ONLY_HOURS_FORMAT')),
    endTime: moment(endDate).format(I18n.t('date.ONLY_HOURS_FORMAT')),
  };
  createEvent({
    // @ts-ignore
    start: moment(startDate).format(I18n.t('date.ICS_DATE_FORMAT')).split('-'),
    // @ts-ignore
    end: moment(endDate).format(I18n.t('date.ICS_DATE_FORMAT')).split('-'),
    title: I18n.t('callback.calendarEvent.EVENT_TITLE'),
    description: `${I18n.t('callback.calendarEvent.ICS_DESCRIPTION', args)}`,
    status: 'CONFIRMED',
    alarms: [{ action: 'display', trigger: TRIGGER }],
    busyStatus: 'TENTATIVE',
  }, (error, value) => {
    if (error) {
      console.log(error);
      notifyErrorWithMessage(I18n.t('callback.calendarEvent.CREATION_ERROR'));
    } else {
      FileSaver.saveAs(
        new Blob([value], { type: FileMimeType.calendar }),
        `${I18n.t('callback.calendarEvent.FILE_NAME')}.ics`,
      );
    }
  });
}
