import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import userApi from '../../../network/api/userApi';
import { DASHBOARD_PAGE_ROUTE } from '../../../const';
import { notifyError } from '../../../network/notification';
import partnerApi from '../../../network/api/partnerApi';
import Button from '../../../components/buttons/Button';
import ButtonType from '../../../types/ButtonType';
import { useOnComponentMounted } from '../../../lib/react-hook-alias/ReactHookAlias';

type Props = {
  history: any,
};

export default function BeforeWeStart({ history }: Props) {
  const [text, setText] = useState<string>(undefined);

  const acknowledgeDataPrivacy = () => {
    userApi
      .setDateForTermsAndConditions()
      .then(() => history.push(DASHBOARD_PAGE_ROUTE))
      .catch(notifyError);
  };

  useOnComponentMounted(() => {
    partnerApi.fetchPartnerDataPrivacy()
      .then((res) => res.text())
      .then(setText)
      .catch(notifyError);
  });

  return (
    <div className="legal-content-page">
      <div className="legal-content-page-header">
        <h1 className="legal-content-page-title">
          {I18n.t('dataPrivacy.TITLE_BEFORE')}
        </h1>

        <p className="legal-content-page-text">
          {I18n.t('dataPrivacy.SUB_TITLE_FIRST_TIME')}
        </p>
      </div>

      <div className="legal-content-page-separator" />

      <div
        className="legal-content-page-body"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div className="button-group">
        <Button
          id="button"
          type={ButtonType.PRIMARY}
          onClick={acknowledgeDataPrivacy}
          text={I18n.t('dataPrivacy.CONTINUE')}
        />
      </div>
    </div>
  );
}
