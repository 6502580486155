import { deviceIsMobile } from './services/displayService';

export const baseApiUrl = '/api';

// Routes
export const ERROR_PAGE_ROUTE = '/error';
export const ERROR_BLOCKED_PAGE_ROUTE = '/error?error=BLOCKED';
export const WELCOME_PAGE_ROUTE = '/welcome';
export const DATA_PRIVACY_ROUTE = '/data-privacy';
export const COMPANY_LEGAL_INFORMATION = '/company-legal-information';
export const TERMS_AND_CONDITION_ROUTE = '/terms-conditions';
export const BEFORE_WE_START_ROUTE = '/before-we-start';
export const DASHBOARD_PAGE_ROUTE = '/dashboard';
export const SESSION_PAGE_ROUTE = '/session';
export const CONTACT_PAGE_ROUTE = '/contact';
export const REGISTRATION_PAGE_ROUTE = '/registration';
export const HISTORY_PAGE_ROUTE = '/history';
export const PROFILE_PAGE_ROUTE = '/profile';
export const LOGOUT_PAGE_ROUTE = '/logout';
export const CALLBACK_REQUEST_PAGE_ROUTE = `${CONTACT_PAGE_ROUTE}/callback`;
export const UPLOAD_PICTURE_PAGE_ROUTE = `${CONTACT_PAGE_ROUTE}/uploadpicture`;
export const SYMPTOM_CHECKER_PAGE_ROUTE = `${DASHBOARD_PAGE_ROUTE}/symptomChecker`;
export const SYMPTOM_CHECKER_RESULTS_PAGE_ROUTE = `${DASHBOARD_PAGE_ROUTE}/results`;
export const EXPORT_FEEDBACK_PAGE_ROUTE = '/admin/export/feedbacks';
export const ANNOUCEMENT_PAGE_ROUTE = '/announcement';

const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());


// Analytics
export const pageNameByPathname = (pathname: string) => {
  const result = pathname.slice(1).replace(/\//g, ' ').replace(/-/g, ' ').replace(/([A-Z])/g, ' $1');
  return capitalize(result);
};


export const TRIGGER = { hours: 1, minutes: 0, before: true };

export type CallbackRequestTypes = 'DOCTOR' | 'NURSE';

export const callbackRequestStaff = {
  DOCTOR: 'DOCTOR',
  NURSE: 'NURSE',
};

export const HAVE_CALLBACK_REQUEST = 'HAVE_CALLBACK_REQUEST';
export const HAVE_REGISTRATION = 'HAVE_REGISTRATION';
export const HAVE_SYMPTOM_CHECKER = 'HAVE_SYMPTOM_CHECKER';
export const HAVE_UPLOAD_PICTURE = 'HAVE_UPLOAD_PICTURE';
export const HAVE_APPOINTMENT_RELEASE = 'HAVE_APPOINTMENT_RELEASE';
export const HAVE_LATE_REGISTRATION = 'HAVE_LATE_REGISTRATION';
export const HAVE_PAST_APPOINTMENT_RELEASE = 'HAVE_PAST_APPOINTMENT_RELEASE';
export const HAVE_REFERRAL = 'HAVE_REFERRAL';


export const ERROR = 'error';
export const PARTNER = 'partner';
export const CONTRACT_ID_PATIENT = 'contractIdPatient';
export const CONTRACT_ID_USER = 'contractIdUser';
export const TOKEN = 'token';
export const PARTNER_NAME = 'partnerName';
export const FORM_SUBMITED = 'formSubmited';
export const TYPE = 'type';
export const HELSANA_ID = '0001';

export const FileMimeType = {
  pdf: 'application/pdf',
  img: 'image',
  text: 'text/plain;charset=utf-8',
  calendar: 'text/calendar',
};

export const NextEventEnum = {
  appointment: 'APPOINTMENT_RELEASE',
  callback: 'CALLBACK_REQUEST',
  PAST_APPOINTMENT: 'PAST_APPOINTMENT',
  LATE_REGISTRATION: 'LATE_REGISTRATION',
};
export const HistoryType = {
  ALL: 'ALL',
  APPOINTMENT_RELEASE: 'APPOINTMENT_RELEASE',
  CALLBACK_REQUEST: 'CALLBACK_REQUEST',
  CONSULTATION: 'CONSULTATION',
  REGISTRATION: 'REGISTRATION',
  PAST_APPOINTMENT: 'PAST_APPOINTMENT',
  LATE_REGISTRATION: 'LATE_REGISTRATION',
  ANNOUNCE_APPOINTMENT: 'ANNOUNCE_APPOINTMENT',
  REFERRAL: 'REFERRAL',
  NONE: 'NONE',
};

export const status = {
  PAST: 'past',
  ACTIVE: 'active',
};

export const order = {
  ASC: 'asc',
  DESC: 'desc',
};

export const caseStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const DEFAULT_ERROR = 'DEFAULT_ERROR';

export const MEDI24_URL = 'https://www.medi24.ch';

export const analyticsParams = {
  processName: {
    SYMPTOM_CHECKER: 'Symptom Checker',
    CALL_MEDI24: 'Phone Call',
    DIRECT_REGISTRATION: 'Direct Registration',
    CALLBACK_REQUEST: 'Callback Request',
    UPLOAD_PICTURE: 'Upload Picture',
    EMERGENCY_CALL: 'Emergency Call',
    FEEDBACK: 'Feedback',
    MEDI24_SUPPORT: 'Medi24 Support',
    ANNOUNCE_APPOINTMENT: 'Announce appointment',
  },
  status: {
    STARTED: 'Started',
    COMPLETED: 'Completed',
  },
  event: {
    category: {
      buttonClick: 'Click on clickable button',
    },
    action: {
      CALL_MEDI24: 'Phone Call',
      EMERGENCY_CALL: 'Emergency Call',
      CLICK_START: 'Click Start',
      CLICK_SUPPORT: 'Click Support',
      CLICK_SUPPORT_HEADER: 'Click Support Header',
      CLICK_SAVE_PROFILE: 'Click Save Profile',
    },
  },
  service: {
    categories: [
      {
        senselyName: 'CALL_MEDI24',
        analyticsName: 'CALL_MEDI24',
      },
      {
        senselyName: 'ANNOUNCE_TREATMENT',
        analyticsName: 'REGISTRATION',
      },
      {
        senselyName: 'CALLBACK',
        analyticsName: 'CALLBACK',
      },
      {
        senselyName: 'CALLBACK',
        analyticsName: 'UPLOAD_PICTURE',
      },
      {
        senselyName: 'CALL_144',
        analyticsName: 'CALL_144',
      },
    ],
  },
};

export const callIfMobile = (number: string) => {
  if (deviceIsMobile()) {
    window.location.href = `tel:${number}`;
  }
};

export const digitalDataStorageKey = 'digitalData';

export const acceptedFiles = 'application/pdf, image/jpeg, image/png';
