import React from 'react';
import {
  Redirect, Switch,
} from 'react-router-dom';

import {
  ANNOUCEMENT_PAGE_ROUTE,
  CALLBACK_REQUEST_PAGE_ROUTE,
  COMPANY_LEGAL_INFORMATION,
  CONTACT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  DATA_PRIVACY_ROUTE, EXPORT_FEEDBACK_PAGE_ROUTE,
  HISTORY_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE, SESSION_PAGE_ROUTE,
  SYMPTOM_CHECKER_PAGE_ROUTE, SYMPTOM_CHECKER_RESULTS_PAGE_ROUTE,
  TERMS_AND_CONDITION_ROUTE, UPLOAD_PICTURE_PAGE_ROUTE,
} from '../const';
import Track from '../components/Track';
import PrivateRoute from '../components/PrivateRoute';
import DataPrivacy from '../pages/legalContentPages/dataPrivacyPage/DataPrivacy';
import TermsAndConditionsPage from '../pages/legalContentPages/termsAndConditionPage/TermsAndConditionsPage';
import CompanyLegalInformation from '../pages/legalContentPages/companyLegalInformationPage/CompanyLegalInformation';
import ContactPage from '../pages/contactPage/ContactPage';
import History from '../pages/history/History';
import SenselyPage from '../pages/sensely/senselySymptomChecker/SenselyPage';
import SenselyResultPage from '../pages/sensely/senselyResult/SenselyResultPage';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Menu from '../components/layout/Menu';
import Profile from '../pages/profile/Profile';
import CallBackForm from '../pages/callBack/CallBackForm';
import AppointmentForm from '../pages/apppointment/appointmentForm/AppointmentForm';
import ExportFeedback from '../pages/exportFeedback/ExportFeedback';
import Dashboard from '../pages/dashboard/Dashboard';
import Session from '../pages/session/Session';
import backgroundShape from '../img/background-shape.svg';

function PrivateRouter() {
  return (
    <div className="page">
      <div className="header-container">
        <Header />
        <Menu />
      </div>
      <div className="page-content">
        <Switch>
          <PrivateRoute
            exact
            path={DATA_PRIVACY_ROUTE}
            component={(props) => <Track><DataPrivacy {...props} /></Track>}
          />
          <PrivateRoute
            exact
            path={TERMS_AND_CONDITION_ROUTE}
            component={(props) => <Track><TermsAndConditionsPage {...props} /></Track>}
          />
          <PrivateRoute
            exact
            path={COMPANY_LEGAL_INFORMATION}
            component={(props) => <Track><CompanyLegalInformation {...props} /></Track>}
          />
          <PrivateRoute
            exact
            path={DASHBOARD_PAGE_ROUTE}
            component={(props) => <Track><Dashboard {...props} /></Track>}
          />
          <PrivateRoute
            exact
            path={CONTACT_PAGE_ROUTE}
            component={(props) => <Track><ContactPage {...props} /></Track>}
          />
          <PrivateRoute
            exact
            path={PROFILE_PAGE_ROUTE}
            component={(props) => (
              <Track>
                <Profile {...props} />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={HISTORY_PAGE_ROUTE}
            component={(props) => (
              <Track>
                <History {...props} />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={CALLBACK_REQUEST_PAGE_ROUTE}
            authorization="HAVE_CALLBACK_REQUEST"
            component={(props) => (
              <Track>
                <CallBackForm {...props} />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={UPLOAD_PICTURE_PAGE_ROUTE}
            authorization="HAVE_UPLOAD_PICTURE"
            component={(props) => (
              <Track>
                <CallBackForm {...props} isUploadPicture />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={SYMPTOM_CHECKER_PAGE_ROUTE}
            authorization="HAVE_SYMPTOM_CHECKER"
            component={(props) => (
              <Track>
                <SenselyPage {...props} />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={SYMPTOM_CHECKER_RESULTS_PAGE_ROUTE}
            authorization="HAVE_SYMPTOM_CHECKER"
            component={(props) => (
              <Track>
                <SenselyResultPage {...props} />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={ANNOUCEMENT_PAGE_ROUTE}
            authorizations={['HAVE_LATE_REGISTRATION', 'HAVE_PAST_APPOINTMENT_RELEASE', 'HAVE_REGISTRATION']}
            component={(props) => (
              <Track>
                <AppointmentForm {...props} />
              </Track>
            )}
          />
          <PrivateRoute
            exact
            path={EXPORT_FEEDBACK_PAGE_ROUTE}
            component={ExportFeedback}
          />
          <PrivateRoute
            exact
            path={SESSION_PAGE_ROUTE}
            component={Session}
          />
          <PrivateRoute path="*" component={() => <Redirect to={DASHBOARD_PAGE_ROUTE} />} />
        </Switch>
      </div>
      <Footer />
      <img
        src={backgroundShape}
        alt="background's shape"
        className="background-shape"
      />
    </div>
  );
}

export default PrivateRouter;
