import React from 'react';

export type CustomRouteType = {
  pageType?: string[],
  default?: boolean,
  children: any
};
export function CustomRoute({ children }: CustomRouteType) {
  return children;
}
CustomRoute.defaultProps = {
  pageIndex: -1,
  default: false,
};
