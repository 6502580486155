import RestClientAuthenticated from '../clients/restClientAuthenticated';
import { UserInfosBean } from '../../pages/profile/ProfileType';

const userApi = {
  changeWelcomePageChoice: (choice:boolean) => new RestClientAuthenticated('/user/welcomeOption', 'POST').jsonBody(choice).execute(),

  setDateForTermsAndConditions: () => new RestClientAuthenticated('/user/termsAndConditions', 'POST').execute(),

  userShouldSeeTermsAndConditions: () => new RestClientAuthenticated('/user/termsAndConditions', 'GET').execute(),

  fetchContactPhoneNumber: () => new RestClientAuthenticated('/user/contactNumber', 'GET').execute(),

  fetchEmergencyPhoneNumber: () => new RestClientAuthenticated('/user/emergencyNumber', 'GET').execute(),

  fetchUserInformation: () => new RestClientAuthenticated('/user', 'GET').execute(),

  changeUserInfos: (userInfos : UserInfosBean) => new RestClientAuthenticated('/user', 'PATCH').jsonBody(userInfos).execute(),

  changeUserPreferredLanguage: (language: string) => new RestClientAuthenticated('/user/language', 'POST').jsonBody(language).execute(),
};

export default userApi;
