import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import {
  ArrowLeft,
  CalendarAdd,
  CloseCircle,
  ExportSquare,
  Eye,
  Trash,
} from 'iconsax-react';
import classNames from 'classnames';
import {
  CALLBACK_REQUEST_PAGE_ROUTE,
  CONTACT_PAGE_ROUTE,
  UPLOAD_PICTURE_PAGE_ROUTE,
} from '../../../const';
import { CallBackDetailsType, CallbackChannel, CallbackFiles } from './CallBackDetailsType';
import { notifyError } from '../../../network/notification';
import { useOnComponentMounted } from '../../../lib/react-hook-alias/ReactHookAlias';
import callbackApi from '../../../network/api/callbackApi';
import Loader from '../../../components/layout/Loader';
import ModalBase from '../../../components/modal/ModalBase';
import Button from '../../../components/buttons/Button';
import phoneCallbackIllustration from '../../../img/phone_callback_large.svg';
import videoCallbackIllustration from '../../../img/video_callback_large.svg';
import { createIcsFile } from '../../../services/icsService/icsService';
import ButtonType from '../../../types/ButtonType';
import Badge from '../../../components/badge/Badge';
import { BadgeType } from '../../../types/BadgeTypes';
import FileVisualiser from '../../../components/fileInputPda/FileVisualiser';
import { extractDuration } from '../../../services/dateService/dateService';

type Props = {
  idCallback: string,
  history: any,
  handleModalState?: Function,
};

function CallBackDetails({
  idCallback,
  history,
  handleModalState,
}: Props) {
  const [callbackDetails, setCallbackDetails] = useState<CallBackDetailsType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewedFile, setPreviewedFile] = useState<CallbackFiles | null>(null);
  const [button, setButton] = useState({
    title: '',
    action: undefined,
  });

  const redirectToCorrectForm = (details: CallBackDetailsType) => {
    if (details.files.length > 0) {
      history.push(UPLOAD_PICTURE_PAGE_ROUTE, { initialValues: details });
    } else {
      history.push(CALLBACK_REQUEST_PAGE_ROUTE, { initialValues: details });
    }
  };

  const handleSecondaryAction = (details: CallBackDetailsType) => {
    if (details && details.isEditable) {
      if (!details.hasSymptomChecker) {
        setButton({
          title: I18n.t('actions.EDIT'),
          action: () => {
            redirectToCorrectForm(details);
          },
        });
      } else {
        setButton({
          title: I18n.t('callback.CONTACT'),
          action: () => {
            history.push(CONTACT_PAGE_ROUTE);
          },
        });
      }
    }
  };

  const getRightBadge = () => ({
    text: callbackDetails?.callbackChannel === CallbackChannel.VIDEO
      ? I18n.t('tile.history.videoCallback') : I18n.t('tile.history.phoneCallback'),
    type: BadgeType.PRIMARY,
  });

  const previewFile = (fileToDisplay: CallbackFiles) => {
    setIsPreviewOpen(true);
    setPreviewedFile(fileToDisplay);
  };

  const closeFilePreview = () => {
    setIsPreviewOpen(false);
    setPreviewedFile(null);
  };

  const removeFile = (fileToDelete: CallbackFiles) => {
    const files = [...callbackDetails.files]
      .filter((file: CallbackFiles) => JSON.stringify(file) !== JSON.stringify(fileToDelete));
    closeFilePreview();
    setIsLoading(true);

    callbackApi.createCallBackRequest({
      ...callbackDetails,
      files,
    })
      .then(() => {
        callbackApi
          .getCallBackDetails(idCallback)
          .then((response) => response.json())
          .then((details: CallBackDetailsType) => {
            setCallbackDetails(details);
            handleSecondaryAction(details);
          })
          .catch(notifyError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const heroClassName = classNames(
    'details-hero',
    { 'details-hero--phone': callbackDetails?.callbackChannel === CallbackChannel.PHONE },
    { 'details-hero--video': callbackDetails?.callbackChannel === CallbackChannel.VIDEO },
  );

  const {
    bodyTitle,
    bodySubtitle,
  } = extractDuration(
    callbackDetails?.startDate?.toString(),
    callbackDetails?.endDate?.toString(),
  );

  useOnComponentMounted(() => {
    callbackApi
      .getCallBackDetails(idCallback)
      .then((response) => response.json())
      .then((details: CallBackDetailsType) => {
        setCallbackDetails(details);
        handleSecondaryAction(details);
      })
      .catch(notifyError)
      .finally(() => {
        setIsLoading(false);
      });
  });

  if (isLoading) {
    return (
      <ModalBase
        isOpen
        toggle={handleModalState}
        fullScreenPopin
        className="preview-popin details-popin"
      >
        <Loader />
      </ModalBase>
    );
  }

  return (
    <>
      <ModalBase
        isOpen
        toggle={handleModalState}
        fullScreenPopin
        className="preview-popin details-popin"
        actionButton={button.title}
        action={button.action}
      >
        <>
          <Button
            id="modal-close_details_large"
            ariaLabel="close"
            onClick={() => handleModalState()}
            icon={<CloseCircle className="preview-leave" />}
          />
          <Button
            id="modal-close_details_small"
            ariaLabel="close"
            onClick={() => handleModalState()}
            icon={<ArrowLeft className="preview-leave" />}
          />
        </>

        {callbackDetails && (
          <>
            <div className={heroClassName}>
              <img
                src={
                  callbackDetails.callbackChannel === CallbackChannel.VIDEO
                    ? videoCallbackIllustration
                    : phoneCallbackIllustration
                }
                alt="Hero's background"
              />
            </div>

            <div className="details-content callback-page">
              <div className="callback-page-header">
                <h2 className="details-text">
                  {I18n.t('eventDetails.callback')}
                </h2>

                <div className="callback-page-action">
                  <Badge
                    text={getRightBadge().text}
                    type={getRightBadge().type}
                  />

                  <Button
                    icon={<CalendarAdd />}
                    onClick={() => {
                      createIcsFile(callbackDetails.startDate,
                        callbackDetails.endDate,
                        callbackDetails && callbackDetails.files.length > 0);
                    }}
                    type={ButtonType.TEXT}
                    text={I18n.t('modal.ICS_YES')}
                  />
                </div>
              </div>

              <div className="callback-page-data-container">
                <div className="callback-page-data-group">
                  <h4 className="callback-data-label">{I18n.t('history.date')}</h4>
                  <h4 className="callback-data-label">{I18n.t('date.period.TIMEFRAME')}</h4>
                  <h4 className="callback-data-label">{I18n.t('tile.history.CALLBACK_NUMBER')}</h4>
                </div>
                <div className="callback-page-data-group">
                  <p className="callback-data-value">{bodyTitle}</p>
                  <p className="callback-data-value">{bodySubtitle}</p>
                  <p className="callback-data-value">{callbackDetails.callBackNumber}</p>
                </div>
              </div>

              <div className="callback-page-separator" />

              {callbackDetails.description && (
                <>
                  <div id="callbackBlock" className="callback-inputs">
                    <div className="callback-input-group">
                      <p className="callback-data-label">
                        {I18n.t('callback.DESCRIPTION')}
                      </p>
                      <p className="callback-data-value">
                        {callbackDetails.description}
                      </p>
                    </div>
                  </div>
                  <div className="callback-page-separator" />
                </>
              )}

              {callbackDetails.files.length > 0 && (
                <div className="callback-inputs">
                  <div className="callback-input-group">
                    <p className="callback-data-label">
                      {I18n.t('consultation.DOCUMENTS')}
                    </p>
                    {callbackDetails.files.map((file: CallbackFiles) => (
                      <div key={file.name} className="callback-page-action">
                        <h3 className="callback-data-value">{file.name}</h3>
                        <Button
                          type={ButtonType.TEXT}
                          htmlType="button"
                          icon={<Eye />}
                          onClick={() => previewFile(file)}
                          text={I18n.t('callback.file.open')}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {callbackDetails.hasSymptomChecker && (
                <div className="callback-inputs">
                  <div className="callback-input-group">
                    <p className="callback-data-label">
                      {I18n.t('services.SYMPTOM_CHECKER')}
                    </p>
                    <a
                      href={callbackDetails.mayoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type={ButtonType.TEXT}
                        text={`${callbackDetails.urgency}`}
                        icon={(
                          <ExportSquare />
                        )}
                      />
                    </a>
                  </div>
                </div>
              )}

              {callbackDetails.isEditable && callbackDetails.hasSymptomChecker && (
                <div className="callback-page-subtitle">
                  {I18n.t('callback.EDIT_ADVICE')}
                </div>
              )}
            </div>
          </>
        )}
      </ModalBase>

      <ModalBase
        isOpen={isPreviewOpen && !!previewedFile}
        toggle={closeFilePreview}
        title={I18n.t('callback.file.modalTitle')}
        titleAction={(
          <>
            <Button
              id="modal-close_mobile"
              ariaLabel="close"
              onClick={closeFilePreview}
              icon={<CloseCircle className="preview-leave" />}
            />
            <Button
              id="modal-close_desktop"
              ariaLabel="close"
              onClick={closeFilePreview}
              icon={<ArrowLeft className="preview-leave" />}
            />
          </>
        )}
        actionButton={I18n.t('actions.BACK')}
        action={closeFilePreview}
        fullScreenPopin
        className="preview-popin preview-popin--nested"
      >
        <div className="callback-page">
          {previewedFile && <FileVisualiser file={previewedFile} />}
          <div className="callback-page-action">
            <h3 className="callback-data-value">{previewedFile?.name}</h3>
            {previewedFile?.pdaCreated && (
              <Button
                type={ButtonType.TEXT}
                onClick={() => { removeFile(previewedFile); }}
                htmlType="button"
                disabled={!(callbackDetails.isEditable && callbackDetails.hasSymptomChecker)}
                icon={<Trash className="fa-trash-alt" />}
              />
            )}
          </div>
        </div>
      </ModalBase>
    </>
  );
}

export default withRouter(CallBackDetails);
