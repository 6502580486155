import React from 'react';
import { setLocale } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionPageLanguage, storeLang } from '../../services/languageService';
import {
  BEFORE_WE_START_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  PARTNER,
  WELCOME_PAGE_ROUTE,
} from '../../const';
import { analyticsLogin } from '../../services/analyticsService';
import { UserTechnicalSession } from '../../services/session/SessionTypes';
import { SessionServiceHolder } from '../../services/session/SessionServiceHolder';

type Props = {
  location: {
    search: string,
  },
  history: any,
  dispatch: Function,
  locale: string,
};

type State = {
  queryParams: URLSearchParams,
};

const JWT = 'jwt';
const INACTIVE = 'inactive';
const REFRESH = 'refresh';
const WARNING = 'warning';
const WELCOME_PAGE = 'welcomePage';
const DATA_PRIVACY_PAGE = 'dataPrivacy';

class Session extends React.Component<Props, State> {
  state = {
    queryParams: new URLSearchParams(this.props.location.search),
  };

  componentDidMount() {
    SessionServiceHolder.getSessionService().discardSession(true);
    const newUserSession: UserTechnicalSession = {
      webSessionToken: this.state.queryParams.get(JWT),
      inactiveDurationInMillis: Number(this.state.queryParams.get(INACTIVE)),
      refreshDurationInMillis: Number(this.state.queryParams.get(REFRESH)),
      inactiveWarningDurationInMillis: Number(this.state.queryParams.get(WARNING)),
    };
    // on ajoute le code du partner dans le storage pour pouvoir y acceder de n'importe ou (page d'erreur, de logout...)
    localStorage.setItem(PARTNER, this.state.queryParams.get(PARTNER));

    SessionServiceHolder.getSessionService().registerNewSession(newUserSession);
    this.props.dispatch(setLocale(sessionPageLanguage()));
    storeLang(sessionPageLanguage());
    analyticsLogin();
    this.directRedirect();
  }

  directRedirect = () => {
    if (this.state.queryParams.get(WELCOME_PAGE) === 'true') {
      this.props.history.push(WELCOME_PAGE_ROUTE);
    } else if (this.state.queryParams.get(DATA_PRIVACY_PAGE) === 'true') {
      this.props.history.push(BEFORE_WE_START_ROUTE);
    } else {
      this.props.history.push(DASHBOARD_PAGE_ROUTE);
    }
  };

  render() {
    return null;
  }
}

export default withRouter(connect((state) => ({
  locale: state.i18n.locale,
}))(Session));
