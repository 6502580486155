import { reduce } from '../network/reduce';

export const CONTACT_NUMBER = 'CONTACT_NUMBER';

const initialState = {
  contactNumber: undefined,
};

export const contactNumberReducer = reduce(initialState, {
  [CONTACT_NUMBER]: 'contactNumber',
});
