import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import {
  Home,
  MessageFavorite,
  Calendar,
  User,
  Logout,
} from 'iconsax-react';
import LogoutModal from '../logout/LogoutModal';

import {
  CONTACT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  HISTORY_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
} from '../../const';

export default function Menu() {
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const handleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  return (
    <div className="menu">
      <LogoutModal
        modal={showLogoutModal}
        toggle={handleLogoutModal}
        backdrop
      />
      <div
        onClick={handleLogoutModal}
        className="logout-item"
      >
        <div className="logout-item-title">{I18n.t('menu.LOGOUT')}</div>
        <Logout className="logout-item-icon" />
      </div>
      <div className="menu-content">
        <NavLink id="menu-dashboard" to={DASHBOARD_PAGE_ROUTE} className="menu-item" activeClassName="selected">
          <Home className="menu-item-icon" />
          <div className="menu-item-title">{I18n.t('menu.START')}</div>
        </NavLink>
        <NavLink id="menu-contact" to={CONTACT_PAGE_ROUTE} className="menu-item" activeClassName="selected">
          <MessageFavorite className="menu-item-icon" />
          <div className="menu-item-title">{I18n.t('menu.CONTACT')}</div>
        </NavLink>
        <NavLink id="menu-history" to={HISTORY_PAGE_ROUTE} className="menu-item" activeClassName="selected">
          <Calendar className="menu-item-icon" />
          <div className="menu-item-title">{I18n.t('menu.HISTORY')}</div>
        </NavLink>
        <NavLink id="menu-profile" to={PROFILE_PAGE_ROUTE} className="menu-item" activeClassName="selected">
          <User className="menu-item-icon" />
          <div className="menu-item-title">{I18n.t('menu.PROFILE')}</div>
        </NavLink>
      </div>
    </div>
  );
}
