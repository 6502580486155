import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppointmentInfos, CallbackInfos } from '../../types/appointmentType';
import ServicesList from '../../components/servicesList/ServicesList';
import { HISTORY_PAGE_ROUTE, HistoryType } from '../../const';
import { User } from '../../services/session/SessionTypes';
import { deviceIsMobile } from '../../services/displayService';
import { notifyError } from '../../network/notification';
import { store as storeFunction } from '../../network/reduce';
import { IS_USER_ALLOWED_TO_GO_BACK } from '../../state/login/loginReducer';
import Badge from '../../components/badge/Badge';
import { BadgeType } from '../../types/BadgeTypes';
import EventCategories from '../../components/history/EventCategories';
import historyApi from '../../network/api/historyApi';
import { currentLanguage } from '../../services/languageService';
import Loader from '../../components/layout/Loader';
import AppointmentReleaseTile from '../../components/tile/history/custom/AppointmentReleaseTile';
import CallbackTile from '../../components/tile/history/custom/CallbackTile';
import HistoryRouter from '../../components/history/HistoryRouter';
import Button from '../../components/buttons/Button';
import ButtonType from '../../types/ButtonType';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import NoEventsTile from '../../components/tile/NoEventsTile';

type Props = {
  history: any,
  translations: Object,
  user: User,
  isUserAllowedToGoBack: boolean,
  dispatch: Function,
};

type ModalStateType = {
  isOpen: boolean,
  id: string,
  type: string,
};

type SecondaryActionType = {
  title: string,
  action: Function,
};

type ActiveHistoryType = AppointmentInfos | CallbackInfos;

function Dashboard({
  history,
  translations,
  user,
  isUserAllowedToGoBack,
  dispatch,
}: Props) {
  const [activeHistory, setActiveHistory] = useState<ActiveHistoryType[]>([]);
  const [activeHistoryFiltered, setActiveHistoryFiltered] = useState<ActiveHistoryType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(HistoryType.ALL);
  const [totalActiveCount, setTotalActiveCount] = useState<number>(0);

  const [name, setName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalState, setModalState] = useState<ModalStateType>({
    isOpen: false,
    id: undefined,
    type: undefined,
  });

  const getDynamicTranslations = () => {
    const translationObject = translations[currentLanguage().code];
    return {
      consultationTitle: translationObject.tile.history.CONSULTATION,
      consultationMainTitle: translationObject.tile.history.CONSULTATION_TITLE,
      callbackTitle: translationObject.tile.history.CALLBACK_REQUEST,
      callbackMainTitle: translationObject.tile.history.CALLBACK_REQUEST_TITLE,
      registrationTitle: translationObject.tile.history.REGISTRATION,
      registration: translationObject.registration.urgencies,
      appointmentReleaseTitle: translationObject.tile.history.APPOINTMENT_RELEASE,
      appointmentRelease: translationObject.doctorSpecialities,
      announceAppointmentTitle: translationObject.tile.history.ANNOUNCE_APPOITMENT,
    };
  };

  const openModalState = (id: string, type: string) => {
    setModalState({
      isOpen: true,
      id,
      type,
    });

    if (deviceIsMobile() && !isUserAllowedToGoBack) {
      dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, true));
    }
  };

  const handleModalState = () => {
    setModalState({
      ...modalState,
      isOpen: !modalState?.isOpen,
    });

    if (deviceIsMobile() && !isUserAllowedToGoBack) {
      dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, true));
    }
  };

  const handleFilterSelection = (category: string) => {
    let filteredData = [];
    if (category === HistoryType.ALL) {
      filteredData = activeHistory;
    } else {
      filteredData = activeHistory
        .filter((event: ActiveHistoryType) => {
          if (category === HistoryType.APPOINTMENT_RELEASE) {
            return [
              HistoryType.APPOINTMENT_RELEASE,
              HistoryType.LATE_REGISTRATION,
              HistoryType.PAST_APPOINTMENT,
            ].includes(event.type);
          }

          return event.type === category;
        });
    }

    setActiveHistoryFiltered(filteredData);
    setSelectedCategory(category);
  };

  useOnComponentMounted(() => {
    dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, undefined));
    setIsLoading(true);
    setFirstName(user?.userName);
    setName(user?.fullName);

    historyApi.fetch('', getDynamicTranslations())
      .then((response) => response.json())
      .then((activeHistoryData: ActiveHistoryType[]) => {
        const data = activeHistoryData?.length > 3
          ? activeHistoryData.slice(0, 3)
          : activeHistoryData;
        setActiveHistory(data);
        setActiveHistoryFiltered(data);
        setTotalActiveCount(activeHistoryData?.length ?? 0);
        setIsLoading(false);
      })
      .catch(notifyError);
  }, []);

  return (
    <div className="home-page">
      {(isUserAllowedToGoBack === false ? false : modalState.isOpen)
        && modalState.id
        && modalState.type
        && (
          <HistoryRouter
            handleModalState={handleModalState}
            id={modalState.id}
            type={modalState.type}
          />
        )}

      <div className="home-page-header">
        <h1 className="home-page-title">
          {`${I18n.t('dashboardPage.WELCOME')} ${name} ${firstName} `}
        </h1>

        <div className="home-page-subtitle-container">
          <h2 className="home-page-subtitle">
            {I18n.t('history.title.active')}
          </h2>
          <Badge
            text={I18n.t(
              activeHistoryFiltered.length === 1 ? 'history.title.event' : 'history.title.events',
              { count: activeHistoryFiltered.length },
            )}
            type={BadgeType.PRIMARY}
          />
        </div>
      </div>

      {isLoading
        ? <Loader />
        : (
          <>
            {activeHistory.length > 0 && (
              <EventCategories
                handleSelect={handleFilterSelection}
                selected={selectedCategory}
              />
            )}

            {activeHistoryFiltered.length > 0
              ? (
                <div id="active" className="history-events-grid">
                  {activeHistoryFiltered.map((infos) => {
                    if (infos.type === HistoryType.APPOINTMENT_RELEASE
                      || infos.type === HistoryType.LATE_REGISTRATION
                      || infos.type === HistoryType.PAST_APPOINTMENT) {
                      return (
                        <AppointmentReleaseTile
                          key={infos.prmId}
                          infos={infos as AppointmentInfos}
                          setModalStatus={openModalState}
                        />
                      );
                    }

                    if (infos.type === HistoryType.CALLBACK_REQUEST) {
                      return (
                        <CallbackTile
                          key={infos.prmId}
                          infos={infos as CallbackInfos}
                          setModalStatus={openModalState}
                        />
                      );
                    }

                    return <React.Fragment key={infos.prmId} />;
                  })}
                </div>
              )
              : <NoEventsTile />}
          </>
        )}

      {(totalActiveCount > activeHistory?.length)
        && (
          <div className="button-group">
            <Button
              htmlType="button"
              type={ButtonType.TEXT}
              onClick={() => {
                history.push(HISTORY_PAGE_ROUTE);
              }}
              disabled={isLoading}
              text={`${I18n.t('history.SEE_MORE')} (${totalActiveCount})`}
            />
          </div>
        )}

      <ServicesList />
    </div>
  );
}

export default withRouter(connect((state) => ({
  user: state.login.userSession.user,
  translations: state.i18n.translations,
  isUserAllowedToGoBack: state.login.isUserAllowedToGoBack,
}))(Dashboard));
