import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Slide, toast } from 'react-toastify';
import { InfoCircle, TickCircle } from 'iconsax-react';
import { deviceIsMobile } from '../services/displayService';
import i18nService from '../services/i18nService';
import closeIcon from '../img/close_icon.svg';
import { SessionServiceHolder } from '../services/session/SessionServiceHolder';

export function initToast() {
  toast.configure({
    position: deviceIsMobile() ? 'bottom-center' : 'top-right', // on phone the toast occupy 100% of the screen. "right" is useful to indicate the slide direction
    hideProgressBar: true,
    closeOnClick: true,
    transition: Slide,
  });
}

const ToastContent = ({ icon, text }) => (
  <div className="toast-content">
    <div className="toast-content-icon">
      {icon}
    </div>

    <p className="toast-content-text">
      {text}
    </p>

    <div className="toast-content-icon toast-content-icon--close">
      <img src={closeIcon} alt="close icon" />
    </div>
  </div>
);

export function notifySuccess(text, response?: Object) {
  toast.success(<ToastContent
    icon={<TickCircle />}
    text={text}
  />);

  if (response) {
    return response;
  }
}

export function notifyError(error: any) {
  if (Promise.resolve(error) === error) {
    return error.then((errorJson) => {
      toast.error(<ToastContent
        icon={(<InfoCircle />)}
        text={I18n.t(`wsError.${errorJson.errorCode}`, i18nService.toMessage(errorJson))}
      />);

      if (errorJson.errorCode === 'AUTHENTIFICATION_EXPIRED') {
        SessionServiceHolder.getSessionService().discardSession();
      }
    });
  }

  toast.error(<ToastContent
    icon={(<InfoCircle />)}
    text={I18n.t('wsError.INTERNAL_ERROR')}
  />);
}

export function notifyErrorWithMessage(message: string) {
  toast.error(<ToastContent
    icon={(<InfoCircle />)}
    text={message}
  />);
}
