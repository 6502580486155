import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export const required = (value) => (value ? undefined : I18n.t('validator.REQUIRED'));

export const notRequired = (value) => undefined;
export const requiredFiles = (value) => {
  if (value && value.length > 0) {
    return undefined;
  }
  return I18n.t('validator.REQUIRED');
};

export const requiredZipcode = (value) => {
  if(!value) {
    return I18n.t('validator.REQUIRED');
  }
  if (!isNaN(value) && !isNaN(parseFloat(value))) {
    return undefined;
  }
  return I18n.t('validator.VALIDATE_ZIPCODE');
}

export const requiredDate = (value: moment.Moment) => {
  if(!value) {
    return I18n.t('validator.REQUIRED');
  }
  if (!(value.isValid())) {
    return I18n.t('validator.VALIDATE_DATE');
  }
  return undefined;
}

export function validatePhone(value: string, required) {
  if(!required && !value) {
    return undefined;
  }

  if (value) {
    const phoneNumber = parsePhoneNumberFromString(value, 'CH');
    if (phoneNumber && phoneNumber.isValid()) {
      return undefined;
    }
    if (phoneNumber && phoneNumber.isPossible()) {
      return I18n.t('validator.VALIDATE_PHONE_NO_0');
    }
    return I18n.t('validator.VALIDATE_PHONE');
  }
  return I18n.t('validator.REQUIRED');
}

export function validateDate(value: moment.Moment) {
  if (value && moment(value).isAfter(moment().add(-1, 'day'))) {
    if (moment(value).year() === moment().year()) {
      return undefined;
    }
    if (moment(value).month() === 12 && moment(value).year() === (moment().year() + 1)) {
      return undefined;
    }
    return I18n.t('validator.DATE_TOO_FAR');
  }
  return I18n.t('validator.DATE');
}

export function validateEmail(value: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (value) {
    if (re.test(value)) {
      return undefined;
    }
    return I18n.t('validator.VALIDATE_EMAIL');
  }
}
