import React from 'react';
import {
  Redirect, Route, Switch,
  withRouter,
} from 'react-router-dom';

import { connect } from 'react-redux';
import {
  BEFORE_WE_START_ROUTE,
  ERROR_BLOCKED_PAGE_ROUTE,
  ERROR_PAGE_ROUTE, EXPORT_FEEDBACK_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  SESSION_PAGE_ROUTE, WELCOME_PAGE_ROUTE,
} from '../const';
import Track from '../components/Track';
import ErrorPage from '../pages/errorPage/ErrorPage';
import Feedback from '../pages/feedback/Feedback';
import ExportFeedback from '../pages/exportFeedback/ExportFeedback';
import Header from '../components/layout/Header';
import Session from '../pages/session/Session';
import BeforeWeStart from '../pages/legalContentPages/beforeWeStartPage/BeforeWeStart';
import WelcomePage from '../pages/welcomePage/WelcomePage';
import Footer from '../components/layout/Footer';
import backgroundShape from '../img/background-shape.svg';


type Props = {
  isConnected: boolean
};

function PublicRouter(isConnected: Props) {
  return (
    <div className="page">
      <div className="header-container">
        <Header />
      </div>
      <div className="page-content">
        <Switch>
          <Route
            exact
            path={ERROR_PAGE_ROUTE}
            component={(props) => <Track><ErrorPage {...props} /></Track>}
          />
          <Route
            exact
            path={SESSION_PAGE_ROUTE}
            component={Session}
          />
          <Route
            exact
            path={LOGOUT_PAGE_ROUTE}
            component={(props) => <Track><Feedback {...props} /></Track>}
          />
          <Route
            exact
            path={EXPORT_FEEDBACK_PAGE_ROUTE}
            component={ExportFeedback}
          />
          {isConnected
            && (
              <Route
                exact
                path={BEFORE_WE_START_ROUTE}
                component={(props) => <Track><BeforeWeStart {...props} /></Track>}
              />
            )}
          {isConnected
            && (
              <Route
                path={WELCOME_PAGE_ROUTE}
                component={(props) => <Track><WelcomePage {...props} /></Track>}
              />
            )}
          <Route path="*" render={() => <Redirect to={ERROR_BLOCKED_PAGE_ROUTE} />} />
        </Switch>
      </div>
      <Footer />
      <img
        src={backgroundShape}
        alt="background's shape"
        className="background-shape"
      />
    </div>
  );
}

export default withRouter(connect((state) => ({
  isConnected: state.login.userSession !== null,
}))(PublicRouter));
