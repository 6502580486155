import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from '../modal/ModalBase';
import { SessionServiceHolder } from '../../services/session/SessionServiceHolder';
import loginHistoryApi from '../../network/api/loginHistoryApi';

type Props = {
  history: any
  className?: string,
  size?: string,
  modal: boolean,
  toggle: any,
};

class LogoutModal extends React.Component<Props> {
  static defaultProps = {
    className: '',
    size: '',
  };

  disconnectUser = () => {
    loginHistoryApi.logout().then(() => {
      SessionServiceHolder.getSessionService().discardSession();
    })
      .catch((error) => console.log('Could not logout history', { error }));
  };

  render() {
    return (
      <ModalBase
        {...this.props}
        isOpen={this.props.modal}
        toggle={this.props.toggle}
        title={I18n.t('modal.LOGOUT_MODAL')}
        action={() => this.disconnectUser()}
        secondaryAction={() => this.props.toggle()}
        actionButton={I18n.t('actions.CONFIRM_LOGOUT')}
        secondaryActionButton={I18n.t('actions.BACK')}
      >
        <div className="classic-text">{I18n.t('modal.LOGOUT_MESSAGE')}</div>
      </ModalBase>
    );
  }
}

export default withRouter(LogoutModal);
