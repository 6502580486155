import RestClientAuthenticated from '../clients/restClientAuthenticated';
import RestClient from "../clients/restClient";

const configurationApi = {
    fetchEnv: () => new RestClient('/configuration/env', 'GET').execute(),
    fetchAdobe: () => new RestClient('/configuration/adobe', 'GET').execute(),
    fetchSenselyPageConfig: (lang: string) => new RestClientAuthenticated(`/sensely/configuration?lang=${lang}`, 'GET').execute(),
    fetchSenselyWebSdkUrlConfig: () => new RestClientAuthenticated(`/sensely/webSdkUrl`, 'GET').execute(),
    fetchSenselyConfigByUrgency: (urgency, timeframe) => new RestClientAuthenticated(`/sensely/configuration/${urgency}?timeframe=${timeframe}`, 'GET').execute(),
};

export default configurationApi;
