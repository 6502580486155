import { Store } from 'redux';
import moment from 'moment';
import { StoreFunction } from '../../network/reduce';
import {
  browserStartUserActivityDetector,
  browserStopUserActivityDetector,
  IdlenessDetector,
  IdlenessDetectorConfiguration,
} from './IdlenessDetector';
import { toJsonError, toPlumeJsonErrorPromise } from '../../network/handleError';
import { analyticsLogout, updateProcessData } from '../analyticsService';
import { SessionRefreshManager } from './SessionRefreshManager';
import { User, UserSession, UserTechnicalSession } from './SessionTypes';
import { BrowserPageActivity, BrowserPageActivityManager } from './BrowserPageActivityManager';
import sessionApi from '../../network/api/sessionApi';
import { USER_SESSION, IS_IDLE } from '../../state/login/loginReducer';
import { analyticsParams, LOGOUT_PAGE_ROUTE } from '../../const';
import feedbackApi from '../../network/api/feedbackApi';

const THRESHOLD_IN_MILLIS_TO_DETECT_EXPIRED_SESSION = 2 * 60 * 1000; // 2 minutes
const LOCAL_STORAGE_CURRENT_SESSION = 'currentUser';

export class SessionServiceConfiguration {
  constructor(
    readonly reduxStore: Store,
    readonly reduxStoreFunction: StoreFunction,
  ) {
  }
}

export interface SessionServiceInterface {
  tryInitializingSessionFromStorage(): void;
  registerNewSession(userTechnicalSession: UserTechnicalSession): void;
  restartSessionRefreshAndIdleDetection(): void;
  discardSession(sso ?: boolean): void;
  currentSession(): UserSession;
}

export class SessionService implements SessionServiceInterface {
  private readonly idlenessDetector: IdlenessDetector;

  private readonly sessionRefreshManager: SessionRefreshManager;

  private readonly browserPageActivityManager: BrowserPageActivityManager;

  private userSession: UserSession;

  private logoutRedirectUrl: string;

  constructor(readonly config: SessionServiceConfiguration) {
    this.sessionRefreshManager = new SessionRefreshManager((sessionToken: string) => this.refreshSession(sessionToken));
    this.idlenessDetector = new IdlenessDetector(new IdlenessDetectorConfiguration(
      browserStartUserActivityDetector,
      browserStopUserActivityDetector,
      () => {
        this.sessionRefreshManager.stopService();
        this.reduxUpdate(IS_IDLE, true);
      },
    ));
    this.browserPageActivityManager = new BrowserPageActivityManager((eventType: BrowserPageActivity) => this.onBrowserPageActivityChange(eventType));
  }

  tryInitializingSessionFromStorage() {
    const webSessionString = localStorage.getItem(LOCAL_STORAGE_CURRENT_SESSION);
    if (webSessionString) {
      const userSession: UserTechnicalSession = JSON.parse(webSessionString);
      console.log('Found existing session in local storage, will try to use it', userSession.webSessionToken);
      if (!this.registerNewSession(userSession)) {
        localStorage.removeItem(LOCAL_STORAGE_CURRENT_SESSION);
      }
    } else {
      console.log('No existing session in local storage');
    }
  }

  registerNewSession(userTechnicalSession: UserTechnicalSession) {
    if (this.storeSession(userTechnicalSession)) {
      this.startSessionRefreshAndIdleDetection();
      return true;
      // TODO appel API feedback token => puis stockage du token dans le sessionService
    }
    console.log('Not starting session services because session is already expired');
    return false;
  }

  restartSessionRefreshAndIdleDetection() {
    if (!SessionService.isUserSessionValid(this.userSession.user)) {
      this.discardSession();
      return;
    }

    this
      .refreshSession(this.userSession.session.webSessionToken)
      .then(() => {
        // ok the session is still valid!
        this.startSessionRefreshAndIdleDetection();
      })
      .catch((e) => {
        console.log('Error refreshing session', e);
      });
  }

  /**
   * Remove the session from:
   * - the local storage
   * - redux
   *
   * Stop any refresh session or idle detection services
   *
   * Set the redux user session to null, that will tell components that the user is disconnected
   */
  discardSession(sso ?: boolean) {
    updateProcessData({
      processName: analyticsParams.processName.FEEDBACK,
      status: analyticsParams.status.STARTED,
      startTime: moment().toISOString(),
    }, true);
    localStorage.removeItem(LOCAL_STORAGE_CURRENT_SESSION);
    this.idlenessDetector.stopService();
    this.sessionRefreshManager.stopService();
    this.browserPageActivityManager.stopService();
    this.userSession = null;

    this.reduxUpdate(USER_SESSION, null);

    // TODO voir si on peut pas mettre cette merde ailleurs
    analyticsLogout();
    if (!sso) {
      window.location.href = this.logoutRedirectUrl;
    }
  }

  /**
   * Permet de récupérer l'objet utilisateur courant.
   * Ne doit généralement pas être appelé directement, il faut passer par redux et l'objet state.login.userSession
   */
  currentSession() {
    return this.userSession;
  }

  private refreshSession(sessionToken: string) {
    return sessionApi
      .refreshSession(sessionToken)
      .then((response) => response.json())
      .then((newWebSession: UserTechnicalSession) => {
        this.storeSession(newWebSession);
        return newWebSession.webSessionToken;
      })
      .catch(toJsonError)
      .catch(toPlumeJsonErrorPromise)
      .catch((errorResponse) => {
        (errorResponse).then((errorJson) => {
          if (errorJson.errorCode === 'ALREADY_EXPIRED_SESSION_TOKEN') {
            this.discardSession();
          } else {
            console.log('Could not update session token', errorJson);
          }
        });
      });
  }

  private storeSession(userTechnicalSession: UserTechnicalSession) {
    const parsedSession = SessionService.parseUserSession(userTechnicalSession);
    if (SessionService.isUserSessionValid(parsedSession)) {
      localStorage.setItem(LOCAL_STORAGE_CURRENT_SESSION, JSON.stringify(userTechnicalSession));
      this.userSession = {
        user: parsedSession,
        session: userTechnicalSession,
      };
      this.reduxUpdate(USER_SESSION, this.userSession);
      return true;
    }
    console.log(`Tried to store expired session, current date=${new Date()} session expiration date=${new Date(parsedSession.exp * 1000)}, you may need to fix your computer clock`, parsedSession);
    return false;
  }

  private startSessionRefreshAndIdleDetection() {
    this.sessionRefreshManager.startService(this.userSession.session.webSessionToken, this.userSession.session.refreshDurationInMillis);
    this.idlenessDetector.startService(this.userSession.session.inactiveWarningDurationInMillis, 1000);
    this.browserPageActivityManager.startService();
    feedbackApi.fetchInfos()
      .then((res) => res.json())
      .then((infos) => {
        this.logoutRedirectUrl = `${LOGOUT_PAGE_ROUTE}?token=${infos.token}&partnerName=${infos.partnerName}`;
      })
      .catch((error) => console.log('Could not fetch feedback info', { error }));
  }

  private onBrowserPageActivityChange(eventType: BrowserPageActivity) {
    if (eventType === BrowserPageActivity.Active) {
      if (!SessionService.isUserSessionValid(this.userSession.user)) {
        this.discardSession();
      }
    }
  }

  private reduxUpdate(action: string, value: any) {
    this.config.reduxStore.dispatch(this.config.reduxStoreFunction(action, value));
  }

  // utils

  private static parseUserSession(userSession: UserTechnicalSession) {
    if (!userSession || !userSession.webSessionToken) {
      return null;
    }
    return SessionService.parseJwtSession(userSession.webSessionToken);
  }

  private static isUserSessionValid(parsedUserSession: User) {
    return parsedUserSession && parsedUserSession.exp && (parsedUserSession.exp * 1000 + THRESHOLD_IN_MILLIS_TO_DETECT_EXPIRED_SESSION) > Date.now();
  }

  private static parseJwtSession(webSessionToken: string): User {
    return JSON.parse(decodeURIComponent(escape(atob(webSessionToken.split('.')[1]))));
  }
}
