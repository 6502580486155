import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Camera } from 'iconsax-react';
import { analyticsParams, UPLOAD_PICTURE_PAGE_ROUTE } from '../../../../../const';
import { updateProcessData } from '../../../../../services/analyticsService';
import ModalUploadPictureWarning from '../../../../modal/modalUploadPicture/ModalUploadPictureWarning';
import { notifyError } from '../../../../../network/notification';
import callbackApi from '../../../../../network/api/callbackApi';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';

type Props = {
  history: any,
  maxAfter: number,
  small: boolean,
  senselyDescription: any,
};

type State = {
  isOpen: boolean,
  hasWarning: boolean
};

class UploadPictureTile extends React.Component<Props, State> {
  state = {
    isOpen: false,
    hasWarning: false,
  };

  componentDidMount() {
    callbackApi
      .hasUserUploadPictureWarning()
      .then((response) => response.text())
      .then((hasWarning) => {
        this.setState({ hasWarning: hasWarning === 'true' });
      })
      .catch(notifyError);
  }


  closeModal = () => {
    this.setState({ isOpen: false });
  };

  goToAnnounceAppointment = () => {
    const { history, senselyDescription, maxAfter } = this.props;
    history.push(UPLOAD_PICTURE_PAGE_ROUTE, { maxAfter, senselyDescription });
  };

  render() {
    const { isOpen, hasWarning } = this.state;
    const { history, maxAfter, senselyDescription } = this.props;

    return (
      <>
        <ModalUploadPictureWarning
          history={history}
          action={this.goToAnnounceAppointment}
          toggle={this.closeModal}
          isOpen={isOpen}
          backdrop
        />
        <FeaturedTile
          title={I18n.t('services.UPLOAD_PICTURE')}
          icon={<Camera />}
          type={FeaturedTileType.SECONDARY}
          onClick={() => {
            updateProcessData({
              processName: analyticsParams.processName.UPLOAD_PICTURE,
              status: analyticsParams.status.STARTED,
              startTime: moment().toISOString(),
            });
            if (hasWarning) {
              this.setState({ isOpen: !isOpen });
            } else {
              history.push(UPLOAD_PICTURE_PAGE_ROUTE, { maxAfter, senselyDescription });
            }
          }}
        />
      </>
    );
  }
}

export default withRouter(UploadPictureTile);
