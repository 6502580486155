export function unwrap<T>(value: any): any {
  if (!value) throw new Error('Unwrapping not possible because the variable is null or undefined!');
  return value; // at this point Flow should understand it cannot be of type Optional or Maybe
}

export const errorObject = {
  errorCode: 'INTERNAL_ERROR',
  statusArguments: [],
};

export const errorNetworkError = {
  errorCode: 'NETWORK_ERROR',
  statusArguments: [],
};

export function networkErrorCatcher(response: Object) {
  console.log('Cannot connect to HTTP server due to a network error', response);
  throw Promise.resolve(errorNetworkError);
}

export function jsonResponseCatcher(response: any) {
  const contentType = response.headers.get('content-type');
  if (response.bodyValue && (!contentType || contentType.indexOf('application/json') === -1)) {
    console.log('Response type is not json', response);
    throw Promise.resolve(errorObject);
  }
  return response;
}

export function toJsonError(response: any) {
  if (typeof response.then === 'function') {
    throw response;
  }
  const contentType = response.headers.get('content-type');
  if (!contentType || contentType.indexOf('application/json') === -1) {
    console.log('Response type is not JSON', response);
    throw Promise.resolve(errorObject);
  }
  throw response.json();
}

export function toPlumeJsonErrorPromise(errorJson: any) {
  // case 1: it is already a json promise error containing the standard errorObject field
  if (typeof errorJson.then === 'function') {
    throw errorJson;
  }
  // case 2: it is an error json object, but not a standard one :(
  if (typeof errorJson.errorCode === 'undefined') {
    console.log('Error response does not contain an errorCode field', errorJson);
    throw Promise.resolve(errorObject);
  }
  // case 3: it is standard error json object
  throw Promise.resolve(errorJson);
}

export default function handleError(response: any) {
  if (response.status === 403) {
    throw Promise.resolve({
      errorCode: 'AUTHENTIFICATION_EXPIRED',
      statusArguments: [],
    });
  }
  if (!response.ok) {
    throw response;
  }
  return response;
}
