import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { loginReducer } from './login/loginReducer';
import { contactNumberReducer } from './contactNumberReducer';

export default combineReducers({
  i18n: i18nReducer,
  login: loginReducer,
  contactNumber: contactNumberReducer,
});
