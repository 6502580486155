import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { DashboardServicesType } from '../../types/dashboardServicesType';
import dashboardApi from '../../network/api/dashboardApi';
import UploadPictureTile from '../tile/service/custom/uploadPictureTile/UploadPictureTile';
import CallbackRequestTile from '../tile/service/custom/callbackRequestTile/CallbackRequestTile';
import SymptomCheckerTile from '../tile/service/custom/symptomCheckerTile/SymptomCheckerTile';
import RegistrationTile from '../tile/service/custom/registrationTile/RegistrationTile';
import Medi24SupportTile from '../tile/service/custom/medi24SupportTile/Medi24SupportTile';
import ReferralTile from '../tile/service/custom/referralTile/ReferralTile';
import PastAppointmentTile from '../tile/service/custom/pastAppointmentTile/PastAppointmentTile';
import LateRegistrationTile from '../tile/service/custom/lateRegistrationTile/LateRegistrationTile';
import { notifyError } from '../../network/notification';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';

type State = {
  dashboardServices: DashboardServicesType,
};

export default function ServicesList() {
  const [dashboardServices, setDashboardServices] = useState<DashboardServicesType>({
    isAllowedToSymptomChecker: false,
    isAllowedToUploadPicture: false,
    isAllowedToCallBackRequest: false,
    isAllowedToRegister: false,
    isAllowedToReferral: false,
    isAllowedToLateRegistration: false,
    isAllowedToPastAppointment: false,
  });

  useOnComponentMounted(() => {
    dashboardApi.getDashboardServices()
      .then((response) => response.json())
      .then((userRights) => {
        setDashboardServices({
          isAllowedToUploadPicture: userRights.uploadPicture,
          isAllowedToSymptomChecker: userRights.symptomChecker,
          isAllowedToCallBackRequest: userRights.callBackRequest,
          isAllowedToRegister: userRights.registration,
          isAllowedToLateRegistration: userRights.lateRegistration,
          isAllowedToPastAppointment: userRights.pastAppointementRealease,
          isAllowedToReferral: userRights.referral,
        });
      })
      .catch(notifyError);
  });

  return (
    <>
      <h2 className="home-page-subtitle">
        {I18n.t('dashboardPage.FUNCTIONALITIES')}
      </h2>
      <div className="featured-tiles">
        {dashboardServices.isAllowedToSymptomChecker && <SymptomCheckerTile />}
        {dashboardServices.isAllowedToRegister && <RegistrationTile />}
        {dashboardServices.isAllowedToPastAppointment && <PastAppointmentTile />}
        {dashboardServices.isAllowedToLateRegistration && <LateRegistrationTile />}
        {dashboardServices.isAllowedToCallBackRequest && <CallbackRequestTile />}
        {dashboardServices.isAllowedToUploadPicture && <UploadPictureTile />}
        {dashboardServices.isAllowedToReferral && <ReferralTile />}
      </div>
      <h2 className="home-page-subtitle">
        {I18n.t('contact.HELP_TITLE')}
      </h2>
      <div className="featured-tiles">
        <Medi24SupportTile />
      </div>
    </>
  );
}
