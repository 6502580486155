import { Moment } from 'moment';
import RestClientAuthenticated from '../clients/restClientAuthenticated';
import { CallbackRequestTypes } from '../../const';

const timeFrameApi = {
  getAvailableTimeFrames: (date: Moment,
    type : CallbackRequestTypes,
    maxAfter?: number) => new RestClientAuthenticated(`/callback/available-time-frames?date=${date.toISOString()}&type=${type}${maxAfter
    ? `&max=${maxAfter}`
    : ''}`, 'GET')
    .execute(),
};

export default timeFrameApi;
