import moment from 'moment';

export type CallbackFiles = {
  base64: string,
  name: string,
  type: string,
  prmId?: string,
  pdaCreated?: boolean,
  blob?: Blob
};

export enum CallbackChannel {
  PHONE = 'PHONE',
  VIDEO = 'VIDEO'
}

export type CallBackDetailsType = {
  startDate: moment.Moment,
  endDate: moment.Moment,
  callBackNumber: string,
  phoneNumber?: string,
  description: string,
  files: CallbackFiles[],
  timeFrameId: number,
  isEditable: boolean,
  isCreatedByDoctor: boolean,
  id?: number,
  callbackChannel: CallbackChannel,
  hasSymptomChecker?: boolean,
  urgency?: unknown,
  mayoLink?: string,
  senselyResult: any,
  type: string,
};

export type CallBackResult = {
  startDate: moment.Moment,
  endDate: moment.Moment,
  uploadPicture: boolean,
  edition: boolean,
};


export const isCallbackFile = (
  file: File | CallbackFiles,
): file is CallbackFiles => (file as CallbackFiles).base64 !== undefined;
