import React, { ReactElement, useMemo } from 'react';
import FeaturedTileType from '../../types/FeaturedTileType';

type Props = {
  id?: string;
  type?: FeaturedTileType;
  icon: ReactElement;
  title: string;
  onClick: () => void;
};

function FeaturedTile({
  id,
  type,
  icon,
  title,
  onClick,
}: Props) {
  const className: string = useMemo(() => {
    const base = 'featured-tile';

    if (type === FeaturedTileType.PRIMARY) {
      return `${base} ${base}--primary`;
    }

    if (type === FeaturedTileType.SECONDARY) {
      return `${base} ${base}--secondary`;
    }

    return base;
  }, [type]);

  return (
    <div id={id} className={className}>
      <div className="featured-tile-icon">
        {icon}
      </div>
      <div className="featured-tile-content">
        {title}
      </div>
      <button
        className="hidden-button"
        type="button"
        onClick={onClick}
        aria-label={`hidden button for ${title} featured tile`}
      />
    </div>
  );
}

export default FeaturedTile;
