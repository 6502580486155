import React, { useState } from 'react';
import { I18n, setLocale } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Edit, Logout } from 'iconsax-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import userApi from '../../network/api/userApi';
import { notifyError, notifySuccess } from '../../network/notification';
import Loader from '../../components/layout/Loader';
import { ProfileType } from './ProfileType';
import { validateEmail, validatePhone } from '../../validator';
import {
  fireActionTriggerCustomEvent,
  getAgeFromBirthdate,
  updateEventInfo,
  updateUserInfo,
} from '../../services/analyticsService';
import { analyticsParams } from '../../const';
import TextInput from '../../components/form/TextInput';
import Button from '../../components/buttons/Button';
import ButtonType from '../../types/ButtonType';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import ModalBase from '../../components/modal/ModalBase';
import { deviceIsMobile } from '../../services/displayService';
import { findLanguageCodeByName, storeLang } from '../../services/languageService';
import LogoutModal from '../../components/logout/LogoutModal';

type Props = {
  dispatch: Function,
};

function Profile({ dispatch }: Props) {
  const [userInformation, setUserInformation] = useState<ProfileType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    isLanguagePreferencesModalOpen,
    setIsLanguagePreferencesModalOpen,
  ] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(userInformation?.language);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const languageChoices: Record<string, string> = I18n.t('profile.languages');

  const handleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  useOnComponentMounted(() => {
    userApi
      .fetchUserInformation()
      .then((response) => (response.json()))
      .then((informations: ProfileType) => {
        updateUserInfo({
          ...window.digitalData.user,
          city: informations.city,
          zip: informations.zipCode,
          age: getAgeFromBirthdate(informations.dateOfBirth),
          gender: informations.gender,
        });
        setUserInformation(informations);
        setSelectedLanguage(informations.language);
        setIsLoading(false);
      })
      .catch(notifyError);
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const userInfosBean = {
      email: values.email,
      mobilePhone: values.mobilePhoneNumber,
    };

    userApi
      .changeUserInfos(userInfosBean)
      .then((response) => response.json())
      .then((newInfos: ProfileType) => {
        notifySuccess(I18n.t('profile.CHANGES_SAVED'));
        setUserInformation(newInfos);
        setSelectedLanguage(newInfos.language);
      })
      .catch((response) => {
        notifyError(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLanguagePreferencesModal = () => {
    setIsLanguagePreferencesModalOpen(!isLanguagePreferencesModalOpen);
  };

  const saveLanguageChanges = () => {
    userApi.changeUserPreferredLanguage(selectedLanguage)
      .then(() => {
        notifySuccess(I18n.t('profile.CHANGES_SAVED'));
        setUserInformation({
          ...userInformation,
          language: selectedLanguage,
        });
        const newLanguageCode: string = findLanguageCodeByName(selectedLanguage).code;
        storeLang(newLanguageCode);
        dispatch(setLocale(newLanguageCode));
        setIsLanguagePreferencesModalOpen(false);
      })
      .catch(notifyError);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="profile-page">
      <div className="profile-page-header">
        <h1 className="profile-page-title">
          {I18n.t('profile.TITLE')}
        </h1>
      </div>

      <div className="profile-page-group">
        <div className="profile-page-action profile-page-action--mobile-only">
          <h2 className="profile-page-subtitle">
            {I18n.t('profile.SUBTITLE')}
          </h2>
          <Button
            type={ButtonType.TEXT}
            onClick={handleLogoutModal}
            text={I18n.t('menu.LOGOUT')}
            icon={<Logout />}
          />
        </div>

        <p className="profile-page-text">
          {I18n.t('profile.WARNING')}
        </p>

        <div className="profile-page-data-container">
          <div className="profile-page-data-group">
            <h4 className="profile-data-label">{I18n.t('profile.NAME')}</h4>
            <h4 className="profile-data-label">{I18n.t('profile.DATE_OF_BIRTH')}</h4>
            <h4 className="profile-data-label">{I18n.t('profile.ADDRESS')}</h4>
          </div>
          <div className="profile-page-data-group">
            <p className="profile-data-value">
              {userInformation.firstName}
              {' '}
              {userInformation.lastName ?? ''}
            </p>
            <p className="profile-data-value">
              {userInformation.dateOfBirth
                // On reçoit la date de naissance en utc on a parfois un décalage d'un jour
                ? moment(userInformation.dateOfBirth).utcOffset('+0200').format(I18n.t('date.ONLY_DATE_FORMAT'))
                : ''}
            </p>
            <div className="profile-data-value-group">
              {userInformation.address && (
                <p className="profile-data-value">
                  {userInformation.address}
                </p>
              )}

              {userInformation.zipCode
                && userInformation.city
                && (
                  <p className="profile-data-value">
                    {`${userInformation.zipCode} ${userInformation.city}`}
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-page-separator" />

      <div className="profile-page-group">
        <h4 className="profile-data-label">{I18n.t('profile.LANGUAGE')}</h4>

        <div className="profile-page-action">
          <p className="profile-data-value">
            {userInformation.language
              ? I18n.t(`profile.languages.${userInformation.language}`)
              : ''}
          </p>

          <Button
            text={I18n.t('profile.CHANGE_LANGUAGE')}
            icon={<Edit />}
            onClick={handleLanguagePreferencesModal}
            type={ButtonType.TEXT}
          />
        </div>

        <p className="profile-page-text">
          {I18n.t('profile.LANGUAGE_SUBTITLE')}
        </p>
      </div>

      <div className="profile-page-separator" />

      <Form
        onSubmit={onSubmit}
        initialValues={userInformation}
        render={({ handleSubmit, form, pristine }) => (
          <form className="profile-page-group" onSubmit={handleSubmit}>
            <h2 className="profile-page-subtitle">
              {I18n.t('profile.ADDITIONNAL_TITLE')}
            </h2>

            <p className="profile-page-text">
              {I18n.t('profile.ADDITIONNAL')}
            </p>

            <div className="profile-inputs">
              <div className="profile-input-group">
                <label className="profile-data-label" htmlFor="email-input">
                  {`${I18n.t('profile.EMAIL')}`}
                </label>

                <Field
                  id="email-input"
                  name="email"
                  component={TextInput}
                  type="email"
                  className="profile-input"
                  validate={validateEmail}
                  required
                />
              </div>

              <div className="profile-input-group">
                <label className="profile-data-label" htmlFor="email-input">
                  {`${I18n.t('profile.MOBILE')}`}
                </label>

                <Field
                  id="mobile-input"
                  name="mobilePhoneNumber"
                  type="tel"
                  className="profile-input"
                  validate={validatePhone}
                  required
                >
                  {(props) => (
                    <TextInput
                      {...props}
                      onChange={(e) => {
                        if (e?.target?.value && parsePhoneNumberFromString(e?.target?.value, 'CH')) {
                          props.input.onChange(parsePhoneNumberFromString(e?.target?.value, 'CH').formatInternational());
                        } else props.input.onChange(e?.target?.value);
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>

            {!pristine && (
              <div className="button-group profile-form-buttons">
                <Button
                  htmlType="button"
                  onClick={() => {
                    form.reset();
                    notifySuccess(I18n.t('callback.CHANGES_DISCARDED'));
                  }}
                  text={I18n.t('callback.DISCARD_CHANGES')}
                />

                <Button
                  id="click"
                  htmlType="submit"
                  type={ButtonType.PRIMARY}
                  onClick={(e) => {
                    handleSubmit(e);
                    updateEventInfo({
                      category: analyticsParams.event.category.buttonClick,
                      action: analyticsParams.event.action.CLICK_SAVE_PROFILE,
                      label: 'Save changes',
                      timestamp: moment().toISOString(),
                    });
                    fireActionTriggerCustomEvent('#click', window.digitalData);
                    updateEventInfo({}, true);
                  }}
                  text={I18n.t('callback.EDIT_CHANGES')}
                />
              </div>
            )}
          </form>
        )}
      />

      <ModalBase
        isOpen={isLanguagePreferencesModalOpen}
        toggle={handleLanguagePreferencesModal}
        fullScreenPopin={deviceIsMobile()}
        actionButton={I18n.t('profile.languagesModal.save')}
        secondaryActionButton={I18n.t('profile.languagesModal.back')}
        secondaryAction={handleLanguagePreferencesModal}
        action={saveLanguageChanges}
        title={I18n.t('profile.languagesModal.title')}
      >
        <div className="profile-page">
          <div className="profile-page-group">
            <p className="profile-page-text">{I18n.t('profile.languagesModal.title')}</p>
            <div className="profile-page-choices">
              {Object.keys(languageChoices).map((choice: string) => (
                <div className="choice-group" key={choice}>
                  <div data-checked={selectedLanguage === choice} className="outer-input" />
                  <h4 className="choice-label">{I18n.t(`profile.languages.${choice}`)}</h4>
                  <button
                    type="button"
                    className="hidden-button"
                    onClick={() => setSelectedLanguage(choice)}
                    aria-label={`button to set language to ${choice}`}
                  />
                </div>
              ))}
            </div>
            <p className="profile-page-text">{I18n.t('profile.languagesModal.warning')}</p>
          </div>
        </div>
      </ModalBase>
      <LogoutModal
        modal={showLogoutModal}
        toggle={handleLogoutModal}
        backdrop
      />
    </div>
  );
}

export default withRouter(connect((state) => ({
  user: state.login.userSession.user,
  translations: state.i18n.translations,
  isUserAllowedToGoBack: state.login.isUserAllowedToGoBack,
}))(Profile));
