import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Car } from 'iconsax-react';
import userApi from '../../../../../network/api/userApi';
import { notifyError } from '../../../../../network/notification';
import { deviceIsMobile } from '../../../../../services/displayService';
import ModalBase from '../../../../modal/ModalBase';
import {
  fireActionTriggerCustomEvent,
  updateEventInfo,
} from '../../../../../services/analyticsService';
import { analyticsParams } from '../../../../../const';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';

type Props = {
  history: any
  senselyDescription?: any,
  emergencyNumberFetched?: Function,
};

type State = {
  emergencyNumber: string,
  isOpen: boolean,
};

class EmergencyTile extends React.Component<Props, State> {
  state = {
    isOpen: false,
    emergencyNumber: '',
  };

  componentDidMount(): void {
    this.getEmergencyNumber();
  }

  getEmergencyNumber = () => {
    userApi
      .fetchEmergencyPhoneNumber()
      .then((response) => (response.text()))
      .then((emergencyNumber) => {
        this.setState({ emergencyNumber });
        if (this.props.emergencyNumberFetched) this.props.emergencyNumberFetched(emergencyNumber);
      })
      .catch(notifyError);
  };

  callEmergency = () => {
    updateEventInfo({
      category: analyticsParams.event.category.buttonClick,
      action: analyticsParams.event.action.EMERGENCY_CALL,
      label: 'Emergency call 144',
      timestamp: moment().toISOString(),
    });
    fireActionTriggerCustomEvent('#click', window.digitalData);
    updateEventInfo({}, true);
    if (deviceIsMobile()) {
      window.location.href = `tel:${this.state.emergencyNumber}`;
    } else if (!this.props.senselyDescription) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  render() {
    return (
      <>
        <ModalBase
          isOpen={this.state.isOpen}
          toggle={() => this.setState({ isOpen: !this.state.isOpen })}
          title={I18n.t('contact.HINT_TITLE')}
          secondaryActionButton={I18n.t('actions.BACK')}
        >
          <div className="classic-text">
            {`${I18n.t('contact.EMERGENCY_HINT', { tel: this.state.emergencyNumber })}`}
          </div>
        </ModalBase>
        <FeaturedTile
          id="click"
          title={I18n.t('contact.emergency.TITLE')}
          icon={<Car />}
          onClick={this.callEmergency}
        />
      </>
    );
  }
}

export default withRouter(EmergencyTile);
