import React, { ReactElement } from 'react';
import classNames from 'classnames';
import ButtonType from '../../types/ButtonType';

type Props = {
  id?: string,
  ariaLabel?: string,
  type?: ButtonType,
  htmlType?: 'button' | 'reset' | 'submit',
  icon?: ReactElement,
  text?: string,
  disabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

function Button({
  id,
  ariaLabel,
  type = ButtonType.SECONDARY,
  htmlType,
  icon,
  text,
  disabled,
  onClick,
}: Props) {
  const className: string = classNames(
    'button',
    { 'button--primary': type === ButtonType.PRIMARY },
    { 'button--text': type === ButtonType.TEXT },
    { 'button--secondary': type === ButtonType.SECONDARY },
    { 'button--icon-only': !!icon && !text },
  );

  if (!icon && !text) {
    return <></>;
  }

  return (
    // htmlType has the right type
    // but since we have an old version of typescript, its still flagged as an error
    // eslint-disable-next-line react/button-has-type
    <button
      id={id}
      aria-label={ariaLabel}
      disabled={disabled}
      className={className}
      type={htmlType ?? 'button'}
      onClick={onClick}
    >
      {!!text && (
        <div className="button-content">
          {text}
        </div>
      )}

      {!!icon && (
        <div className="button-icon">
          {icon}
        </div>
      )}
    </button>
  );
}

export default Button;
