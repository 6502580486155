import { AnyAction } from 'redux';

type ActionType = {
  type: string,
  value: any
};

export type initialStateType = {
  message: string,
  isMessageDisplayed: false,
};

export interface StoreFunction {
  (action: string, value: any): AnyAction
}

export const reduce = (initialState: any, actionMap: any) => (state: initialStateType = initialState, action: ActionType) => {
  if (actionMap[action.type]) {
    return {
      ...state,
      [actionMap[action.type]]: action.value,
    };
  }
  return {
    ...state,
  };
};

export const store: StoreFunction = (action: string, value: any) => {
  return {
    type: action,
    value,
  };
};
