import React from 'react';
import { I18n } from 'react-redux-i18n';
import videoCallback from '../../../../img/video_callback.svg';
import phoneCallback from '../../../../img/phone_callback.svg';
import EventTile from './EventTile';
import { BadgeType } from '../../../../types/BadgeTypes';
import { BadgeProps } from '../../../badge/Badge';

type Props = {
  infos: {
    endDate: string,
    startDate: string,
    prmId: string,
    documentsNames: string[],
    type: string,
    phoneNumber?: string,
    isVideoCall?: boolean,
  },
  setModalStatus: Function,
};

export default function CallbackTile({
  infos,
  setModalStatus,
}: Props) {
  const handleModalStatus = () => {
    const { prmId, type } = infos;
    setModalStatus(prmId, type);
  };

  const useRightImage = () => (
    infos.isVideoCall ? videoCallback : phoneCallback
  );

  const useRightBadge: () => BadgeProps = () => ({
    text: infos.isVideoCall ? I18n.t('tile.history.videoCallback') : I18n.t('tile.history.phoneCallback'),
    type: BadgeType.PRIMARY,
  });

  return (
    <EventTile
      title={I18n.t('tile.history.CALLBACK_REQUEST')}
      illustration={useRightImage()}
      documents={infos.documentsNames}
      onClick={handleModalStatus}
      startDate={infos.startDate}
      endDate={infos.endDate}
      badge={useRightBadge()}
    />
  );
}
