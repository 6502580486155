import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from '../ModalBase';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  action: any,
  lateAppointmentDaysLimit: number,
};

function ModalAnnounceReminder(props: Props) {
  const { lateAppointmentDaysLimit } = props;

  return (
    <ModalBase
      {...props}
      title={I18n.t('modal.announceReminder.TITLE')}
      actionButton={I18n.t('modal.announceReminder.YES')}
      secondaryActionButton={I18n.t('modal.announceReminder.NO')}
    >
      <div className="classic-text">
        {`${I18n.t('modal.announceReminder.CONTENT', { lateAppointmentDaysLimit })}`}
      </div>
    </ModalBase>
  );
}

export default withRouter(ModalAnnounceReminder);
