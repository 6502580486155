import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I24Support } from 'iconsax-react';
import { deviceIsMobile } from '../../../../../services/displayService';
import senselyApi from '../../../../../network/api/senselyApi';
import { analyticsParams, caseStatus } from '../../../../../const';
import servicePackageApi from '../../../../../network/api/servicePackageApi';
import {
  fireActionTriggerCustomEvent,
  updateEventInfo,
} from '../../../../../services/analyticsService';
import ModalHotlineNumber from '../../../../modal/ModalHotlineNumber';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';

type Props = {
  senselyDescription?: any,
  history: any,
  hotlineNumberFetched?: Function,
};

type State = {
  isOpen: boolean,
  hotlineNumber: string,
};

class HotlineTile extends React.Component<Props, State> {
  state = {
    isOpen: false,
    hotlineNumber: undefined,
  };

  componentDidMount(): void {
    this.getHotlineNumber();
  }

  getHotlineNumber = () => {
    servicePackageApi
      .fetchContactPhoneNumber()
      .then((response) => (response.text()))
      .then((hotlineNumber) => {
        this.setState({ hotlineNumber });
        if (this.props.hotlineNumberFetched) this.props.hotlineNumberFetched(hotlineNumber);
      });
  };

  callContact = () => {
    updateEventInfo({
      category: analyticsParams.event.category.buttonClick,
      action: analyticsParams.event.action.CALL_MEDI24,
      label: 'Call Medi24',
      timestamp: moment().toISOString(),
    });
    fireActionTriggerCustomEvent('#click', window.digitalData);
    updateEventInfo({}, true);
    if (this.props.senselyDescription) { // if Sensely Results exits we need to save them in the PRM
      senselyApi
        .saveSenselyResults({
          senselyDescription: {
            interview: this.props.senselyDescription.interview,
            recommendationTitle: this.props.senselyDescription.recommendationTitle,
            recommendationUrl: this.props.senselyDescription.recommendationUrl,
          },
          consultationStatus: caseStatus.ACTIVE,
        })
        .catch((error) => console.log('Error saving sensely results', { error }));
    }
    if (deviceIsMobile() && this.state.hotlineNumber) { // If the user is on mobile, we redirect to his keyboard
      window.location.href = `tel:${this.state.hotlineNumber}`;
    } else if (!deviceIsMobile() && this.state.hotlineNumber && !this.props.senselyDescription) { // If the user is on desktop and doesn't have made a Symptom Checker we display the number
      this.setState({ isOpen: true });
    } // If the user is on desktop and have made a Symptom Checker, the Symptom Result Page will display another pop in with the number
  };

  render() {
    return (
      <>
        <ModalHotlineNumber
          isOpen={this.state.isOpen}
          toggle={() => this.setState({ isOpen: !this.state.isOpen })}
          number={this.state.hotlineNumber}
        />
        <FeaturedTile
          id="click"
          title={I18n.t('contact.hotline.TITLE')}
          icon={<I24Support />}
          type={FeaturedTileType.PRIMARY}
          onClick={this.callContact}
        />
      </>
    );
  }
}

export default withRouter(HotlineTile);
