import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import medi24Logo from '../../img/medi24.svg';
import { store as storeFunction } from '../../network/reduce';
import { IS_USER_ALLOWED_TO_GO_BACK } from '../../state/login/loginReducer';
import {DASHBOARD_PAGE_ROUTE, HISTORY_PAGE_ROUTE} from '../../const';

type Props = {
  isUserAllowedToGoBack: boolean,
  contactNumber: string,
  sensely?: boolean
  dispatch: Function,
  location: {
    pathname: string,
  },
};

function Header({ isUserAllowedToGoBack, dispatch, location }: Props) {
  return (
    <div className="header">
      {isUserAllowedToGoBack && (location.pathname === HISTORY_PAGE_ROUTE || location.pathname === DASHBOARD_PAGE_ROUTE)
          && (
          <div
            onClick={() => {
              dispatch(storeFunction(IS_USER_ALLOWED_TO_GO_BACK, false));
            }}
          >
            <i
              className="fas fa-arrow-left"
            />
          </div>
          )}
      <img className="header-logo" src={medi24Logo} alt="header-logo" />
    </div>
  );
}

export default withRouter(connect((state) => ({
  isUserAllowedToGoBack: state.login.isUserAllowedToGoBack,
}))(Header));
