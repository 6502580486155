import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from '../ModalBase';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  action: any,
};

function ModalUploadPictureWarning(props: Props) {
  return (
    <ModalBase
      {...props}
      title={I18n.t('modal.uploadPictureWarning.TITLE')}
      actionButton={I18n.t('modal.uploadPictureWarning.YES')}
      secondaryActionButton={I18n.t('modal.uploadPictureWarning.NO')}
    >
      <div className="classic-text">
        {`${I18n.t('modal.uploadPictureWarning.CONTENT')}`}
      </div>
    </ModalBase>
  );
}

export default withRouter(ModalUploadPictureWarning);
