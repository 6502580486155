import RestClientAuthenticated from '../clients/restClientAuthenticated';
import RestClient from "../clients/restClient";

const partnerApi = {
  fetchPartner: () => new RestClientAuthenticated('/partner', 'GET').execute(),

  fetchPartnerDataPrivacy: () => new RestClientAuthenticated('/partnerTexts/dataPrivacy', 'GET').execute(),

  fetchPartnerTermsAndCondition: () => new RestClientAuthenticated('/partnerTexts/termsAndConditions', 'GET').execute(),

  fetchPartnerCompanyLegalInformation: () => new RestClientAuthenticated('/partnerTexts/companyLegalInformation', 'GET').execute(),

  fetchSupportNumber: () => new RestClientAuthenticated('/partner/supportNumber', 'GET').execute(),

  fetchSupportInformation: (partnerCode: string) => new RestClient(`/partner/supportInformation?partner=${partnerCode}`, 'GET').execute()
};

export default partnerApi;
