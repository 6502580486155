import RestClientAuthenticated from '../clients/restClientAuthenticated';

const announceAppointmentApi = {
  fetchAnnounceAppointmentConfiguration: (type) => new RestClientAuthenticated(`/announceAppointment/configuration?type=${type}`).execute(),

  hasUserExceededAnnounceAppointment: (type) => new RestClientAuthenticated(`/announceAppointment/isAmountExceeded?type=${type}`).execute(),

  fetchAnnounceAppointmentDetails: (announceAppointmentId : string) => new RestClientAuthenticated(`/announceAppointment/${announceAppointmentId}`, 'GET').execute(),

  extendAnnounceAppointment: (announceAppointmentId: string) => new RestClientAuthenticated(`/announceAppointment/extend/${announceAppointmentId}`, 'GET').execute(),

};

export default announceAppointmentApi;
