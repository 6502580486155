import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { HISTORY_PAGE_ROUTE } from '../../../const';
import { notifyError, notifySuccess } from '../../../network/notification';
import ModalBase from '../ModalBase';
import callbackApi from '../../../network/api/callbackApi';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  callbackId: string,
};

class CallBackDeleteModal extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    size: '',
  };

  deleteCallback = () => {
    callbackApi
      .deleteCallBackDetails(this.props.callbackId)
      .then((response) => {
        notifySuccess(I18n.t('callback.CALLBACK_DELETED'), response);
        this.props.history.push(HISTORY_PAGE_ROUTE);
      })
      .catch(notifyError);
  };

  render() {
    return (
      <ModalBase
        {...this.props}
        title={I18n.t('callback.DELETE_MODAL')}
        action={() => this.deleteCallback()}
        secondaryAction={() => this.props.toggle()}
        actionButton={I18n.t('actions.YES')}
        secondaryActionButton={I18n.t('actions.NO')}
      >
        <div className="classic-text">{I18n.t('callback.DELETE_WARNING')}</div>
      </ModalBase>
    );
  }
}

export default withRouter(CallBackDeleteModal);
