import React, { useState } from 'react';
import { I18n, setLocale } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { errorPageLanguage, storeLang } from '../../services/languageService';
import {
  CONTRACT_ID_PATIENT,
  CONTRACT_ID_USER,
  DEFAULT_ERROR,
  ERROR,
  HELSANA_ID,
  PARTNER,
} from '../../const';
import partnerApi from '../../network/api/partnerApi';
import { notifyError } from '../../network/notification';
import { SupportInformation } from './SupportInformation';
import { useOnComponentMounted } from '../../lib/react-hook-alias/ReactHookAlias';
import SignupForm from './signupForm/SignupForm';

type Props = {
  dispatch: Function,
  location: {
    search: string,
  },
};

const PRM_NOT_FOUND = 'PRM_NOT_FOUND';

function ErrorPage({ dispatch, location }: Props) {
  const [error] = useState<string>(
    new URLSearchParams(location.search).get(ERROR) || DEFAULT_ERROR,
  );
  const [partner] = useState<string | undefined>(
    new URLSearchParams(location.search).get(PARTNER) || undefined,
  );
  const [contractIdPatient] = useState<string | undefined>(
    new URLSearchParams(location.search).get(CONTRACT_ID_PATIENT) || undefined,
  );
  const [contractIdUser] = useState<string | undefined>(
    new URLSearchParams(location.search).get(CONTRACT_ID_USER) || undefined,
  );
  const [supportInformation, setSupportInformation] = useState<SupportInformation | undefined>();
  const [isOpenSignupForm, setIsOpenRegistration] = useState<boolean>(false);

  useOnComponentMounted(() => {
    dispatch(setLocale(errorPageLanguage()));
    storeLang(errorPageLanguage());

    if (partner) {
      partnerApi
        .fetchSupportInformation(partner)
        .then((response) => response.json())
        .then((information) => {
          setSupportInformation(information);
        })
        .catch(notifyError);
    }
  });

  return (
    <>
      {isOpenSignupForm ? (
        <SignupForm
          partner={partner}
          contractIdPatient={contractIdPatient}
          contractIdUser={contractIdUser}
          backAction={() => setIsOpenRegistration(false)}
        />
      ) : (
        <div className="error-page">
          <div className="error-page-header">
            <h1 className="error-page-title">
              {I18n.t('authenticationErrors.AUTHENTICATION')}
            </h1>
          </div>
          {partner === HELSANA_ID
          && error === PRM_NOT_FOUND
            ? (
              <p className="error-page-text">
                {I18n.t('authenticationErrors.PRM_NOT_FOUND_HELSANA.PART_1')}
                <a onClick={() => setIsOpenRegistration(true)}>
                  {I18n.t('authenticationErrors.PRM_NOT_FOUND_HELSANA.LINK')}
                </a>
                {I18n.t('authenticationErrors.PRM_NOT_FOUND_HELSANA.PART_2')}
              </p>
            )
            : (
              <p className="error-page-text">
                {!supportInformation
                  ? I18n.t(`authenticationErrors.${error}`)
                  : I18n.t(`authenticationErrors.${error}`, {
                    partner: supportInformation.partnerName,
                    email: supportInformation.emailSupport,
                  })}
              </p>
            )}
        </div>
      )}
    </>
  );
}

export const ErrorPageTest = () => (
  <ErrorPage
    dispatch={() => {
    }}
    location={{ search: DEFAULT_ERROR }}
  />
);

export default withRouter(connect((state) => ({
  locale: state.i18n.locale,
}))(ErrorPage));
