import { SessionServiceInterface } from './SessionService';

/**
 * Permet l'accès au service de session.
 * Le service de session ne doit pas être utilisé pour récupérer les informations sur l'utilisateur courant,
 * pour ça il faut passer par redux et l'objet CURRENT_SESSION
 */
export class SessionServiceHolder {
  private static sessionService: SessionServiceInterface;

  static setSessionService(sessionService: SessionServiceInterface) {
    SessionServiceHolder.sessionService = sessionService;
  }

  static getSessionService() {
    return SessionServiceHolder.sessionService;
  }

  // existe uniquement pour gérer l'ancien code, le nouveau code ne devrait jamais utiliser ça,
  // mais utiliser une méthode static qui prend en paramètre le user qui vient de redux et qui vérifie la permission
  static hasPermissions(permissions: string[]) {
    return permissions.some((permission) => SessionServiceHolder.getSessionService().currentSession()?.user.permissions.includes(permission) ?? false) ;
  }

  // existe uniquement pour gérer l'ancien code, le nouveau code ne devrait jamais utiliser ça,
  // mais utiliser une méthode static qui prend en paramètre le user qui vient de redux et qui vérifie la permission
  static hasPermission(permission: string) {
    return SessionServiceHolder.getSessionService().currentSession()?.user.permissions.includes(permission) ?? false;
  }
}
