import React from 'react';
import {Redirect, Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {DASHBOARD_PAGE_ROUTE} from '../const';
import { SessionServiceHolder } from '../services/session/SessionServiceHolder';

type Props = {
    component: any,
    authorization: string,
    authorizations: string[],
    isConnected: boolean,
    path?: string | Array<string>,
    location: {
        pathname: string,
    }
};

class PrivateRoute extends React.Component<Props> {

    render() {
        let {
            component: Component, authorization, authorizations,isConnected, path,
        } = this.props;
        return (
            <Route
                exact
                path={path}
                render={(props) => {
                    if(authorizations){
                        if (SessionServiceHolder.hasPermissions(authorizations)) {
                            return <Component {...props} />;
                        }
                        return <Redirect to={DASHBOARD_PAGE_ROUTE}/>;
                    }
                    if (authorization) {
                        if (SessionServiceHolder.hasPermission(authorization)) {
                            return <Component {...props} />;
                        }
                        return <Redirect to={DASHBOARD_PAGE_ROUTE}/>;
                    }
                    if (isConnected) {
                        return <Component {...props} />;
                    }
                    return <Redirect to="/error?error=BLOCKED"/>;
                }}
            />
        );
    }
}

export default withRouter(connect(
    (state) => ({
        isConnected: state.login.userSession !== null,
    }),
)(PrivateRoute));
