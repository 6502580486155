import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { BrifecaseCross } from 'iconsax-react';
import {
  analyticsParams,
  ANNOUCEMENT_PAGE_ROUTE,
  HistoryType,
  TYPE,
} from '../../../../../const';
import { updateProcessData } from '../../../../../services/analyticsService';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';

type Props = {
  history: any,
  small: boolean,
  senselyDescription: any,
};

class RegistrationTile extends React.Component<Props> {
  goToRegistration = () => {
    const { history, senselyDescription } = this.props;
    history.push(`${ANNOUCEMENT_PAGE_ROUTE}?${TYPE}=${HistoryType.REGISTRATION}`, { senselyDescription });
  };

  render() {
    return (
      <FeaturedTile
        title={I18n.t('services.REGISTRATION')}
        icon={<BrifecaseCross />}
        type={FeaturedTileType.SECONDARY}
        onClick={() => {
          this.goToRegistration();
          updateProcessData({
            processName: analyticsParams.processName.ANNOUNCE_APPOINTMENT,
            status: analyticsParams.status.STARTED,
            startTime: moment().toISOString(),
          }, true);
        }}
      />
    );
  }
}

export default withRouter(RegistrationTile);
