import React from 'react';
import classNames from 'classnames';
import { BadgeType } from '../../types/BadgeTypes';

export type BadgeProps = {
  text: string,
  type: BadgeType,
  icon?: React.ReactElement,
};

export default function Badge({ text, type, icon }: BadgeProps) {
  const className: string = classNames(
    'badge',
    { 'badge--primary': type === BadgeType.PRIMARY },
    { 'badge--primary-outlined': type === BadgeType.PRIMARY_OUTLINED },
    { 'badge--secondary-outlined': type === BadgeType.SECONDARY_OUTLINED },
  );

  return (
    <div className={className}>
      {icon}
      <span>
        {text}
      </span>
    </div>
  );
}
