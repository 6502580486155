import handleError, {
  networkErrorCatcher,
} from '../handleError';
import { baseApiUrl } from '../../const';

const stringify = (body) => {
  if (typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
};

export default class HttpClient {
  apiExtensionUrl: string;

  headersValue: HeadersInit;

  methodvalue: string;

  bodyValue: string;

  constructor(apiExtensionUrl: string, method: string = 'GET') {
    this.apiExtensionUrl = apiExtensionUrl;
    this.headersValue = {};
    this.methodvalue = method;
    this.bodyValue = null;
  }

  headers(headers: HeadersInit) {
    Object.assign(this.headersValue, headers);
    return this;
  }

  body(body: string) {
    this.bodyValue = body;
    return this;
  }

  jsonBody(objectBody: Object) {
    this.headers({ 'Content-Type': 'application/json' });
    this.body(stringify(objectBody));
    return this;
  }

  execute() {
    return fetch(baseApiUrl + this.apiExtensionUrl, {
      headers: this.headersValue,
      method: this.methodvalue,
      body: this.bodyValue,
      credentials: 'same-origin',
    })
      .catch(networkErrorCatcher)
      .then(handleError);
  }
}
