const en = {
  footer: {
    COPYRIGHT: '© Medi24 AG 2023. All rights reserved.',
    link: {
      DATA_PROTECTION: 'Data privacy',
      TERMS_AND_CONDITION: 'Terms and Conditions',
      COMPANY_LEGAL_INFORMATION: 'Legal Notice',
      HANDLE_COOKIES: 'Cookie management',
    },
  },
  actions: {
    YES: 'Yes',
    NO: 'No',
    OK: 'Ok',
    BACK: 'Back',
    CONFIRM_LOGOUT: 'Log out',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    DELETE: 'Delete',
    NEW: 'New',
    CONFIRM: 'Confirm',
    SEARCH: 'Search...',
    EDIT: 'Edit',
  },
  authenticationErrors: {
    AUTHENTICATION: 'Your authentication has failed',
    DEFAULT_ERROR: 'There was an error',
    WRONG_PARTNER: 'There was an error connecting to MyMedi24. Please try again later.',
    SAML_SETTINGS: 'There was an error connecting to MyMedi24. Please try again later.',
    SAML_RESPONSE: 'There was an error connecting to MyMedi24. Please try again later.',
    PRM_NOT_FOUND: 'Your patient file cannot be found in our medical database. \n'
      + 'Most probably this is due to a recent change of the data of your insurer that will be fixed with the next data synchronisation. \n'
      + 'Please try again in one week from now or contact your insurer.',
    PRM_NOT_FOUND_HELSANA: {
      PART_1: 'Are you using myMedi24 for the first time? Then please proceed as follows: \n \n'
        + 'If you have the BASIS or GP model: \n'
        + 'Start by registering ',
      LINK: 'here',
      PART_2: '. It’ll only take a few minutes to get access to myMedi24. \n \n'
        + 'If you have the Premed-24, Telmed or Flexmed model: \n'
        + 'Please try again in a week. Your data is still being synchronised.'
    },
    BLOCKED: 'To protect your data, this link is blocked for unauthorized direct use. Please log in to your insurer \'s application to use the Medi24 online services.',
    PATIENT_INACTIVE: 'You are no longer able to use the services of MyMedi24 as your contract with %{partner} has been terminated. If you think you are seeing this message by mistake, please contact us at %{email}.',
    UNABLE_TO_IDENTIFY_PATIENT: 'We were unable to identify you with the information provided by %{partner}. For more information, please contact us at %{email}.',
  },
  welcomePage: {
    WELCOME: 'Welcome',
    CONNECTED: 'You are now connected to the services of Medi24.',
    DISCLAIMER: 'None of the data collected here will be shared with your insurer.',
    CONTINUE: 'Continue',
    DO_NOT_SHOW_AGAIN: 'Do not show me this again',
  },
  dataPrivacy: {
    TITLE_BEFORE: 'Before we get started',
    TITLE_ALREADY_VALIDATE: 'Data privacy',
    SUB_TITLE_FIRST_TIME: 'Please read and accept our terms of use and privacy policy. This enables us to provide you with the best possible user experience.',
    SUB_TITLE: 'We would like to remind you that the following data privacy policy is now applied to all pages you will consult in MyMedi24.',
    CONTINUE: 'Continue',
  },
  termsAndConditions: {
    TITLE: 'Terms and Conditions',
    SUB_TITLE: 'We would like to remind you that the following Terms and Conditions notice is now applied for all pages you will consult in the MyMedi24.',
  },
  companyLegalInformation: {
    TITLE: 'Legal notice',
  },
  dashboardPage: {
    WELCOME: 'Welcome, \n',
    NEXT_APPOINTMENT: 'Your next event',
    ALL_APPOINTMENT: 'All events',
    TREATMENT_SINCE: 'from',
    VALID_TILL: 'until',
    CALLBACK: 'Callback',
    CALL_DATE: 'We will call you on',
    BETWEEN: 'between',
    NO_NEXT_APPOINTMENT: 'You do not have any upcoming appointments at this time',
    FUNCTIONALITIES: 'My Functionalities',
  },
  menu: {
    START: 'Start',
    CONTACT: 'Contact',
    PROFILE: 'Profile',
    HISTORY: 'My record',
    LOGOUT: 'Logout',
  },
  services: {
    UPLOAD_PICTURE: 'Upload picture',
    CALLBACK_REQUEST: 'Callback request',
    SYMPTOM_CHECKER: 'Symptom checker',
    CONSULTATION: 'Past Consultation',
    REGISTRATION: 'Announce appointment',
    ANNOUNCEMENT: 'Announce emergency ',
    REFERRAL: 'Create referral',
  },
  contact: {
    HINT_SUPPORT: 'Should you have any further questions concerning the online services of MyMedi24 please contact the application support at: ',
    HINT_TITLE: 'Contact',
    HINT: 'To contact us please dial the following number:',
    EMERGENCY_HINT: 'In case of an emergency please dial the following number: %{tel}',
    TITLE: 'Contact',
    CONTACT_TITLE: 'How would you like to contact us',
    HELP_TITLE: 'Do you need help with MyMedi24',
    hotline: {
      TITLE: 'Call Medi24',
      BODY: 'For a telemedical consultation call Medi24 at the following number:',
      TEL: ' %{tel}',
    },
    SUPPORT_TITLE: 'MyMedi24 Support',
    emergency: {
      TITLE: 'Emergency call 144',
      BODY: 'If this is an emergency, please dial 144.',
    },
    CLOSE: 'Close',
  },
  signup: {
    TITLE: 'MyMedi24 registration',
    INSURANCE_PRODUCT: 'Insurance product',
    INSURANCE_PRODUCT_INPUT: 'Do you have the BASIS or general practitioner model?',
    PERSONAL_INFORMATION: 'Personal information',
    FIRST_NAME_INPUT: 'First name',
    LAST_NAME_INPUT: 'Last name',
    DATE_OF_BIRTH_INPUT: 'Date of birth',
    GENDER: 'Sex',
    GENDER_CHOICES: {
      M: 'Male',
      F: 'Female'
    },
    ADDRESS: 'Address',
    STREET_INPUT: 'Street',
    ZIP_CODE_INPUT: 'Zip code',
    CITY_INPUT: 'City',
    LANGUAGES: 'Languages',
    FIRST_LANGUAGE_INPUT: 'First language',
    SECOND_LANGUAGE_INPUT: 'Second language',
    LANGUAGE_CHOICES: {
      DEUTSCH: 'German',
      FRENCH: 'French',
      ITALIAN: 'Italian',
      ENGLISH: 'English'
    },
    CONTACT_INFORMATION: 'Contact information',
    PHONE_NUMBER_INPUT: 'Phone number',
    MOBILE_PHONE_NUMBER_INPUT: 'Mobile phone number',
    SIGN_UP: 'Register',
    successModal: {
      title: 'Online Registration',
      description: 'Your registration was successful.\nYou can now proceed using the MyMedi24 services',
      action: 'Continue'
    },
    errorModal: {
      title: 'Online Registration',
      description: 'Unfortunately your registration for MyMedi24 was not successful.\n' +
        + 'Please try again later.\n'
        + '\n'
        + 'In case the problem persists please contact MyMedi24 via the phone number communicated to you by your health insurer.',
      action: 'Close'
    }
  },
  sensely: {
    WARNING_TEXT: 'Running the symptom checker on MyMedi24 does not release you from your obligations that derive from your health insurance contract such as, for example, to announce your visits to a doctor or to undergo a telemedical consultati on.\n'
      + '\n'
      + 'Please complete all steps to the end of the process and conclude by clicking on "Done" to choose from the next steps as recommended by MyMedi24.\n'
      + '\n'
      + 'Please note that the symptom checker can offer assistance in case of uncertainty about how to proceed. Providing medical diagnosis, however, is not possible.\n'
      + '\n'
      + 'In case you should feel uncomfortable with the recommendation provided by MyMedi24, please do not hesitat to contact Medi24 for further assistance.',
    LOADER: 'Loading the Symptom Checker...',
    QUIT_PROCESS: 'Quit process',
    QUIT_PROCESS_2: 'Would you like to save the interview nevertheless?',
    CALLBACK: 'We will contact you regarding your medical concern.',
    UPLOAD_PICTURE: 'Upload a picture / document and we will contact you.',
    REGISTRATION: 'Inform us about a consultation.',
    ANNOUNCEMENT: 'Inform us about a consultation.',
    REFERRAL: 'Inform us about a referral.',
    TITLE: 'What would you like to do?',
    footer: {
      TEXT1: 'If you do not want to choose any of the options and will still see your doctor, remember to call Medi24 to announce the appointment.',
      TEXT2: 'After the consultation by your doctor, call Medi24 to announce the emergency appointment.',
      TEXT3: 'If you do not want to choose any of the options and will still see your doctor, remember to announce the appointment to Medi24 by telephone or in MyMedi24.',
      TEXT4: 'If you are in doubt whether this urgent recommendation applies to your situation, please call Medi24 to verify this result with a specialist.',
      TEXT5: 'If you are in doubt as to whether this urgent recommendation applies to your situation, please call Medi24 to verify this result with a specialist. \n'
        + 'After the consultation by your doctor, call Medi24 to announce the emergency appointment.',
      TEXT6: 'If you are in doubt whether this emergency recommendation applies to your situation, please call Medi24 to verify this result with a specialist. \n'
        + 'If you do not want to choose any of the options and will still see your doctor, remember to announce the appointment to Medi24 by telephone or in MyMedi24.',
    },
    RESULTS_SAVED: 'Saved results',
    SENSELY_TITLE: 'Note on the Symptom Checker',
  },
  message: {
    SUCCESS: 'Changes have been successfully applied.',
    ERROR: 'An error occurred while applying the changes',
    YES: 'Yes',
    NO: 'No',
    SENSELY_INIT_ERROR: 'An error occurred when connecting to the Symptom Checker.',
  },
  wsError: {
    AUTHENTIFICATION_EXPIRED: 'Authentification expired',
    INTERNAL_ERROR: 'Connection error',
    NETWORK_ERROR: 'Network error, your internet connexion seems unavailable',
    TIMEFRAME_NOT_AVAILABLE: 'The requested timeframe is not available any more. Please select another timeframe or another date.',
    MESSAGE: '%{field0}',
    NO_PARTNER_FOUND: 'Your insurance partner was not found.',
    NOT_ALLOWED_BY_PARTNER: 'Your partner does not authorize you to extend an appointment release.',
    ALREADY_EXTENDED_MY_MEDI_24: 'This is already an extension.',
    ALREADY_CREATED: 'You have already done an extension for this specific appointment.',
    ERROR_HTML_SENSELY: 'A problem occurred while saving the result',
    ERROR_SENSELY_URGENCY_NOT_FOUND: 'Unknown urgency',
    NO_SENSELY_RESULTS: 'Unable to transmit result',
    TEXT_NOT_FOUND: 'The document you requested is not available at the moment. Please try again later.',
    NOT_FOUND: 'Information not found',
    UNABLE_TO_CREATE_FEEDBACK: 'Your feedback could not be saved.',
    ALREADY_EXPIRED_SESSION_TOKEN: 'Your session is expired.',
    AMOUNT_EXCEEDED: 'You have already fulfilled your annual quota',
  },
  logout: {
    DISCONNECTED: 'Logged out successfully',
  },
  date: {
    LONG_DATE_NO_YEAR: 'dddd DD. MMMM',
    long: 'MMMM Do, YYYY HH:mm',
    DATE_START: 'Start date',
    DATE_END: 'End date',
    DATE_FORMAT: 'ddd, DD.MM.YYYY',
    ONLY_DATE_FORMAT: 'DD.MM.YYYY',
    ONLY_HOURS_FORMAT: 'HH[h]mm',
    ICS_DATE_FORMAT: 'YYYY-M-D-H-m',
    HISTORY_TILE_DATE_FORMAT: 'D.MM.YY',
    period: {
      ON: 'On',
      BETWEEN: 'Between',
      TIMEFRAME: 'Timeframe',
      VALID_FROM: 'Valid from',
      TO: 'to',
    },
  },
  modal: {
    LOGOUT_MODAL: 'Logout',
    LOGOUT_MESSAGE: 'Are you sure you would like to log out?',
    INACTIVITY_MESSAGE: 'You are inactive, you will be logged out in: ',
    ICS: 'Calendar Event',
    ICS_CONTENT: 'Click "Add to calendar" to create an appointment in your personal calendar',
    ICS_YES: 'Add to calendar',
    ICS_NO: 'Close',
    YES: 'Yes',
    NO: 'No',
    senselyExit: {
      TITLE: 'Would you like to leave the Symptom Checker?',
      CONTENT: 'If you leave the Symptom Checker now, you will lose the entered data. Do you want to quit?',
      SAVE_ASSESSMENT: 'Before leaving, do you want to save the Symptom Checker assessment?',
    },
    senselySaved: {
      TITLE: 'You’re leaving the Symptom Checker',
      CONTENT_SAVED: 'Your information has been saved. Press "Ok" to exit the Symptom Checker.',
    },
    announceReminder: {
      TITLE: 'Reminder',
      YES: 'Yes, it was an emergency',
      NO: 'Close',
      CONTENT: 'Retroactive announcements of appointments are only valid for emergency treatments withing %{lateAppointmentDaysLimit} days of their execution according to the insurance terms and conditions.',
    },
    announceAmountExceeded: {
      TITLE: 'Maximum number exceeded',
      YES: 'Call Medi24',
      NO: 'Close',
      CONTENT: 'You can only announce emergency treatments retroactively via the app %{limit} times a year. For any further announcements please contact our hotline.',
      CONTENT_REFERRAL: 'Referrals can be reported online up to %{limit} times per calendar year. For additional referrals, please call us.',
    },
    referralNotAllowed: {
      TITLE: 'Invalid referral',
      YES: 'Call Medi24',
      NO: 'Close',
      CONTENT: 'You can only report a referral when you have a valid and active appointment release.',
    },
    uploadPictureWarning: {
      TITLE: 'Reminder',
      YES: 'Yes',
      NO: 'No',
      CONTENT: 'Please consider that by using this service additional charges may incur according to the insurance terms and conditions. Would you still like to use this service?',
    },
    CLOSE: 'Close',
  },
  appointmentRelease: {
    APPOINTMENT_RELEASE: 'Appointment Release',
    FROM: 'From ',
    TO: 'to ',
    EXTEND: 'Extend period by %{days} days',
    EXTENSION_VALIDATED: 'Your appointment release has been extended.',
    EXTENSION_ADVICE: 'If you want to further extend this appointment release, please contact Medi24.',
    CONTACT: 'Contact Medi24',
    DETAIL: {
      SPECIALIST: 'Your specialist',
      SPECIALTY: 'Medical specialty',
      TIMEFRAME: 'Timeframe',
      SITUATION: 'Situation',
    },
  },
  callback: {
    UPLOAD_PICTURES: 'Your picture upload',
    CALLBACK_REQUEST: 'Callback request',
    CALLBACK_DESCRIPTION: 'In order for us to assess your situation, please describe your request as precisely as possible.',
    DESCRIPTION: 'Description',
    ARRANGE: 'Request callback',
    EDIT_CHANGES: 'Save changes',
    EDIT_ADVICE: 'If you want to edit or delete this request, please contact Medi24.',
    CONTACT: 'Contact Medi24',
    UPLOAD: 'If you have photos, documents, certificates or other records, you can upload them here.',
    UPLOAD_BUTTON: 'Upload file(s)',
    WHEN_HOW: 'When and how can we reach you?',
    DATE_INPUT: 'Date',
    PHONE_INPUT: 'Number for callback',
    TIME_INPUT: 'Time',
    NO_TIMEFRAMES_AVAILABLE: 'Desired time frame is not available, please choose another date.',
    CHARACTERS_LEFT: 'characters left',
    CALLBACK_REGISTERED: 'We have received your request.',
    CALLBACK_DELETED: 'Your callback request has successfully been deleted.',
    CALENDAR: 'Add to calendar',
    REQUEST: 'Your Request',
    FILES: 'Enclosed files',
    NUMBER: 'Callback number',
    calendarEvent: {
      EVENT_TITLE: 'MyMedi24 callback',
      FILE_NAME: 'callbackMyMedi24',
      CREATION_ERROR: 'File creation failed',
      ICS_DESCRIPTION: 'As requested, on %{weekday}, the %{date} a %{role} from Medi24 will call you between %{startTime} and %{endTime}',
      ICS_EDITION: 'Do not forget to delete the previous event. It is no longer valid.',
      DOCTOR: 'doctor',
      NURSE: 'medical professional',
      PARTNER: 'To manage your callback request on MyMedi24, please log in via the app of your insurer. %{partnerUrl}',
    },
    file: {
      NOT_AVAILABLE: 'We cannot display this file',
      open: 'Open Document',
      modalTitle: 'Uploaded picture',
    },
    CHANGES_DISCARDED: 'Changes discarded',
    CALLBACK_MODIFIED: 'Changes applied',
    DISCARD_CHANGES: 'Discard changes',
    DELETE_WARNING: 'Are you sure you want to delete this request? You will not be able to retrieve it later',
    DELETE_MODAL: 'Delete',
    SAVE_CHANGES: 'Save changes',
    DETAIL: {
      DESCRIPTION_HTML: 'We will call you on <b>%{date}</b> at <b>%{number}</b> between <b>%{hours}</b>.',
    },
    ACTION_CANCELED: 'Action cancelled',
    REQUIRED: '* REQUIRED FIELD',
    type: {
      label: 'CALLBACK TYPE',
      PHONE: 'Phone call',
      VIDEO: 'Video call',
      BOTH: 'Doesn\'t matter',
    },
  },
  consultation: {
    TRIAGE: 'Recommendation of Medi24',
    triageRecommendation: {
      RECOMMENDATION_3: 'It was recommended to you to call an ambulance.',
      RECOMMENDATION_1: 'It was recommended to you to visit the nearest emergency admission on your own.',
      RECOMMENDATION_4: 'It was recommended to you to visit a Permanence.',
      RECOMMENDATION_5: 'It was recommended to you to visit a medical practice/Permanence.',
      RECOMMENDATION_2: 'It was recommended to you to get in contact with your family doctor or an emergency medical physician.',
      RECOMMENDATION_6: 'It was recommended to you to get in contact with your family doctor or an emergency medical physician within the next 24 hours.',
      RECOMMENDATION_7: 'It was recommended to you to make an appointment with your family doctor within the next 2 - 7 days.',
      RECOMMENDATION_8: 'It was recommended to you to visit a pharmacy.',
      RECOMMENDATION_9: 'It was recommended to you to treat your symptoms at home according to our recommendations. In case your situation worsens Medi24 is available to you 24/7.',
      RECOMMENDATION_283030000: 'A teleconsultation was recommended to you.',
    },
    triageTitle: {
      Test: 'This is an english test',
    },
    SICK_LEAVE_NOTE: 'Sick leave note',
    PRESCRIPTION: 'Prescription',
    description: {
      ON: 'On',
      AT: 'At',
    },
    symptomChecker: {
      TITLE: 'Symptom Checker',
      LINK: 'See assessment',
    },
    callback: {
      FILES: 'Enclosed files',
      REQUEST: 'Your request',
      UPLOAD_PICTURE: 'Picture upload',
      CALLBACK_REQUEST: 'Callback request',
    },
    DOCUMENTS: 'Documents',
    TILE: {
      BASED_ON: 'Based on',
    },
    PREVIOUS: 'Previous',
    NEXT: 'Next',
  },
  fromTo: { remaining: 'Days remaining: %{diff} ' },
  tile: {
    history: {
      REGISTRATION: 'Registration',
      CONSULTATION: 'Consultation',
      CONSULTATION_TITLE: 'Medi24 consultation',
      APPOINTMENT_RELEASE: 'Appt. release',
      CALLBACK_REQUEST: 'Callback',
      CALLBACK_REQUEST_TITLE: 'Medi24 will call you',
      ANNOUNCE_APPOITMENT: 'Announcement',
      PAST_APPOINTMENT: 'Announce emergency',
      LATE_REGISTRATION: 'Announce emergency',
      ALL: 'All',
      daysAdded: '%{days} days added',
      extend: 'Click here to extend',
      DETAILS: 'Details',
      CALLBACK_NUMBER: 'Number',
      TYPE: 'Type',
      referral: 'Orientation',
      urgent: 'Emergency',
      extension: 'Extended',
      phoneCallback: 'Phone call',
      videoCallback: 'Video call',
      event: 'Event',
      events: 'Events',
    },
  },
  history: {
    mainTitle: 'My Record',
    title: {
      active: 'Active events',
      event: '%{count} Event',
      events: '%{count} Events',
      pastEvents: 'Past events',
    },
    date: 'Date',
    SEE_MORE: 'See more',
    NO_EVENTS: 'No events',
  },
  validator: {
    FILE: 'Please upload at least one file',
    FILE_TOO_BIG: 'File too big, max size is: 50 MB',
    FILE_NOT_ACCEPTED: 'This file type is not accepted',
    REQUIRED: 'This field is mandatory',
    VALIDATE_ZIPCODE: 'Please enter a valid postcode',
    VALIDATE_PHONE_NO_0: 'Please enter a correct phone number',
    VALIDATE_PHONE: 'This phone number is not valid',
    VALIDATE_EMAIL: 'This email address is not valid',
    DATE_TOO_FAR: 'Please select a date in the current year',
    DATE: 'Select a date in the future',
  },
  doctorSpecialities: {
    R010000: 'General internal medicine',
    R010200: 'Surgery',
    R010300: 'Dermatology and venereology',
    R010400: 'Gynecology and obstetrics',
    R010600: 'Endocrinology and diabetology',
    R010700: 'Pneumology',
    R010800: 'Neurosurgery',
    R010900: 'Neurology',
    R011000: 'Psychiatry and psychotherapy',
    R011300: 'Ophthalmology',
    R011400: 'Orthopedic surgery / Apparent musculoskeletal trauma',
    R011500: 'Otorhinolaryngology',
    R011600: 'Pediatrics',
    R011700: 'Radiology',
    R011800: 'Tropical diseases and travel medicine',
    R011900: 'Urology',
    R012000: 'Rheumatology',
    R012200: 'Angiology',
    R013500: 'Cardiology',
    R013600: 'Haematology',
    R014500: 'Gastroenterology',
    R015200: 'Physical medicine and rehabilitation',
    R015400: 'Allergology and clinical immunology',
    R015700: 'Infectiology',
    R015900: 'Maxillofacial surgery',
    R016300: 'Medical oncology',
    R016400: 'Nephrology',
    R030000: 'Chiropractic',
    R100000: 'Hospitals',
    R101400: 'Rehabilitation clinic',
    R103100: 'Psychiatric clinics',
    R110000: 'Nursing home',
    Z999980: 'Doctor',
  },
  registration: {
    DESCRIPTION: 'Description',
    REGISTRATION_DETAILS: 'Registration',
    REGISTRATION: 'Registration',
    REGISTRATION_DESCRIPTION: 'To help us understand your situation, please fill in the following fields',
    URGENCY: 'Urgency',
    urgencies: {
      URGENCY_URGENT: 'Urgent',
      URGENCY_NORMAL: 'Normal',
    },
    placeholders: {
      DESCRIPTION: 'I visit my family doctor; I go to Dr. Winter; I have fever; etc.',
      DESCRIPTION_APPOINTMENT: 'I had an emergency treatment at the Inselspital; I needed to call an ambulance; I had symptoms of poisoning etc.\n'
          + '(Announcements of treatments not connected to an emergency will not be considered.)',
      DESCRIPTION_REFERRAL: '"I was referred by my primary care physician to a dermatologist." or "My treating doctor refers me to an allergist for clarification of my allergic reactions." etc.',
    },
    ARRANGE: 'Create Registration',
    REFERRAL: 'Report referral',
    CREATION: 'Registration created',
    CANCEL: 'Cancel',
    SELECT: 'Select...',
    DATE_TOP_MESSAGE: 'When did the emergency happened?',
    DATE_TOP_MESSAGE_REFERRAL: 'When is the first appointment with the specialist doctor?',
    DATE_BOTTOM_MESSAGE: 'The retroactive announcement of an emergency treatment needs to be done within %{days} days. If you have questions about  your insurance terms, contact the MyMedi24 Support: %{number}.',
    DOCTOR_TOP_MESSAGE: 'Which specialty does the treating doctor belong to?',
    DOCTOR_TOP_MESSAGE_REFERRAL: 'Which specialty does the treating doctor belong to?',
    DOCTOR_BOTTOM_MESSAGE: 'Please indicate the medical specialty of the treating doctor or select "Hospital" if you get treated in a hospital.',
    DOCTOR_BOTTOM_MESSAGE_REFERRAL: 'Please indicate the medical specialty of the treating doctor or select "Hospital" if you get treated in a hospital.',
    INFOS_DOCTOR: 'Name and location of the treating doctor',
  },
  announceAppointment: {
    ANNOUNCE: 'Announce emergency ',
    urgencies: {
      URGENCY_URGENT: 'Emergency treatment',
      URGENCY_NORMAL: 'Treatment',
    },
    doctorSpeciality: 'Doctor speciality',
    FROM: 'FROM:',
    TO: 'TO:',
    EXTEND: 'Extend period by %{days} days',
    EXTENSION_VALIDATED: 'Your appointment release has been extended.',
    EXTENSION_ADVICE: 'If you want to further extend this appointment release, please contact Medi24.',
    CONTACT: 'Contact Medi24',
  },
  profile: {
    TITLE: 'Profile',
    SUBTITLE: 'Your personal data',
    WARNING: 'We received the following data from your insurer to create your record. If this data is outdated please contact your insurer to update it.',
    NAME: 'Name',
    DATE_OF_BIRTH: 'Date of birth',
    ADDRESS: 'Address',
    LANGUAGE: 'Language',
    CHANGE_LANGUAGE: 'Change Language',
    LANGUAGE_SUBTITLE: 'Note that the change of language will affect further use of the service and contact.',
    languages: {
      ENGLISH: 'English',
      FRENCH: 'French',
      ITALIAN: 'Italian',
      DEUTSCH: 'German',
    },
    languagesModal: {
      title: 'Change Language',
      subtitle: 'Please select your preferred language.',
      warning: 'Note that the change of language will affect further use of the service and contact',
      back: 'Back',
      save: 'Save changes',
    },
    ADDITIONNAL_TITLE: 'Additional contact information',
    ADDITIONNAL: 'This additional data helps Medi24 provide the best service level possible. It will not be shared with any other party.',
    EMAIL: 'Email address',
    MOBILE: 'Mobile phone number',
    CHANGES_SAVED: 'Information saved',
  },
  feedback: {
    SUB_TITLE: 'You have been successfully logged out and can now close the browser window. \n'
      + 'Before you leave, we would appreciate feedback on your user experience so that we can further improve our service and tailor it to your needs.\n'
      + 'To use MyMedi24 services again, please log back in using your %{partner} user account.\n'
      + 'Thank you and goodbye!',
    DESCRIPTION: 'Feedback (optional)',
    CONSENT: 'I agree to be contacted by the customer support for further details.',
    SEND: 'Send my feedback',
    SUCCESS: 'Feedback sent',
    RATING_MANDATORY: 'If you would like to give us a rating, please do so by clicking the desired amount of stars (1=bad, 5=very good).',
  },
  eventDetails: {
    callback: 'Callback from Medi24',
  },
};

export default en;
