import React from 'react';
import { withRouter } from 'react-router-dom';

import { pageNameByPathname } from '../const';
import { fireViewEndCustomEvent, sendDataViewStart } from '../services/analyticsService';
import { currentLanguage } from '../services/languageService';

type Props = {
  children: any,
  history: {
    listen: Function,
  },
  location: {
    pathname: string,
    search: string,
  }
};

class Track extends React.Component<Props> {
  componentWillMount() {
    sendDataViewStart({
      pageName: pageNameByPathname(this.props.location.pathname),
      URL: window.location.href.split('?')[0],
      fullURL: window.location.href,
      language: currentLanguage().code,
    });
  }

  componentDidMount() {
    fireViewEndCustomEvent();
  }


  render() {
    return this.props.children;
  }
}

export default withRouter(Track);
