import moment, { Moment } from 'moment';
import configurationApi from '../network/api/configurationApi';
import partnerApi from '../network/api/partnerApi';
import { digitalDataStorageKey } from '../const';
import { SessionServiceHolder } from './session/SessionServiceHolder';
import { notifyError } from '../network/notification';

declare global {
  interface Window {
    digitalData: any;
  }
}

export type EventInfo = {
  category?: string,
  action?: string,
  label?: string,
  timestamp?: string,
};

export type PageInfo = {
  pageName: string,
  URL: string,
  fullURL: string,
  language: string,
};

export type ProcessInfo = {
  category?: string,
  processName?: string,
  startTime?: string,
  completeTime?: string,
  duration?: number,
  status?: string,
};

export type UserInfo = {
  profileId?: string,
  login?: {
    isLoggedIn?: boolean,
  },
  loginDuration?: number,
  city?: string,
  zip?: string,
  age?: number,
  gender?: string,
};

export type ServiceInfo = {
  selectedPartner?: string,
  type?: string,
  categories?: string,
};

function initializeDigitalData() {
  window.digitalData = {
    event: {
      category: '',
      action: '',
      label: '',
      timestamp: '',
    },
    page: {
      pageInfo: {
        pageName: '',
        URL: '',
        fullURL: '',
        language: '',
      },
    },
    process: {
      category: '',
      processName: '',
      startTime: '',
      completeTime: '',
      duration: 0,
      status: '',
    },
    user: {
      profileId: '',
      login: {
        isLoggedIn: false,
      },
      loginDuration: 0,
      city: '',
      zip: '',
      age: 0,
      gender: '',
    },
    service: {
      selectedPartner: '',
      categories: '||||',
    },
  };
  updateLocalStorage();
}

function updatePageInfo(pageInfo: PageInfo) {
  window.digitalData.page.pageInfo.pageName = pageInfo.pageName;
  window.digitalData.page.pageInfo.language = pageInfo.language;
  window.digitalData.page.pageInfo.URL = pageInfo.URL;
  window.digitalData.page.pageInfo.fullURL = pageInfo.fullURL;
  updateLocalStorage();
}

export function getPageData() {
  if (!window.digitalData) {
    const storedDigitalData = localStorage.getItem(digitalDataStorageKey);
    if (storedDigitalData) {
      window.digitalData = JSON.parse(storedDigitalData);
    } else {
      initializeDigitalData();
    }
  }
}

export function initAdobeScript() {
  return new Promise((resolve) => {
    configurationApi.fetchAdobe()
      .then((res) => res.text())
      .then((adobeScriptId) => {
        resolve(adobeScriptId);
      })
      .catch((e) => resolve(''));
  });
}

let loginTime: Moment = null;

export function getAgeFromBirthdate(birthdate) {
  const a = moment();
  const b = moment(birthdate);
  return a.diff(b, 'years');
}

export function analyticsLogin() {
  initializeDigitalData();
  loginTime = moment();
  updateUserInfo({
    login: {
      isLoggedIn: true,
    },
    profileId: SessionServiceHolder.getSessionService().currentSession()?.user.patientContractNumber,
  });
  updateLocalStorage();

  partnerApi.fetchPartner()
    .then((response) => response.json())
    .then((partnerParam) => {
      updateServiceInfo({
        selectedPartner: partnerParam.name,
      });
      updateLocalStorage();
    })
    .catch(notifyError);
}

export function analyticsLogout() {
  if (loginTime) {
    const durationInSeconds = moment().diff(loginTime, 'seconds');
    updateUserInfo({
      login: {
        isLoggedIn: false,
      },
      loginDuration: durationInSeconds,
    });
  }
  loginTime = null;
}

export function updateEventInfo(eventInfo: EventInfo, clearOthers: boolean = false) {
  if (eventInfo.category || clearOthers) window.digitalData.event.category = eventInfo.category;
  if (eventInfo.action || clearOthers) window.digitalData.event.action = eventInfo.action;
  if (eventInfo.label || clearOthers) window.digitalData.event.label = eventInfo.label;
  if (eventInfo.timestamp || clearOthers) window.digitalData.event.timestamp = eventInfo.timestamp;
  updateLocalStorage();
}

export function updateProcessData(processInfo: ProcessInfo, clearOthers: boolean = false) {
  if (processInfo.category || clearOthers) window.digitalData.process.category = processInfo.category;
  if (processInfo.processName || clearOthers) window.digitalData.process.processName = processInfo.processName;
  if (processInfo.startTime || clearOthers) window.digitalData.process.startTime = processInfo.startTime;
  if (processInfo.completeTime || clearOthers) window.digitalData.process.completeTime = processInfo.completeTime;
  if (processInfo.duration || clearOthers) window.digitalData.process.duration = processInfo.duration;
  if (processInfo.status || clearOthers) window.digitalData.process.status = processInfo.status;
  if (clearOthers) {
    window.digitalData.process.duration = undefined;
  }
  if (processInfo.completeTime && window.digitalData.process.startTime) {
    window.digitalData.process.duration = moment(window.digitalData.process.completeTime).diff(moment(window.digitalData.process.startTime), 'seconds');
  }
  updateLocalStorage();
}

export function updateUserInfo(userInfo: UserInfo) {
  if (userInfo.profileId) window.digitalData.user.profileId = userInfo.profileId;
  if (userInfo.login !== undefined) {
    if (userInfo.login.isLoggedIn !== undefined) window.digitalData.user.login.isLoggedIn = userInfo.login.isLoggedIn;
  }
  if (userInfo.loginDuration) window.digitalData.user.loginDuration = userInfo.loginDuration;
  if (userInfo.city) window.digitalData.user.city = userInfo.city;
  if (userInfo.zip) window.digitalData.user.zip = userInfo.zip;
  if (userInfo.age) window.digitalData.user.age = userInfo.age;
  if (userInfo.gender) window.digitalData.user.gender = userInfo.gender;
  updateLocalStorage();
}

export function updateServiceInfo(serviceInfo: ServiceInfo) {
  if (serviceInfo.selectedPartner) window.digitalData.service.selectedPartner = serviceInfo.selectedPartner;
  if (serviceInfo.categories) window.digitalData.service.categories = serviceInfo.categories || '||||';
  updateLocalStorage();
}

function updateLocalStorage() {
  localStorage.setItem(digitalDataStorageKey, JSON.stringify(window.digitalData));
}

export function sendDataViewStart(pageInfo: PageInfo) {
  updatePageInfo(pageInfo);
  // @ts-ignore
  fireViewStartCustomEvent(window.digitalData);
}

export function fireViewEndCustomEvent() {
  const event = new CustomEvent('event-view-end');
  const obj = document.querySelector('#app');
  obj.dispatchEvent(event);
}


function fireViewStartCustomEvent(data) {
  const event = new CustomEvent('event-view-start', data);
  document.dispatchEvent(event);
}

export function fireActionTriggerCustomEvent(id, data) {
  const event = new CustomEvent('event-action-trigger', data);
  if (document.querySelector(id) !== null) {
    document.querySelector(id).dispatchEvent(event);
  } else {
    document.dispatchEvent(event);
  }
}
