import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FieldRenderProps } from 'react-final-form';
import { TextField } from '@material-ui/core';

const TextInput = ({
  input: {
    value, onChange, onBlur,
  },
  dateFormat,
  meta,
  formControlProps,
  ...rest
}: FieldRenderProps<string>) => (
  <FormControl
    {...formControlProps}
    style={{
      minWidth: '100%',
    }}
  >
    <TextField
      {...rest}
      onBlur={onBlur}
      variant="outlined"
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
        if (rest.onChange) {
          rest.onChange(newValue);
        }
      }}
      error={meta.error && meta.touched}
    />
    {meta.error && meta.touched && (
    <p className="error-text">{meta.error}</p>
    )}
  </FormControl>
);

export default TextInput;
