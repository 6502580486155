const fr = {
  footer: {
    COPYRIGHT: '© Medi24 AG 2023. Tous droits réservés.',
    link: {
      DATA_PROTECTION: 'Protection des données',
      TERMS_AND_CONDITION: 'Conditions d\'utilisation',
      COMPANY_LEGAL_INFORMATION: 'Mentions légales',
      HANDLE_COOKIES: 'Gestion des cookies',
    },
  },
  actions: {
    YES: 'Oui',
    NO: 'Non',
    OK: 'Ok',
    BACK: 'Retour',
    CONFIRM_LOGOUT: 'Se déconnecter',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher...',
    EDIT: 'Modifier',
  },
  authenticationErrors: {
    AUTHENTICATION: 'Votre authentification a échoué',
    DEFAULT_ERROR: 'Il y a eu une erreur',
    WRONG_PARTNER: 'Une erreur s\'est produite lors de votre connexion à MyMedi24. Veuillez réessayer plus tard.',
    SAML_SETTINGS: 'Une erreur s\'est produite lors de votre connexion à MyMedi24. Veuillez réessayer plus tard.',
    SAML_RESPONSE: 'Une erreur s\'est produite lors de votre connexion à MyMedi24. Veuillez réessayer plus tard.',
    PRM_NOT_FOUND: 'Votre dossier de patient n\'a pas été trouvé dans la base de données. \n'
      + 'Le problème est probablement dû à un changement de données de votre assureur et sera résolu à la prochaine synchronisation. \n \n'
      + 'Veuillez réessayer d\'ici une semaine ou contacter votre assureur.',
    PRM_NOT_FOUND_HELSANA: {
      PART_1: 'Vous utilisez myMedi24 pour la première fois ? Veuillez procéder de la manière suivante : \n \n'
        + 'Avec les modèles Basis ou médecin de famille : \n'
        + 'Inscrivez-vous d’abord ',
      LINK: 'ici',
      PART_2: '. Après quelques minutes, vous aurez accès à myMedi24. \n \n'
        + 'Avec les modèles Premed-24, Telmed ou Flexmed : \n'
        + 'Réessayez dans une semaine. Vos données sont toujours en cours de synchronisation.'
    },
    BLOCKED: 'Afin de protéger vos données, ce lien est bloqué pour toute utilisation directe non autorisée. Veuillez vous connecter à l\'application de votre assureur pour utiliser les services en ligne de Medi24.',
    PATIENT_INACTIVE: 'Vous ne pouvez plus utiliser les services de MyMedi24 car votre contrat avec %{partner} a été résilié. Si vous pensez que vous voyez ce message par erreur, veuillez nous contacter à %{email}.',
    UNABLE_TO_IDENTIFY_PATIENT: 'Nous n\'avons pas pu vous identifier avec les informations fournies par %{partner}. Pour plus d\'information, veuillez nous contacter à %{email}.',
  },
  welcomePage: {
    WELCOME: 'Bienvenue',
    CONNECTED: 'Vous êtes maintenant connecté aux services de Medi24.',
    DISCLAIMER: 'Aucune des données collectées ici ne sera partagée avec votre assureur.',
    CONTINUE: 'Continuer',
    DO_NOT_SHOW_AGAIN: 'Ne plus me montrer ceci.',
  },
  dataPrivacy: {
    TITLE_BEFORE: 'Avant de commencer',
    TITLE_ALREADY_VALIDATE: 'Protection des données',
    SUB_TITLE_FIRST_TIME: 'Comme il s\'agit de votre première connexion, nous vous rappelons que la déclaration de confidentialité suivante s\'applique désormais à toutes les pages que vous consulterez sur MyMedi24. Veuillez en prendre connaissance en cliquant sur continuer.',
    SUB_TITLE: 'Nous vous rappelons que la déclaration de confidentialité suivante s\'applique désormais à toutes les pages que vous consulterez sur MyMedi24.',
    CONTINUE: 'Continuer',
  },
  termsAndConditions: {
    TITLE: 'Conditions d\'utilisation',
    SUB_TITLE: 'Nous vous rappelons que les conditions d\'utilisation suivantes s\'appliquent désormais à toutes les pages que vous consulterez sur MyMedi24.',
  },
  companyLegalInformation: {
    TITLE: 'Mentions légales',
  },
  dashboardPage: {
    WELCOME: 'Bienvenue, \n',
    NEXT_APPOINTMENT: 'Votre prochain rendez‑vous',
    ALL_APPOINTMENT: 'Tous les évènements',
    TREATMENT_SINCE: 'Depuis',
    VALID_TILL: 'Jusqu\'au',
    CALLBACK: 'Rappel',
    CALL_DATE: 'Nous vous appelerons le',
    BETWEEN: 'Entre',
    NO_NEXT_APPOINTMENT: 'Vous n\'avez aucun rendez-vous à venir pour le moment',
    FUNCTIONALITIES: 'Mes fonctionnalités',
  },
  menu: {
    START: 'Accueil',
    CONTACT: 'Contact',
    PROFILE: 'Profil',
    HISTORY: 'Mon dossier',
    LOGOUT: 'Déconnexion',
  },
  services: {
    UPLOAD_PICTURE: 'Ajouter une photo',
    CALLBACK_REQUEST: 'Demande de rappel',
    SYMPTOM_CHECKER: 'Symptom Checker',
    CONSULTATION: 'Ancienne Consultation',
    REGISTRATION: 'Annonce RDV',
    ANNOUNCEMENT: 'Signaler une urgence',
    REFERRAL: 'Annnoncer une orientation',
  },
  contact: {
    HINT_SUPPORT: 'Si vous avez besoin d\'aide pour utiliser notre service en ligne MyMedi24, veuillez contacter le support de l\'application au : ',
    HINT_TITLE: 'Appeler',
    HINT: 'Pour nous contacter, veuillez composer le numéro suivant :',
    EMERGENCY_HINT: 'En cas d\'urgence, veuillez composer le numéro suivant : %{tel}',
    TITLE: 'Contact',
    CONTACT_TITLE: 'Comment souhaitez-vous nous contacter ?',
    HELP_TITLE: 'Avez-vous besoin d\'aide avec MyMedi24',
    hotline: {
      TITLE: 'Appeler Medi24',
      BODY: 'Pour une consultation télémédicale, appelez Medi24 au numéro suivant :',
      TEL: ' %{tel}',
    },
    SUPPORT_TITLE: 'Support MyMedi24',
    emergency: {
      TITLE: 'Appel d\'urgence 144',
      BODY: 'S\'il s\'agit d\'une urgence, veuillez composer le 144.',
    },
    CLOSE: 'Fermer',
  },
  signup: {
    TITLE: 'Inscription à MyMedi24',
    INSURANCE_PRODUCT: 'Produit d\'assurance',
    INSURANCE_PRODUCT_INPUT: 'Avez vous le modèle Basis ou médecin de famille ?',
    PERSONAL_INFORMATION: 'Informations personnelles',
    FIRST_NAME_INPUT: 'Prénom',
    LAST_NAME_INPUT: 'Nom de famille',
    DATE_OF_BIRTH_INPUT: 'Date de naissance',
    GENDER: 'Sexe',
    GENDER_CHOICES: {
      M: 'Homme',
      F: 'Femme'
    },
    ADDRESS: 'Addresse',
    STREET_INPUT: 'Rue',
    ZIP_CODE_INPUT: 'Code postal',
    CITY_INPUT: 'Ville',
    LANGUAGES: 'Langue',
    FIRST_LANGUAGE_INPUT: 'Première langue',
    SECOND_LANGUAGE_INPUT: 'Seconde langue',
    LANGUAGE_CHOICES: {
      DEUTSCH: 'Allemand',
      FRENCH: 'Français',
      ITALIAN: 'Italien',
      ENGLISH: 'Anglais'
    },
    CONTACT_INFORMATION: 'Informations de contact',
    PHONE_NUMBER_INPUT: 'Numéro de téléphone',
    MOBILE_PHONE_NUMBER_INPUT: 'Numéro de téléphone mobile',
    SIGN_UP: 'S\'inscrire',
    successModal: {
      title: 'Inscription en ligne',
      description: 'Votre inscription a été effectuée avec succès.\nVous pouvez maintenant commencer à utiliser les services MyMedi24',
      action: 'Continuer'
    },
    errorModal: {
      title: 'Inscription en ligne',
      description: 'Malheureusement, votre inscription à MyMedi24 n\'a pas abouti.\n'
        + 'Veuillez réessayer plus tard.\n'
        + '\n'
        + 'Si le problème persiste, veuillez contacter MyMedi24 via le numéro de téléphone qui vous a été communiqué par votre caisse d\'assurance maladie',
      action: 'Close'
    }
  },
  sensely: {
    WARNING_TEXT: 'Veuillez tenir compte des informations suivantes lorsque vous utilisez le symptom checker sur MyMedi24 :\n'
      + '\n'
      + 'L\'exécution du symptom checker sur MyMedi24 ne vous libère pas de vos obligations provenant de votre contrat d\'assurance maladie, comme l\'obligation d\'annoncer les visites médicales ou d\'effectuer une consultation télémédicale.\n'
      + '\n'
      + 'Par conséquent, veuillez compléter toutes les étapes du processus et cliquer sur "Terminé" pour choisir parmi les étapes suivantes recommandées par Medi24 en fonction de l\'urgence de votre demande telle que déterminée par le système.\n'
      + '\n'
      + 'Si vous n\'êtes pas d\'accord avec le niveau d\'urgence fourni par le vérificateur de symptômes, n\'hésitez pas à appeler Medi24 pour obtenir une assistance supplémentaire.',
    LOADER: 'Chargement du Symptom Checker...',
    QUIT_PROCESS: 'Quitter le processus',
    QUIT_PROCESS_2: 'Souhaitez-vous tout de même enregistrer l\'interview ?',
    CALLBACK: 'Nous vous contacterons au sujet de votre problème médical.',
    UPLOAD_PICTURE: 'Téléchargez une photo / un document et nous vous contacterons.',
    REGISTRATION: 'Nous informer d\'une consultation.',
    ANNOUNCEMENT: 'Nous informer d\'une consultation.',
    REFERRAL: 'Nous informer d\'une orientation.',
    TITLE: 'Que voulez-vous faire ?',
    footer: {
      TEXT1: 'Si vous ne voulez choisir aucune des options et que vous voulez quand même voir votre médecin, n\'oubliez pas d\'appeler Medi24 pour annoncer le rendez-vous.',
      TEXT2: 'Après avoir consulté votre médecin, appelez Medi24 pour annoncer le rendez-vous d\'urgence.',
      TEXT3: 'Si vous ne voulez choisir aucune des options et que vous allez quand même voir votre médecin, n\'oubliez pas d\'annoncer le rendez-vous à Medi24 par téléphone ou dans MyMedi24.',
      TEXT4: 'Si vous n\'êtes pas sûr que cette recommandation urgente s\'applique à votre situation, veuillez appeler Medi24 pour vérifier ce résultat avec un spécialiste.',
      TEXT5: 'Si vous n\'êtes pas sûr que cette recommandation urgente s\'applique à votre situation, veuillez appeler Medi24 pour vérifier ce résultat avec un spécialiste. \n'
        + 'Après avoir consulté votre médecin, appelez Medi24 pour annoncer le rendez-vous d\'urgence.',
      TEXT6: 'Si vous n\'êtes pas sûr que cette recommandation urgente s\'applique à votre situation, veuillez appeler Medi24 pour vérifier ce résultat avec un spécialiste. \n'
        + 'Si vous ne voulez choisir aucune des options et que vous allez quand même voir votre médecin, n\'oubliez pas d\'annoncer le rendez-vous à Medi24 par téléphone ou dans MyMedi24',
    },
    RESULTS_SAVED: 'Résultats sauvegardés',
    SENSELY_TITLE: 'Information sur le Symptom Checker',
  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte.',
    ERROR: 'Une erreur s\'est produite lors de l\'enregistrement des modifications.',
    YES: 'Oui',
    NO: 'Non',
    SENSELY_INIT_ERROR: 'Une erreur s\'est produite lors de la connexion au Symptom Checker',
  },
  wsError: {
    AUTHENTIFICATION_EXPIRED: 'Session expirée',
    INTERNAL_ERROR: 'Erreur de connexion',
    NETWORK_ERROR: 'Erreur réseau, votre connexion internet semble indisponible',
    TIMEFRAME_NOT_AVAILABLE: 'La période demandée n\'est plus disponible. Veuillez sélectionner une autre période ou une autre date.',
    MESSAGE: '%{field0}',
    NO_PARTNER_FOUND: 'Votre partenaire d\'assurance n\'a pas été trouvé.',
    NOT_ALLOWED_BY_PARTNER: 'Votre partenaire ne vous autorise pas à prolonger une autorisation de rendez-vous.',
    ALREADY_EXTENDED_MY_MEDI_24: 'Il s\'agit déjà d\'une extension de rendez-vous.',
    ALREADY_CREATED: 'Vous avez déjà fait une demande de prolongation pour ce rendez-vous spécifique.',
    ERROR_HTML_SENSELY: 'Il y a eu un problème lors de la sauvegarde du résultat',
    ERROR_SENSELY_URGENCY_NOT_FOUND: 'Urgence inconnue',
    NO_SENSELY_RESULTS: 'Impossible de transmettre le résultat',
    TEXT_NOT_FOUND: 'Le document que vous avez demandé n\'est pas disponible pour le moment. Veuillez réessayer plus tard.',
    NOT_FOUND: 'Informations non trouvées',
    UNABLE_TO_CREATE_FEEDBACK: 'Vos retours n\'ont pas pu être sauvegardés',
    ALREADY_EXPIRED_SESSION_TOKEN: 'Votre session est expirée.',
    AMOUNT_EXCEEDED: 'Vous avez déjà effectué votre quota annuel',
  },
  logout: {
    DISCONNECTED: 'Déconnecté avec succès',
  },
  date: {
    LONG_DATE_NO_YEAR: 'dddd DD. MMMM',
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'ddd, DD.MM.YYYY',
    ONLY_DATE_FORMAT: 'DD.MM.YYYY',
    ONLY_HOURS_FORMAT: 'HH[h]mm',
    ICS_DATE_FORMAT: 'YYYY-M-D-H-m',
    HISTORY_TILE_DATE_FORMAT: 'D.MM.YY',
    period: {
      ON: 'Le',
      BETWEEN: 'Entre',
      TIMEFRAME: 'Période',
      VALID_FROM: 'Valable du',
      TO: 'au',
    },
  },
  modal: {
    LOGOUT_MODAL: 'Déconnexion',
    LOGOUT_MESSAGE: 'Voulez vous vraiment vous déconnecter ?',
    INACTIVITY_MESSAGE: 'Vous êtes inactif, vous serez déconnecté dans : ',
    ICS: 'Événement',
    ICS_CONTENT: 'Cliquez sur "Ajouter au calendrier" pour créer un rendez-vous dans votre calendrier personnel',
    ICS_YES: 'Ajouter au calendrier',
    ICS_NO: 'Fermer',
    YES: 'Oui',
    NO: 'Non',
    senselyExit: {
      TITLE: 'Souhaitez-vous quitter le Symptom Checker ?',
      CONTENT: 'Si vous quittez le Symptom Checker maintenant, vous perdrez les données saisies. Voules-vous quitter ?',
      SAVE_ASSESSMENT: 'Avant de quitter, voulez-vous sauvegarder le bilan du Symptom Checker ?',
    },
    senselySaved: {
      TITLE: 'Vous quittez le Symptom Checker',
      CONTENT_SAVED: 'Vos informations ont bien été enregistrées. Appuyez sur OK pour quitter le Symptom Checker.',
    },
    announceReminder: {
      TITLE: 'Rappel',
      YES: 'Oui, il s\'agit d\'une urgence',
      NO: 'Fermer',
      CONTENT: 'Les annonces rétroactives de rendez-vous ne sont valables que pour les traitements d\'urgence dans les %{lateAppointmentDaysLimit} jours suivant leur exécution, conformément aux conditions d\'assurance.',
    },
    announceAmountExceeded: {
      TITLE: 'Nombre maximum atteint',
      YES: 'Appeler Medi24',
      NO: 'Fermer',
      CONTENT: 'Vous ne pouvez annoncer les traitements d\'urgence que rétroactivement via l\'application %{limit} fois par an. Pour toute autre annonce, veuillez contacter notre hotline.',
      CONTENT_REFERRAL: 'Les orientations vers un spécialiste ne peuvent être annoncées que %{limit} fois par an en ligne. Pour annoncer d\'autres orientations nous vous prions de nous appeler.',
    },
    referralNotAllowed: {
      TITLE: 'Orientation injustifiée vers un spécialiste',
      YES: 'Appeler Medi24',
      NO: 'Fermer',
      CONTENT: 'Vous pouvez annoncer une orientation vers un spécialiste seulement si vous avez un créneau horaire actif valable. Pour recevoir plus d\' informations n\'hésitez pas à nous appeler.',
    },
    uploadPictureWarning: {
      TITLE: 'Rappel',
      YES: 'Oui',
      NO: 'Non',
      CONTENT: 'Veuillez considérer que l\'utilisation de ce service peut entraîner des frais supplémentaires conformément aux conditions d\'assurance. Souhaitez-vous toujours utiliser ce service ?',
    },
    CLOSE: 'Fermer',
  },
  appointmentRelease: {
    APPOINTMENT_RELEASE: 'Autorisation de rendez-vous',
    FROM: 'Du ',
    TO: 'au ',
    EXTEND: 'Prolonger le délai de %{days} jours',
    EXTENSION_VALIDATED: 'Votre autorisation de rendez-vous a été prolongée.',
    EXTENSION_ADVICE: 'Si vous souhaitez prolonger cette autorisation de rendez-vous, veuillez contacter Medi24.',
    CONTACT: 'Contacter Medi24',
    DETAIL: {
      SPECIALIST: 'Votre specialiste',
      SPECIALTY: 'La spécialité médicale',
      TIMEFRAME: 'Période',
      SITUATION: 'Situation',
    },
  },
  callback: {
    UPLOAD_PICTURES: 'Votre ajout de photo',
    CALLBACK_REQUEST: 'Demande de rappel',
    CALLBACK_DESCRIPTION: 'Pour que nous puissions évaluer votre situation, veuillez décrire votre demande le plus précisément possible.',
    DESCRIPTION: 'Description',
    ARRANGE: 'Demander un rappel',
    EDIT_CHANGES: 'Sauvegarder les changements',
    EDIT_ADVICE: 'Si vous souhaitez modifier ou supprimer cette demande de rappel, veuillez contacter Medi24.',
    CONTACT: 'Contacter Medi24',
    UPLOAD: 'Si vous avez des photos, des documents, des certificats ou d\'autres enregistrements, vous pouvez les télécharger ici.',
    UPLOAD_BUTTON: 'Télécharger le(s) fichier(s)',
    WHEN_HOW: 'Quand et comment pouvons-nous vous joindre ?',
    DATE_INPUT: 'Date',
    PHONE_INPUT: 'Numéro de rappel',
    TIME_INPUT: 'Horaire',
    NO_TIMEFRAMES_AVAILABLE: 'Le délai souhaité n\'est pas disponible, veuillez choisir une autre date.',
    CHARACTERS_LEFT: 'caractères restants',
    CALLBACK_REGISTERED: 'Nous avons reçu votre demande',
    CALLBACK_DELETED: 'Votre demande de rappel a été supprimée avec succès.',
    CALENDAR: 'Ajouter au calendrier',
    REQUEST: 'Votre requête',
    FILES: 'Fichiers joints',
    NUMBER: 'Numéro de rappel',
    calendarEvent: {
      EVENT_TITLE: 'Rappel par myMedi24',
      FILE_NAME: 'rappelMyMedi24',
      CREATION_ERROR: 'La création du fichier a échoué',
      ICS_DESCRIPTION: 'Comme demandé, un %{role} de Medi24 vous appellera le %{weekday} %{date} entre %{startTime} et %{endTime}.',
      ICS_EDITION: 'N\'oubliez pas de supprimer l\'évènement précédent dans votre calendrier. Celui-ci n\'est plus valide.',
      DOCTOR: 'médecin',
      NURSE: 'professionnel médical',
      PARTNER: 'Pour gérer votre demande de rappel sur MyMedi24, veuillez vous connecter via l\'application de votre assureur. %{partnerUrl}',
    },
    file: {
      NOT_AVAILABLE: 'Nous ne pouvons pas afficher le fichier suivant',
      open: 'Afficher le fichier',
      modalTitle: 'Image téléchargée',
    },
    CHANGES_DISCARDED: 'Modifications annulées',
    CALLBACK_MODIFIED: 'Modifications appliquées',
    DISCARD_CHANGES: 'Annuler les modifications',
    DELETE_WARNING: 'Êtes-vous sûr de vouloir supprimer cette demande ? Vous ne pourrez pas la récupérer plus tard',
    DELETE_MODAL: 'Supprimer',
    SAVE_CHANGES: 'Enregistrer les modifications',
    DETAIL: {
      DESCRIPTION_HTML: 'Nous vous appellerons le <b>%{date}</b> au <b>%{number}</b> entre <b>%{hours}</b>.',
    },
    ACTION_CANCELED: 'Action annulée',
    REQUIRED: '* CHAMPS REQUIS',
    type: {
      label: 'Type de rappel',
      PHONE: 'Appel téléphonique',
      VIDEO: 'Appel vidéo',
      BOTH: 'Peu importe',
    },
  },
  consultation: {
    TRIAGE: 'Recommendation de Medi24',
    triageRecommendation: {
      RECOMMENDATION_3: 'Il vous a été recommandé d\'appeler une ambulance.',
      RECOMMENDATION_1: 'Il vous a été recommandé de vous rendre vous-même au service d\'urgences le plus proche.',
      RECOMMENDATION_4: 'Il vous a été recommandé de vous rendre vous-même à une permanence.',
      RECOMMENDATION_5: 'Il vous a été recommandé de vous rendre vous-même à un cabinet médical / permanence.',
      RECOMMENDATION_2: 'Il vous a été recommandé d\'entrer en contact avec votre médecin de famille ou un médecin urgentiste.',
      RECOMMENDATION_6: 'Il vous a été recommandé d\'entrer en contact avec votre médecin de famille ou un médecin urgentiste dans les prochaines 24 heures.',
      RECOMMENDATION_7: 'Il vous a été recommandé de prendre rendez-vous avec votre médecin de famille dans les 2 à 7 prochains jours.',
      RECOMMENDATION_8: 'Il vous a été recommandé de vous rendre en pharmacie.',
      RECOMMENDATION_9: 'Il vous a été recommandé de traiter vos symptômes à domicile selon nos recommandations. Si votre situation s\'aggrave, Medi24 est à votre disposition 24h/24 et 7j/7.',
      RECOMMENDATION_283030000: 'Une téléconsultation vous a été recommandée.',
    },
    triageTitle: {
      Test: 'This is a french test',
    },
    SICK_LEAVE_NOTE: 'Certificat d\'incapacité de travail',
    PRESCRIPTION: 'Prescription',
    description: {
      ON: 'Le',
      AT: 'À',
    },
    symptomChecker: {
      TITLE: 'Symptom Checker',
      LINK: 'Voir l\'évaluation',
    },
    callback: {
      FILES: 'Documents joints',
      REQUEST: 'Votre requête',
      UPLOAD_PICTURE: 'Ajout de photo',
      CALLBACK_REQUEST: 'Demande de rappel',
    },
    DOCUMENTS: 'Documents',
    TILE: {
      BASED_ON: 'Basé sur',
    },
    PREVIOUS: 'Précédent',
    NEXT: 'Suivant',
  },
  fromTo: { remaining: 'Jour(s) restant(s) : %{diff}' },
  tile: {
    history: {
      REGISTRATION: 'Annonce RDV',
      CONSULTATION: 'Consultation',
      CONSULTATION_TITLE: 'Consultation Medi24',
      APPOINTMENT_RELEASE: 'Autorisation RDV',
      CALLBACK_REQUEST: 'Rappel',
      CALLBACK_REQUEST_TITLE: 'Medi24 vous appellera',
      ANNOUNCE_APPOITMENT: 'Annonce',
      PAST_APPOINTMENT: 'Signaler une urgence',
      LATE_REGISTRATION: 'Signaler une urgence',
      ALL: 'Tout',
      daysAdded: '%{days} jours ajoutés',
      extend: 'Cliquez ici pour prolonger',
      DETAILS: 'Détails',
      CALLBACK_NUMBER: 'Numéro',
      TYPE: 'Type',
      referral: 'Orientation',
      urgent: 'Urgence',
      phoneCallback: 'Appel téléphonique',
      videoCallback: 'Appel video',
      event: 'Événement',
      events: 'Événements',
    },
  },
  history: {
    mainTitle: 'Mon dossier',
    title: {
      active: 'Événements actifs',
      event: '%{count} Évenement',
      events: '%{count} Évenements',
      pastEvents: 'Événements passés',
    },
    date: 'Date',
    SEE_MORE: 'Voir plus',
    NO_EVENTS: 'Aucun événement',
  },
  validator: {
    FILE: 'Veuillez ajouter au moins un fichier',
    FILE_TOO_BIG: 'Fichier trop volumineux, taille maximale : 50 MB',
    FILE_NOT_ACCEPTED: 'Ce type de fichier n\'est pas accepté',
    REQUIRED: 'Ce champ est obligatoire',
    VALIDATE_ZIPCODE: 'Veuillez entrer un code postal valide',
    VALIDATE_PHONE_NO_0: 'Veuillez entrer un numéro de téléphone valide',
    VALIDATE_PHONE: 'Ce numéro de téléphone n\'est pas valide',
    VALIDATE_EMAIL: 'Cet e-mail n\'est pas valide',
    DATE_TOO_FAR: 'Merci de sélectionner une date dans l\'année en cours.',
    DATE: 'Merci de sélectionner une date dans le futur.',
  },
  doctorSpecialities: {
    R010000: 'Médecine interne générale',
    R010200: 'Chirurgie',
    R010300: 'Dermatologie et vénéréologie',
    R010400: 'Gynécologie et obstétrique',
    R010600: 'Endocrinologie et diabétologie',
    R010700: 'Pneumologie',
    R010800: 'Neurochirurgie',
    R010900: 'Neurologie',
    R011000: 'Psychiatrie et psychothérapie',
    R011300: 'Ophthalmologie',
    R011400: 'Chir. orthopédique/traumatologie appar. locomoteur',
    R011500: 'Oto-rhino-laryngologie',
    R011600: 'Pédiatrie',
    R011700: 'Radiologie',
    R011800: 'Maladies tropicales et médecine des voyages',
    R011900: 'Urologie',
    R012000: 'Rhumatologie',
    R012200: 'Angiologie',
    R013500: 'Cardiologie',
    R013600: 'Hématologie',
    R014500: 'Gastroentérologie',
    R015200: 'Médecine physique et réadaptation',
    R015400: 'Allergologie et immunologie clinique',
    R015700: 'Infectiologie',
    R015900: 'Chirurgie maxillo-faciale',
    R016300: 'Oncologie médicale',
    R016400: 'Néphrologie',
    R030000: 'Chiropractie',
    R100000: 'Hôpital',
    R101400: 'Clinique de réadaptation',
    R103100: 'Cliniques psychiatriques',
    R110000: 'Maison de retraite',
    Z999980: 'Médecin',
  },
  registration: {
    DESCRIPTION: 'Description',
    REGISTRATION_DETAILS: 'Annonce RDV',
    REGISTRATION: 'Annoncer un RDV',
    REGISTRATION_DESCRIPTION: 'Pour nous aider à comprendre votre situation, veuillez remplir les champs suivants',
    URGENCY: 'Urgence',
    urgencies: {
      URGENCY_URGENT: 'Urgent',
      URGENCY_NORMAL: 'Normal',
    },
    placeholders: {
      DESCRIPTION: 'Je consulte mon médecin de famille ; je vais voir le Dr Winter ; j\'ai de la fièvre, etc.',
      DESCRIPTION_APPOINTMENT: 'J\'ai eu un traitement d\'urgence au CHUV; J\'ai dû appeler une ambulance ; J\'ai eu des symptômes d\'empoisonnement, etc.\n'
        + '(Les annonces de traitements non liés à une urgence ne seront pas prises en compte).',
      DESCRIPTION_REFERRAL: '"Mon médecin de famille m\'a envoyé chez un dermatologue", "mon médecin traitant m\'envoie chez un allergologue pour examiner mes réactions allergiques", etc.',
    },
    ARRANGE: 'Créer une annonce RDV',
    REFERRAL: 'Annoncer une orientation',
    CREATION: 'Annonce RDV créée',
    CANCEL: 'Annuler',
    SELECT: 'Choisir...',
    DATE_TOP_MESSAGE: "Quand l'urgence s'est-elle produite ?",
    DATE_TOP_MESSAGE_REFERRAL: 'Quand aura lieu le premier rendez-vous chez le spécialiste ?',
    DATE_BOTTOM_MESSAGE: 'L\'annonce rétroactive d\'un traitement d\'urgence doit être faite dans les %{days} jours. Si vous avez des questions concernant vos conditions d\'assurance, contactez le support MyMedi24 : %{number}.',
    DOCTOR_TOP_MESSAGE: 'Quelle est la spécialité du médecin traitant?',
    DOCTOR_TOP_MESSAGE_REFERRAL: 'Quelle est la spécialité du médecin traitant?',
    DOCTOR_BOTTOM_MESSAGE: 'Veuillez indiquer le domaine d\'expertise médicale du premier médecin qui vous a soigné ou choisissez "Hôpital" si vous avez été soigné dans un établissement hospitalier.',
    DOCTOR_BOTTOM_MESSAGE_REFERRAL: 'Nous vous prions d\'indiquer la spécialité du médecin traitant ou de choisir "hôpital" si vous vous faites soigner dans un hôpital.',
    INFOS_DOCTOR: 'Nom et lieu du médecin traitant',
  },
  announceAppointment: {
    ANNOUNCE: 'Signaler une urgence',
    urgencies: {
      URGENCY_URGENT: 'Traitement d\'urgence',
      URGENCY_NORMAL: 'Traitement',
    },
    doctorSpeciality: 'Spécialité du médecin',
    FROM: 'DU :',
    TO: 'AU :',
    EXTEND: 'Prolonger le délai de %{days} jours',
    EXTENSION_VALIDATED: 'Votre autorisation de rendez-vous a été prolongée.',
    EXTENSION_ADVICE: 'Si vous souhaitez prolonger cette autorisation de rendez-vous, veuillez contacter Medi24.',
    CONTACT: 'Contacter Medi24',
  },
  profile: {
    TITLE: 'Profil',
    SUBTITLE: 'Vos données personnelles',
    WARNING: 'Nous avons reçu les données suivantes de votre assureur pour créer votre dossier. Si ces données sont obsolètes, veuillez contacter votre assureur pour les mettre à jour.',
    NAME: 'Nom',
    DATE_OF_BIRTH: 'Date de naissance',
    ADDRESS: 'Adresse',
    LANGUAGE: 'Langue',
    CHANGE_LANGUAGE: 'Changer de langue',
    LANGUAGE_SUBTITLE: 'Notez que le changement de langue affectera l\'utilisation ultérieure du service et du contact.',
    languages: {
      ENGLISH: 'Anglais',
      FRENCH: 'Français',
      ITALIAN: 'Italien',
      DEUTSCH: 'Allemand',
    },
    languagesModal: {
      title: 'Changer de langue',
      subtitle: 'Veuillez sélectionner votre langue préférée.',
      warning: 'Notez que le changement de langue affectera l\'utilisation ultérieure du service et le contact',
      back: 'Retour',
      save: 'Enregistrer les modifications',
    },
    ADDITIONNAL_TITLE: 'Coordonnées supplémentaires',
    ADDITIONNAL: 'Ces coordonnées supplémentaires que vous partagez directement avec Medi24 nous aident à vous fournir le meilleur niveau de service possible. Elles ne seront partagées avec aucune autre partie.',
    EMAIL: 'Adresse e-mail',
    MOBILE: 'Numéro de téléphone portable',
    CHANGES_SAVED: 'Informations sauvegardées',
  },
  feedback: {
    SUB_TITLE: 'Vous avez bien réussi à vous déconnecter et vous pouvez fermer la fenêtre de navigateur.'
      + '\n Avant que vous quittiez nous apprécierions recevoir un feedback sur votre expérience comme utilisateur/utilisatrice ce qui nous offre la possibilité d\'améliorer notre service et de le modifier à vos besoins.'
      + '\n Pour utiliser à nouveau le service de MyMedi24, connectez-vous avec votre compte d\'utilisateur Helsana.'
      + '\n Merci beaucoup et au revoir.',
    DESCRIPTION: 'Retour d\'information (facultatif)',
    CONSENT: 'J\'accepte d\'être contacté par le service clientèle pour plus de détails.',
    SEND: 'Envoyer mon avis',
    SUCCESS: 'Avis envoyé',
    RATING_MANDATORY: 'Pour donner une évaluation, veuillez cliquer sur le nombre d\'étoiles souhaité (1=mauvais, 5=très bien).',
  },
  eventDetails: {
    callback: 'Rappel de Medi24',
  },
};

export default fr;
