import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ModalBase from '../ModalBase';
import ModalHotlineNumber from '../ModalHotlineNumber';
import servicePackageApi from '../../../network/api/servicePackageApi';
import { deviceIsMobile } from '../../../services/displayService';

type Props = {
  history: any
  className?: string,
  size?: string,
  isOpen: boolean,
  toggle: any,
  limit: number,
  referral?: boolean
};

type State = {
  isOpenHotline: boolean,
  hotlineNumber: string,
};

class ModalAnnounceLimit extends React.Component<Props, State> {
  state = {
    isOpenHotline: false,
    hotlineNumber: '',
  };

  componentDidMount() {
    servicePackageApi
      .fetchContactPhoneNumber()
      .then((response) => (response.text()))
      .then((hotlineNumber) => {
        this.setState({ hotlineNumber });
      });
  }

  onCallButtonClick = () => {
    const { props } = this;
    const { hotlineNumber } = this.state;
    props.toggle();
    if (deviceIsMobile()) {
      window.location.href = `tel:${hotlineNumber}`;
    } else {
      this.setState({
        isOpenHotline: true,
      });
    }
  };

  render() {
    const { props } = this;
    const { isOpenHotline, hotlineNumber } = this.state;
    return (
      <>
        <ModalBase
          {...props}
          title={I18n.t('modal.announceAmountExceeded.TITLE')}
          actionButton={I18n.t('modal.announceAmountExceeded.YES')}
          secondaryActionButton={I18n.t('modal.announceAmountExceeded.NO')}
          action={this.onCallButtonClick}
        >
          <div className="classic-text">
            {props.referral ? `${I18n.t('modal.announceAmountExceeded.CONTENT_REFERRAL', { limit: props.limit })}` : `${I18n.t('modal.announceAmountExceeded.CONTENT', { limit: props.limit })}`}
          </div>
        </ModalBase>
        <ModalHotlineNumber
          isOpen={isOpenHotline}
          toggle={() => this.setState({ isOpenHotline: !isOpenHotline })}
          number={hotlineNumber}
        />
      </>
    );
  }
}

export default withRouter(ModalAnnounceLimit);
