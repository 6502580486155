import RestClientAuthenticated from '../clients/restClientAuthenticated';
import { CallBackRequestCreation } from '../../types/appointmentType';

const callbackApi = {
  createCallBackRequest: (callBackRequest: CallBackRequestCreation) => new RestClientAuthenticated('/callback', 'POST').jsonBody(callBackRequest).execute(),
  getCallBackDetails: (callbackId : string) => new RestClientAuthenticated(`/callback/${callbackId}`, 'GET').execute(),
  deleteCallBackDetails: (callbackId : string) => new RestClientAuthenticated(`/callback/${callbackId}`, 'DELETE').execute(),
  hasUserUploadPictureWarning: () => new RestClientAuthenticated('/callback/upload-picture/warning', 'GET').execute(),
};

export default callbackApi;
