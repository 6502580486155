
const PAGE_CHANGE_EVENTS = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

export const browserStartUserActivityDetector = function(registerUserActivity: () => void) {
  for (const eventName of PAGE_CHANGE_EVENTS) {
    document.addEventListener(eventName, registerUserActivity, true);
  }
}

export const browserStopUserActivityDetector = function(registerUserActivity: () => void) {
  for (const eventName of PAGE_CHANGE_EVENTS) {
    document.removeEventListener(eventName, registerUserActivity, true);
  }
}

export class IdlenessDetectorConfiguration {
  constructor(
    /**
     * Calls registerUserActivity when user activity is detected
     */
    readonly startUserActivityDetector: (registerUserActivity: () => void) => void,
    /**
     * Unregister any service that may register user activity
     */
    readonly stopUserActivityDetector: (registerUserActivity: () => void) => void,
    readonly onIdlenessDetected: () => void
  ) {
  }
}

/**
 * Manages user idleness detection in the webpage.
 * The user activity monitoring can be customized, but default it checks mouse events, keyboards events and touch events.
 */
export class IdlenessDetector {
  private jsIntervalForUserActivity: ReturnType<typeof setInterval>;
  private lastActivityTimestampInMillis: number;
  private registerUserActivityFunction: () => void;

  constructor(readonly config: IdlenessDetectorConfiguration) {
  }

  /**
   * Start monitoring user activity and running actions in case of idleness
   */
  startService(inactiveDurationInMillis: number, idlenessDetectionCheckThreshold: number) {
    if (this.registerUserActivityFunction) {
      // do not start the service if it is already started
      return;
    }
    this.registerUserActivityFunction = () => this.registerUserActivity(inactiveDurationInMillis, idlenessDetectionCheckThreshold);
    this.lastActivityTimestampInMillis = Date.now();
    this.config.startUserActivityDetector(this.registerUserActivityFunction);
    this.startIdlenessDetection(inactiveDurationInMillis, idlenessDetectionCheckThreshold);
  }

  /**
   * Stop monitoring user activity and running actions in case of idleness
   */
  stopService() {
    this.config.stopUserActivityDetector(this.registerUserActivityFunction);
    this.stopIdlenessDetection();
    this.registerUserActivityFunction = null;
  }

  /**
   * Get the number of milliseconds since the user is idle
   */
  idleTimeInMillis() {
    return Date.now() - this.lastActivityTimestampInMillis;
  }

  /**
   * Indicate that a user activity has been detected.
   * The inactivity counter is reset.
   */
  private registerUserActivity(inactiveDurationInMillis: number, idlenessDetectionCheckThreshold: number) {
    this.lastActivityTimestampInMillis = Date.now();
    if (this.jsIntervalForUserActivity == null) {
      this.startIdlenessDetection(inactiveDurationInMillis, idlenessDetectionCheckThreshold);
    }
  }

  private startIdlenessDetection(inactiveDurationInMillis: number, idlenessDetectionCheckThreshold: number) {
    this.jsIntervalForUserActivity = setInterval(() => this.verifyUserIdleness(inactiveDurationInMillis), idlenessDetectionCheckThreshold);
  }

  private stopIdlenessDetection() {
    clearInterval(this.jsIntervalForUserActivity);
    this.jsIntervalForUserActivity = null;
  }

  /**
   * If the global idleness overcome the idleness threshold, then the onIdlenessDetected function is called
   */
  private verifyUserIdleness(inactiveDurationInMillis: number) {
    if (this.idleTimeInMillis() > inactiveDurationInMillis) {
      this.config.onIdlenessDetected();
      this.stopIdlenessDetection();
    }
  }

}
