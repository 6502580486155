import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Call } from 'iconsax-react';
import {
  analyticsParams,
  CALLBACK_REQUEST_PAGE_ROUTE,
} from '../../../../../const';
import { updateProcessData } from '../../../../../services/analyticsService';
import FeaturedTile from '../../../../featuredTiles/FeaturedTile';
import FeaturedTileType from '../../../../../types/FeaturedTileType';

type Props = {
  maxAfter?: number,
  senselyDescription?: any,
};

function CallbackRequestTile({ maxAfter, senselyDescription }: Props) {
  const history = useHistory();

  return (
    <FeaturedTile
      title={I18n.t('services.CALLBACK_REQUEST')}
      icon={<Call />}
      type={FeaturedTileType.SECONDARY}
      onClick={() => {
        updateProcessData({
          processName: analyticsParams.processName.CALLBACK_REQUEST,
          status: analyticsParams.status.STARTED,
          startTime: moment().toISOString(),
        });
        history.push(CALLBACK_REQUEST_PAGE_ROUTE, { maxAfter, senselyDescription });
      }}
    />
  );
}

export default withRouter(CallbackRequestTile);
