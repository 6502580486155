import { SessionServiceHolder } from './session/SessionServiceHolder';

const LANGUAGE_LOCAL_STORAGE = 'LANGUAGE';

const DEFAULT_LANG = 'en';

export type Language = {
  code: string,
  name: string,
};
const languagesAvailable: Language[] = [
  {
    code: 'fr',
    name: 'FRENCH',
  },
  {
    code: 'en',
    name: 'ENGLISH',
  },
  {
    code: 'de',
    name: 'DEUTSCH',
  },
  {
    code: 'it',
    name: 'ITALIAN',
  },
];

let localLanguage: Language = null;

export function browserLanguage() {
  return navigator.language
    ? navigator.language.substring(0, 2)
    : null;
}

function languageByCode(code: string) {
  const foundLanguage = languagesAvailable
    .filter((language) => language.code === code)[0];
  if (!foundLanguage) {
    console.log(`Language not found for ${code}, using ${languagesAvailable[0].code}`);
    return languagesAvailable[1];
  }
  return foundLanguage;
}

export function findLanguageCodeByName(name: string) {
  const foundLanguage = languagesAvailable
    .find((language) => language.name === name);

  return foundLanguage ?? languagesAvailable[1];
}

export function storeLang(lang: string) {
  localStorage.setItem(LANGUAGE_LOCAL_STORAGE, lang);
  localLanguage = languageByCode(lang);
}

export function sessionLanguage() : any {
  return SessionServiceHolder.getSessionService().currentSession()?.user.language;
}

export function languages() {
  return languagesAvailable;
}

export const reloadLanguage: () => Language = () => {
  localLanguage = languageByCode(localStorage.getItem(LANGUAGE_LOCAL_STORAGE)
        || sessionLanguage()
        || browserLanguage()
        || DEFAULT_LANG);
  return localLanguage;
};

export const currentLanguage: () => Language = () => {
  if (localLanguage) {
    return localLanguage;
  }

  return reloadLanguage();
};

export function errorPageLanguage() {
  if (browserLanguage()) {
    return browserLanguage();
  }
  return currentLanguage().code;
}

export function sessionPageLanguage() {
  if (sessionLanguage()) {
    return sessionLanguage();
  } if (browserLanguage()) {
    return browserLanguage();
  }
  return currentLanguage().code;
}
