let hidden;
let visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
  //@ts-ignore
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
  //@ts-ignore
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}
const isVisibilityChangeEventUnavailable = typeof document.addEventListener === "undefined" || hidden === undefined;

export enum BrowserPageActivity {
  Active,
  Inactive,
}

/**
 * Provide a way to be notified if the tab containing the JS app is in the foreground or not.
 * This works only if the "visibilitychange" browser API is present.
 */
export class BrowserPageActivityManager {
  private handleVisibilityChangeFunction;

  constructor(readonly onBrowserPageActivityChange: (pageActivityEvent: BrowserPageActivity) => void) {
  }

  startService() {
    if (this.handleVisibilityChangeFunction) {
      // do not start the service if it is already started
      return;
    }

    if (isVisibilityChangeEventUnavailable) {
      console.log("Visibility API is not implemented in browser, activity/inactivity detection may not work correctly");
    } else {
      this.handleVisibilityChangeFunction = () => this.handleVisibilityChange();
      document.addEventListener(visibilityChange, this.handleVisibilityChangeFunction, false);
    }
  }

  stopService() {
    if (!isVisibilityChangeEventUnavailable) {
      document.removeEventListener(visibilityChange, this.handleVisibilityChangeFunction, false);
    }
    this.handleVisibilityChangeFunction = null;
  }

  private handleVisibilityChange() {
    if (document[hidden]) {
      this.onBrowserPageActivityChange(BrowserPageActivity.Inactive);
    } else {
      this.onBrowserPageActivityChange(BrowserPageActivity.Active);
    }
  }

}
