import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { AppointmentReleaseType } from '../../pages/appointmentRelease/AppointmentReleaseType';
import { extractDuration, handleDaylightSavingTime } from '../../services/dateService/dateService';

type Props = {
  appointmentRelease: AppointmentReleaseType;
  isConsultation?: boolean,
};

function Attributes({ appointmentRelease, isConsultation }: Props) {
  const {
    doctorName,
    doctorSpeciality,
    isDummy,
    address,
    zipcode,
    urgency,
    startDate,
    endDate,
    extended,
  } = appointmentRelease;

  const {
    bodyTitle,
  } = extractDuration(
    startDate?.toString(),
    endDate?.toString(),
  );

  return (
    <div className="callback-page-data-container">
      <div className="callback-page-data-group">
        <h4 className="callback-data-label">{I18n.t('date.period.TIMEFRAME')}</h4>

        {(doctorName || doctorSpeciality) && (
          <h4 className="callback-data-label">
            {isDummy ? I18n.t('appointmentRelease.DETAIL.SPECIALTY') : I18n.t('appointmentRelease.DETAIL.SPECIALIST')}
          </h4>
        )}

        {(!doctorName && !doctorSpeciality) && (
          <h4 className="callback-data-label">
            {doctorSpeciality ? I18n.t('appointmentRelease.DETAIL.SPECIALTY') : I18n.t('appointmentRelease.DETAIL.SITUATION')}
          </h4>
        )}

        {extended && isConsultation && (
          <h4 className="callback-data-label">
            {I18n.t('tile.history.daysAdded', { days: '' })}
          </h4>
        )}
      </div>

      <div className="callback-page-data-group">
        <p className="callback-data-value">{bodyTitle}</p>

        {(doctorName || doctorSpeciality) && (
          <div className="callback-data-value-group">
            <p className="callback-data-value">
              {isDummy ? I18n.t(`doctorSpecialities.${doctorSpeciality}`) : doctorName}
            </p>

            {!isDummy && (
              <p className="callback-data-value">
                {`${address ?? ''} ${zipcode ?? ''}`}
              </p>
            )}
          </div>
        )}

        {(!doctorName && !doctorSpeciality) && (
          <p className="callback-data-value">
            {doctorSpeciality ? I18n.t(`doctorSpecialities.${doctorSpeciality}`) : I18n.t(`registration.urgencies.${urgency}`)}
          </p>
        )}

        {extended && isConsultation && (
          <p className="callback-data-value">
            {handleDaylightSavingTime(moment(endDate)).diff(handleDaylightSavingTime(moment(startDate)), 'days')}
          </p>
        )}
      </div>
    </div>
  );
}

export default Attributes;
